
import { Vue, Watch, Component, Prop } from 'vue-property-decorator';
import EpButton from '../EpButton/EpButton.vue';
import EpToggle from '../forms/EpToggle.vue';
import EpSearch from '../forms/EpSearch.vue';
import EpSpinner from '../EpSpinner/EpSpinner.vue';
import { KoodistoSelectStore } from './KoodistoSelectStore';
import EpMaterialIcon from '@shared/components/EpMaterialIcon/EpMaterialIcon.vue';
import _ from 'lodash';

@Component({
  components: {
    EpButton,
    EpSearch,
    EpSpinner,
    EpToggle,
    EpMaterialIcon,
  },
})
export default class EpKoodistoSelect extends Vue {
  @Prop({ default: null })
  private value!: any;

  @Prop({ required: true })
  private store!: KoodistoSelectStore;

  @Prop({ default: true })
  private isEditing!: boolean;

  @Prop({ required: false, default: false, type: Boolean })
  private multiple!: boolean;

  @Prop({ required: false, default: true, type: Boolean })
  private naytaArvo!: boolean;

  @Prop({ required: false, default: () => ['nimi', 'arvo', 'voimaantulo'] })
  private defaultFields!: string[];

  @Prop({ required: false })
  private additionalFields!: any[];

  private isLoading = false;
  private query = '';
  private vanhentuneet = false;
  private innerModel: any[] = [];

  get selectedUris() {
    return _.map(this.innerModel, 'uri');
  }

  get raw() {
    if (!this.store) {
      return null;
    }
    return this.store.data.value;
  }

  get items() {
    if (!this.raw) {
      return null;
    }

    return _(this.raw?.data)
      .map(x => {
        const nimi = _.mapValues(_.keyBy(x.metadata, v => _.toLower(v.kieli)), v => v.nimi);
        const kuvaus = _.mapValues(_.keyBy(x.metadata, v => _.toLower(v.kieli)), v => v.kuvaus);
        return {
          ...x,
          nimi,
          kuvaus,
          selected: _.includes(this.selectedUris, x.koodiUri),
        };
      })
      .value();
  }

  get sivu() {
    if (!this.raw) {
      return 1;
    }
    return this.raw.sivu + 1;
  }

  set sivu(value: number) {
    this.store.query(this.query, value - 1);
  }

  @Watch('query')
  async onQueryChange(newValue) {
    await this.initStoreQuery(newValue, this.sivu - 1, this.vanhentuneet);
  }

  @Watch('vanhentuneet')
  async onVanhentuneetChange(newValue) {
    await this.initStoreQuery(this.query, this.sivu - 1, newValue);
  }

  async initStoreQuery(query, sivu, vanhentuneet) {
    this.isLoading = true;
    await this.store.query(query, sivu, !vanhentuneet);
    this.isLoading = false;
  }

  openDialog() {
    (this.$refs.editModal as any).show();
    this.onQueryChange('');
  }

  get multiselect() {
    return _.isArray(this.value) || this.multiple;
  }

  onRowSelected(items) {
    if (!_.isEmpty(items)) {
      const row = {
        uri: items[0].koodiUri,
        arvo: items[0].koodiArvo,
        nimi: items[0].nimi,
        versio: items[0].versio,
        koodisto: items[0].koodisto?.koodistoUri || items[0].koodisto,
        ..._.pick(items[0], _.map(this.additionalFields, 'key')),
      };

      if (!this.multiselect) {
        this.$emit('input', row);
        this.$emit('add', row, this.value);
        (this.$refs.editModal as any).hide();
      }
      else {
        if (_.includes(this.selectedUris, row.uri)) {
          this.innerModel = _.filter(this.innerModel, koodi => koodi.uri !== row.uri);
        }
        else {
          this.innerModel = [
            ...this.innerModel,
            row,
          ];
        }
      }
    }
  }

  lisaaValitut() {
    if (this.multiselect) {
      this.$emit('input', this.innerModel);
      this.$emit('add', this.innerModel);
    }
  }

  alusta() {
    this.innerModel = [];
  }

  get fields() {
    return [
      ..._.filter([{
        key: 'nimi',
        label: this.$t('nimi'),
      }, {
        key: 'arvo',
        label: this.$t('arvo'),
        thStyle: { width: '6rem' },
      }, {
        key: 'voimaantulo',
        label: this.$t('voimaantulo'),
        thStyle: { width: '10rem' },
      }], field => _.includes(this.defaultFields, field.key)),
      ...(this.additionalFields ? this.additionalFields : []),
    ];
  }

  get koodisto() {
    return this.store.koodisto.value;
  }
}
