
import * as _ from 'lodash';
import { Component, Vue, Watch } from 'vue-property-decorator';
import EpHeader from '@/components/EpHeader/EpHeader.vue';
import EpToggle from '@shared/components/forms/EpToggle.vue';
import EpSearch from '@shared/components/forms/EpSearch.vue';
import EpMultiSelect from '@shared/components/forms/EpMultiSelect.vue';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import { MaaraysDtoTyyppiEnum } from '@shared/api/eperusteet';
import { Meta } from '@shared/utils/decorators';
import EpVoimassaoloFilter from '@shared/components/EpVoimassaoloFilter/EpVoimassaoloFilter.vue';
import { MaarayksetStore } from '@shared/stores/MaarayksetStore';
import { Debounced } from '@shared/utils/delay';
import maaraysDocSmall from '@assets/img/images/maarays_doc_small.svg';
import EpVoimassaolo from '@shared/components/EpVoimassaolo/EpVoimassaolo.vue';
import { Kielet } from '@shared/stores/kieli';
import EpButton from '@shared/components/EpButton/EpButton.vue';
import EpMaterialIcon from '@shared/components//EpMaterialIcon/EpMaterialIcon.vue';
import EpMaarayskokoelmaKoulutustyyppiSelect from '@shared/components/EpMaarayskokoelmaKoulutustyyppiSelect/EpMaarayskokoelmaKoulutustyyppiSelect.vue';
import EpBPagination from '@shared/components/EpBPagination/EpBPagination.vue';

@Component({
  components: {
    EpHeader,
    EpToggle,
    EpSearch,
    EpMultiSelect,
    EpBPagination,
    EpSpinner,
    EpVoimassaoloFilter,
    EpVoimassaolo,
    EpButton,
    EpMaterialIcon,
    EpMaarayskokoelmaKoulutustyyppiSelect,
  },
})
export default class RouteMaarayskokoelma extends Vue {
  private maarayksetStore: MaarayksetStore | null = null;

  private perPage = 10;
  private sivu = 1;
  private query = {
    nimi: '',
    sivukoko: 10,
    julkaistu: true,
    laadinta: false,
    jarjestysTapa: 'voimassaoloAlkaa',
    jarjestys: 'DESC',
    koulutustyypit: [],
    tyyppi: null,
    tuleva: true,
    voimassaolo: true,
  } as any;

  async mounted() {
    if (this.$route.query.tyyppi) {
      this.query.tyyppi = this.$route.query.tyyppi;
    }

    this.maarayksetStore = new MaarayksetStore();
    await this.maarayksetStore.init();
    await this.fetch();
  }

  @Watch('sivu')
  async sivuChange() {
    await this.fetch();
  }

  @Watch('query', { deep: true })
  async queryChange() {
    if (this.maarayksetStore) {
      this.sivu = 1;
      await this.fetch();
    }
  }

  @Debounced(300)
  async fetch() {
    await this.maarayksetStore?.fetch(
      {
        ...this.query,
        tyyppi: this.query.tyyppi === 'kaikki' ? null : this.query.tyyppi,
        kieli: this.kieli,
        sivu: this.sivu - 1,
      });
  }

  get maaraykset() {
    return this.maarayksetStore?.maaraykset.value?.data;
  }

  get maarayksetCount() {
    return this.maarayksetStore?.maaraykset.value?.kokonaismäärä;
  }

  @Meta
  getMetaInfo() {
    return {
      title: this.$t('route-maarayskokoelma'),
    };
  }

  get tyyppiVaihtoehdot() {
    return [
      'kaikki',
      MaaraysDtoTyyppiEnum.OPETUSHALLITUKSENMUU,
      MaaraysDtoTyyppiEnum.AMMATILLINENMUU,
      MaaraysDtoTyyppiEnum.PERUSTE,
    ];
  }

  get voimasssaVaihtoehdot() {
    return [
      'KAIKKI',
      'TULEVA',
      'VOIMASSAOLO',
      'POISTUNUT',
    ];
  }

  get koulutustyyppiVaihtoehdot() {
    return this.maarayksetStore?.koulutustyypit.value;
  }

  get kuva() {
    return maaraysDocSmall;
  }

  get kieli() {
    return Kielet.getSisaltoKieli.value;
  }

  get murupolku() {
    return [
      {
        label: 'route-maarayskokoelma',
        location: { name: 'maaraykset' },
      },
    ];
  }

  vaihdaJarjestys() {
    this.query.jarjestys = this.query.jarjestys === 'DESC' ? 'ASC' : 'DESC';
  }

  searchIdentity(kt: string) {
    return _.toLower(this.$t(kt) as any);
  }
}
