
import { Vue, Component, Prop } from 'vue-property-decorator';
import { koulutustyyppiThemeColor, rgb2string } from '@shared/utils/perusteet';
import osaamismerkkiLogoFI from '@assets/img/images/osaamismerkki_main_FI.svg';

@Component({
  components: {},
})
export default class KoulutustyyppiTile extends Vue {
  @Prop({ required: true })
  private tyyppi!: any;

  get rgbColor() {
    return rgb2string(koulutustyyppiThemeColor(this.tyyppi.route.params?.koulutustyyppi));
  }

  get name() {
    return this.$t(this.tyyppi.name);
  }

  get icon() {
    if (this.tyyppi.name === 'kansalliset-perustaitojen-osaamismerkit') {
      return null;
    }
    else if (this.tyyppi.name === 'opetushallituksen-maaraykset') {
      return 'gavel';
    }
    return 'account_balance';
  }

  get osaamimerkkiLogo() {
    return osaamismerkkiLogoFI;
  }
}
