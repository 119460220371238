
import { Mixins, Prop, Component } from 'vue-property-decorator';
import EpButton from '@shared/components/EpButton/EpButton.vue';
import EpField from '@shared/components/forms/EpField.vue';
import EpFormContent from '@shared/components/forms/EpFormContent.vue';
import EpInput from '@shared/components/forms/EpInput.vue';
import EpToggle from '@shared/components/forms/EpToggle.vue';
import EpValidation from '@shared/mixins/EpValidation';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import { kasiteValidator } from '@shared/validators/kasite';
import VueSelect from 'vue-select';
import { IKasiteHandler, ITermi } from './KasiteHandler';
import _ from 'lodash';

@Component({
  components: {
    EpButton,
    EpField,
    EpFormContent,
    EpInput,
    EpSpinner,
    EpToggle,
    VueSelect,
  },
})
export default class TermitEditor extends Mixins(EpValidation) {
  @Prop({ required: true })
  private value!: string | null;

  @Prop({ required: true })
  private handler!: IKasiteHandler;

  private kasitteet: ITermi[] = [];

  private isLoading = false;
  private isEditing = false;
  private valittu: ITermi | null = null;
  private muokattava: ITermi = {};

  get validationConfig() {
    return {
      muokattava: {
        ...kasiteValidator(),
      },
    };
  }

  private filterBy(option, label, search) {
    const k = (this as any).$kaanna;
    const v = k(option.termi) + ' ' + k(option.selitys);
    return (v)
      .toLowerCase()
      .indexOf(search.toLowerCase()) > -1;
  }

  async mounted() {
    try {
      this.isLoading = true;
      this.kasitteet = await this.handler.getAll();
      if (this.value) {
        this.valittu = _.find(this.kasitteet, (k) => k.avain === this.value) || null;
      }
    }
    catch (err) {
      throw err;
    }
    finally {
      this.isLoading = false;
    }
  }

  async peruuta() {
    this.isEditing = false;
  }

  async tallenna() {
    try {
      this.isLoading = true;
      const uusi = await this.handler.addOrUpdate(this.muokattava);
      if (!this.muokattava.avain) {
        this.kasitteet.unshift(uusi);
      }
    }
    finally {
      this.isLoading = false;
      this.isEditing = false;
    }
  }

  muokkaa(valittu) {
    if (valittu) {
      this.muokattava = valittu;
    }
    else {
      this.muokattava = {
        alaviite: false,
      };
    }
    this.isEditing = true;
  }

  onSelect(valittu) {
    this.valittu = valittu;
    if (this.valittu && this.valittu.avain) {
      this.$emit('input', this.valittu.avain);
    }
  }

  get alaviiteSupported() {
    return _.has(this.muokattava, 'alaviite');
  }
}
