
import _ from 'lodash';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Kielet } from '@shared/stores/kieli';
import EpHeader from '@/components/EpHeader/EpHeader.vue';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import EpSearch from '@shared/components/forms/EpSearch.vue';
import OpetussuunnitelmaTile from './OpetussuunnitelmaTile.vue';
import EpMultiSelect from '@shared/components/forms/EpMultiSelect.vue';
import { Koulutustyyppi } from '@shared/tyypit';
import { VapaasivistystyoPaikallisetStore } from '@/stores/VapaasivistystyoPaikallisetStore';
import { Ulkopuoliset } from '@shared/api/eperusteet';
import { KoodistoSelectStore } from '@shared/components/EpKoodistoSelect/KoodistoSelectStore';
import { voimassaoloTieto } from '@/utils/voimassaolo';
import EpBPagination from '@shared/components/EpBPagination/EpBPagination.vue';
import { PerusteKoosteStore } from '@/stores/PerusteKoosteStore';
import { isVstLukutaito } from '@shared/utils/perusteet';
import EpVoimassaoloFilter from '@shared/components/EpVoimassaoloFilter/EpVoimassaoloFilter.vue';
import { JulkinenApi } from '@shared/api/amosaa';

@Component({
  components: {
    EpHeader,
    EpSearch,
    EpSpinner,
    OpetussuunnitelmaTile,
    EpMultiSelect,
    EpBPagination,
    EpVoimassaoloFilter,
  },
})
export default class VstPaikalliset extends Vue {
  @Prop({ required: true })
  private paikallinenStore!: VapaasivistystyoPaikallisetStore;

  @Prop({ required: true })
  private perusteKoosteStore!: PerusteKoosteStore;

  private valittuPeruste = {};
  private perPage = 10;
  private query = this.initQuery();

  async fetch() {
    await this.paikallinenStore.fetchQuery(this.query);
  }

  @Watch('julkaistutPerusteet', { immediate: true })
  async perusteetChange() {
    if (_.size(this.perusteKoosteStore.perusteJulkaisut) > 0) {
      if (_.get(this.valittuPeruste, 'id')) {
        const peruste = _.find(this.julkaistutPerusteet, peruste => _.get(peruste, 'id') === _.toNumber(_.get(this.$route.params, 'perusteId'))) || this.julkaistutPerusteet![0];
        await this.setActivePeruste(peruste);
      }
      else {
        await this.setActivePeruste(null);
      }
    }
  }

  get perusteetOptions() {
    if (this.julkaistutPerusteet) {
      return [
        {
          nimi: null,
        },
        ...this.julkaistutPerusteet,
      ];
    }
    return [];
  }

  async setActivePeruste(perusteJulkaisu) {
    if (perusteJulkaisu?.id) {
      this.query.perusteId = _.toNumber(perusteJulkaisu.id);
      this.query.perusteenDiaarinumero = perusteJulkaisu.diaarinumero;
    }
    else {
      this.query = this.initQuery();
    }
    await this.fetch();
  }

  get julkaistutPerusteet() {
    if (this.perusteKoosteStore.perusteJulkaisut) {
      return _.chain(this.perusteKoosteStore.perusteJulkaisut.value)
        .filter(julkaistuPeruste => !isVstLukutaito(julkaistuPeruste.koulutustyyppi))
        .map(julkaistuPeruste => ({
          ...julkaistuPeruste,
          kaannettyNimi: this.$kaanna(julkaistuPeruste.nimi!),
        }))
        .orderBy(['voimassaoloAlkaa', 'kaannettyNimi'], ['desc', 'asc'])
        .value();
    }
  }

  get kieli() {
    return Kielet.getSisaltoKieli.value;
  }

  get page() {
    return this.opetussuunnitelmatPaged?.sivu! + 1;
  }

  set page(page) {
    this.query = {
      ...this.query,
      sivu: page - 1,
    };
  }

  @Watch('kieli')
  kieliChange(val) {
    this.query = {
      ...this.query,
      kieli: val,
    };
  }

  get queryNimi() {
    return this.query.nimi;
  }

  @Watch('queryNimi')
  nimiChange() {
    this.query.sivu = 0;
  }

  @Watch('query', { deep: true })
  async queryChange() {
    if (this.query.oppilaitosTyyppiKoodiUri === 'kaikki') {
      this.query.oppilaitosTyyppiKoodiUri = null;
    }
    await this.fetch();
  }

  get total() {
    return this.opetussuunnitelmatPaged?.kokonaismäärä;
  }

  get opetussuunnitelmat() {
    return this.paikallinenStore.opetussuunnitelmat.value;
  }

  get opetussuunnitelmatPaged() {
    return this.paikallinenStore.opetussuunnitelmatPaged.value;
  }

  get opetussuunnitelmatMapped() {
    return _.chain(this.opetussuunnitelmat)
      .map(ops => ({
        ...ops,
        toimijat: _.filter(ops.organisaatiot, org => _.includes(org.tyypit, 'Koulutustoimija')),
        oppilaitokset: _.filter(ops.organisaatiot, org => _.includes(org.tyypit, 'Oppilaitos')),
        route: {
          name: 'toteutussuunnitelma',
          params: {
            toteutussuunnitelmaId: _.toString(ops.id),
            koulutustyyppi: 'vapaasivistystyo',
          },
        },
        voimassaoloTieto: voimassaoloTieto(ops),
      }))
      .sortBy(ops => Kielet.sortValue(ops.nimi))
      .value();
  }

  kaannaPerusteNimi(option) {
    if (option.nimi) {
      return this.$kaanna(option.nimi);
    }
    return this.$t('kaikki');
  }

  private initQuery() {
    return {
      perusteenDiaarinumero: null,
      perusteId: 0,
      koulutustyyppi: [
        Koulutustyyppi.vapaasivistystyo,
        Koulutustyyppi.vapaasivistystyolukutaito,
      ],
      oppilaitosTyyppiKoodiUri: null,
      nimi: null,
      sivu: 0,
      sivukoko: 10,
      kieli: this.kieli,
      tuleva: true,
      voimassaolo: true,
      poistunut: false,
      jotpatyyppi: [
        'NULL',
        'VST',
      ],
    };
  }
}
