
import { Vue, Component, Prop } from 'vue-property-decorator';
import { isVstLukutaito, koulutustyyppiThemeColor, rgb2string } from '@shared/utils/perusteet';
import EpVoimassaolo from '@shared/components/EpVoimassaolo/EpVoimassaolo.vue';
import EpMaterialIcon from '@shared/components/EpMaterialIcon/EpMaterialIcon.vue';

interface PerusteJulkiData {
  nimi:{ [key: string]: string; };
  voimassaoloAlkaa?: number,
  voimassaoloLoppuu?: number,
  laajuus?: number,
  koulutustyyppi?: string,
}

@Component({
  components: {
    EpMaterialIcon,
    EpVoimassaolo,
  },
})
export default class PerusteTile extends Vue {
  @Prop({ required: true })
  private julkaisu!: PerusteJulkiData;

  @Prop({ required: false, type: String })
  private koulutustyyppi!: string;

  get rgbColor() {
    return rgb2string(koulutustyyppiThemeColor(this.koulutustyyppi));
  }

  get isHallitus() {
    return !isVstLukutaito(this.julkaisu.koulutustyyppi!);
  }
}
