
import { Component, Prop, Mixins } from 'vue-property-decorator';
import _ from 'lodash';
import DatePicker from 'vue2-datepicker';
import { Kielet } from '../../stores/kieli';
import EpFormContent from './EpFormContent.vue';
import EpValidation from '../../mixins/EpValidation';

@Component({
  components: {
    DatePicker,
    EpFormContent,
  },
})
export default class EpDatepicker extends Mixins(EpValidation) {
  @Prop({ required: true })
  private value!: any;

  @Prop({
    default: 'sd',
    validator(value) {
      return _.includes(['date', 'datetime', 'sd'], value);
    },
  })
  private type!: string;

  @Prop({ default: false, type: Boolean })
  private isEditing!: boolean;

  @Prop({ default: '', type: String })
  private help!: string;

  @Prop({ default: true, required: false })
  private showValidValidation!: boolean;

  @Prop({ default: false, type: Boolean })
  private endOfDay!: boolean;

  get modelValue() {
    if (_.isNumber(this.value)) {
      return new Date(this.value);
    }

    return this.value;
  }

  get state() {
    if (!this.showValidValidation || this.isValid) {
      return null;
    }

    return this.isValid;
  }

  get locdate() {
    if (!this.value) {
      return this.$t('ei-asetettu');
    }
    else if (this.type === 'datetime') {
      return (this as any).$ldt(this.value);
    }
    else if (this.type === 'sd') {
      return (this as any).$sd(this.value);
    }
    else {
      return (this as any).$ld(this.value);
    }
  }

  get lang() {
    return Kielet.getAikakaannokset;
  }

  get locale() {
    return Kielet.getUiKieli.value;
  }

  private onInput(event: any) {
    if (event && this.endOfDay) {
      event.setHours(23);
      event.setMinutes(59);
      event.setSeconds(59);
    }
    this.$emit('input', event);
    if (this.validation) {
      (this.validation as any).$touch();
    }
  }
}
