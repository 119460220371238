
import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import EpPagination from '../EpPagination/EpPagination.vue';
import EpSpinner from '../EpSpinner/EpSpinner.vue';
import { Ammattitaitovaatimukset } from '../../api/eperusteet';
import EpMaterialIcon from '@shared/components/EpMaterialIcon/EpMaterialIcon.vue';

@Component({
  components: {
    EpPagination,
    EpSpinner,
    EpMaterialIcon,
  },
})
export default class Kayttolistaus extends Vue {
  @Prop({ required: true })
  private koodi!: any;

  private isLoading = true;
  private data: any = null;
  private page = 1;
  private perPage = 8;

  get id() {
    return _.uniqueId('koodidialogi_');
  }

  async fetch(page = this.page) {
    const res = await Ammattitaitovaatimukset.getTutkinnonOsatByAmmattitaitovaatimus(
      page - 1,
      this.perPage,
      this.koodi.uri,
      true);
    this.page = page;
    this.data = res.data;
  }

  async shown() {
    this.isLoading = true;
    this.data = null;
    try {
      if (this.koodi?.uri) {
        await this.fetch();
      }
    }
    finally {
      this.isLoading = false;
    }
  }

  get triggers() {
    return 'hover click blur';
  }
}
