
import { Component, Prop, Vue } from 'vue-property-decorator';
import EpButton from '../EpButton/EpButton.vue';
import EpMaterialIcon from '@shared/components/EpMaterialIcon/EpMaterialIcon.vue';
import _ from 'lodash';

@Component({
  components: {
    EpButton,
    EpMaterialIcon,
  },
})
export default class EpPagination extends Vue {
  @Prop({ required: true })
  private value!: number;

  @Prop({ required: true })
  private perPage!: number;

  @Prop({ required: true })
  private totalRows!: number;

  get leftDisabled() {
    return this.value < 2;
  }

  get rightDisabled() {
    return this.value > this.count - 1;
  }

  get leftEllipsis() {
    return this.value - 1 > 1;
  }

  get rightEllipsis() {
    return this.value < this.count - 1;
  }

  get count() {
    return Math.ceil(this.totalRows / this.perPage);
  }

  get pages() {
    if (this.count < 6) {
      return _.range(1, this.count + 1);
    }
    else {
      const result: (number | null)[] = [];
      if (!this.leftDisabled) {
        result.push(1);
      }
      if (this.leftEllipsis) {
        if (this.value > 3) {
          result.push(null);
        }
        result.push(this.value - 1);
      }
      result.push(this.value);
      if (this.rightEllipsis) {
        result.push(this.value + 1);
        if (this.value < this.count - 2) {
          result.push(null);
        }
      }
      if (this.value !== this.count) {
        result.push(this.count);
      }
      return result;
    }
  }

  async setValue(value: number) {
    if (value > 0 && value <= this.count) {
      this.$emit('input', value);
    }
  }
}
