
import _ from 'lodash';
import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';

import EpSpinner from '../EpSpinner/EpSpinner.vue';
import EpValidation from '../../mixins/EpValidation';

@Component({
  components: {
    EpSpinner,
  },
})
export default class EpSelect extends Mixins(EpValidation) {
  @Prop({
    default: false,
    type: Boolean,
  })
  private isEditing!: boolean;

  @Prop({ required: true })
  private items!: any[];

  @Prop({
    required: true,
  })
  private value!: any | any[];

  @Prop({ default: false, type: Boolean })
  private useCheckboxes!: boolean;

  @Prop({ default: false, type: Boolean })
  private multiple!: boolean;

  @Prop({ default: true, type: Boolean })
  private enableEmptyOption!: boolean;

  @Prop({ default: '', type: String })
  private help!: string;

  @Prop({ default: '', type: String })
  private placeholder!: string;

  @Prop({ default: false, type: Boolean })
  private disabled!: boolean;

  @Prop({ default: false, type: Boolean })
  private emptyOptionDisabled!: boolean;

  get displayValue() {
    return _.filter(this.items, (item) => _.includes(this.value, item));
  }

  set innerModel(innerModel) {
    if (_.isArray(innerModel)) {
      if (!_.isEqual(innerModel, this.innerModel)) {
        this.$emit('input', [...innerModel]);
      }
    }
    else {
      if (!_.isEqual(innerModel, this.innerModel)) {
        this.$emit('input', innerModel);
      }
    }

    if (this.validation?.$touch) {
      this.validation?.$touch();
    }
  }

  get innerModel() {
    if (this.value) {
      return this.value;
    }
    else if (this.multiple) {
      return [];
    }
    else {
      return null;
    }
  }

  @Watch('items', { immediate: true })
  itemsChange() {
    if (!this.enableEmptyOption && _.size(this.items) > 0 && this.value === null && !this.multiple) {
      this.innerModel = _.first(this.items);
    }
  }
}
