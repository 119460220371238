
import _ from 'lodash';
import { Prop, Vue, Component } from 'vue-property-decorator';
import { PerusteDataStore } from '@/stores/PerusteDataStore';
import EpFormContent from '@shared/components/forms/EpFormContent.vue';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import EpJulkaisuHistoriaJulkinen from '@shared/components/EpJulkaisuHistoriaJulkinen/EpJulkaisuHistoriaJulkinen.vue';

@Component({
  components: {
    EpJulkaisuHistoriaJulkinen,
    EpFormContent,
    EpSpinner,
  },
})
export default class RoutePerusteMuutoshistoria extends Vue {
  @Prop({ required: true })
  private perusteDataStore!: PerusteDataStore;

  get julkaisut() {
    return this.perusteDataStore.julkaisut;
  }

  palaaTietoihin() {
    this.$router.replace({ name: 'perusteTiedot' }).catch(() => {});
  }
}
