
import _ from 'lodash';
import { Prop, Vue, Component, Watch } from 'vue-property-decorator';
import { baseURL, LiiteDtoTyyppiEnum, LiitetiedostotParam, PerusteKaikkiDtoTyyppiEnum } from '@shared/api/eperusteet';
import { isKoulutustyyppiAmmatillinen, isKoulutustyyppiPdfTuettuOpintopolku } from '@shared/utils/perusteet';
import { Kielet, UiKielet } from '@shared/stores/kieli';
import { PerusteDataStore } from '@/stores/PerusteDataStore';
import EpFormContent from '@shared/components/forms/EpFormContent.vue';
import EpField from '@shared/components/forms/EpField.vue';
import EpDatepicker from '@shared/components/forms/EpDatepicker.vue';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import EpContentViewer from '@shared/components/EpContentViewer/EpContentViewer.vue';
import EpExternalLink from '@shared/components/EpExternalLink/EpExternalLink.vue';

@Component({
  components: {
    EpFormContent,
    EpField,
    EpDatepicker,
    EpSpinner,
    EpContentViewer,
    EpExternalLink,
  },
})
export default class RoutePerusteTiedot extends Vue {
  @Prop({ required: true })
  private perusteDataStore!: PerusteDataStore;

  get kieli() {
    return Kielet.getSisaltoKieli.value;
  }

  @Watch('kieli')
  async onKieliChange() {
    await this.perusteDataStore.getDokumentti();
  }

  get isAmmatillinen() {
    return this.peruste && isKoulutustyyppiAmmatillinen(this.peruste.koulutustyyppi!);
  }

  get isOpas() {
    return this.peruste?.tyyppi === _.toLower(PerusteKaikkiDtoTyyppiEnum.OPAS);
  }

  get korvaavatPerusteet() {
    const perusteetByDiaarinumero = _.groupBy(this.perusteDataStore.korvaavatPerusteet, 'diaarinumero');
    return _.map(this.peruste?.korvattavatDiaarinumerot, diaarinumero => ({
      diaarinumero,
      perusteet: perusteetByDiaarinumero[diaarinumero] || [],
    }));
  }

  get peruste() {
    return this.perusteDataStore.peruste;
  }

  get kvliitteita() {
    return _.some(UiKielet, uiKieli => {
      return this.perusteDataStore.kvLiitteet[uiKieli];
    });
  }

  get kvliitteet() {
    return this.perusteDataStore.kvLiitteet;
  }

  get kuvat() {
    return this.perusteDataStore.kuvat;
  }

  get termit() {
    return this.perusteDataStore.termit;
  }

  get julkaisut() {
    return this.perusteDataStore.julkaisut;
  }

  get liitteet() {
    return _.map(this.perusteDataStore.liitteet, kvo => (
      {
        ...kvo,
        url: baseURL + LiitetiedostotParam.getLiite(this.peruste!.id!, kvo.id!).url,
      }
    ));
  }

  get koulutusvienninOhjeet() {
    return _.filter(this.liitteet, liite => liite.tyyppi === _.toLower(LiiteDtoTyyppiEnum.KOULUTUSVIENNINOHJE));
  }

  get kaannokset() {
    return _.filter(this.liitteet, liite => liite.tyyppi === _.toLower(LiiteDtoTyyppiEnum.KAANNOS));
  }

  get osaamisalaKuvaukset() {
    return _.chain((this.peruste?.suoritustavat as any[]))
      .map(suoritustapa => this.perusteDataStore.osaamisalaKuvaukset[suoritustapa.suoritustapakoodi!])
      .map(suoritustavanOsaamisalakuvaukset => _.values(suoritustavanOsaamisalakuvaukset))
      .flatMap()
      .flatMap()
      .value();
  }

  get hasMaaraykset() {
    return !_.isEmpty(this.maaraykset);
  }

  get maaraykset() {
    return _.sortBy([
      ...this.maarayskokoelmanMuutosmaaraykset,
      ...this.perusteenMuutosmaaraykset,
      ...(this.perusteDataStore?.maarays ? [this.perusteenMaarays] : []),
      ...(this.julkaisujenMuutosMaaraykset),
    ], (maarays: any) => maarays.voimassaoloAlkaa || 0).reverse();
  }

  get perusteenMaarays() {
    return {
      ...this.perusteDataStore.maarays,
      voimassaoloAlkaa: _.size(this.perusteDataStore?.maarays?.korvattavatMaaraykset) > 0 || _.size(this.perusteDataStore.maarays?.muutettavatMaaraykset) > 0 ? this.perusteDataStore.maarays?.voimassaoloAlkaa : null,
    };
  }

  get maarayskokoelmanMuutosmaaraykset() {
    return _.chain(this.perusteDataStore.muutosmaaraykset)
      .sortBy('voimassaoloAlkaa')
      .reverse()
      .value();
  }

  get perusteenMuutosmaaraykset() {
    return _.chain(this.peruste?.muutosmaaraykset)
      .filter(muutosmaarays => _.has(muutosmaarays.liitteet, this.kieli))
      .map(muutosmaarays => {
        return {
          ...muutosmaarays,
          url: baseURL + LiitetiedostotParam.getLiite(this.peruste!.id!, muutosmaarays.liitteet![this.kieli].id!).url,
          nimi: !!muutosmaarays.nimi && muutosmaarays.nimi[this.kieli] ? muutosmaarays.nimi[this.kieli] : muutosmaarays.liitteet![this.kieli].nimi,
        };
      })
      .value();
  }

  get julkaisujenMuutosMaaraykset() {
    return _.chain(this.julkaisut)
      .filter(julkaisu => (julkaisu.liitteet || []).length > 0)
      .map(julkaisu => {
        return _.map(julkaisu.liitteet, liite => {
          return {
            voimassaoloAlkaa: julkaisu.muutosmaaraysVoimaan,
            ...liite,
            url: baseURL + LiitetiedostotParam.getJulkaisuLiite(this.peruste!.id!, liite.liite!.id!).url,
          };
        });
      })
      .flatMap()
      .filter(liite => liite.kieli === this.kieli)
      .value();
  }

  get hasKorvattavatDiaarinumerot() {
    return !_.isEmpty(this.korvaavatPerusteet);
  }

  get korvattavatDiaarinumerotFields() {
    return [{
      key: 'diaarinumero',
      thStyle: 'width: 30%',
      label: this.$t('diaarinumero'),
    }, {
      key: 'perusteet',
      label: this.$t('perusteet'),
    }];
  }

  get dokumentti() {
    if (this.isOpas || isKoulutustyyppiPdfTuettuOpintopolku(this.peruste?.koulutustyyppi)) {
      return this.perusteDataStore.dokumentti;
    }
  }

  get dokumenttiKielistykset() {
    return {
      'opas': {
        'otsikko': 'opas-pdfna',
        'linkki': 'avaa-opas-pdfna',
      },
      'normaali': {
        'otsikko': 'peruste-pdfna',
        'linkki': 'avaa-peruste-pdfna',
      },
    }[this.peruste?.tyyppi || 'normaali'];
  }

  get koulutuskooditFields() {
    return [{
      key: 'koulutuskoodiArvo',
      label: this.$t('koodi'),
      thStyle: 'width: 15%',
    }, {
      key: 'nimi',
      label: this.$t('koulutuksen-nimi'),
      formatter: (value: any, key: string, item: any) => {
        return this.$kaanna(value);
      },
    }];
  }

  get koulutusvienninohjeFields() {
    return [{
      key: 'nimi',
      label: this.$t('tiedosto'),
    }, {
      key: 'lisatieto',
      label: this.$t('diaarinumero'),
      thStyle: 'width: 30%',
    }];
  }

  get osaamisalatFields() {
    return [{
      key: 'nimi',
      label: this.$t('nimi'),
      thStyle: 'width: 75%',
      formatter: (value: any, key: string, item: any) => {
        return this.$kaanna(value);
      },
    }, {
      key: 'arvo',
      label: this.$t('koodi'),
      thStyle: 'width: 15%',
    }];
  }

  get tutkintonimikkeetFields() {
    return [{
      key: 'nimi',
      label: this.$t('nimi'),
      thStyle: 'width: 75%',
      formatter: (value: any, key: string, item: any) => {
        return this.$kaanna(value);
      },
    }, {
      key: 'tutkintonimikeArvo',
      label: this.$t('koodi'),
      thStyle: 'width: 15%',
    }];
  }

  get isEiTarvitaOhjettaTyyppi() {
    return this.peruste?.poikkeamismaaraysTyyppi?.valueOf() === 'ei_tarvita_ohjetta';
  }

  get isEiVoiPoiketaTyyppi() {
    return this.peruste?.poikkeamismaaraysTyyppi?.valueOf() === 'ei_voi_poiketa';
  }

  get isKoulutusvientiliiteTyyppi() {
    return this.peruste?.poikkeamismaaraysTyyppi?.valueOf() === 'koulutusvientiliite';
  }

  get showKoulutusvienninOhje() {
    return this.isEiTarvitaOhjettaTyyppi || this.isEiVoiPoiketaTyyppi || (this.isKoulutusvientiliiteTyyppi && this.koulutusvienninOhjeet && this.koulutusvienninOhjeet.length > 0);
  }
}
