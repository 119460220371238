
import _ from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { PerusteDataStore } from '@/stores/PerusteDataStore';
import EpSearch from '@shared/components/forms/EpSearch.vue';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import { deepFind, typeSort } from '@/utils/sisaltohaku';
import EpBPagination from '@shared/components/EpBPagination/EpBPagination.vue';

@Component({
  components: {
    EpSearch,
    EpSpinner,
    EpBPagination,
  },
  watch: {
    query: {
      handler: 'queryImplDebounce',
      immediate: true,
    },
  },
})
export default class EpPerusteHaku extends Vue {
  @Prop({ required: true })
  private perusteDataStore!: PerusteDataStore;

  @Prop({ required: true })
  private query!: string;

  private queryImplDebounce = _.debounce(this.queryImpl, 300);
  private tulokset: any[] | null = null;
  private sivu = 1;
  private sivukoko = 10;

  async queryImpl(query) {
    if (query.length > 2) {
      this.tulokset = null;
      const julkaisu = await this.perusteDataStore.peruste;
      const result: any[] = [];
      deepFind(julkaisu, [], result, _.toLower(query));
      this.tulokset = result;
    }
  }

  clear() {
    this.query = '';
  }

  get tuloksetSorted() {
    return _.chain(this.tulokset)
      .map(tulos => {
        var numerointi = _.find(this.perusteDataStore.flattenedSidenav, { location: tulos.location })?.meta?.numerointi;
        return {
          ...tulos,
          numerointi,
          nimi: (numerointi || '') + ' '
                + (this.$kaanna(tulos.target.perusteenOsa.nimi) || this.$t(tulos.osanTyyppi))
                + (tulos.target.perusteenOsa?.meta?.nimi ? ', ' + this.$t(tulos.target.perusteenOsa.meta?.nimi) : ''),
        };
      })
      .sortBy(tulos => tulos.target.perusteenOsa?.meta?.nimi || 99)
      .sortBy(tulos => typeSort[tulos.type])
      .slice((this.sivu - 1) * this.sivukoko, this.sivu * this.sivukoko)
      .value();
  }
}
