
import { Component, Vue, Prop } from 'vue-property-decorator';
import EpFormContent from '@shared/components/forms/EpFormContent.vue';
import * as _ from 'lodash';

@Component({
  components: {
    EpFormContent,
  },
})
export default class GeneerinenArviointiTaulukko extends Vue {
  @Prop({ required: true })
  private arviointi: any;

  get osaamistasonKriteeritSorted() {
    return _.sortBy(this.arviointi.osaamistasonKriteerit, kriteeri => _.get(kriteeri, 'osaamistaso.koodi.arvo'));
  }

  get osaamistasonKriteeritFields() {
    return [{
      key: 'osaamistaso',
      label: this.$t('osaamistaso') as string,
      thStyle: { display: 'none' },
    }, {
      key: 'kriteerit',
      label: this.$t('kriteerit') as string,
      thStyle: { display: 'none' },
    }] as any[];
  }

  get kriteeriton() {
    return this.arviointi.osaamistasonKriteerit.length === 1
      && _.chain(this.arviointi.osaamistasonKriteerit)
        .map('kriteerit')
        .flatten()
        .isEmpty()
        .value();
  }

  get osaamistasonOtsikko() {
    return _.get(this.arviointi, 'osaamistasonKriteerit[0].osaamistaso.otsikko');
  }
}
