
import * as _ from 'lodash';
import { Vue, Component, Prop } from 'vue-property-decorator';

import EpColorIndicator from '@shared/components/EpColorIndicator/EpColorIndicator.vue';
import EpContentViewer from '@shared/components/EpContentViewer/EpContentViewer.vue';

@Component({
  components: {
    EpColorIndicator,
    EpContentViewer,
  },
})
export default class ModuuliEsitys extends Vue {
  @Prop({ required: false, default: true })
  private isPerusteView!: boolean;

  @Prop({ required: true })
  private moduuli!: any;

  @Prop({ required: false, type: Array })
  private termit!: any[];

  @Prop({ required: false, type: Array })
  private kuvat!: any[];

  get koodi() {
    return this.moduuli.koodi;
  }

  get hasKuvaus() {
    if (this.moduuli) {
      return this.moduuli.kuvaus;
    }
  }

  get tyyppi() {
    if (this.moduuli) {
      return this.moduuli.pakollinen ? 'pakollinen' : 'valinnainen';
    }
  }

  get tavoitteet() {
    if (this.moduuli) {
      return this.moduuli.tavoitteet;
    }
  }

  get hasTavoitteet() {
    return !_.isEmpty(this.tavoitteet);
  }

  get sisallot() {
    if (this.moduuli) {
      return this.moduuli.sisallot;
    }
  }

  get hasSisallot() {
    return !_.isEmpty(this.sisallot);
  }
}
