
import { PerusteDataStore } from '@/stores/PerusteDataStore';
import * as _ from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import EpCollapse from '@shared/components/EpCollapse/EpCollapse.vue';

@Component({
  components: {
    EpSpinner,
    EpCollapse,
  },
})
export default class RouteAipeLaajaAlaisetOsaamiset extends Vue {
  @Prop({ required: true })
  private perusteDataStore!: PerusteDataStore;

  get laajaAlaisetOsaamiset() {
    return this.perusteDataStore.getJulkaistuPerusteSisalto('aipe.laajaalaisetosaamiset');
  }
}
