
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Matala, OpintokokonaisuusDtoTyyppiEnum } from '@shared/api/amosaa';
import EpFormContent from '@shared/components/forms/EpFormContent.vue';
import EpContentViewer from '@shared/components/EpContentViewer/EpContentViewer.vue';
import * as _ from 'lodash';
import { OsaamismerkitStore } from '@/stores/OsaamismerkitStore';
import EpOsaamismerkit from '@/routes/osaamismerkit/EpOsaamismerkit.vue';

@Component({
  components: {
    EpOsaamismerkit,
    EpFormContent,
    EpContentViewer,
  },
})
export default class EpToteutussuunnitelmaOpintokokonaisuus extends Vue {
  @Prop({ required: true })
  private sisaltoviite!: Matala;

  @Prop({ required: true })
  private kuvat!: any[];

  private osaamismerkitStore = new OsaamismerkitStore();

  async mounted() {
    let koodit = _.map(this.sisaltoviite.opintokokonaisuus?.osaamismerkkiKappale?.osaamismerkkiKoodit, koodi => _.toNumber(koodi.koodi));
    await this.osaamismerkitStore.updateOsaamismerkkiQuery({ koodit: koodit, poistunut: true });
    await this.osaamismerkitStore.fetchKategoriat({ poistunut: true });
  }

  get osaamismerkkiKategoriat() {
    return _.chain(this.osaamismerkitStore.kategoriat.value)
      .map(kategoria => {
        return {
          text: this.$kaanna(kategoria.nimi),
          value: kategoria.id,
          data: kategoria,
        };
      })
      .uniqWith(_.isEqual)
      .sortBy('text')
      .filter('text')
      .value();
  }

  get opintokokonaisuus() {
    return this.sisaltoviite.opintokokonaisuus;
  }

  opintokokonaisuusNimiOtsikko(tyyppi: OpintokokonaisuusDtoTyyppiEnum): string {
    return {
      [_.toLower(OpintokokonaisuusDtoTyyppiEnum.OMA)]: 'opintokokonaisuuden-nimi',
      [_.toLower(OpintokokonaisuusDtoTyyppiEnum.PERUSTEESTA)]: 'osaamiskokonaisuuden-nimi',
    }[tyyppi];
  }

  opintokokonaisuusTavoiteOtsikko(tyyppi: OpintokokonaisuusDtoTyyppiEnum): string {
    return {
      [_.toLower(OpintokokonaisuusDtoTyyppiEnum.OMA)]: 'osaamistavoitteet',
      [_.toLower(OpintokokonaisuusDtoTyyppiEnum.PERUSTEESTA)]: 'opetuksen-tavoitteet',
    }[tyyppi];
  }

  get laajuusYksikkoLyhenne() {
    return this.opintokokonaisuus?.laajuusYksikko
      ? this.$t(_.toLower(this.opintokokonaisuus?.laajuusYksikko) + '-lyhenne')
      : this.$t('opintopiste');
  }
}
