
import { Component, Prop, Vue } from 'vue-property-decorator';
import _ from 'lodash';
import draggable from 'vuedraggable';
import EpButton from '@shared/components/EpButton/EpButton.vue';
import EpInput from '@shared/components/forms/EpInput.vue';
import EpMaterialIcon from '@shared/components/EpMaterialIcon/EpMaterialIcon.vue';

@Component({
  components: {
    EpButton,
    draggable,
    EpInput,
    EpMaterialIcon,
  },
})
export default class EpTavoitealueKeskeisetSisaltoalueet extends Vue {
  @Prop({ required: true })
  private value!: any[];

  get keskeisetSisaltoalueet() {
    return this.value;
  }

  set keskeisetSisaltoalueet(value) {
    this.$emit('input', value);
  }

  lisaaKeskeinenSisaltoalue() {
    this.keskeisetSisaltoalueet = [
      ...this.keskeisetSisaltoalueet,
      {},
    ];
  }

  poistaKeskeinenSisaltoalue(keskeinenSisaltoalue) {
    this.keskeisetSisaltoalueet = _.filter(this.keskeisetSisaltoalueet, rivi => rivi !== keskeinenSisaltoalue);
  }

  get defaultDragOptions() {
    return {
      animation: 300,
      emptyInsertThreshold: 10,
      handle: '.order-handle',
      ghostClass: 'dragged',
    };
  }

  get keskeisetSisaltoalueetOptions() {
    return {
      ...this.defaultDragOptions,
      group: {
        name: 'keskeisetsisaltoalueet',
      },
    };
  }
}
