
import _ from 'lodash';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import EpContentViewer from '@shared/components/EpContentViewer/EpContentViewer.vue';
import { PerusteDataStore } from '@/stores/PerusteDataStore';

@Component({
  components: {
    EpContentViewer,
  },
})
export default class RouteAipeVaihe extends Vue {
  @Prop({ required: true })
  private perusteDataStore!: PerusteDataStore;

  get vaiheId() {
    return _.toNumber(this.$route.params.vaiheId);
  }

  get vaihe() {
    return this.perusteDataStore.getJulkaistuPerusteSisalto({ id: this.vaiheId });
  }

  get oppiaineet() {
    if (this.vaihe) {
      return _.map(this.vaihe.oppiaineet, oppiaine => {
        return {
          ...oppiaine,
          route: { name: 'aipeoppiaine', params: { oppiaineId: _.toString(oppiaine.id) } },
        };
      });
    }
  }

  get tavoitealueet() {
    if (this.vaihe) {
      return this.vaihe.opetuksenKohdealueet;
    }
  }

  get oppiaine() {
    return this.$route.params.oppiaineId;
  }

  get fields() {
    return [{
      key: 'nimi',
      thStyle: {
        display: 'none',
      },
    }];
  }

  get kuvat() {
    return this.perusteDataStore.kuvat;
  }
}

