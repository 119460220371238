
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class EpBPagination extends Vue {
  @Prop({ required: true, default: 1 })
  private value!: number;

  @Prop({ required: true })
  private itemsPerPage!: number;

  @Prop({ required: true })
  private total!: number;

  @Prop({ required: false })
  private ariaControls?: string;

  get currentPage() {
    return this.value;
  }

  set currentPage(value) {
    this.$emit('input', value);
  }

  get controls() {
    return this.ariaControls;
  }

  get perPage() {
    return this.itemsPerPage;
  }

  get totalPages() {
    return this.total;
  }
}
