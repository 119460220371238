
import _ from 'lodash';
import { Vue, Component, Prop } from 'vue-property-decorator';

import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import { OpetussuunnitelmaDataStore } from '@/stores/OpetussuunnitelmaDataStore';

@Component({
  components: {
    EpSpinner,
  },
})
export default class RouteOpetussuunnitelmaOppiaineet extends Vue {
  @Prop({ required: true })
  private opetussuunnitelmaDataStore!: OpetussuunnitelmaDataStore;

  get current() {
    return this.opetussuunnitelmaDataStore.current;
  }

  get oppiaineet() {
    if (this.current) {
      return this.current.children;
    }
  }

  get oppiaineetFormatted() {
    if (!_.isEmpty(this.oppiaineet)) {
      return _.map(this.oppiaineet, oa => ({
        ...oa,
        koodiLabel: _.get(oa, 'meta.koodi.arvo') || _.get(oa, 'meta.koodi'),
      }));
    }
  }
}
