
import { Prop, Component, Vue } from 'vue-property-decorator';
import EpColorIndicator from '@shared/components/EpColorIndicator/EpColorIndicator.vue';

interface Voimassaolo {
  voimaantulo?: number;
  voimassaoloAlkaa?: number;
  voimassaoloLoppuu?: number;
  siirtymaPaattyy?: number;
}

@Component({
  components: {
    EpColorIndicator,
  },
})
export default class EpVoimassaolo extends Vue {
  @Prop({ required: true })
  private voimassaolo!: Voimassaolo;

  get voimassaolonAlku() {
    return this.voimassaolo.voimaantulo || this.voimassaolo.voimassaoloAlkaa;
  }

  get eraantynyt() {
    return this.voimassaolo.voimassaoloLoppuu && Date.now() > this.voimassaolo.voimassaoloLoppuu;
  }

  get voimassa() {
    return this.voimassaolonAlku && this.voimassaolonAlku < Date.now() && (!this.voimassaolo.voimassaoloLoppuu || Date.now() < this.voimassaolo.voimassaoloLoppuu);
  }

  get tulossa() {
    return this.voimassaolonAlku && this.voimassaolonAlku > Date.now();
  }

  get siirtymaAjalla() {
    return this.voimassaolo.siirtymaPaattyy && Date.now() < this.voimassaolo.siirtymaPaattyy;
  }
}
