
import { Component, Vue, Prop } from 'vue-property-decorator';
import EpFormContent from '@shared/components/forms/EpFormContent.vue';
import EpAmmatillinenArvioinninKohdealueet from '@/components/EpAmmatillinen/EpAmmatillinenArvioinninKohdealueet.vue';
import EpAmmattitaitovaatimukset from '@shared/components/EpAmmattitaitovaatimukset/EpAmmattitaitovaatimukset.vue';
import GeneerinenArviointiTaulukko from '@/components/EpAmmatillinen/GeneerinenArviointiTaulukko.vue';
import EpValmaTelmaSisalto from '@/components/EpAmmatillinen/EpValmaTelmaSisalto.vue';
import _ from 'lodash';

@Component({
  components: {
    EpFormContent,
    EpAmmatillinenArvioinninKohdealueet,
    EpAmmattitaitovaatimukset,
    GeneerinenArviointiTaulukko,
    EpValmaTelmaSisalto,
  },
})
export default class EpTutkinnonosaNormaali extends Vue {
  @Prop({ required: true })
  tutkinnonosa: any;

  @Prop({ required: true })
  arviointiasteikot!: any[];

  get hasArviointi() {
    return !_.isNil(this.tutkinnonosa.arviointi?.lisatiedot)
      || !_.isEmpty(this.tutkinnonosa.arviointi?.arvioinninKohdealueet)
      || !_.isNil(this.tutkinnonosa.geneerinenArviointiasteikko);
  }

  get osaamistasonKriteeritFields() {
    return [{
      key: 'osaamistaso',
      label: this.$t('osaamistaso') as string,
      thStyle: { display: 'none' },
    }, {
      key: 'kriteerit',
      label: this.$t('kriteerit') as string,
      thStyle: { display: 'none' },
    }] as any[];
  }
}
