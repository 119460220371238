
import { Component, Prop, Vue } from 'vue-property-decorator';
import EpColorCircle from '@shared/components/EpColorIndicator/EpColorCircle.vue';

@Component({
  components: {
    EpColorCircle,
  },
})
export default class EpKoulutuksenOsaKortti extends Vue {
    @Prop({ required: true })
    private koulutuksenosa!: any;

    @Prop({ required: true })
    private route!: any;

    get laajuusmerkit() {
      return [
        this.koulutuksenosa.laajuusMaksimi >= 10 ? '#E75B00' : '#FCBF88',
        this.koulutuksenosa.laajuusMaksimi >= 20 ? '#E75B00' : '#FCBF88',
        this.koulutuksenosa.laajuusMaksimi >= 30 ? '#E75B00' : '#FCBF88',
      ];
    }
}
