
import * as _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Maintenance } from '@shared/api/eperusteet';
import { BrowserStore } from '@shared/stores/BrowserStore';

@Component
export default class EpPalauteLinkki extends Vue {
  @Prop({ required: true })
  private yllapitoAvain!: string;

  private yllapitoValue: string | null = null;

  async mounted() {
    try {
      this.yllapitoValue = (await Maintenance.getYllapito(this.yllapitoAvain)).data;
    }
    catch (e) {
      this.yllapitoValue = null;
    }
  }

  get url() {
    return this.yllapitoValue + '/?ref=' + encodeURIComponent(this.browserLocationHref);
  }

  get browserLocationHref() {
    return BrowserStore.location.href;
  }
}
