
import { Vue, Component, Prop } from 'vue-property-decorator';
import EpMaterialIcon from '@shared/components/EpMaterialIcon/EpMaterialIcon.vue';
import osaamismerkkiLogoFI from '@assets/img/images/osaamismerkki_main_FI.svg';
import osaamismerkkiLogoSV from '@assets/img/images/osaamismerkki_main_SV.svg';
import { Kielet } from '@shared/stores/kieli';

@Component({
  components: {
    EpMaterialIcon,
  },
})
export default class OsaamismerkkiTile extends Vue {
  get sisaltokieli() {
    return Kielet.getSisaltoKieli.value;
  }

  get osaamimerkkiLogo() {
    if (this.sisaltokieli === 'sv') {
      return osaamismerkkiLogoSV;
    }
    else {
      return osaamismerkkiLogoFI;
    }
  }
}
