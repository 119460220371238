
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Lops2019OppiaineStore } from '@/stores/Lops2019OppiaineStore';
import { PerusteDataStore } from '@/stores/PerusteDataStore';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import OppiaineEsitys from './OppiaineEsitys.vue';
import EpOpasKiinnitysLinkki from '@shared/components/EpOpasKiinnitysLinkki/EpOpasKiinnitysLinkki.vue';
import * as _ from 'lodash';

@Component({
  components: {
    EpSpinner,
    OppiaineEsitys,
    EpOpasKiinnitysLinkki,
  },
})
export default class RouteOppiaine extends Vue {
  @Prop({ required: true })
  private perusteDataStore!: PerusteDataStore;

  get termit() {
    return this.perusteDataStore.termit;
  }

  get kuvat() {
    return this.perusteDataStore.kuvat;
  }

  get oppiaineId() {
    return _.toNumber(this.$route.params.oppiaineId);
  }

  get oppiaine() {
    return this.perusteDataStore.getJulkaistuPerusteSisalto({ id: this.oppiaineId }) as any;
  }

  get oppiaineKoodiUri() {
    return this.oppiaine?.koodi?.uri;
  }
}
