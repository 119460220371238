
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { KoulutuksenJarjestajatStore } from '@/stores/KoulutuksenJarjestajatStore';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import EpSearch from '@shared/components/forms/EpSearch.vue';
import EpExternalLink from '@shared/components/EpExternalLink/EpExternalLink.vue';
import * as _ from 'lodash';
import { Kielet } from '@shared/stores/kieli';
import EpAmmatillinenRow from '@/components/EpAmmatillinen/EpAmmatillinenRow.vue';
import EpBPagination from '@shared/components/EpBPagination/EpBPagination.vue';

@Component({
  components: {
    EpBPagination,
    EpSpinner,
    EpSearch,
    EpExternalLink,
    EpAmmatillinenRow,
  },
})
export default class RouteAmmatillinenKoulutuksenJarjestajat extends Vue {
  @Prop({ required: true })
  koulutuksenJarjestajatStore!: KoulutuksenJarjestajatStore;

  private query = '';
  private page = 1;
  private perPage = 10;

  mounted() {
    this.koulutuksenJarjestajatStore.fetch();
  }

  get koulutustoimijat() {
    if (this.koulutuksenJarjestajatStore.koulutustoimijat.value) {
      return _.chain(this.koulutuksenJarjestajatStore.koulutustoimijat.value)
        .filter(koulutustoimija => Kielet.search(this.query, koulutustoimija.nimi))
        .value();
    }
  }

  get koulutustoimijatPaged() {
    if (this.koulutustoimijat) {
      return _.chain(this.koulutustoimijat)
        .drop(this.perPage * (this.page - 1))
        .take(this.perPage)
        .value();
    }
  }

  get total() {
    return _.size(this.koulutustoimijat);
  }

  @Watch('query')
  onQueryChanged() {
    this.page = 1;
  }
}
