import { render, staticRenderFns } from "./EpToteutussuunnitelmaTutkinnonosa.vue?vue&type=template&id=2bd26d68&scoped=true"
import script from "./EpToteutussuunnitelmaTutkinnonosa.vue?vue&type=script&lang=ts"
export * from "./EpToteutussuunnitelmaTutkinnonosa.vue?vue&type=script&lang=ts"
import style0 from "./EpToteutussuunnitelmaTutkinnonosa.vue?vue&type=style&index=0&id=2bd26d68&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bd26d68",
  null
  
)

export default component.exports