
import { Component, Prop, Vue } from 'vue-property-decorator';
import EpContentViewer from '@shared/components/EpContentViewer/EpContentViewer.vue';
import EpCollapse from '@shared/components/EpCollapse/EpCollapse.vue';
import EpFormContent from '@shared/components/forms/EpFormContent.vue';

@Component({
  components: {
    EpContentViewer,
    EpCollapse,
    EpFormContent,
  },
})
export default class EpToteutukset extends Vue {
  @Prop({ required: true })
  toteutukset!: any[];

  @Prop({ required: true })
  kuvat!: any[];

  get koodiFields() {
    return [{
      key: 'nimi',
      label: this.$t('nimi') as string,
      thStyle: { width: '40%' },
      formatter: (value:any) => {
        return this.$kaanna(value);
      },
    }, {
      key: 'koodiArvo',
      label: this.$t('koodi') as string,
    }] as any[];
  }
}
