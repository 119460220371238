
import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Lops2019OpintojaksoDto } from '@shared/api/ylops';

@Component
export default class EpOpintojaksoSelect extends Vue {
  @Prop({ required: false })
  private options!: Lops2019OpintojaksoDto[];

  @Prop({ required: true })
  private value!: Lops2019OpintojaksoDto[];

  @Prop({ required: false, default: false })
  private isEditing!: boolean;

  get opintojaksot() {
    if (!this.isEditing) {
      return this.value;
    }

    return _.map(this.options, (option) => {
      return {
        ...option,
        selected: _.includes(_.map(this.value, 'koodi'), option.koodi),
      };
    });
  }

  select(opintojakso) {
    if (!this.isEditing) {
      return;
    }

    if (_.includes(_.map(this.value, 'koodi'), opintojakso.koodi)) {
      this.$emit('input', _.filter(this.value, (oj) => oj.koodi !== opintojakso.koodi));
    }
    else {
      this.$emit('input', [
        ...this.value,
        opintojakso,
      ]);
    }
  }
}
