
import { Prop, Component, Vue, Watch } from 'vue-property-decorator';
import { TiedoteStore } from '@/stores/TiedoteStore';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import EpSearch from '@shared/components/forms/EpSearch.vue';
import EpHeader from '@/components/EpHeader/EpHeader.vue';
import EpContentViewer from '@shared/components/EpContentViewer/EpContentViewer.vue';
import { Kielet } from '@shared/stores/kieli';
import { Meta } from '@shared/utils/decorators';
import { JulkaistutKoulutustyypitStore } from '@/stores/JulkaistutKoulutustyypitStore';

@Component({
  components: {
    EpSpinner,
    EpHeader,
    EpSearch,
    EpContentViewer,
  },
})
export default class RouteUutiset extends Vue {
  @Prop({ required: true })
  private tiedoteStore!: TiedoteStore;

  @Prop({ required: true })
  private julkaistutKoulutustyypitStore!: JulkaistutKoulutustyypitStore;

  private page = 1;
  private query = '';

  public mounted() {
    this.tiedoteStore.updateFilter({
      nimi: this.query,
      koulutustyypit: this.julkaistutKoulutustyypit,
      kieli: Kielet.getSisaltoKieli.value,
    });
  }

  get julkaistutKoulutustyypit() {
    return this.julkaistutKoulutustyypitStore.julkaistutKoulutustyypit.value;
  }

  @Watch('sisaltoKieli')
  async sisaltoKieliChange() {
    await this.tiedoteStore.updateFilter({
      kieli: [Kielet.getSisaltoKieli.value],
    });
  }

  get tiedotteet() {
    return {
      tiedotteet: this.tiedoteStore.tiedotteet,
      filter: this.tiedoteStore.filter,
      amount: this.tiedoteStore.amount,
    };
  }

  get isTiedotteetEmpty() {
    return this.tiedotteet.amount === 0;
  }

  private updatePage(value) {
    this.page = value;
    this.tiedoteStore.updateFilter({
      sivu: value - 1,
    });
  }

  private setValue(value) {
    this.page = 1;
    this.tiedoteStore.updateFilter({
      nimi: value,
      sivu: 0,
    });
  }

  get sisaltoKieli() {
    return Kielet.getSisaltoKieli.value;
  }

  get murupolku() {
    return [{
      label: 'ajankohtaista',
      location: {
        name: 'uutiset',
      },
    }];
  }

  @Meta
  getMetaInfo() {
    return {
      title: (this as any).$t('ajankohtaista'),
    };
  }
}
