
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ToteutussuunnitelmaDataStore } from '@/stores/ToteutussuunnitelmaDataStore';
import EpFormContent from '@shared/components/forms/EpFormContent.vue';
import EpContentViewer from '@shared/components/EpContentViewer/EpContentViewer.vue';
import EpField from '@shared/components/forms/EpField.vue';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import { Kielet } from '@shared/stores/kieli';

@Component({
  components: {
    EpContentViewer,
    EpFormContent,
    EpField,
    EpSpinner,
  },
})
export default class EpToteutussuunnitelmaTiedot extends Vue {
  @Prop({ required: true })
  private store!: ToteutussuunnitelmaDataStore | null;

  async mounted() {
    this.kieliChanged();
  }

  @Watch('kieli')
  async kieliChanged() {
    await this.store!.getDokumenttiTila();
  }

  get toteutussuunnitelma() {
    return this.store!.opetussuunnitelma;
  }

  get dokumenttiUrl() {
    return this.store!.dokumenttiUrl;
  }

  get kuvat() {
    return this.store!.kuvat;
  }

  get kieli() {
    return Kielet.getSisaltoKieli.value;
  }

  get koulutustoimija() {
    return this.store?.koulutustoimija;
  }

  get kuvausOtsikko() {
    return this.store?.isAmmatillinen ? 'tutkinnon-suorittaneen-osaaminen' : 'opetussuunnitelma-tiivistelma';
  }
}
