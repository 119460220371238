
import * as _ from 'lodash';
import { Prop, Component, Vue } from 'vue-property-decorator';
import EpCollapse from '@shared/components/EpCollapse/EpCollapse.vue';
import EpContentViewer from '@shared/components/EpContentViewer/EpContentViewer.vue';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import { OpetussuunnitelmaDataStore } from '@/stores/OpetussuunnitelmaDataStore';

@Component({
  components: {
    EpCollapse,
    EpContentViewer,
    EpSpinner,
  },
})
export default class RouteOpetussuunnitelmaKurssi extends Vue {
  @Prop({ required: true })
  private opetussuunnitelmaDataStore!: OpetussuunnitelmaDataStore;

  get termit() {
    return [
      this.opetussuunnitelmaDataStore.perusteTermit,
      this.opetussuunnitelmaDataStore.termit,
    ];
  }

  get kuvat() {
    return this.opetussuunnitelmaDataStore.kuvat;
  }

  get kurssiId() {
    return _.toNumber(this.$route.params.kurssiId);
  }

  get kurssi() {
    return this.opetussuunnitelmaDataStore.getJulkaistuSisalto({ id: this.kurssiId });
  }

  get sisaltoAvaimet() {
    return ['tavoitteetJaKeskeinenSisalto', 'tavoitteetJaKeskeisetSisallot', 'tehtava', 'tavoitteet', 'keskeinenSisalto', 'keskeisetSisallot'];
  }
}
