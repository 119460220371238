
import _ from 'lodash';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { IPaikallinenStore } from '@/stores/IPaikallinenStore';
import { PerusteKoosteStore } from '@/stores/PerusteKoosteStore';
import { Kielet } from '@shared/stores/kieli';
import EpHeader from '@/components/EpHeader/EpHeader.vue';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import EpSearch from '@shared/components/forms/EpSearch.vue';
import EpExternalLink from '@shared/components/EpExternalLink/EpExternalLink.vue';
import OpetussuunnitelmaTile from './OpetussuunnitelmaTile.vue';
import EpBPagination from '@shared/components/EpBPagination/EpBPagination.vue';
import EpMultiSelect from '@shared/components/forms/EpMultiSelect.vue';
import { ryhmanKoulutustyypit } from '@shared/utils/perusteet';
import { Page } from '@shared/tyypit';

@Component({
  components: {
    EpHeader,
    EpSearch,
    EpSpinner,
    EpExternalLink,
    OpetussuunnitelmaTile,
    EpBPagination,
    EpMultiSelect,
  },
})
export default class Paikalliset extends Vue {
  @Prop({ required: true })
  private paikallinenStore!: IPaikallinenStore;

  @Prop({ required: true })
  private perusteKoosteStore!: PerusteKoosteStore;

  @Prop({ required: true })
  private koulutustyyppi!: string;

  private query = '';
  private page = 1;
  private perPage = 10;
  private valittuPeruste: any | null = null;

  async mounted() {
    this.fetch();
  }

  @Watch('koulutustyyppi')
  onKoulutustyyppiChanged() {
    this.valittuPeruste = null;
  }

  @Watch('query')
  async onQueryChanged() {
    this.page = 1;
    await this.fetch();
  }

  @Watch('page')
  async onPageChanged() {
    await this.fetch();
  }

  @Watch('valittuPeruste')
  async onPerusteChange() {
    await this.fetch();
  }

  @Watch('kieli')
  async onKieliChange() {
    await this.fetch();
  }

  get kieli() {
    return Kielet.getSisaltoKieli.value;
  }

  async fetch() {
    await this.paikallinenStore.fetchQuery!({
      query: this.query,
      peruste: this.valittuPeruste,
      ...(!this.valittuPeruste?.nimi && { koulutustyypit: ryhmanKoulutustyypit(this.koulutustyyppi) }),
      page: this.page - 1,
    });
  }

  get julkaistutPerusteet() {
    if (this.perusteKoosteStore.perusteJulkaisut) {
      return _.chain(this.perusteKoosteStore.perusteJulkaisut.value)
        .map(julkaistuPeruste => ({
          ...julkaistuPeruste,
          kaannettyNimi: this.$kaanna(julkaistuPeruste.nimi!),
        }))
        .orderBy(['voimassaoloAlkaa', 'kaannettyNimi'], ['desc', 'asc'])
        .value();
    }
  }

  get perusteetOptions() {
    if (this.julkaistutPerusteet) {
      return [
        {},
        ...this.julkaistutPerusteet,
      ];
    }
    return [];
  }

  get isLoading() {
    return !this.paikallinenStore.opetussuunnitelmatPaged!.value;
  }

  get opetussuunnitelmatKokonaismaara() {
    if (this.paikallinenStore.opetussuunnitelmatPaged?.value) {
      return this.paikallinenStore.opetussuunnitelmatPaged!.value['kokonaismäärä'];
    }
    return 0;
  }

  get opetussuunnitelmat() {
    return _.chain(this.paikallinenStore.opetussuunnitelmatPaged?.value?.data)
      .map(ops => ({
        ...ops,
        toimijat: _.filter(ops.organisaatiot, org => _.includes(org.tyypit, 'Koulutustoimija')),
        oppilaitokset: _.filter(ops.organisaatiot, org => _.includes(org.tyypit, 'Oppilaitos')),
        route: {
          name: 'opetussuunnitelma',
          params: {
            opetussuunnitelmaId: _.toString(ops.id),
          },
        },
      }))
      .sortBy(ops => Kielet.sortValue(ops.nimi))
      .value();
  }

  kaannaPerusteNimi(option) {
    if (option?.nimi) {
      return this.$kaanna(option.nimi);
    }
    return this.$t('kaikki');
  }

  async mouseOver(opetussuunnitelma) {
    await this.paikallinenStore.addToCache!(opetussuunnitelma.id);
  }
}
