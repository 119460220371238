
import { Vue, Component, Prop } from 'vue-property-decorator';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import { PerusteDto, Perusteet } from '@shared/api/eperusteet';
import EpMaterialIcon from '@shared/components/EpMaterialIcon/EpMaterialIcon.vue';

@Component({
  components: {
    EpSpinner,
    EpMaterialIcon,
  },
})
export default class EpOpasKiinnitysLinkki extends Vue {
  @Prop({ required: false })
  private koodiUri!: string;

  private oppaat: PerusteDto[] | null = null;

  async mounted() {
    if (this.koodiUri) {
      this.oppaat = (await Perusteet.getOpasKiinnitettyKoodi(this.koodiUri)).data;
    }
  }
}
