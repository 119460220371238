
import * as _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class EpPaikallinenTarkennus extends Vue {
  @Prop({ required: false, default: true, type: Boolean })
  private avattava!: boolean;

  @Prop({ required: false, default: false, type: Boolean })
  private headerh4!: boolean;

  @Prop({ required: false, default: false, type: Boolean })
  private noheader!: boolean;
}
