
import { Vue, Component, Prop } from 'vue-property-decorator';
import _ from 'lodash';
import { Kielet } from '@shared/stores/kieli';
import { OsaamismerkitStore } from '@/stores/OsaamismerkitStore';
import EpVoimassaolo from '@shared/components/EpVoimassaolo/EpVoimassaolo.vue';

@Component({
  components: {
    EpVoimassaolo,
  },
})
export default class EpOsaamismerkit extends Vue {
  @Prop({ required: true })
  private osaamismerkitStore!: OsaamismerkitStore;

  @Prop({ required: true })
  private osaamismerkkiKategoriat!: any[];

  @Prop({ required: false, default: false, type: Boolean })
  private hideKuvaus?: Boolean;

  generateImageUrl(liite) {
    return liite ? 'data:' + liite.mime + ';base64,' + liite.binarydata : null;
  }

  get osaamismerkit() {
    return _.chain(this.osaamismerkitStore.osaamismerkit.value)
      .filter(osaamismerkki => !!osaamismerkki?.nimi && !!osaamismerkki.nimi[Kielet.getSisaltoKieli.value])
      .map(osaamismerkki => ({
        ...osaamismerkki,
        image: this.generateImageUrl(osaamismerkki.kategoria?.liite),
        isVanhentunut: this.isVanhentunut(osaamismerkki),
      }))
      .sortBy(om => Kielet.sortValue(om.nimi))
      .value();
  }

  get osaamismerkitCount() {
    return this.osaamismerkit.length;
  }

  get kategoriaGroup() {
    return _.chain(this.osaamismerkkiKategoriat)
      .map(kategoria => ({
        ...kategoria,
        osaamismerkit: _.filter(this.osaamismerkit, osaamismerkki => osaamismerkki.kategoria?.id === kategoria.value),
      }))
      .filter(kategoria => kategoria.osaamismerkit.length > 0)
      .value();
  }

  private isVanhentunut(osaamismerkki) {
    let currentDate = new Date(new Date().setHours(0, 0, 0, 0));
    return osaamismerkki.voimassaoloLoppuu && _.toNumber(osaamismerkki.voimassaoloLoppuu) < currentDate.getTime();
  }
}
