
import { Component, Prop, Vue } from 'vue-property-decorator';
import _ from 'lodash';
import { KoodistoSelectStore } from '../EpKoodistoSelect/KoodistoSelectStore';
import { Koodisto } from '@shared/api/eperusteet';
import draggable from 'vuedraggable';
import EpButton from '@shared/components/EpButton/EpButton.vue';
import EpKoodistoSelect from '@shared/components/EpKoodistoSelect/EpKoodistoSelect.vue';
import EpTavoitealueKeskeisetSisaltoalueet from './EpTavoitealueKeskeisetSisaltoalueet.vue';
import EpTavoitealueTavoitteet from './EpTavoitealueTavoitteet.vue';
import EpMaterialIcon from '@shared/components/EpMaterialIcon/EpMaterialIcon.vue';

@Component({
  components: {
    EpKoodistoSelect,
    EpButton,
    draggable,
    EpTavoitealueKeskeisetSisaltoalueet,
    EpTavoitealueTavoitteet,
    EpMaterialIcon,
  },
})
export default class EpTavoitesisaltoalueTavoitealueet extends Vue {
  @Prop({ required: true })
  private value!: any[];

  @Prop({ required: false, default: false, type: Boolean })
  private isEditing!: boolean;

  get tavoitealueet() {
    return this.value;
  }

  set tavoitealueet(value) {
    this.$emit('input', value);
  }

  private readonly tavoitealueetKoodisto = new KoodistoSelectStore({
    koodisto: 'tavoitealueet',
    async query(query: string, sivu = 0, koodisto: string) {
      const { data } = (await Koodisto.kaikkiSivutettuna(koodisto, query, {
        params: {
          sivu,
          sivukoko: 10,
        },
      }));
      return data as any;
    },
  });

  lisaaTavoitealue(tyyppi: 'OTSIKKO' | 'TAVOITESISALTOALUE') {
    this.tavoitealueet = [
      ...this.tavoitealueet,
      {
        tavoiteAlueTyyppi: tyyppi,
        ...(tyyppi === 'TAVOITESISALTOALUE' && { tavoitteet: [] }),
        ...(tyyppi === 'TAVOITESISALTOALUE' && { keskeisetSisaltoalueet: [] }),
      },
    ];
  }

  poistaTavoitealue(tavoitealue) {
    this.tavoitealueet = _.filter(this.tavoitealueet, rivi => rivi !== tavoitealue);
  }

  get defaultDragOptions() {
    return {
      animation: 300,
      emptyInsertThreshold: 10,
      handle: '.order-handle',
      disabled: !this.isEditing,
      ghostClass: 'dragged',
    };
  }
  get tavoitealueOptions() {
    return {
      ...this.defaultDragOptions,
      group: {
        name: 'tavoitealue',
      },
    };
  }

  get tavoitteetOptions() {
    return {
      ...this.defaultDragOptions,
      group: {
        name: 'tavoitteet',
      },
    };
  }

  get keskeisetSisaltoalueetOptions() {
    return {
      ...this.defaultDragOptions,
      group: {
        name: 'keskeisetsisaltoalueet',
      },
    };
  }
}
