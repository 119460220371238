
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class EpAlert extends Vue {
  @Prop({ required: true })
  private text!: string;

  @Prop({ default: false, required: false })
  private ops!: boolean;

  @Prop({ default: false, required: false })
  private onlyText!: boolean;
}
