
import { Prop, Component, Vue } from 'vue-property-decorator';
import { KoodiPerusteella } from '@/stores/TiedoteStore';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import { KoodiDto } from '@shared/api/eperusteet';
import _ from 'lodash';

@Component({
  components: {
    EpSpinner,
  },
})
export default class UutisenKoodit extends Vue {
  @Prop({ required: true })
  private kooditPerusteilla!: KoodiPerusteella[];

  get popovertarget() {
    return this.kooditPerusteilla[0].uri;
  }

  get perusteelliset() {
    return _.filter(this.kooditPerusteilla, koodi => !_.isEmpty(koodi.perusteet));
  }
}
