
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { ValmisteillaOlevatStore } from '@/stores/ValmisteillaOlevatStore';
import { AmmatillisetKoulutustyypit } from '@shared/utils/perusteet';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import EpBPagination from '@shared/components/EpBPagination/EpBPagination.vue';
import EpMaterialIcon from '@shared/components//EpMaterialIcon/EpMaterialIcon.vue';
import * as _ from 'lodash';

@Component({
  components: {
    EpSpinner,
    EpBPagination,
    EpMaterialIcon,
  },
})
export default class RouteAmmatillinenValmisteillaOlevat extends Vue {
  @Prop({ required: true })
  private valmisteillaOlevatStore!: ValmisteillaOlevatStore;
  private query = {
    sivu: 0,
    sivukoko: 10,
    koulutustyyppit: AmmatillisetKoulutustyypit,
  };
  private toggled: number[] = [];

  async mounted() {
    await this.fetch();
  }

  async fetch() {
    await this.valmisteillaOlevatStore.fetch(this.query.sivu, this.query.sivukoko, this.query.koulutustyyppit);
  }

  @Watch('query')
  onQueryChanged() {
    this.page = 1;
  }

  get perusteet() {
    return this.valmisteillaOlevatStore.perusteet.value;
  }

  get perusteetMapped() {
    return _.map(this.perusteet!.data, peruste => {
      return {
        ...peruste,
        toggled: _.includes(this.toggled, peruste.id),
        perusteenAikataulut: _.sortBy(_.filter(peruste.perusteenAikataulut, 'julkinen'), 'tapahtumapaiva'),
      };
    });
  }

  toggle(peruste) {
    if (_.includes(this.toggled, peruste.id)) {
      this.toggled = _.filter(this.toggled, id => id !== peruste.id);
    }
    else {
      this.toggled = [
        ...this.toggled,
        peruste.id,
      ];
    }
  }

  get total() {
    return this.perusteet!.kokonaismäärä;
  }
  get pages() {
    return this.perusteet!.sivuja;
  }
  get perPage() {
    return this.perusteet!.sivukoko;
  }

  get page() {
    return this.perusteet!.sivu + 1;
  }
  set page(value) {
    this.query.sivu = value - 1;
    this.fetch();
  }
}
