
import _ from 'lodash';
import { Vue, Component, Prop } from 'vue-property-decorator';
import EpMaterialIcon from '@shared/components/EpMaterialIcon/EpMaterialIcon.vue';
import EpContent from '@shared/components/EpContent/EpContent.vue';

@Component({
  components: {
    EpContent,
    EpMaterialIcon,
  },
})
export default class EpOpintojaksonLaajaAlaisetOsaamiset extends Vue {
  @Prop({ required: false })
  private opetussuunnitelmaStore!: any;

  @Prop({ required: true })
  private value!: any;

  @Prop({ required: false, default: false })
  private isEditing!: boolean;

  @Prop({ required: true })
  private opintojaksonOppiaineidenTiedot!: any;

  @Prop({ required: true })
  private laajaAlaistenKoodit!: any;

  @Prop({ required: false, default: true })
  private showEmptyAlert!: boolean;

  @Prop({ required: false, default: true })
  private showPerustesisalto!: boolean;

  get laajaAlaisetKooditByUri() {
    return _.keyBy(this.laajaAlaistenKoodit, 'koodi');
  }
}
