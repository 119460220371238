
import { Vue, Component, Prop } from 'vue-property-decorator';
import PerusteAmmatillinenHaku from './PerusteAmmatillinenHaku.vue';
import { AmmatillinenOpasHakuStore } from '@/stores/AmmatillinenOpasHakuStore';

@Component({
  components: {
    PerusteAmmatillinenHaku,
  },
})
export default class RouteAmmatillinenOhjeet extends Vue {
  private perusteHakuStoreOhjeet = new AmmatillinenOpasHakuStore();
}
