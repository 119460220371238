
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class EpColorCircle extends Vue {
  @Prop({ default: '#000000' })
  color!: string;

  @Prop({ required: false })
  help!: string | undefined;

  @Prop({ default: 10 })
  size!: number;

  get circleStyle() {
    return {
      'min-height': this.size + 'px',
      'min-width': this.size + 'px',
      'background': this.color,
    };
  }

  get circleClass() {
    return this.help ? 'circle circle-tooltip' : 'circle';
  }
}
