
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import _ from 'lodash';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import EpSearch from '@shared/components/forms/EpSearch.vue';
import { IPerusteHakuStore } from '@/stores/IPerusteHakuStore';
import EpAmmatillinenRow from '@/components/EpAmmatillinen/EpAmmatillinenRow.vue';
import EpMultiSelect from '@shared/components/forms/EpMultiSelect.vue';
import { ValmisteillaOlevatStore } from '@/stores/ValmisteillaOlevatStore';
import { AmmatillisetKoulutustyypit } from '@shared/utils/perusteet';
import { Kielet } from '@shared/stores/kieli';
import { voimassaoloTieto } from '@/utils/voimassaolo';
import EpBPagination from '@shared/components/EpBPagination/EpBPagination.vue';
import EpSisaltotyyppiFilter from '@shared/components/EpSisaltotyyppiFilter/EpSisaltotyyppiFilter.vue';
import EpAmmatillinenPerusteItem from '@/components/EpAmmatillinen/EpAmmatillinenPerusteItem.vue';
import EpAmmatillinenTutkinnonosaItem from '@/components/EpAmmatillinen/EpAmmatillinenTutkinnonosaItem.vue';

@Component({
  components: {
    EpSearch,
    EpSpinner,
    EpAmmatillinenRow,
    EpMultiSelect,
    EpBPagination,
    EpSisaltotyyppiFilter,
    EpAmmatillinenPerusteItem,
    EpAmmatillinenTutkinnonosaItem,
  },
})
export default class PerusteAmmatillinenHaku extends Vue {
  @Prop({ required: true })
  private perusteHakuStore!: IPerusteHakuStore;

  @Prop({ type: String })
  private tyyppi!: 'peruste' | 'opas' | 'kooste';

  private valmisteillaOlevatStore: ValmisteillaOlevatStore = new ValmisteillaOlevatStore();
  private tutkintotyyppi = 'kaikki';

  private toggleQuery: any = {};

  async mounted() {
    this.initQuery();
    this.page = 1;
    if (!this.perusteHakuStore.perusteet) {
      await this.perusteHakuStore.fetch();
    }
    await this.valmisteillaOlevatStore.fetch(0, 1, AmmatillisetKoulutustyypit);
  }

  initQuery() {
    this.filters.nimiTaiKoodi = undefined;
    this.toggleQuery = {
      tuleva: true,
      voimassaolo: true,
      siirtyma: false,
      poistunut: false,
      perusteet: true,
      tutkinnonosat: false,
    };
  }

  get tutkintotyypit() {
    return [
      'kaikki',
      'koulutustyyppi_1',
      'koulutustyyppi_11',
      'koulutustyyppi_12',
    ];
  }

  get kieli() {
    return Kielet.getSisaltoKieli.value;
  }

  @Watch('kieli')
  async kieliChange() {
    this.perusteHakuStore.updateFilters({ kieli: this.kieli });
  }

  @Watch('tutkintotyyppi')
  tutkintotyyppiChange() {
    if (this.tutkintotyyppi === 'kaikki') {
      this.perusteHakuStore.updateFilters({ koulutustyyppi: [
        'koulutustyyppi_1',
        'koulutustyyppi_11',
        'koulutustyyppi_12',
        'koulutustyyppi_5',
        'koulutustyyppi_18',
      ] });
    }
    else {
      this.perusteHakuStore.updateFilters({ koulutustyyppi: [this.tutkintotyyppi] });
    }
  }

  @Watch('query')
  onQueryChanged() {
    this.page = 1;
  }

  @Watch('toggleQuery', { deep: true })
  voimassaoloFilterChanged() {
    this.perusteHakuStore.updateFilters(this.toggleQuery);
    this.page = 1;
  }

  get searchPlaceholder() {
    if (this.tyyppi === 'opas') {
      return this.$t('ohjeen-tai-materiaalin-nimi');
    }
    else {
      return this.$t('voit-hakea-tutkintoa-nimella');
    }
  }

  get perusteetJaTutkinnonosat() {
    if (this.perusteHakuStore.perusteet) {
      return this.mapPerusteet(this.perusteHakuStore.perusteet);
    }
  }

  mapPerusteet(perusteet) {
    return _.chain(perusteet)
      .map(sisalto => ({
        ...sisalto,
        route: this.perusteTutkinnonosaRoute(sisalto),
        voimassaoloTieto: voimassaoloTieto(sisalto),
        koulutuskoodit: _.join(_.map(sisalto.koulutukset, 'koulutuskoodiArvo'), ', '),
        perusteet: sisalto.perusteet ? this.mapPerusteet(sisalto.perusteet) : null,
        ...(sisalto.sisaltotyyppi === 'peruste' && sisalto.tyyppi !== 'opas' && { tutkintotag: 'tutkinnon-peruste' }),
        ...(sisalto.sisaltotyyppi === 'tutkinnonosa' && { tutkintotag: 'tutkinnon-osa' }),

      }))
      .value();
  }

  perusteTutkinnonosaRoute(perusteTaiTutkinnonosa) {
    if (perusteTaiTutkinnonosa.sisaltotyyppi === 'tutkinnonosa' && perusteTaiTutkinnonosa.tutkinnonosa?.tyyppi === 'normaali' && perusteTaiTutkinnonosa.perusteet?.length > 1) {
      return null;
    }
    if (perusteTaiTutkinnonosa.tutkinnonosa?.tyyppi === 'reformi_tutke2') {
      return {
        name: 'yhteinentutkinnonosa',
        params: {
          koodi: perusteTaiTutkinnonosa.tutkinnonosa.koodiUri,
        },
      };
    }
    if (perusteTaiTutkinnonosa.perusteet?.length === 1) {
      return {
        name: 'tutkinnonosa',
        params: {
          perusteId: perusteTaiTutkinnonosa.perusteet[0].id,
          tutkinnonOsaViiteId: perusteTaiTutkinnonosa.id,
        },
        query: { redirect: 'true' },
      };
    }
    if (this.tyyppi === 'opas' || this.tyyppi === 'kooste') {
      return {
        name: 'peruste',
        params: {
          koulutustyyppi: 'ammatillinen',
          perusteId: _.toString(perusteTaiTutkinnonosa.id || perusteTaiTutkinnonosa.perusteId),
        },
      };
    }
    else {
      return {
        name: 'ammatillinenkooste',
        params: {
          perusteId: _.toString(perusteTaiTutkinnonosa.id),
        },
      };
    }
  }

  get total() {
    return this.perusteHakuStore.total;
  }

  get pages() {
    return this.perusteHakuStore.pages;
  }

  get perPage() {
    return this.perusteHakuStore.perPage;
  }

  get filters() {
    return this.perusteHakuStore.filters;
  }

  get query() {
    return this.filters.nimiTaiKoodi;
  }

  set query(value) {
    this.perusteHakuStore.updateFilters({ nimiTaiKoodi: value });
  }

  get page() {
    return this.perusteHakuStore.page + 1;
  }

  set page(value) {
    this.perusteHakuStore.page = value - 1;
    this.perusteHakuStore.updateFilters({ sivu: this.perusteHakuStore.page });
  }

  get valmisteillaOlevat() {
    return this.valmisteillaOlevatStore.perusteet.value;
  }
}
