
import { Vue, Component, Prop } from 'vue-property-decorator';
import EpToggle from '../forms/EpToggle.vue';
import Sticky from 'vue-sticky-directive';
import { BrowserStore } from '../../stores/BrowserStore';
import _ from 'lodash';

@Component({
  components: {
    EpToggle,
  },
  directives: {
    Sticky,
  },
})
export default class EpSidebar extends Vue {
  @Prop({ required: false, default: false, type: Boolean })
  private scrollEnabled!: boolean;

  private browserStore = new BrowserStore();

  get showNavigation() {
    return this.browserStore.navigationVisible.value;
  }

  private settings = {
    autoScroll: true,
    showSubchapter: true,
  };

  get scrollAnchor() {
    return this.scrollEnabled && !_.includes(['peruste', 'perusteTiedot'], this.$route?.name) ? 'scroll-anchor' : 'disabled-scroll-anchor';
  }
}
