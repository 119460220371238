
import { Vue, Component, Prop } from 'vue-property-decorator';
import _ from 'lodash';
import { PerusteDataStore } from '@/stores/PerusteDataStore';
import EpContentViewer from '@shared/components/EpContentViewer/EpContentViewer.vue';
import EpTutkinnonosaNormaali from '@/components/EpAmmatillinen/EpTutkinnonosaNormaali.vue';
import EpTutkinnonosaTutke from '@/components/EpAmmatillinen/EpTutkinnonosaTutke.vue';
import EpAmmatillinenArvioinninKohdealueet from '@/components/EpAmmatillinen/EpAmmatillinenArvioinninKohdealueet.vue';
import GeneerinenArviointiTaulukko from '@/components/EpAmmatillinen/GeneerinenArviointiTaulukko.vue';

@Component({
  components: {
    GeneerinenArviointiTaulukko,
    EpAmmatillinenArvioinninKohdealueet,
    EpTutkinnonosaTutke,
    EpTutkinnonosaNormaali,
    EpContentViewer,
  },
})
export default class RoutePerusteKoosteEng extends Vue {
  @Prop({ required: true })
  private perusteDataStore!: PerusteDataStore;

  get arviointiasteikot() {
    return this.perusteDataStore.arviointiasteikot;
  }

  get peruste() {
    return this.perusteDataStore.peruste;
  }

  get kuvat() {
    return this.perusteDataStore.kuvat;
  }

  get termit() {
    return this.perusteDataStore.termit;
  }

  get perusteenTutkinnonosatById() {
    return _.keyBy(this.perusteDataStore.getJulkaistuPerusteSisalto('tutkinnonOsat'), 'id');
  }

  get perusteenTutkinnonosaViitteet() {
    return _.chain(this.perusteDataStore.getJulkaistuPerusteSisalto('suoritustavat'))
      .map(st => st.tutkinnonOsaViitteet)
      .flatMap()
      .value();
  }

  get tutkinnonOsaViitteet() {
    if (this.perusteenTutkinnonosaViitteet) {
      return _.chain(this.perusteenTutkinnonosaViitteet)
        .map(viite => {
          return {
            ...viite,
            tutkinnonOsa: this.perusteenTutkinnonosatById[viite._tutkinnonOsa],
          };
        })
        .sortBy('jarjestys')
        .map((tutkinnonosaViite, index) => ({
          ...tutkinnonosaViite,
          jarjestys: index + 1,
        }))
        .value();
    }
  }

  get geneerisetArvioinnit() {
    return _.chain(this.tutkinnonOsaViitteet)
      .map(viite => {
        return viite.tutkinnonOsa.geneerinenArviointiasteikko;
      })
      .filter(viite => !_.isEmpty(viite))
      .unionBy('id')
      .value();
  }

  get tutkinnonOsaFields() {
    let baseFields = [{
      key: 'jarjestys',
      label: this.$t('nro', 'en') as string,
      sortable: true,
    }, {
      key: 'nimi',
      sortable: true,
      sortByFormatted: true,
      label: this.$t('nimi', 'en') as string,
      formatter: (item: any) => {
        return this.$kaanna(item?.tutkinnonOsa?.nimi, false, true, 'en');
      },
    }];
    let showLaajuusColumn = _.some(this.tutkinnonOsaViitteet, viite => _.has(viite, 'laajuus'));
    if (showLaajuusColumn) {
      return [...baseFields, {
        key: 'laajuus',
        sortable: true,
        label: this.$t('laajuus', 'en') as string,
        formatter: (value: any, key: string, item: any) => {
          if (value) {
            return value + ' ' + this.$t('osaamispiste', 'en');
          }
          if (_.isNumber(item.laajuus) && _.isNumber(item.laajuusMaksimi)) {
            return item.laajuus + ' - ' + item.laajuusMaksimi + ' ' + this.$t('osaamispiste', 'en');
          }
        },
      }];
    }
    return baseFields;
  }

  get osaamisalatFields() {
    return [{
      key: 'nimi',
      label: this.$t('nimi', 'en'),
      thStyle: 'width: 75%',
      formatter: (value: any) => {
        return this.$kaanna(value, false, true, 'en');
      },
    }, {
      key: 'arvo',
      label: this.$t('koodi', 'en'),
      thStyle: 'width: 15%',
    }];
  }

  get tutkintonimikkeetFields() {
    return [{
      key: 'nimi',
      label: this.$t('nimi', 'en'),
      thStyle: 'width: 75%',
      formatter: (value: any) => {
        return this.$kaanna(value, false, true, 'en');
      },
    }, {
      key: 'tutkintonimikeArvo',
      label: this.$t('koodi', 'en'),
      thStyle: 'width: 15%',
    }];
  }

  get koulutuskooditFields() {
    return [{
      key: 'koulutuskoodiArvo',
      label: this.$t('koodi', 'en'),
      thStyle: 'width: 15%',
    }, {
      key: 'nimi',
      label: this.$t('koulutuksen-nimi', 'en'),
      formatter: (value: any, key: string, item: any) => {
        return this.$kaanna(value, false, true, 'en');
      },
    }];
  }
}
