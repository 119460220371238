
import { Vue, Component, Prop } from 'vue-property-decorator';
import { highlight } from '@/utils/kieli';
import _ from 'lodash';
import { VoimassaoloTieto } from '@/utils/voimassaolo';
import EpVoimassaolo from '@shared/components/EpVoimassaolo/EpVoimassaolo.vue';
import { OpetussuunnitelmaDto } from '@shared/api/amosaa';

@Component({
  components: {
    EpVoimassaolo,
  },
})
export default class OpetussuunnitelmaTile extends Vue {
  @Prop({ required: true })
  private ops!: OpetussuunnitelmaDto;

  @Prop({ default: '' })
  private query!: string;

  @Prop({ required: false })
  private voimassaoloTiedot!: VoimassaoloTieto[];

  @Prop({ required: false, default: false, type: Boolean })
  private showJotpaInfo!: Boolean;

  @Prop({ required: false, default: true, type: Boolean })
  private showOpsIcon?: boolean;

  get nimi() {
    return highlight(this.$kaanna((this.ops.nimi as Object)), this.query);
  }

  get toimijat() {
    return _.map((this.ops as any).toimijat, (toimija) => highlight(this.$kaanna(toimija.nimi), this.query));
  }

  get oppilaitokset() {
    return _.map((this.ops as any).oppilaitokset, (oppilaitos) => highlight(this.$kaanna(oppilaitos.nimi), this.query));
  }
}
