
import * as _ from 'lodash';
import { Vue, Component, Prop } from 'vue-property-decorator';

import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import EpColorIndicator from '@shared/components/EpColorIndicator/EpColorIndicator.vue';
import EpContentViewer from '@shared/components/EpContentViewer/EpContentViewer.vue';
import OppiaineEsitys from '@/routes/perusteet/sisalto/lops2019/oppiaineet/OppiaineEsitys.vue';
import { OpetussuunnitelmaDataStore } from '@/stores/OpetussuunnitelmaDataStore';
import { KoodistoKoodiDto, Opetussuunnitelmat } from '@shared/api/ylops';
import { KoodistoLops2019LaajaAlaiset } from '@shared/utils/perusteet';

@Component({
  components: {
    EpSpinner,
    EpColorIndicator,
    EpContentViewer,
    OppiaineEsitys,
  },
})
export default class RouteOpetussuunnitelmaPoppiaine extends Vue {
  @Prop({ required: true })
  private opetussuunnitelmaDataStore!: OpetussuunnitelmaDataStore;

  private koodit: KoodistoKoodiDto[] = [];

  get opetussuunnitelmaId() {
    return _.toNumber(this.$route.params.opetussuunnitelmaId);
  }

  async mounted() {
    this.koodit = (await Opetussuunnitelmat.getKoodistonKoodit(this.opetussuunnitelmaId, KoodistoLops2019LaajaAlaiset)).data;
  }

  get termit() {
    return this.opetussuunnitelmaDataStore.kaikkiTermit;
  }

  get kuvat() {
    return this.opetussuunnitelmaDataStore.kuvat;
  }

  get oppiaineId() {
    return _.toNumber(this.$route.params.oppiaineId);
  }

  get oppiaine() {
    return this.opetussuunnitelmaDataStore.getJulkaistuSisalto({ id: this.oppiaineId });
  }

  get perusteenOppiaine() {
    if (this.oppiaine.perusteenOppiaineUri) {
      return _.find(this.opetussuunnitelmaDataStore.getJulkaistuPerusteSisalto('lops2019.oppiaineet'), oppiaine => oppiaine.koodi.uri === this.oppiaine.perusteenOppiaineUri);
    }
  }

  get kooditFormatted() {
    const koodit: any = {};
    if (this.koodit) {
      _.each(this.koodit, koodi => {
        koodit[koodi.koodiUri!] = koodi;
      });
    }
    return koodit;
  }

  get koodi() {
    if (this.oppiaine) {
      return this.oppiaine.koodi;
    }
  }

  get hasTehtava() {
    if (this.oppiaine) {
      return this.oppiaine.tehtava && this.oppiaine.tehtava.kuvaus;
    }
  }

  get hasPerusteenOppianeenTehtava() {
    if (this.perusteenOppiaine) {
      return this.perusteenOppiaine.tehtava && this.perusteenOppiaine.tehtava.kuvaus;
    }
  }

  get tavoitteet() {
    if (this.oppiaine) {
      return this.oppiaine.tavoitteet;
    }
  }

  get hasArviointi() {
    if (this.oppiaine) {
      return this.oppiaine.arviointi && this.oppiaine.arviointi.kuvaus;
    }
  }

  get hasPerusteenOppianeenArviointi() {
    if (this.perusteenOppiaine) {
      return this.perusteenOppiaine.arviointi && this.perusteenOppiaine.arviointi.kuvaus;
    }
  }

  get hasOpiskeluymparistoTyotavat() {
    return this.oppiaine && this.oppiaine.opiskeluymparistoTyotavat && !_.isEmpty(this.oppiaine.opiskeluymparistoTyotavat.kuvaus);
  }

  get hasPerusteenOpiskeluymparistoTyotavat() {
    return this.perusteenOppiaine && this.perusteenOppiaine.opiskeluymparistoTyotavat && !_.isEmpty(this.perusteenOppiaine.opiskeluymparistoTyotavat.kuvaus);
  }

  get perusteenOppiaineenTavoitteet() {
    if (this.perusteenOppiaine) {
      return this.perusteenOppiaine.tavoitteet;
    }
  }

  get hasLaajaAlainenOsaaminen() {
    if (this.oppiaine) {
      return !_.isEmpty(this.oppiaine.laajaAlainenOsaaminen);
    }
  }

  get hasPerusteenOppiaineenLaajaAlainenOsaaminen() {
    if (this.perusteenOppiaine) {
      return this.perusteenOppiaine.laajaAlaisetOsaamiset && this.perusteenOppiaine.laajaAlaisetOsaamiset.kuvaus;
    }
  }

  get hasTavoitteet() {
    if (this.tavoitteet) {
      return !_.isEmpty(this.tavoitteet) && !_.isEmpty(this.tavoitteet.tavoitealueet);
    }
  }

  get hasPerusteenOppiaineenTavoitteet() {
    if (this.perusteenOppiaineenTavoitteet) {
      return !_.isEmpty(this.perusteenOppiaineenTavoitteet) && !_.isEmpty(this.perusteenOppiaineenTavoitteet.tavoitealueet);
    }
  }

  get opintojaksot() {
    if (this.oppiaine && this.oppiaine.koodi) {
      return _.filter(this.opetussuunnitelmaDataStore.getJulkaistuSisalto('opintojaksot'), oj => {
        const uri = this.oppiaine!.koodi!.uri;
        return _.some(oj.oppiaineet, { koodi: uri });
      });
    }
  }

  get opintojaksotExtended() {
    if (this.current) {
      const opintojaksot = _.find(this.current.children, { type: 'opintojaksot' });
      if (opintojaksot) {
        return _.map(opintojaksot.children, oj => {
          return {
            ...oj,
            nimi: oj.label,
          };
        });
      }
    }
  }

  get current() {
    return this.opetussuunnitelmaDataStore.current;
  }

  get hasOpintojaksot() {
    return !_.isEmpty(this.opintojaksotExtended);
  }
}
