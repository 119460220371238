
import _ from 'lodash';
import { Vue, Component, Prop } from 'vue-property-decorator';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import EpTutkinnonosaNormaali from '@/components/EpAmmatillinen/EpTutkinnonosaNormaali.vue';
import EpTutkinnonosaTutke from '@/components/EpAmmatillinen/EpTutkinnonosaTutke.vue';
import EpOpasKiinnitysLinkki from '@shared/components/EpOpasKiinnitysLinkki/EpOpasKiinnitysLinkki.vue';
import { PerusteDataStore } from '@/stores/PerusteDataStore';

@Component({
  components: {
    EpSpinner,
    EpTutkinnonosaNormaali,
    EpTutkinnonosaTutke,
    EpOpasKiinnitysLinkki,
  },
})
export default class RouteTutkinnonosa extends Vue {
  @Prop({ required: true })
  private perusteDataStore!: PerusteDataStore;

  mounted() {
    if (this.$route.query?.redirect) {
      const viite = _.find(this.perusteenTutkinnonosaViitteet, viite => _.toNumber(viite._tutkinnonOsa) === _.toNumber(this.$route.params.tutkinnonOsaViiteId));
      this.$router.replace(
        {
          name: 'tutkinnonosa',
          params: {
            perusteId: _.toString(this.perusteDataStore.peruste?.id),
            tutkinnonOsaViiteId: viite.id,
          },
        });
    }
  }

  get tutkinnonosaViiteId() {
    return _.toNumber(this.$route.params.tutkinnonOsaViiteId);
  }

  get tutkinnonosaViite() {
    return this.perusteDataStore.getJulkaistuPerusteSisalto({ id: this.tutkinnonosaViiteId }) as any;
  }

  get perusteenTutkinnonosaViitteet() {
    return _.chain(this.perusteDataStore.getJulkaistuPerusteSisalto('suoritustavat'))
      .map(st => st.tutkinnonOsaViitteet)
      .flatMap()
      .value();
  }

  get perusteenTutkinnonosa() {
    return this.perusteDataStore.getJulkaistuPerusteSisalto({ id: _.toNumber(_.get(this.tutkinnonosaViite, '_tutkinnonOsa')) }) as any;
  }

  get laajuus() {
    if (_.isNumber(this.tutkinnonosaViite.laajuus) && _.isNumber(this.tutkinnonosaViite.laajuusMaksimi)) {
      return this.tutkinnonosaViite.laajuus + ' - ' + this.tutkinnonosaViite.laajuusMaksimi;
    }

    return this.tutkinnonosaViite.laajuus;
  }

  get laajuusText() {
    if (!this.tutkinnonosaViite.laajuus) {
      return '';
    }
    return ', ' + this.laajuus + ' ' + this.$t('osaamispiste');
  }

  get arviointiasteikot() {
    return this.perusteDataStore.arviointiasteikot;
  }

  get tutkinnonosaKoodiUri() {
    return this.tutkinnonosa?.koodi?.uri;
  }

  get perusteenKielet() {
    return this.perusteDataStore.peruste?.kielet;
  }

  get tutkinnonosa() {
    let tutkinnonosa = _.cloneDeep(this.perusteenTutkinnonosa);

    if (_.get(tutkinnonosa, 'geneerinenArviointiasteikko') && !!_.get(tutkinnonosa.geneerinenArviointiasteikko, '_arviointiAsteikko')) {
      const arviointiAsteikko = _.keyBy(this.arviointiasteikot, 'id')[_.get(tutkinnonosa.geneerinenArviointiasteikko, '_arviointiAsteikko')];
      const osaamistasot = _.keyBy(arviointiAsteikko.osaamistasot, 'id');
      tutkinnonosa.geneerinenArviointiasteikko.osaamistasonKriteerit = _.map(tutkinnonosa.geneerinenArviointiasteikko.osaamistasonKriteerit, otKriteeri => {
        return {
          ...otKriteeri,
          osaamistaso: osaamistasot[_.get(otKriteeri, '_osaamistaso')],
        };
      });
    }

    return tutkinnonosa;
  }
}
