
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Kielet } from '@shared/stores/kieli';

/**
 * Tämän komponentin tehtävä on ainoastaan esittää label lomakekentälle
 */
@Component
export default class EpFormContent extends Vue {
  @Prop({ required: false, type: String })
  private name!: string;

  @Prop({ type: String })
  private headerType!: string;

  @Prop({ type: String })
  private headerClass!: string;

  @Prop({ required: false, default: true })
  private showHeader!:boolean;

  @Prop({ required: false, type: String })
  private kieli?: string;

  private lang: string = this.kieli ? this.kieli : this.sisaltoKieli;

  @Watch('sisaltoKieli')
  async kieliChanged() {
    this.lang = this.kieli ? this.kieli : this.sisaltoKieli;
  }

  get sisaltoKieli() {
    return Kielet.getSisaltoKieli.value;
  }
}
