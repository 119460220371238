
import * as _ from 'lodash';
import { Prop, Component, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class EpValmaTelmaSisalto extends Vue {
  @Prop()
  private valmaTelmaSisalto!: any;
}
