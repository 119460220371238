// tslint:disable
/**
 * ePerusteet amosaa api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AbstractRakenneOsaDto
 */
export interface AbstractRakenneOsaDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AbstractRakenneOsaDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {KoodiDto}
     * @memberof AbstractRakenneOsaDto
     */
    vieras?: KoodiDto;
    /**
     * 
     * @type {string}
     * @memberof AbstractRakenneOsaDto
     */
    tunniste?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AbstractRakenneOsaDto
     */
    pakollinen?: boolean;
}
/**
 * 
 * @export
 * @interface AmmattitaitovaatimuksenKohde
 */
export interface AmmattitaitovaatimuksenKohde {
    /**
     * 
     * @type {number}
     * @memberof AmmattitaitovaatimuksenKohde
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTeksti}
     * @memberof AmmattitaitovaatimuksenKohde
     */
    otsikko?: LokalisoituTeksti;
    /**
     * 
     * @type {LokalisoituTeksti}
     * @memberof AmmattitaitovaatimuksenKohde
     */
    selite?: LokalisoituTeksti;
    /**
     * 
     * @type {AmmattitaitovaatimuksenKohdealue}
     * @memberof AmmattitaitovaatimuksenKohde
     */
    ammattitaitovaatimuksenkohdealue?: AmmattitaitovaatimuksenKohdealue;
    /**
     * 
     * @type {Array<Ammattitaitovaatimus>}
     * @memberof AmmattitaitovaatimuksenKohde
     */
    vaatimukset?: Array<Ammattitaitovaatimus>;
}
/**
 * 
 * @export
 * @interface AmmattitaitovaatimuksenKohdeDto
 */
export interface AmmattitaitovaatimuksenKohdeDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AmmattitaitovaatimuksenKohdeDto
     */
    otsikko?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AmmattitaitovaatimuksenKohdeDto
     */
    selite?: { [key: string]: string; };
    /**
     * 
     * @type {Array<AmmattitaitovaatimusDto>}
     * @memberof AmmattitaitovaatimuksenKohdeDto
     */
    vaatimukset?: Array<AmmattitaitovaatimusDto>;
}
/**
 * 
 * @export
 * @interface AmmattitaitovaatimuksenKohdealue
 */
export interface AmmattitaitovaatimuksenKohdealue {
    /**
     * 
     * @type {number}
     * @memberof AmmattitaitovaatimuksenKohdealue
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTeksti}
     * @memberof AmmattitaitovaatimuksenKohdealue
     */
    otsikko?: LokalisoituTeksti;
    /**
     * 
     * @type {Array<AmmattitaitovaatimuksenKohde>}
     * @memberof AmmattitaitovaatimuksenKohdealue
     */
    vaatimuksenKohteet?: Array<AmmattitaitovaatimuksenKohde>;
    /**
     * 
     * @type {Array<OmaTutkinnonosa>}
     * @memberof AmmattitaitovaatimuksenKohdealue
     */
    tutkinnonOsat?: Array<OmaTutkinnonosa>;
}
/**
 * 
 * @export
 * @interface AmmattitaitovaatimuksenKohdealueDto
 */
export interface AmmattitaitovaatimuksenKohdealueDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AmmattitaitovaatimuksenKohdealueDto
     */
    otsikko?: { [key: string]: string; };
    /**
     * 
     * @type {Array<AmmattitaitovaatimuksenKohdeDto>}
     * @memberof AmmattitaitovaatimuksenKohdealueDto
     */
    vaatimuksenKohteet?: Array<AmmattitaitovaatimuksenKohdeDto>;
}
/**
 * 
 * @export
 * @interface Ammattitaitovaatimukset2019
 */
export interface Ammattitaitovaatimukset2019 {
    /**
     * 
     * @type {Date}
     * @memberof Ammattitaitovaatimukset2019
     */
    luotu?: Date;
    /**
     * 
     * @type {string}
     * @memberof Ammattitaitovaatimukset2019
     */
    luoja?: string;
    /**
     * 
     * @type {Date}
     * @memberof Ammattitaitovaatimukset2019
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof Ammattitaitovaatimukset2019
     */
    muokkaaja?: string;
    /**
     * 
     * @type {number}
     * @memberof Ammattitaitovaatimukset2019
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTeksti}
     * @memberof Ammattitaitovaatimukset2019
     */
    kohde?: LokalisoituTeksti;
    /**
     * 
     * @type {Array<Ammattitaitovaatimus2019>}
     * @memberof Ammattitaitovaatimukset2019
     */
    vaatimukset?: Array<Ammattitaitovaatimus2019>;
    /**
     * 
     * @type {Array<Ammattitaitovaatimus2019Kohdealue>}
     * @memberof Ammattitaitovaatimukset2019
     */
    kohdealueet?: Array<Ammattitaitovaatimus2019Kohdealue>;
}
/**
 * 
 * @export
 * @interface Ammattitaitovaatimus
 */
export interface Ammattitaitovaatimus {
    /**
     * 
     * @type {number}
     * @memberof Ammattitaitovaatimus
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTeksti}
     * @memberof Ammattitaitovaatimus
     */
    selite?: LokalisoituTeksti;
    /**
     * 
     * @type {string}
     * @memberof Ammattitaitovaatimus
     */
    ammattitaitovaatimusKoodi?: string;
    /**
     * 
     * @type {number}
     * @memberof Ammattitaitovaatimus
     */
    jarjestys?: number;
    /**
     * 
     * @type {AmmattitaitovaatimuksenKohde}
     * @memberof Ammattitaitovaatimus
     */
    ammattitaitovaatimuksenkohde?: AmmattitaitovaatimuksenKohde;
}
/**
 * 
 * @export
 * @interface Ammattitaitovaatimus2019
 */
export interface Ammattitaitovaatimus2019 {
    /**
     * 
     * @type {Date}
     * @memberof Ammattitaitovaatimus2019
     */
    luotu?: Date;
    /**
     * 
     * @type {string}
     * @memberof Ammattitaitovaatimus2019
     */
    luoja?: string;
    /**
     * 
     * @type {Date}
     * @memberof Ammattitaitovaatimus2019
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof Ammattitaitovaatimus2019
     */
    muokkaaja?: string;
    /**
     * 
     * @type {number}
     * @memberof Ammattitaitovaatimus2019
     */
    id?: number;
    /**
     * 
     * @type {KoodistoKoodi}
     * @memberof Ammattitaitovaatimus2019
     */
    koodi?: KoodistoKoodi;
    /**
     * 
     * @type {LokalisoituTeksti}
     * @memberof Ammattitaitovaatimus2019
     */
    vaatimus?: LokalisoituTeksti;
}
/**
 * 
 * @export
 * @interface Ammattitaitovaatimus2019Kohdealue
 */
export interface Ammattitaitovaatimus2019Kohdealue {
    /**
     * 
     * @type {Date}
     * @memberof Ammattitaitovaatimus2019Kohdealue
     */
    luotu?: Date;
    /**
     * 
     * @type {string}
     * @memberof Ammattitaitovaatimus2019Kohdealue
     */
    luoja?: string;
    /**
     * 
     * @type {Date}
     * @memberof Ammattitaitovaatimus2019Kohdealue
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof Ammattitaitovaatimus2019Kohdealue
     */
    muokkaaja?: string;
    /**
     * 
     * @type {number}
     * @memberof Ammattitaitovaatimus2019Kohdealue
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTeksti}
     * @memberof Ammattitaitovaatimus2019Kohdealue
     */
    kuvaus?: LokalisoituTeksti;
    /**
     * 
     * @type {Array<Ammattitaitovaatimus2019>}
     * @memberof Ammattitaitovaatimus2019Kohdealue
     */
    vaatimukset?: Array<Ammattitaitovaatimus2019>;
}
/**
 * 
 * @export
 * @interface AmmattitaitovaatimusDto
 */
export interface AmmattitaitovaatimusDto {
    /**
     * 
     * @type {number}
     * @memberof AmmattitaitovaatimusDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AmmattitaitovaatimusDto
     */
    selite?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof AmmattitaitovaatimusDto
     */
    ammattitaitovaatimusKoodi?: string;
    /**
     * 
     * @type {number}
     * @memberof AmmattitaitovaatimusDto
     */
    jarjestys?: number;
}
/**
 * 
 * @export
 * @interface ArvioinninKohde
 */
export interface ArvioinninKohde {
    /**
     * 
     * @type {number}
     * @memberof ArvioinninKohde
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTeksti}
     * @memberof ArvioinninKohde
     */
    otsikko?: LokalisoituTeksti;
    /**
     * 
     * @type {LokalisoituTeksti}
     * @memberof ArvioinninKohde
     */
    selite?: LokalisoituTeksti;
    /**
     * 
     * @type {Arviointiasteikko}
     * @memberof ArvioinninKohde
     */
    arviointiasteikko: Arviointiasteikko;
    /**
     * 
     * @type {Array<OsaamistasonKriteeri>}
     * @memberof ArvioinninKohde
     */
    osaamistasonKriteerit?: Array<OsaamistasonKriteeri>;
    /**
     * 
     * @type {Array<ArvioinninKohdealue>}
     * @memberof ArvioinninKohde
     */
    arvioinninKohdealueet?: Array<ArvioinninKohdealue>;
}
/**
 * 
 * @export
 * @interface ArvioinninKohdeDto
 */
export interface ArvioinninKohdeDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ArvioinninKohdeDto
     */
    otsikko?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ArvioinninKohdeDto
     */
    selite?: { [key: string]: string; };
    /**
     * 
     * @type {Array<OsaamistasonKriteeriDto>}
     * @memberof ArvioinninKohdeDto
     */
    osaamistasonKriteerit?: Array<OsaamistasonKriteeriDto>;
    /**
     * 
     * @type {Reference}
     * @memberof ArvioinninKohdeDto
     */
    _arviointiasteikko?: Reference;
}
/**
 * 
 * @export
 * @interface ArvioinninKohdeExportDto
 */
export interface ArvioinninKohdeExportDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ArvioinninKohdeExportDto
     */
    otsikko?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ArvioinninKohdeExportDto
     */
    selite?: { [key: string]: string; };
    /**
     * 
     * @type {ArviointiasteikkoDto}
     * @memberof ArvioinninKohdeExportDto
     */
    arviointiasteikko?: ArviointiasteikkoDto;
    /**
     * 
     * @type {Array<OsaamistasonKriteeriDto>}
     * @memberof ArvioinninKohdeExportDto
     */
    osaamistasonKriteerit?: Array<OsaamistasonKriteeriDto>;
    /**
     * 
     * @type {Reference}
     * @memberof ArvioinninKohdeExportDto
     */
    _arviointiasteikko?: Reference;
}
/**
 * 
 * @export
 * @interface ArvioinninKohdealue
 */
export interface ArvioinninKohdealue {
    /**
     * 
     * @type {number}
     * @memberof ArvioinninKohdealue
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTeksti}
     * @memberof ArvioinninKohdealue
     */
    otsikko?: LokalisoituTeksti;
    /**
     * 
     * @type {Array<ArvioinninKohde>}
     * @memberof ArvioinninKohdealue
     */
    arvioinninKohteet?: Array<ArvioinninKohde>;
}
/**
 * 
 * @export
 * @interface ArvioinninKohdealueDto
 */
export interface ArvioinninKohdealueDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ArvioinninKohdealueDto
     */
    otsikko?: { [key: string]: string; };
    /**
     * 
     * @type {Array<ArvioinninKohdeDto>}
     * @memberof ArvioinninKohdealueDto
     */
    arvioinninKohteet?: Array<ArvioinninKohdeDto>;
}
/**
 * 
 * @export
 * @interface ArvioinninKohdealueExportDto
 */
export interface ArvioinninKohdealueExportDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ArvioinninKohdealueExportDto
     */
    otsikko?: { [key: string]: string; };
    /**
     * 
     * @type {Array<ArvioinninKohdeExportDto>}
     * @memberof ArvioinninKohdealueExportDto
     */
    arvioinninKohteet?: Array<ArvioinninKohdeExportDto>;
}
/**
 * 
 * @export
 * @interface Arviointi
 */
export interface Arviointi {
    /**
     * 
     * @type {number}
     * @memberof Arviointi
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTeksti}
     * @memberof Arviointi
     */
    lisatiedot?: LokalisoituTeksti;
    /**
     * 
     * @type {Array<ArvioinninKohdealue>}
     * @memberof Arviointi
     */
    arvioinninKohdealueet?: Array<ArvioinninKohdealue>;
    /**
     * 
     * @type {Osaamistavoite}
     * @memberof Arviointi
     */
    osaamistavoite?: Osaamistavoite;
}
/**
 * 
 * @export
 * @interface ArviointiAsteikkoDto
 */
export interface ArviointiAsteikkoDto {
    /**
     * 
     * @type {number}
     * @memberof ArviointiAsteikkoDto
     */
    id?: number;
    /**
     * 
     * @type {Array<OsaamistasoDto>}
     * @memberof ArviointiAsteikkoDto
     */
    osaamistasot?: Array<OsaamistasoDto>;
}
/**
 * 
 * @export
 * @interface ArviointiDto
 */
export interface ArviointiDto {
    /**
     * 
     * @type {number}
     * @memberof ArviointiDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ArviointiDto
     */
    lisatiedot?: { [key: string]: string; };
    /**
     * 
     * @type {Array<ArvioinninKohdealueDto>}
     * @memberof ArviointiDto
     */
    arvioinninKohdealueet?: Array<ArvioinninKohdealueDto>;
}
/**
 * 
 * @export
 * @interface ArviointiExportDto
 */
export interface ArviointiExportDto {
    /**
     * 
     * @type {number}
     * @memberof ArviointiExportDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ArviointiExportDto
     */
    lisatiedot?: { [key: string]: string; };
    /**
     * 
     * @type {Array<ArvioinninKohdealueExportDto>}
     * @memberof ArviointiExportDto
     */
    arvioinninKohdealueet?: Array<ArvioinninKohdealueExportDto>;
}
/**
 * 
 * @export
 * @interface Arviointiasteikko
 */
export interface Arviointiasteikko {
    /**
     * 
     * @type {number}
     * @memberof Arviointiasteikko
     */
    id?: number;
    /**
     * 
     * @type {Array<Osaamistaso>}
     * @memberof Arviointiasteikko
     */
    osaamistasot?: Array<Osaamistaso>;
}
/**
 * 
 * @export
 * @interface ArviointiasteikkoDto
 */
export interface ArviointiasteikkoDto {
    /**
     * 
     * @type {number}
     * @memberof ArviointiasteikkoDto
     */
    id?: number;
    /**
     * 
     * @type {Array<OsaamistasoDto>}
     * @memberof ArviointiasteikkoDto
     */
    osaamistasot?: Array<OsaamistasoDto>;
}
/**
 * 
 * @export
 * @interface CachedPeruste
 */
export interface CachedPeruste {
    /**
     * 
     * @type {number}
     * @memberof CachedPeruste
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTeksti}
     * @memberof CachedPeruste
     */
    nimi?: LokalisoituTeksti;
    /**
     * 
     * @type {string}
     * @memberof CachedPeruste
     */
    diaarinumero?: string;
    /**
     * 
     * @type {string}
     * @memberof CachedPeruste
     */
    peruste?: string;
    /**
     * 
     * @type {number}
     * @memberof CachedPeruste
     */
    perusteId?: number;
    /**
     * 
     * @type {Date}
     * @memberof CachedPeruste
     */
    luotu?: Date;
    /**
     * 
     * @type {string}
     * @memberof CachedPeruste
     */
    koulutustyyppi?: CachedPerusteKoulutustyyppiEnum;
    /**
     * 
     * @type {Array<KoulutusDto>}
     * @memberof CachedPeruste
     */
    koulutukset?: Array<KoulutusDto>;
    /**
     * 
     * @type {Array<Koulutuskoodi>}
     * @memberof CachedPeruste
     */
    koulutuskoodit?: Array<Koulutuskoodi>;
}

/**
    * @export
    * @enum {string}
    */
export enum CachedPerusteKoulutustyyppiEnum {
    PERUSTUTKINTO = 'PERUSTUTKINTO',
    AMMATTITUTKINTO = 'AMMATTITUTKINTO',
    ERIKOISAMMATTITUTKINTO = 'ERIKOISAMMATTITUTKINTO',
    TELMA = 'TELMA',
    VALMA = 'VALMA',
    VAPAASIVISTYSTYO = 'VAPAASIVISTYSTYO',
    MAAHANMUUTTAJIENKOTOUTUMISKOULUTUS = 'MAAHANMUUTTAJIENKOTOUTUMISKOULUTUS',
    VAPAASIVISTYSTYOLUKUTAITO = 'VAPAASIVISTYSTYOLUKUTAITO',
    TUTKINTOONVALMENTAVA = 'TUTKINTOONVALMENTAVA'
}

/**
 * 
 * @export
 * @interface CachedPerusteBaseDto
 */
export interface CachedPerusteBaseDto {
    /**
     * 
     * @type {number}
     * @memberof CachedPerusteBaseDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CachedPerusteBaseDto
     */
    diaarinumero?: string;
    /**
     * 
     * @type {number}
     * @memberof CachedPerusteBaseDto
     */
    perusteId?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CachedPerusteBaseDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof CachedPerusteBaseDto
     */
    koulutustyyppi?: CachedPerusteBaseDtoKoulutustyyppiEnum;
    /**
     * 
     * @type {Array<KoulutusDto>}
     * @memberof CachedPerusteBaseDto
     */
    koulutukset?: Array<KoulutusDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum CachedPerusteBaseDtoKoulutustyyppiEnum {
    PERUSTUTKINTO = 'PERUSTUTKINTO',
    AMMATTITUTKINTO = 'AMMATTITUTKINTO',
    ERIKOISAMMATTITUTKINTO = 'ERIKOISAMMATTITUTKINTO',
    TELMA = 'TELMA',
    VALMA = 'VALMA',
    VAPAASIVISTYSTYO = 'VAPAASIVISTYSTYO',
    MAAHANMUUTTAJIENKOTOUTUMISKOULUTUS = 'MAAHANMUUTTAJIENKOTOUTUMISKOULUTUS',
    VAPAASIVISTYSTYOLUKUTAITO = 'VAPAASIVISTYSTYOLUKUTAITO',
    TUTKINTOONVALMENTAVA = 'TUTKINTOONVALMENTAVA'
}

/**
 * 
 * @export
 * @interface DokumenttiDto
 */
export interface DokumenttiDto {
    /**
     * 
     * @type {number}
     * @memberof DokumenttiDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof DokumenttiDto
     */
    opsId?: number;
    /**
     * 
     * @type {string}
     * @memberof DokumenttiDto
     */
    luoja?: string;
    /**
     * 
     * @type {string}
     * @memberof DokumenttiDto
     */
    kieli?: DokumenttiDtoKieliEnum;
    /**
     * 
     * @type {Date}
     * @memberof DokumenttiDto
     */
    aloitusaika?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DokumenttiDto
     */
    valmistumisaika?: Date;
    /**
     * 
     * @type {string}
     * @memberof DokumenttiDto
     */
    tila?: DokumenttiDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof DokumenttiDto
     */
    edistyminen?: DokumenttiDtoEdistyminenEnum;
    /**
     * 
     * @type {string}
     * @memberof DokumenttiDto
     */
    virhekoodi?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DokumenttiDto
     */
    perusteenSisalto?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DokumenttiDto
     */
    julkaisuDokumentti?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum DokumenttiDtoKieliEnum {
    FI = 'FI',
    SV = 'SV',
    SE = 'SE',
    RU = 'RU',
    EN = 'EN'
}
/**
    * @export
    * @enum {string}
    */
export enum DokumenttiDtoTilaEnum {
    EIOLE = 'EI_OLE',
    JONOSSA = 'JONOSSA',
    LUODAAN = 'LUODAAN',
    EPAONNISTUI = 'EPAONNISTUI',
    VALMIS = 'VALMIS'
}
/**
    * @export
    * @enum {string}
    */
export enum DokumenttiDtoEdistyminenEnum {
    META = 'META',
    TUNTEMATON = 'TUNTEMATON',
    TEKSTIKAPPALEET = 'TEKSTIKAPPALEET',
    KUVAT = 'KUVAT',
    VIITTEET = 'VIITTEET',
    TYYLIT = 'TYYLIT'
}

/**
 * 
 * @export
 * @interface DokumenttiKuvaDto
 */
export interface DokumenttiKuvaDto {
    /**
     * 
     * @type {number}
     * @memberof DokumenttiKuvaDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof DokumenttiKuvaDto
     */
    opsId?: number;
    /**
     * 
     * @type {string}
     * @memberof DokumenttiKuvaDto
     */
    kieli?: DokumenttiKuvaDtoKieliEnum;
    /**
     * 
     * @type {boolean}
     * @memberof DokumenttiKuvaDto
     */
    kansikuva?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DokumenttiKuvaDto
     */
    ylatunniste?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DokumenttiKuvaDto
     */
    alatunniste?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum DokumenttiKuvaDtoKieliEnum {
    FI = 'FI',
    SV = 'SV',
    SE = 'SE',
    RU = 'RU',
    EN = 'EN'
}

/**
 * 
 * @export
 * @interface EtusivuDto
 */
export interface EtusivuDto {
    /**
     * 
     * @type {number}
     * @memberof EtusivuDto
     */
    toteutussuunnitelmatKeskeneraiset?: number;
    /**
     * 
     * @type {number}
     * @memberof EtusivuDto
     */
    toteutussuunnitelmatJulkaistut?: number;
    /**
     * 
     * @type {number}
     * @memberof EtusivuDto
     */
    ktYhteinenOsuusKeskeneraiset?: number;
    /**
     * 
     * @type {number}
     * @memberof EtusivuDto
     */
    ktYhteinenOsuusJulkaistut?: number;
    /**
     * 
     * @type {number}
     * @memberof EtusivuDto
     */
    toteutussuunnitelmaPohjatKeskeneraiset?: number;
    /**
     * 
     * @type {number}
     * @memberof EtusivuDto
     */
    toteutussuunnitelmaPohjatValmiit?: number;
}
/**
 * 
 * @export
 * @interface GeneerinenArviointiasteikkoKaikkiDto
 */
export interface GeneerinenArviointiasteikkoKaikkiDto {
    /**
     * 
     * @type {number}
     * @memberof GeneerinenArviointiasteikkoKaikkiDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof GeneerinenArviointiasteikkoKaikkiDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof GeneerinenArviointiasteikkoKaikkiDto
     */
    kohde?: { [key: string]: string; };
    /**
     * 
     * @type {ArviointiAsteikkoDto}
     * @memberof GeneerinenArviointiasteikkoKaikkiDto
     */
    arviointiAsteikko?: ArviointiAsteikkoDto;
    /**
     * 
     * @type {Array<GeneerisenArvioinninOsaamistasonKriteeriKaikkiDto>}
     * @memberof GeneerinenArviointiasteikkoKaikkiDto
     */
    osaamistasonKriteerit?: Array<GeneerisenArvioinninOsaamistasonKriteeriKaikkiDto>;
}
/**
 * 
 * @export
 * @interface GeneerisenArvioinninOsaamistasonKriteeriKaikkiDto
 */
export interface GeneerisenArvioinninOsaamistasonKriteeriKaikkiDto {
    /**
     * 
     * @type {OsaamistasoDto}
     * @memberof GeneerisenArvioinninOsaamistasonKriteeriKaikkiDto
     */
    osaamistaso?: OsaamistasoDto;
    /**
     * 
     * @type {Array<{ [key: string]: string; }>}
     * @memberof GeneerisenArvioinninOsaamistasonKriteeriKaikkiDto
     */
    kriteerit?: Array<{ [key: string]: string; }>;
}
/**
 * 
 * @export
 * @interface JulkaisuBaseDto
 */
export interface JulkaisuBaseDto {
    /**
     * 
     * @type {number}
     * @memberof JulkaisuBaseDto
     */
    revision?: number;
    /**
     * 
     * @type {OpetussuunnitelmaBaseDto}
     * @memberof JulkaisuBaseDto
     */
    opetussuunnitelma?: OpetussuunnitelmaBaseDto;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof JulkaisuBaseDto
     */
    tiedote?: { [key: string]: string; };
    /**
     * 
     * @type {Date}
     * @memberof JulkaisuBaseDto
     */
    luotu?: Date;
    /**
     * 
     * @type {string}
     * @memberof JulkaisuBaseDto
     */
    luoja?: string;
    /**
     * 
     * @type {KayttajanTietoDto}
     * @memberof JulkaisuBaseDto
     */
    kayttajanTieto?: KayttajanTietoDto;
    /**
     * 
     * @type {string}
     * @memberof JulkaisuBaseDto
     */
    tila?: JulkaisuBaseDtoTilaEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum JulkaisuBaseDtoTilaEnum {
    JULKAISEMATON = 'JULKAISEMATON',
    KESKEN = 'KESKEN',
    JULKAISTU = 'JULKAISTU',
    VIRHE = 'VIRHE'
}

/**
 * 
 * @export
 * @interface JulkaisuKevytDto
 */
export interface JulkaisuKevytDto {
    /**
     * 
     * @type {number}
     * @memberof JulkaisuKevytDto
     */
    revision?: number;
    /**
     * 
     * @type {Date}
     * @memberof JulkaisuKevytDto
     */
    luotu?: Date;
}
/**
 * 
 * @export
 * @interface KayttajaDto
 */
export interface KayttajaDto {
    /**
     * 
     * @type {number}
     * @memberof KayttajaDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof KayttajaDto
     */
    oid?: string;
    /**
     * 
     * @type {string}
     * @memberof KayttajaDto
     */
    etunimet?: string;
    /**
     * 
     * @type {string}
     * @memberof KayttajaDto
     */
    kutsumanimi?: string;
    /**
     * 
     * @type {string}
     * @memberof KayttajaDto
     */
    sukunimi?: string;
    /**
     * 
     * @type {Date}
     * @memberof KayttajaDto
     */
    tiedotekuittaus?: Date;
    /**
     * 
     * @type {Array<Reference>}
     * @memberof KayttajaDto
     */
    suosikit?: Array<Reference>;
}
/**
 * 
 * @export
 * @interface KayttajaKtoDto
 */
export interface KayttajaKtoDto {
    /**
     * 
     * @type {number}
     * @memberof KayttajaKtoDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof KayttajaKtoDto
     */
    oid?: string;
    /**
     * 
     * @type {string}
     * @memberof KayttajaKtoDto
     */
    etunimet?: string;
    /**
     * 
     * @type {string}
     * @memberof KayttajaKtoDto
     */
    kutsumanimi?: string;
    /**
     * 
     * @type {string}
     * @memberof KayttajaKtoDto
     */
    sukunimi?: string;
    /**
     * 
     * @type {Date}
     * @memberof KayttajaKtoDto
     */
    tiedotekuittaus?: Date;
    /**
     * 
     * @type {Array<Reference>}
     * @memberof KayttajaKtoDto
     */
    suosikit?: Array<Reference>;
    /**
     * 
     * @type {string}
     * @memberof KayttajaKtoDto
     */
    organisaatioOid?: string;
}
/**
 * 
 * @export
 * @interface KayttajanTietoDto
 */
export interface KayttajanTietoDto {
    /**
     * 
     * @type {string}
     * @memberof KayttajanTietoDto
     */
    kayttajanimi?: string;
    /**
     * 
     * @type {string}
     * @memberof KayttajanTietoDto
     */
    kutsumanimi?: string;
    /**
     * 
     * @type {string}
     * @memberof KayttajanTietoDto
     */
    etunimet?: string;
    /**
     * 
     * @type {string}
     * @memberof KayttajanTietoDto
     */
    sukunimi?: string;
    /**
     * 
     * @type {string}
     * @memberof KayttajanTietoDto
     */
    oidHenkilo?: string;
    /**
     * 
     * @type {string}
     * @memberof KayttajanTietoDto
     */
    kieliKoodi?: string;
    /**
     * 
     * @type {object}
     * @memberof KayttajanTietoDto
     */
    yhteystiedot?: object;
}
/**
 * 
 * @export
 * @interface KayttajaoikeusDto
 */
export interface KayttajaoikeusDto {
    /**
     * 
     * @type {number}
     * @memberof KayttajaoikeusDto
     */
    id?: number;
    /**
     * 
     * @type {Reference}
     * @memberof KayttajaoikeusDto
     */
    kayttaja?: Reference;
    /**
     * 
     * @type {Reference}
     * @memberof KayttajaoikeusDto
     */
    opetussuunnitelma?: Reference;
    /**
     * 
     * @type {Reference}
     * @memberof KayttajaoikeusDto
     */
    koulutustoimija?: Reference;
    /**
     * 
     * @type {string}
     * @memberof KayttajaoikeusDto
     */
    oikeus?: KayttajaoikeusDtoOikeusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum KayttajaoikeusDtoOikeusEnum {
    ESTETTY = 'ESTETTY',
    LUKU = 'LUKU',
    MUOKKAUS = 'MUOKKAUS',
    LISAYS = 'LISAYS',
    POISTO = 'POISTO',
    HALLINTA = 'HALLINTA'
}

/**
 * 
 * @export
 * @interface KevytTekstiKappaleDto
 */
export interface KevytTekstiKappaleDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KevytTekstiKappaleDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KevytTekstiKappaleDto
     */
    teksti?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface Koko
 */
export interface Koko {
    /**
     * 
     * @type {number}
     * @memberof Koko
     */
    minimi?: number;
    /**
     * 
     * @type {number}
     * @memberof Koko
     */
    maksimi?: number;
}
/**
 * 
 * @export
 * @interface KoodiDto
 */
export interface KoodiDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KoodiDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof KoodiDto
     */
    arvo?: string;
    /**
     * 
     * @type {string}
     * @memberof KoodiDto
     */
    uri?: string;
    /**
     * 
     * @type {string}
     * @memberof KoodiDto
     */
    koodisto?: string;
    /**
     * 
     * @type {number}
     * @memberof KoodiDto
     */
    versio?: number;
}
/**
 * 
 * @export
 * @interface KoodistoDto
 */
export interface KoodistoDto {
    /**
     * 
     * @type {number}
     * @memberof KoodistoDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof KoodistoDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof KoodistoDto
     */
    koodiArvo?: string;
    /**
     * 
     * @type {string}
     * @memberof KoodistoDto
     */
    koodistoUri?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KoodistoDto
     */
    nimi?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface KoodistoKoodi
 */
export interface KoodistoKoodi {
    /**
     * 
     * @type {number}
     * @memberof KoodistoKoodi
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof KoodistoKoodi
     */
    koodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof KoodistoKoodi
     */
    koodiArvo?: string;
}
/**
 * 
 * @export
 * @interface KoodistoKoodiDto
 */
export interface KoodistoKoodiDto {
    /**
     * 
     * @type {string}
     * @memberof KoodistoKoodiDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof KoodistoKoodiDto
     */
    koodiArvo?: string;
    /**
     * 
     * @type {Date}
     * @memberof KoodistoKoodiDto
     */
    voimassaAlkuPvm?: Date;
    /**
     * 
     * @type {Date}
     * @memberof KoodistoKoodiDto
     */
    voimassaLoppuPvm?: Date;
    /**
     * 
     * @type {KoodistoDto}
     * @memberof KoodistoKoodiDto
     */
    koodisto?: KoodistoDto;
    /**
     * 
     * @type {Array<KoodistoMetadataDto>}
     * @memberof KoodistoKoodiDto
     */
    metadata?: Array<KoodistoMetadataDto>;
}
/**
 * 
 * @export
 * @interface KoodistoMetadataDto
 */
export interface KoodistoMetadataDto {
    /**
     * 
     * @type {string}
     * @memberof KoodistoMetadataDto
     */
    nimi?: string;
    /**
     * 
     * @type {string}
     * @memberof KoodistoMetadataDto
     */
    kieli?: string;
    /**
     * 
     * @type {string}
     * @memberof KoodistoMetadataDto
     */
    kuvaus?: string;
}
/**
 * 
 * @export
 * @interface KotoKielitaitotasoDto
 */
export interface KotoKielitaitotasoDto {
    /**
     * 
     * @type {number}
     * @memberof KotoKielitaitotasoDto
     */
    id?: number;
    /**
     * 
     * @type {Array<KotoTaitotasoDto>}
     * @memberof KotoKielitaitotasoDto
     */
    taitotasot?: Array<KotoTaitotasoDto>;
    /**
     * 
     * @type {Array<KotoTaitotasoLaajaAlainenOsaaminenDto>}
     * @memberof KotoKielitaitotasoDto
     */
    laajaAlaisetOsaamiset?: Array<KotoTaitotasoLaajaAlainenOsaaminenDto>;
}
/**
 * 
 * @export
 * @interface KotoKielitaitotasoExportDto
 */
export interface KotoKielitaitotasoExportDto {
    /**
     * 
     * @type {number}
     * @memberof KotoKielitaitotasoExportDto
     */
    id?: number;
    /**
     * 
     * @type {Array<KotoTaitotasoDto>}
     * @memberof KotoKielitaitotasoExportDto
     */
    taitotasot?: Array<KotoTaitotasoDto>;
    /**
     * 
     * @type {Array<KotoTaitotasoLaajaAlainenOsaaminenDto>}
     * @memberof KotoKielitaitotasoExportDto
     */
    laajaAlaisetOsaamiset?: Array<KotoTaitotasoLaajaAlainenOsaaminenDto>;
    /**
     * 
     * @type {KotoKielitaitotasoDto}
     * @memberof KotoKielitaitotasoExportDto
     */
    perusteenOsa?: KotoKielitaitotasoDto;
}
/**
 * 
 * @export
 * @interface KotoLaajaAlainenOsaaminenDto
 */
export interface KotoLaajaAlainenOsaaminenDto {
    /**
     * 
     * @type {number}
     * @memberof KotoLaajaAlainenOsaaminenDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KotoLaajaAlainenOsaaminenDto
     */
    teksti?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface KotoLaajaAlainenOsaaminenExportDto
 */
export interface KotoLaajaAlainenOsaaminenExportDto {
    /**
     * 
     * @type {number}
     * @memberof KotoLaajaAlainenOsaaminenExportDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KotoLaajaAlainenOsaaminenExportDto
     */
    teksti?: { [key: string]: string; };
    /**
     * 
     * @type {KotoLaajaAlainenOsaaminenDto}
     * @memberof KotoLaajaAlainenOsaaminenExportDto
     */
    perusteenOsa?: KotoLaajaAlainenOsaaminenDto;
}
/**
 * 
 * @export
 * @interface KotoLaajaAlaisenOsaamisenAlueDto
 */
export interface KotoLaajaAlaisenOsaamisenAlueDto {
    /**
     * 
     * @type {KoodiDto}
     * @memberof KotoLaajaAlaisenOsaamisenAlueDto
     */
    koodi?: KoodiDto;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KotoLaajaAlaisenOsaamisenAlueDto
     */
    kuvaus?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface KotoOpintoDto
 */
export interface KotoOpintoDto {
    /**
     * 
     * @type {number}
     * @memberof KotoOpintoDto
     */
    id?: number;
    /**
     * 
     * @type {Array<KotoTaitotasoDto>}
     * @memberof KotoOpintoDto
     */
    taitotasot?: Array<KotoTaitotasoDto>;
    /**
     * 
     * @type {Array<KotoTaitotasoLaajaAlainenOsaaminenDto>}
     * @memberof KotoOpintoDto
     */
    laajaAlaisetOsaamiset?: Array<KotoTaitotasoLaajaAlainenOsaaminenDto>;
}
/**
 * 
 * @export
 * @interface KotoOpintoExportDto
 */
export interface KotoOpintoExportDto {
    /**
     * 
     * @type {number}
     * @memberof KotoOpintoExportDto
     */
    id?: number;
    /**
     * 
     * @type {Array<KotoTaitotasoDto>}
     * @memberof KotoOpintoExportDto
     */
    taitotasot?: Array<KotoTaitotasoDto>;
    /**
     * 
     * @type {Array<KotoTaitotasoLaajaAlainenOsaaminenDto>}
     * @memberof KotoOpintoExportDto
     */
    laajaAlaisetOsaamiset?: Array<KotoTaitotasoLaajaAlainenOsaaminenDto>;
    /**
     * 
     * @type {KotoOpintoDto}
     * @memberof KotoOpintoExportDto
     */
    perusteenOsa?: KotoOpintoDto;
}
/**
 * 
 * @export
 * @interface KotoTaitotasoDto
 */
export interface KotoTaitotasoDto {
    /**
     * 
     * @type {number}
     * @memberof KotoTaitotasoDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof KotoTaitotasoDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KotoTaitotasoDto
     */
    tavoiteTarkennus?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KotoTaitotasoDto
     */
    sisaltoTarkennus?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface KotoTaitotasoLaajaAlainenOsaaminenDto
 */
export interface KotoTaitotasoLaajaAlainenOsaaminenDto {
    /**
     * 
     * @type {number}
     * @memberof KotoTaitotasoLaajaAlainenOsaaminenDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof KotoTaitotasoLaajaAlainenOsaaminenDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KotoTaitotasoLaajaAlainenOsaaminenDto
     */
    teksti?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface KoulutuksenJarjestajaDto
 */
export interface KoulutuksenJarjestajaDto {
    /**
     * 
     * @type {number}
     * @memberof KoulutuksenJarjestajaDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof KoulutuksenJarjestajaDto
     */
    oid?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KoulutuksenJarjestajaDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KoulutuksenJarjestajaDto
     */
    url?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KoulutuksenJarjestajaDto
     */
    kuvaus?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface KoulutuksenOsaDto
 */
export interface KoulutuksenOsaDto {
    /**
     * 
     * @type {number}
     * @memberof KoulutuksenOsaDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KoulutuksenOsaDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KoulutuksenOsaDto
     */
    kooditettuNimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof KoulutuksenOsaDto
     */
    nimiKoodi?: string;
    /**
     * 
     * @type {number}
     * @memberof KoulutuksenOsaDto
     */
    laajuusMinimi?: number;
    /**
     * 
     * @type {number}
     * @memberof KoulutuksenOsaDto
     */
    laajuusMaksimi?: number;
    /**
     * 
     * @type {string}
     * @memberof KoulutuksenOsaDto
     */
    koulutusOsanKoulutustyyppi?: KoulutuksenOsaDtoKoulutusOsanKoulutustyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof KoulutuksenOsaDto
     */
    koulutusOsanTyyppi?: KoulutuksenOsaDtoKoulutusOsanTyyppiEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KoulutuksenOsaDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {Array<{ [key: string]: string; }>}
     * @memberof KoulutuksenOsaDto
     */
    tavoitteet?: Array<{ [key: string]: string; }>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KoulutuksenOsaDto
     */
    keskeinenSisalto?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KoulutuksenOsaDto
     */
    laajaAlaisenOsaamisenKuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KoulutuksenOsaDto
     */
    arvioinninKuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {KoulutuksenosanPaikallinenTarkennusDto}
     * @memberof KoulutuksenOsaDto
     */
    paikallinenTarkennus?: KoulutuksenosanPaikallinenTarkennusDto;
    /**
     * 
     * @type {string}
     * @memberof KoulutuksenOsaDto
     */
    koodiArvo?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum KoulutuksenOsaDtoKoulutusOsanKoulutustyyppiEnum {
    TUTKINTOKOULUTUKSEENVALMENTAVA = 'TUTKINTOKOULUTUKSEENVALMENTAVA',
    PERUSOPETUS = 'PERUSOPETUS',
    LUKIOKOULUTUS = 'LUKIOKOULUTUS',
    AMMATILLINENKOULUTUS = 'AMMATILLINENKOULUTUS'
}
/**
    * @export
    * @enum {string}
    */
export enum KoulutuksenOsaDtoKoulutusOsanTyyppiEnum {
    YHTEINEN = 'YHTEINEN',
    VALINNAINEN = 'VALINNAINEN'
}

/**
 * 
 * @export
 * @interface KoulutuksenOsaKevytDto
 */
export interface KoulutuksenOsaKevytDto {
    /**
     * 
     * @type {number}
     * @memberof KoulutuksenOsaKevytDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KoulutuksenOsaKevytDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KoulutuksenOsaKevytDto
     */
    kooditettuNimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof KoulutuksenOsaKevytDto
     */
    nimiKoodi?: string;
    /**
     * 
     * @type {string}
     * @memberof KoulutuksenOsaKevytDto
     */
    koodiArvo?: string;
}
/**
 * 
 * @export
 * @interface KoulutuksenosaLaajaalainenOsaaminenDto
 */
export interface KoulutuksenosaLaajaalainenOsaaminenDto {
    /**
     * 
     * @type {number}
     * @memberof KoulutuksenosaLaajaalainenOsaaminenDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof KoulutuksenosaLaajaalainenOsaaminenDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KoulutuksenosaLaajaalainenOsaaminenDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KoulutuksenosaLaajaalainenOsaaminenDto
     */
    laajaAlaisenOsaamisenKuvaus?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface KoulutuksenosanPaikallinenTarkennusDto
 */
export interface KoulutuksenosanPaikallinenTarkennusDto {
    /**
     * 
     * @type {number}
     * @memberof KoulutuksenosanPaikallinenTarkennusDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KoulutuksenosanPaikallinenTarkennusDto
     */
    tavoitteetKuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {Array<{ [key: string]: string; }>}
     * @memberof KoulutuksenosanPaikallinenTarkennusDto
     */
    tavoitteet?: Array<{ [key: string]: string; }>;
    /**
     * 
     * @type {Array<KoulutuksenosaLaajaalainenOsaaminenDto>}
     * @memberof KoulutuksenosanPaikallinenTarkennusDto
     */
    laajaalaisetosaamiset?: Array<KoulutuksenosaLaajaalainenOsaaminenDto>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KoulutuksenosanPaikallinenTarkennusDto
     */
    keskeinenSisalto?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KoulutuksenosanPaikallinenTarkennusDto
     */
    arvioinninKuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {Array<KoulutuksenJarjestajaDto>}
     * @memberof KoulutuksenosanPaikallinenTarkennusDto
     */
    koulutuksenJarjestajat?: Array<KoulutuksenJarjestajaDto>;
}
/**
 * 
 * @export
 * @interface KoulutusDto
 */
export interface KoulutusDto {
    /**
     * 
     * @type {number}
     * @memberof KoulutusDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KoulutusDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof KoulutusDto
     */
    koulutuskoodiArvo?: string;
    /**
     * 
     * @type {string}
     * @memberof KoulutusDto
     */
    koulutuskoodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof KoulutusDto
     */
    koulutusalakoodi?: string;
    /**
     * 
     * @type {string}
     * @memberof KoulutusDto
     */
    opintoalakoodi?: string;
}
/**
 * 
 * @export
 * @interface Koulutuskoodi
 */
export interface Koulutuskoodi {
    /**
     * 
     * @type {number}
     * @memberof Koulutuskoodi
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTeksti}
     * @memberof Koulutuskoodi
     */
    nimi?: LokalisoituTeksti;
    /**
     * 
     * @type {string}
     * @memberof Koulutuskoodi
     */
    koulutuskoodiArvo?: string;
    /**
     * 
     * @type {string}
     * @memberof Koulutuskoodi
     */
    koulutuskoodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof Koulutuskoodi
     */
    koulutusalakoodi?: string;
    /**
     * 
     * @type {string}
     * @memberof Koulutuskoodi
     */
    opintoalakoodi?: string;
    /**
     * 
     * @type {CachedPeruste}
     * @memberof Koulutuskoodi
     */
    cachedPeruste?: CachedPeruste;
}
/**
 * 
 * @export
 * @interface KoulutustoimijaBaseDto
 */
export interface KoulutustoimijaBaseDto {
    /**
     * 
     * @type {number}
     * @memberof KoulutustoimijaBaseDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof KoulutustoimijaBaseDto
     */
    organisaatio?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KoulutustoimijaBaseDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof KoulutustoimijaBaseDto
     */
    organisaatioRyhma?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KoulutustoimijaBaseDto
     */
    oppilaitostyyppi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof KoulutustoimijaBaseDto
     */
    oppilaitosTyyppiKoodiUri?: string;
}
/**
 * 
 * @export
 * @interface KoulutustoimijaDto
 */
export interface KoulutustoimijaDto {
    /**
     * 
     * @type {number}
     * @memberof KoulutustoimijaDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof KoulutustoimijaDto
     */
    organisaatio?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KoulutustoimijaDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof KoulutustoimijaDto
     */
    organisaatioRyhma?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KoulutustoimijaDto
     */
    oppilaitostyyppi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof KoulutustoimijaDto
     */
    oppilaitosTyyppiKoodiUri?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KoulutustoimijaDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {Array<Reference>}
     * @memberof KoulutustoimijaDto
     */
    ystavat?: Array<Reference>;
    /**
     * 
     * @type {boolean}
     * @memberof KoulutustoimijaDto
     */
    salliystavat?: boolean;
}
/**
 * 
 * @export
 * @interface KoulutustoimijaJulkinenDto
 */
export interface KoulutustoimijaJulkinenDto {
    /**
     * 
     * @type {number}
     * @memberof KoulutustoimijaJulkinenDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof KoulutustoimijaJulkinenDto
     */
    organisaatio?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KoulutustoimijaJulkinenDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof KoulutustoimijaJulkinenDto
     */
    organisaatioRyhma?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KoulutustoimijaJulkinenDto
     */
    oppilaitostyyppi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof KoulutustoimijaJulkinenDto
     */
    oppilaitosTyyppiKoodiUri?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KoulutustoimijaJulkinenDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof KoulutustoimijaJulkinenDto
     */
    salliystavat?: boolean;
}
/**
 * 
 * @export
 * @interface KoulutustoimijaYstavaDto
 */
export interface KoulutustoimijaYstavaDto {
    /**
     * 
     * @type {number}
     * @memberof KoulutustoimijaYstavaDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof KoulutustoimijaYstavaDto
     */
    organisaatio?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KoulutustoimijaYstavaDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof KoulutustoimijaYstavaDto
     */
    organisaatioRyhma?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KoulutustoimijaYstavaDto
     */
    oppilaitostyyppi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof KoulutustoimijaYstavaDto
     */
    oppilaitosTyyppiKoodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof KoulutustoimijaYstavaDto
     */
    status?: KoulutustoimijaYstavaDtoStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum KoulutustoimijaYstavaDtoStatusEnum {
    YHTEISTYO = 'YHTEISTYO',
    ODOTETAAN = 'ODOTETAAN'
}

/**
 * 
 * @export
 * @interface Laajuus
 */
export interface Laajuus {
    /**
     * 
     * @type {number}
     * @memberof Laajuus
     */
    minimi?: number;
    /**
     * 
     * @type {number}
     * @memberof Laajuus
     */
    maksimi?: number;
    /**
     * 
     * @type {string}
     * @memberof Laajuus
     */
    yksikko?: LaajuusYksikkoEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum LaajuusYksikkoEnum {
    OPINTOVIIKKO = 'OPINTOVIIKKO',
    OPINTOPISTE = 'OPINTOPISTE',
    OSAAMISPISTE = 'OSAAMISPISTE',
    KURSSI = 'KURSSI',
    TUNTI = 'TUNTI',
    VIIKKO = 'VIIKKO',
    VUOSIVIIKKOTUNTI = 'VUOSIVIIKKOTUNTI',
    VUOSI = 'VUOSI'
}

/**
 * 
 * @export
 * @interface LiiteDto
 */
export interface LiiteDto {
    /**
     * 
     * @type {string}
     * @memberof LiiteDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof LiiteDto
     */
    tyyppi?: string;
    /**
     * 
     * @type {string}
     * @memberof LiiteDto
     */
    nimi?: string;
    /**
     * 
     * @type {Date}
     * @memberof LiiteDto
     */
    luotu?: Date;
}
/**
 * 
 * @export
 * @interface LokalisoituTeksti
 */
export interface LokalisoituTeksti {
    /**
     * 
     * @type {number}
     * @memberof LokalisoituTeksti
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LokalisoituTeksti
     */
    tunniste?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LokalisoituTeksti
     */
    teksti?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LokalisoituTeksti
     */
    tekstiAsStringMap?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface LokalisoituTekstiDto
 */
export interface LokalisoituTekstiDto {
    /**
     * 
     * @type {number}
     * @memberof LokalisoituTekstiDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LokalisoituTekstiDto
     */
    tunniste?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LokalisoituTekstiDto
     */
    tekstit?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface LukkoDto
 */
export interface LukkoDto {
    /**
     * 
     * @type {string}
     * @memberof LukkoDto
     */
    haltijaOid?: string;
    /**
     * 
     * @type {string}
     * @memberof LukkoDto
     */
    haltijaNimi?: string;
    /**
     * 
     * @type {number}
     * @memberof LukkoDto
     */
    luotu?: number;
    /**
     * 
     * @type {number}
     * @memberof LukkoDto
     */
    vanhentuu?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LukkoDto
     */
    oma?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LukkoDto
     */
    vanhentunut?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LukkoDto
     */
    revisio?: number;
}
/**
 * 
 * @export
 * @interface Matala
 */
export interface Matala {
    /**
     * 
     * @type {number}
     * @memberof Matala
     */
    id?: number;
    /**
     * 
     * @type {TekstiKappaleDto}
     * @memberof Matala
     */
    tekstiKappale?: TekstiKappaleDto;
    /**
     * 
     * @type {TekstiKappaleDto}
     * @memberof Matala
     */
    pohjanTekstikappale?: TekstiKappaleDto;
    /**
     * 
     * @type {boolean}
     * @memberof Matala
     */
    pakollinen?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Matala
     */
    valmis?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Matala
     */
    liikkumaton?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Matala
     */
    naytaPohjanTeksti?: boolean;
    /**
     * 
     * @type {Reference}
     * @memberof Matala
     */
    owner?: Reference;
    /**
     * 
     * @type {Reference}
     * @memberof Matala
     */
    vanhempi?: Reference;
    /**
     * 
     * @type {CachedPerusteBaseDto}
     * @memberof Matala
     */
    peruste?: CachedPerusteBaseDto;
    /**
     * 
     * @type {string}
     * @memberof Matala
     */
    tyyppi?: MatalaTyyppiEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Matala
     */
    ohjeteksti?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Matala
     */
    perusteteksti?: { [key: string]: string; };
    /**
     * 
     * @type {TutkinnonosaDto}
     * @memberof Matala
     */
    tosa?: TutkinnonosaDto;
    /**
     * 
     * @type {SuorituspolkuDto}
     * @memberof Matala
     */
    suorituspolku?: SuorituspolkuDto;
    /**
     * 
     * @type {OpintokokonaisuusDto}
     * @memberof Matala
     */
    opintokokonaisuus?: OpintokokonaisuusDto;
    /**
     * 
     * @type {KoulutuksenOsaDto}
     * @memberof Matala
     */
    koulutuksenosa?: KoulutuksenOsaDto;
    /**
     * 
     * @type {TuvaLaajaAlainenOsaaminenDto}
     * @memberof Matala
     */
    tuvaLaajaAlainenOsaaminen?: TuvaLaajaAlainenOsaaminenDto;
    /**
     * 
     * @type {KotoKielitaitotasoDto}
     * @memberof Matala
     */
    kotoKielitaitotaso?: KotoKielitaitotasoDto;
    /**
     * 
     * @type {KotoOpintoDto}
     * @memberof Matala
     */
    kotoOpinto?: KotoOpintoDto;
    /**
     * 
     * @type {KotoLaajaAlainenOsaaminenDto}
     * @memberof Matala
     */
    kotoLaajaAlainenOsaaminen?: KotoLaajaAlainenOsaaminenDto;
    /**
     * 
     * @type {number}
     * @memberof Matala
     */
    perusteenOsaId?: number;
    /**
     * 
     * @type {string}
     * @memberof Matala
     */
    kommentti?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Matala
     */
    naytaPerusteenTeksti?: boolean;
    /**
     * 
     * @type {Array<OmaOsaAlueDto>}
     * @memberof Matala
     */
    osaAlueet?: Array<OmaOsaAlueDto>;
    /**
     * 
     * @type {number}
     * @memberof Matala
     */
    linkattuOps?: number;
    /**
     * 
     * @type {number}
     * @memberof Matala
     */
    linkattuSisaltoViiteId?: number;
    /**
     * 
     * @type {number}
     * @memberof Matala
     */
    linkattuPeruste?: number;
    /**
     * 
     * @type {string}
     * @memberof Matala
     */
    linkattuTyyppi?: MatalaLinkattuTyyppiEnum;
    /**
     * 
     * @type {OsaamismerkkiKappaleDto}
     * @memberof Matala
     */
    osaamismerkkiKappale?: OsaamismerkkiKappaleDto;
    /**
     * 
     * @type {Array<Reference>}
     * @memberof Matala
     */
    lapset?: Array<Reference>;
    /**
     * 
     * @type {Reference}
     * @memberof Matala
     */
    _tekstiKappale?: Reference;
}

/**
    * @export
    * @enum {string}
    */
export enum MatalaTyyppiEnum {
    TEKSTIKAPPALE = 'TEKSTIKAPPALE',
    TUTKINNONOSAT = 'TUTKINNONOSAT',
    TUTKINNONOSA = 'TUTKINNONOSA',
    TOSARYHMA = 'TOSARYHMA',
    SUORITUSPOLUT = 'SUORITUSPOLUT',
    SUORITUSPOLKU = 'SUORITUSPOLKU',
    OSASUORITUSPOLKU = 'OSASUORITUSPOLKU',
    OPINTOKOKONAISUUS = 'OPINTOKOKONAISUUS',
    KOULUTUKSENOSA = 'KOULUTUKSENOSA',
    KOULUTUKSENOSAT = 'KOULUTUKSENOSAT',
    LAAJAALAINENOSAAMINEN = 'LAAJAALAINENOSAAMINEN',
    KOTOKIELITAITOTASO = 'KOTO_KIELITAITOTASO',
    KOTOOPINTO = 'KOTO_OPINTO',
    KOTOLAAJAALAINENOSAAMINEN = 'KOTO_LAAJAALAINENOSAAMINEN',
    OSAAMISMERKKI = 'OSAAMISMERKKI',
    LINKKI = 'LINKKI'
}
/**
    * @export
    * @enum {string}
    */
export enum MatalaLinkattuTyyppiEnum {
    TEKSTIKAPPALE = 'TEKSTIKAPPALE',
    TUTKINNONOSAT = 'TUTKINNONOSAT',
    TUTKINNONOSA = 'TUTKINNONOSA',
    TOSARYHMA = 'TOSARYHMA',
    SUORITUSPOLUT = 'SUORITUSPOLUT',
    SUORITUSPOLKU = 'SUORITUSPOLKU',
    OSASUORITUSPOLKU = 'OSASUORITUSPOLKU',
    OPINTOKOKONAISUUS = 'OPINTOKOKONAISUUS',
    KOULUTUKSENOSA = 'KOULUTUKSENOSA',
    KOULUTUKSENOSAT = 'KOULUTUKSENOSAT',
    LAAJAALAINENOSAAMINEN = 'LAAJAALAINENOSAAMINEN',
    KOTOKIELITAITOTASO = 'KOTO_KIELITAITOTASO',
    KOTOOPINTO = 'KOTO_OPINTO',
    KOTOLAAJAALAINENOSAAMINEN = 'KOTO_LAAJAALAINENOSAAMINEN',
    OSAAMISMERKKI = 'OSAAMISMERKKI',
    LINKKI = 'LINKKI'
}

/**
 * 
 * @export
 * @interface MuodostumisSaantoDto
 */
export interface MuodostumisSaantoDto {
    /**
     * 
     * @type {Laajuus}
     * @memberof MuodostumisSaantoDto
     */
    laajuus?: Laajuus;
    /**
     * 
     * @type {Koko}
     * @memberof MuodostumisSaantoDto
     */
    koko?: Koko;
}
/**
 * 
 * @export
 * @interface NavigationNodeDto
 */
export interface NavigationNodeDto {
    /**
     * 
     * @type {number}
     * @memberof NavigationNodeDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof NavigationNodeDto
     */
    label?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof NavigationNodeDto
     */
    type?: NavigationNodeDtoTypeEnum;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof NavigationNodeDto
     */
    meta?: { [key: string]: object; };
    /**
     * 
     * @type {Array<NavigationNodeDto>}
     * @memberof NavigationNodeDto
     */
    children?: Array<NavigationNodeDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum NavigationNodeDtoTypeEnum {
    Root = 'root',
    Tiedot = 'tiedot',
    Viite = 'viite',
    Liitteet = 'liitteet',
    Liite = 'liite',
    Peruste = 'peruste',
    Opetussuunnitelma = 'opetussuunnitelma',
    Tekstikappale = 'tekstikappale',
    Tutkinnonosat = 'tutkinnonosat',
    TutkinnonosatPakolliset = 'tutkinnonosat_pakolliset',
    TutkinnonosatPaikalliset = 'tutkinnonosat_paikalliset',
    TutkinnonosatTuodut = 'tutkinnonosat_tuodut',
    ValmatelmaKoulutuksenosat = 'valmatelmaKoulutuksenosat',
    Tutkinnonosa = 'tutkinnonosa',
    Tosaryhma = 'tosaryhma',
    Suorituspolut = 'suorituspolut',
    Suorituspolku = 'suorituspolku',
    Osasuorituspolku = 'osasuorituspolku',
    Opintokokonaisuus = 'opintokokonaisuus',
    Koulutuksenosa = 'koulutuksenosa',
    Koulutuksenosat = 'koulutuksenosat',
    Laajaalainenosaaminen = 'laajaalainenosaaminen',
    KotoKielitaitotaso = 'koto_kielitaitotaso',
    KotoOpinto = 'koto_opinto',
    KotoLaajaalainenosaaminen = 'koto_laajaalainenosaaminen',
    OpetussuunnitelmaRakenne = 'opetussuunnitelma_rakenne',
    Linkki = 'linkki',
    PakollisetOsaalueet = 'pakolliset_osaalueet',
    ValinnaisetOsaalueet = 'valinnaiset_osaalueet',
    PaikallisetOsaalueet = 'paikalliset_osaalueet',
    Osaalue = 'osaalue',
    Osaamismerkki = 'osaamismerkki'
}

/**
 * 
 * @export
 * @interface OhjeDto
 */
export interface OhjeDto {
    /**
     * 
     * @type {number}
     * @memberof OhjeDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OhjeDto
     */
    kysymys?: string;
    /**
     * 
     * @type {string}
     * @memberof OhjeDto
     */
    vastaus?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OhjeDto
     */
    lokalisoituKysymys?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OhjeDto
     */
    lokalisoituVastaus?: { [key: string]: string; };
    /**
     * 
     * @type {Date}
     * @memberof OhjeDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {Array<KoulutustoimijaBaseDto>}
     * @memberof OhjeDto
     */
    koulutustoimijat?: Array<KoulutustoimijaBaseDto>;
    /**
     * 
     * @type {string}
     * @memberof OhjeDto
     */
    toteutus?: OhjeDtoToteutusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum OhjeDtoToteutusEnum {
    YKSINKERTAINEN = 'YKSINKERTAINEN',
    PERUSOPETUS = 'PERUSOPETUS',
    LOPS = 'LOPS',
    AMMATILLINEN = 'AMMATILLINEN',
    TPO = 'TPO',
    LOPS2019 = 'LOPS2019',
    VAPAASIVISTYSTYO = 'VAPAASIVISTYSTYO',
    TUTKINTOONVALMENTAVA = 'TUTKINTOONVALMENTAVA',
    KOTOUTUMISKOULUTUS = 'KOTOUTUMISKOULUTUS'
}

/**
 * 
 * @export
 * @interface OletusToteutusDto
 */
export interface OletusToteutusDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OletusToteutusDto
     */
    lahdeNimi?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OletusToteutusDto
     */
    otsikko?: { [key: string]: string; };
    /**
     * 
     * @type {TekstiosaDto}
     * @memberof OletusToteutusDto
     */
    tavatjaymparisto?: TekstiosaDto;
    /**
     * 
     * @type {TekstiosaDto}
     * @memberof OletusToteutusDto
     */
    arvioinnista?: TekstiosaDto;
    /**
     * 
     * @type {Array<VapaaTekstiDto>}
     * @memberof OletusToteutusDto
     */
    vapaat?: Array<VapaaTekstiDto>;
}
/**
 * 
 * @export
 * @interface OmaOsaAlueDto
 */
export interface OmaOsaAlueDto {
    /**
     * 
     * @type {number}
     * @memberof OmaOsaAlueDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OmaOsaAlueDto
     */
    tyyppi?: OmaOsaAlueDtoTyyppiEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OmaOsaAlueDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof OmaOsaAlueDto
     */
    perusteenOsaAlueKoodi?: string;
    /**
     * 
     * @type {number}
     * @memberof OmaOsaAlueDto
     */
    perusteenOsaAlueId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OmaOsaAlueDto
     */
    piilotettu?: boolean;
    /**
     * 
     * @type {Array<OmaOsaAlueToteutusDto>}
     * @memberof OmaOsaAlueDto
     */
    toteutukset?: Array<OmaOsaAlueToteutusDto>;
    /**
     * 
     * @type {PaikallisetAmmattitaitovaatimukset2019Dto}
     * @memberof OmaOsaAlueDto
     */
    osaamistavoitteet?: PaikallisetAmmattitaitovaatimukset2019Dto;
    /**
     * 
     * @type {number}
     * @memberof OmaOsaAlueDto
     */
    geneerinenarviointi?: number;
    /**
     * 
     * @type {number}
     * @memberof OmaOsaAlueDto
     */
    laajuus?: number;
    /**
     * 
     * @type {string}
     * @memberof OmaOsaAlueDto
     */
    koodi?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OmaOsaAlueDto
     */
    paikallinenTarkennus?: { [key: string]: string; };
    /**
     * 
     * @type {Array<KevytTekstiKappaleDto>}
     * @memberof OmaOsaAlueDto
     */
    vapaat?: Array<KevytTekstiKappaleDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum OmaOsaAlueDtoTyyppiEnum {
    PAKOLLINEN = 'PAKOLLINEN',
    VALINNAINEN = 'VALINNAINEN',
    PAIKALLINEN = 'PAIKALLINEN'
}

/**
 * 
 * @export
 * @interface OmaOsaAlueExportDto
 */
export interface OmaOsaAlueExportDto {
    /**
     * 
     * @type {number}
     * @memberof OmaOsaAlueExportDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OmaOsaAlueExportDto
     */
    tyyppi?: OmaOsaAlueExportDtoTyyppiEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OmaOsaAlueExportDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof OmaOsaAlueExportDto
     */
    perusteenOsaAlueKoodi?: string;
    /**
     * 
     * @type {number}
     * @memberof OmaOsaAlueExportDto
     */
    perusteenOsaAlueId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OmaOsaAlueExportDto
     */
    piilotettu?: boolean;
    /**
     * 
     * @type {Array<OmaOsaAlueToteutusDto>}
     * @memberof OmaOsaAlueExportDto
     */
    toteutukset?: Array<OmaOsaAlueToteutusDto>;
    /**
     * 
     * @type {PaikallisetAmmattitaitovaatimukset2019Dto}
     * @memberof OmaOsaAlueExportDto
     */
    osaamistavoitteet?: PaikallisetAmmattitaitovaatimukset2019Dto;
    /**
     * 
     * @type {number}
     * @memberof OmaOsaAlueExportDto
     */
    geneerinenarviointi?: number;
    /**
     * 
     * @type {number}
     * @memberof OmaOsaAlueExportDto
     */
    laajuus?: number;
    /**
     * 
     * @type {string}
     * @memberof OmaOsaAlueExportDto
     */
    koodi?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OmaOsaAlueExportDto
     */
    paikallinenTarkennus?: { [key: string]: string; };
    /**
     * 
     * @type {Array<KevytTekstiKappaleDto>}
     * @memberof OmaOsaAlueExportDto
     */
    vapaat?: Array<KevytTekstiKappaleDto>;
    /**
     * 
     * @type {GeneerinenArviointiasteikkoKaikkiDto}
     * @memberof OmaOsaAlueExportDto
     */
    geneerinenArviointiasteikko?: GeneerinenArviointiasteikkoKaikkiDto;
}

/**
    * @export
    * @enum {string}
    */
export enum OmaOsaAlueExportDtoTyyppiEnum {
    PAKOLLINEN = 'PAKOLLINEN',
    VALINNAINEN = 'VALINNAINEN',
    PAIKALLINEN = 'PAIKALLINEN'
}

/**
 * 
 * @export
 * @interface OmaOsaAlueKevytDto
 */
export interface OmaOsaAlueKevytDto {
    /**
     * 
     * @type {number}
     * @memberof OmaOsaAlueKevytDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OmaOsaAlueKevytDto
     */
    tyyppi?: OmaOsaAlueKevytDtoTyyppiEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OmaOsaAlueKevytDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof OmaOsaAlueKevytDto
     */
    perusteenOsaAlueId?: number;
    /**
     * 
     * @type {string}
     * @memberof OmaOsaAlueKevytDto
     */
    perusteenOsaAlueKoodi?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OmaOsaAlueKevytDto
     */
    piilotettu?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OmaOsaAlueKevytDto
     */
    koodi?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum OmaOsaAlueKevytDtoTyyppiEnum {
    PAKOLLINEN = 'PAKOLLINEN',
    VALINNAINEN = 'VALINNAINEN',
    PAIKALLINEN = 'PAIKALLINEN'
}

/**
 * 
 * @export
 * @interface OmaOsaAlueToteutusDto
 */
export interface OmaOsaAlueToteutusDto {
    /**
     * 
     * @type {number}
     * @memberof OmaOsaAlueToteutusDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OmaOsaAlueToteutusDto
     */
    otsikko?: { [key: string]: string; };
    /**
     * 
     * @type {TekstiosaDto}
     * @memberof OmaOsaAlueToteutusDto
     */
    tavatjaymparisto?: TekstiosaDto;
    /**
     * 
     * @type {TekstiosaDto}
     * @memberof OmaOsaAlueToteutusDto
     */
    arvioinnista?: TekstiosaDto;
    /**
     * 
     * @type {Array<VapaaTekstiDto>}
     * @memberof OmaOsaAlueToteutusDto
     */
    vapaat?: Array<VapaaTekstiDto>;
    /**
     * 
     * @type {boolean}
     * @memberof OmaOsaAlueToteutusDto
     */
    oletustoteutus?: boolean;
}
/**
 * 
 * @export
 * @interface OmaTutkinnonosa
 */
export interface OmaTutkinnonosa {
    /**
     * 
     * @type {Date}
     * @memberof OmaTutkinnonosa
     */
    luotu?: Date;
    /**
     * 
     * @type {string}
     * @memberof OmaTutkinnonosa
     */
    luoja?: string;
    /**
     * 
     * @type {Date}
     * @memberof OmaTutkinnonosa
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof OmaTutkinnonosa
     */
    muokkaaja?: string;
    /**
     * 
     * @type {number}
     * @memberof OmaTutkinnonosa
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTeksti}
     * @memberof OmaTutkinnonosa
     */
    tavoitteet?: LokalisoituTeksti;
    /**
     * 
     * @type {string}
     * @memberof OmaTutkinnonosa
     */
    koodiPrefix?: string;
    /**
     * 
     * @type {number}
     * @memberof OmaTutkinnonosa
     */
    laajuus?: number;
    /**
     * 
     * @type {string}
     * @memberof OmaTutkinnonosa
     */
    koodi?: string;
    /**
     * 
     * @type {number}
     * @memberof OmaTutkinnonosa
     */
    geneerinenarviointi?: number;
    /**
     * 
     * @type {Ammattitaitovaatimukset2019}
     * @memberof OmaTutkinnonosa
     */
    ammattitaitovaatimukset?: Ammattitaitovaatimukset2019;
    /**
     * 
     * @type {Array<AmmattitaitovaatimuksenKohdealue>}
     * @memberof OmaTutkinnonosa
     */
    ammattitaitovaatimuksetLista?: Array<AmmattitaitovaatimuksenKohdealue>;
    /**
     * 
     * @type {Arviointi}
     * @memberof OmaTutkinnonosa
     */
    arviointi?: Arviointi;
    /**
     * 
     * @type {LokalisoituTeksti}
     * @memberof OmaTutkinnonosa
     */
    ammattitaidonOsoittamistavat?: LokalisoituTeksti;
}
/**
 * 
 * @export
 * @interface OmaTutkinnonosaDto
 */
export interface OmaTutkinnonosaDto {
    /**
     * 
     * @type {number}
     * @memberof OmaTutkinnonosaDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OmaTutkinnonosaDto
     */
    koodi?: string;
    /**
     * 
     * @type {number}
     * @memberof OmaTutkinnonosaDto
     */
    laajuus?: number;
    /**
     * 
     * @type {number}
     * @memberof OmaTutkinnonosaDto
     */
    geneerinenarviointi?: number;
    /**
     * 
     * @type {PaikallisetAmmattitaitovaatimukset2019Dto}
     * @memberof OmaTutkinnonosaDto
     */
    ammattitaitovaatimukset?: PaikallisetAmmattitaitovaatimukset2019Dto;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OmaTutkinnonosaDto
     */
    ammattitaidonOsoittamistavat?: { [key: string]: string; };
    /**
     * 
     * @type {Array<AmmattitaitovaatimuksenKohdealueDto>}
     * @memberof OmaTutkinnonosaDto
     */
    ammattitaitovaatimuksetLista?: Array<AmmattitaitovaatimuksenKohdealueDto>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OmaTutkinnonosaDto
     */
    tavoitteet?: { [key: string]: string; };
    /**
     * 
     * @type {ArviointiDto}
     * @memberof OmaTutkinnonosaDto
     */
    arviointi?: ArviointiDto;
}
/**
 * 
 * @export
 * @interface OmaTutkinnonosaExportDto
 */
export interface OmaTutkinnonosaExportDto {
    /**
     * 
     * @type {number}
     * @memberof OmaTutkinnonosaExportDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OmaTutkinnonosaExportDto
     */
    koodi?: string;
    /**
     * 
     * @type {number}
     * @memberof OmaTutkinnonosaExportDto
     */
    laajuus?: number;
    /**
     * 
     * @type {number}
     * @memberof OmaTutkinnonosaExportDto
     */
    geneerinenarviointi?: number;
    /**
     * 
     * @type {PaikallisetAmmattitaitovaatimukset2019Dto}
     * @memberof OmaTutkinnonosaExportDto
     */
    ammattitaitovaatimukset?: PaikallisetAmmattitaitovaatimukset2019Dto;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OmaTutkinnonosaExportDto
     */
    ammattitaidonOsoittamistavat?: { [key: string]: string; };
    /**
     * 
     * @type {Array<AmmattitaitovaatimuksenKohdealueDto>}
     * @memberof OmaTutkinnonosaExportDto
     */
    ammattitaitovaatimuksetLista?: Array<AmmattitaitovaatimuksenKohdealueDto>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OmaTutkinnonosaExportDto
     */
    tavoitteet?: { [key: string]: string; };
    /**
     * 
     * @type {ArviointiExportDto}
     * @memberof OmaTutkinnonosaExportDto
     */
    arviointi?: ArviointiExportDto;
    /**
     * 
     * @type {GeneerinenArviointiasteikkoKaikkiDto}
     * @memberof OmaTutkinnonosaExportDto
     */
    geneerinenArviointiasteikko?: GeneerinenArviointiasteikkoKaikkiDto;
}
/**
 * 
 * @export
 * @interface OmaTutkinnonosaIntegrationDto
 */
export interface OmaTutkinnonosaIntegrationDto {
    /**
     * 
     * @type {number}
     * @memberof OmaTutkinnonosaIntegrationDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OmaTutkinnonosaIntegrationDto
     */
    koodi?: string;
    /**
     * 
     * @type {string}
     * @memberof OmaTutkinnonosaIntegrationDto
     */
    koodiPrefix?: string;
}
/**
 * 
 * @export
 * @interface OmaTutkinnonosaKevytDto
 */
export interface OmaTutkinnonosaKevytDto {
    /**
     * 
     * @type {number}
     * @memberof OmaTutkinnonosaKevytDto
     */
    laajuus?: number;
}
/**
 * 
 * @export
 * @interface OmaTutkinnonosaSuorituspolkuDto
 */
export interface OmaTutkinnonosaSuorituspolkuDto {
    /**
     * 
     * @type {number}
     * @memberof OmaTutkinnonosaSuorituspolkuDto
     */
    laajuus?: number;
}
/**
 * 
 * @export
 * @interface OpetussuunnitelmaAikatauluDto
 */
export interface OpetussuunnitelmaAikatauluDto {
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmaAikatauluDto
     */
    id?: number;
    /**
     * 
     * @type {OpetussuunnitelmaBaseDto}
     * @memberof OpetussuunnitelmaAikatauluDto
     */
    opetussuunnitelma?: OpetussuunnitelmaBaseDto;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetussuunnitelmaAikatauluDto
     */
    tavoite?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaAikatauluDto
     */
    tapahtuma?: OpetussuunnitelmaAikatauluDtoTapahtumaEnum;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaAikatauluDto
     */
    tapahtumapaiva?: Date;
}

/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaAikatauluDtoTapahtumaEnum {
    LUOMINEN = 'LUOMINEN',
    TAVOITE = 'TAVOITE',
    JULKAISU = 'JULKAISU'
}

/**
 * 
 * @export
 * @interface OpetussuunnitelmaBaseDto
 */
export interface OpetussuunnitelmaBaseDto {
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmaBaseDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetussuunnitelmaBaseDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaBaseDto
     */
    tila?: OpetussuunnitelmaBaseDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaBaseDto
     */
    tyyppi?: OpetussuunnitelmaBaseDtoTyyppiEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetussuunnitelmaBaseDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {KoulutustoimijaBaseDto}
     * @memberof OpetussuunnitelmaBaseDto
     */
    koulutustoimija?: KoulutustoimijaBaseDto;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaBaseDto
     */
    luotu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaBaseDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {Reference}
     * @memberof OpetussuunnitelmaBaseDto
     */
    pohja?: Reference;
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmaBaseDto
     */
    perusteId?: number;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaBaseDto
     */
    perusteDiaarinumero?: string;
    /**
     * 
     * @type {CachedPerusteBaseDto}
     * @memberof OpetussuunnitelmaBaseDto
     */
    peruste?: CachedPerusteBaseDto;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaBaseDto
     */
    jotpatyyppi?: OpetussuunnitelmaBaseDtoJotpatyyppiEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaBaseDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU',
    JULKAISTU = 'JULKAISTU'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaBaseDtoTyyppiEnum {
    POHJA = 'POHJA',
    OPS = 'OPS',
    OPSPOHJA = 'OPSPOHJA',
    YLEINEN = 'YLEINEN',
    YHTEINEN = 'YHTEINEN'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaBaseDtoJotpatyyppiEnum {
    VST = 'VST',
    MUU = 'MUU'
}

/**
 * 
 * @export
 * @interface OpetussuunnitelmaDto
 */
export interface OpetussuunnitelmaDto {
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmaDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetussuunnitelmaDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaDto
     */
    tila?: OpetussuunnitelmaDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaDto
     */
    tyyppi?: OpetussuunnitelmaDtoTyyppiEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetussuunnitelmaDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {KoulutustoimijaBaseDto}
     * @memberof OpetussuunnitelmaDto
     */
    koulutustoimija?: KoulutustoimijaBaseDto;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaDto
     */
    luotu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {Reference}
     * @memberof OpetussuunnitelmaDto
     */
    pohja?: Reference;
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmaDto
     */
    perusteId?: number;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaDto
     */
    perusteDiaarinumero?: string;
    /**
     * 
     * @type {CachedPerusteBaseDto}
     * @memberof OpetussuunnitelmaDto
     */
    peruste?: CachedPerusteBaseDto;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaDto
     */
    jotpatyyppi?: OpetussuunnitelmaDtoJotpatyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaDto
     */
    kommentti?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpetussuunnitelmaDto
     */
    julkaisukielet?: Array<OpetussuunnitelmaDtoJulkaisukieletEnum>;
    /**
     * 
     * @type {Array<LiiteDto>}
     * @memberof OpetussuunnitelmaDto
     */
    liitteet?: Array<LiiteDto>;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaDto
     */
    paatospaivamaara?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaDto
     */
    voimaantulo?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaDto
     */
    voimassaoloLoppuu?: Date;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaDto
     */
    hyvaksyja?: string;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaDto
     */
    paatosnumero?: string;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaDto
     */
    suoritustapa?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OpetussuunnitelmaDto
     */
    esikatseltavissa?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpetussuunnitelmaDto
     */
    tutkintonimikkeet?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpetussuunnitelmaDto
     */
    osaamisalat?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaDto
     */
    koulutustyyppi?: OpetussuunnitelmaDtoKoulutustyyppiEnum;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaDto
     */
    viimeisinJulkaisuAika?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaDto
     */
    perustePaivitettyPvm?: Date;
    /**
     * 
     * @type {Array<OsaamisenArvioinninToteutussuunnitelmaDto>}
     * @memberof OpetussuunnitelmaDto
     */
    osaamisenArvioinninToteutussuunnitelmat?: Array<OsaamisenArvioinninToteutussuunnitelmaDto>;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaDto
     */
    oppilaitosTyyppiKoodiUri?: string;
    /**
     * 
     * @type {KoodistoKoodiDto}
     * @memberof OpetussuunnitelmaDto
     */
    oppilaitosTyyppiKoodi?: KoodistoKoodiDto;
}

/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU',
    JULKAISTU = 'JULKAISTU'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaDtoTyyppiEnum {
    POHJA = 'POHJA',
    OPS = 'OPS',
    OPSPOHJA = 'OPSPOHJA',
    YLEINEN = 'YLEINEN',
    YHTEINEN = 'YHTEINEN'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaDtoJotpatyyppiEnum {
    VST = 'VST',
    MUU = 'MUU'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaDtoJulkaisukieletEnum {
    FI = 'FI',
    SV = 'SV',
    SE = 'SE',
    RU = 'RU',
    EN = 'EN'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaDtoKoulutustyyppiEnum {
    PERUSTUTKINTO = 'PERUSTUTKINTO',
    AMMATTITUTKINTO = 'AMMATTITUTKINTO',
    ERIKOISAMMATTITUTKINTO = 'ERIKOISAMMATTITUTKINTO',
    TELMA = 'TELMA',
    VALMA = 'VALMA',
    VAPAASIVISTYSTYO = 'VAPAASIVISTYSTYO',
    MAAHANMUUTTAJIENKOTOUTUMISKOULUTUS = 'MAAHANMUUTTAJIENKOTOUTUMISKOULUTUS',
    VAPAASIVISTYSTYOLUKUTAITO = 'VAPAASIVISTYSTYOLUKUTAITO',
    TUTKINTOONVALMENTAVA = 'TUTKINTOONVALMENTAVA'
}

/**
 * 
 * @export
 * @interface OpetussuunnitelmaJulkinenDto
 */
export interface OpetussuunnitelmaJulkinenDto {
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmaJulkinenDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetussuunnitelmaJulkinenDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaJulkinenDto
     */
    tila?: OpetussuunnitelmaJulkinenDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaJulkinenDto
     */
    tyyppi?: OpetussuunnitelmaJulkinenDtoTyyppiEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetussuunnitelmaJulkinenDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {KoulutustoimijaBaseDto}
     * @memberof OpetussuunnitelmaJulkinenDto
     */
    koulutustoimija?: KoulutustoimijaBaseDto;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaJulkinenDto
     */
    luotu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaJulkinenDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {Reference}
     * @memberof OpetussuunnitelmaJulkinenDto
     */
    pohja?: Reference;
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmaJulkinenDto
     */
    perusteId?: number;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaJulkinenDto
     */
    perusteDiaarinumero?: string;
    /**
     * 
     * @type {CachedPerusteBaseDto}
     * @memberof OpetussuunnitelmaJulkinenDto
     */
    peruste?: CachedPerusteBaseDto;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaJulkinenDto
     */
    jotpatyyppi?: OpetussuunnitelmaJulkinenDtoJotpatyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaJulkinenDto
     */
    kommentti?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpetussuunnitelmaJulkinenDto
     */
    julkaisukielet?: Array<OpetussuunnitelmaJulkinenDtoJulkaisukieletEnum>;
    /**
     * 
     * @type {Array<LiiteDto>}
     * @memberof OpetussuunnitelmaJulkinenDto
     */
    liitteet?: Array<LiiteDto>;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaJulkinenDto
     */
    paatospaivamaara?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaJulkinenDto
     */
    voimaantulo?: Date;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaJulkinenDto
     */
    hyvaksyja?: string;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaJulkinenDto
     */
    paatosnumero?: string;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaJulkinenDto
     */
    suoritustapa?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpetussuunnitelmaJulkinenDto
     */
    tutkintonimikkeet?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpetussuunnitelmaJulkinenDto
     */
    osaamisalat?: Array<string>;
}

/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaJulkinenDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU',
    JULKAISTU = 'JULKAISTU'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaJulkinenDtoTyyppiEnum {
    POHJA = 'POHJA',
    OPS = 'OPS',
    OPSPOHJA = 'OPSPOHJA',
    YLEINEN = 'YLEINEN',
    YHTEINEN = 'YHTEINEN'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaJulkinenDtoJotpatyyppiEnum {
    VST = 'VST',
    MUU = 'MUU'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaJulkinenDtoJulkaisukieletEnum {
    FI = 'FI',
    SV = 'SV',
    SE = 'SE',
    RU = 'RU',
    EN = 'EN'
}

/**
 * 
 * @export
 * @interface OpetussuunnitelmaKaikkiDto
 */
export interface OpetussuunnitelmaKaikkiDto {
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmaKaikkiDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetussuunnitelmaKaikkiDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaKaikkiDto
     */
    tila?: OpetussuunnitelmaKaikkiDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaKaikkiDto
     */
    tyyppi?: OpetussuunnitelmaKaikkiDtoTyyppiEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetussuunnitelmaKaikkiDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {KoulutustoimijaBaseDto}
     * @memberof OpetussuunnitelmaKaikkiDto
     */
    koulutustoimija?: KoulutustoimijaBaseDto;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaKaikkiDto
     */
    luotu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaKaikkiDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {Reference}
     * @memberof OpetussuunnitelmaKaikkiDto
     */
    pohja?: Reference;
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmaKaikkiDto
     */
    perusteId?: number;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaKaikkiDto
     */
    perusteDiaarinumero?: string;
    /**
     * 
     * @type {CachedPerusteBaseDto}
     * @memberof OpetussuunnitelmaKaikkiDto
     */
    peruste?: CachedPerusteBaseDto;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaKaikkiDto
     */
    jotpatyyppi?: OpetussuunnitelmaKaikkiDtoJotpatyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaKaikkiDto
     */
    kommentti?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpetussuunnitelmaKaikkiDto
     */
    julkaisukielet?: Array<OpetussuunnitelmaKaikkiDtoJulkaisukieletEnum>;
    /**
     * 
     * @type {Array<LiiteDto>}
     * @memberof OpetussuunnitelmaKaikkiDto
     */
    liitteet?: Array<LiiteDto>;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaKaikkiDto
     */
    paatospaivamaara?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaKaikkiDto
     */
    voimaantulo?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaKaikkiDto
     */
    voimassaoloLoppuu?: Date;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaKaikkiDto
     */
    hyvaksyja?: string;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaKaikkiDto
     */
    paatosnumero?: string;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaKaikkiDto
     */
    suoritustapa?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OpetussuunnitelmaKaikkiDto
     */
    esikatseltavissa?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpetussuunnitelmaKaikkiDto
     */
    tutkintonimikkeet?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpetussuunnitelmaKaikkiDto
     */
    osaamisalat?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaKaikkiDto
     */
    koulutustyyppi?: OpetussuunnitelmaKaikkiDtoKoulutustyyppiEnum;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaKaikkiDto
     */
    viimeisinJulkaisuAika?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaKaikkiDto
     */
    perustePaivitettyPvm?: Date;
    /**
     * 
     * @type {Array<OsaamisenArvioinninToteutussuunnitelmaDto>}
     * @memberof OpetussuunnitelmaKaikkiDto
     */
    osaamisenArvioinninToteutussuunnitelmat?: Array<OsaamisenArvioinninToteutussuunnitelmaDto>;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaKaikkiDto
     */
    oppilaitosTyyppiKoodiUri?: string;
    /**
     * 
     * @type {KoodistoKoodiDto}
     * @memberof OpetussuunnitelmaKaikkiDto
     */
    oppilaitosTyyppiKoodi?: KoodistoKoodiDto;
    /**
     * 
     * @type {SisaltoViiteExportDto}
     * @memberof OpetussuunnitelmaKaikkiDto
     */
    sisalto?: SisaltoViiteExportDto;
    /**
     * 
     * @type {Array<SuorituspolkuRakenneDto>}
     * @memberof OpetussuunnitelmaKaikkiDto
     */
    suorituspolut?: Array<SuorituspolkuRakenneDto>;
    /**
     * 
     * @type {Array<SisaltoViiteExportDto>}
     * @memberof OpetussuunnitelmaKaikkiDto
     */
    tutkinnonOsat?: Array<SisaltoViiteExportDto>;
    /**
     * 
     * @type {Array<SisaltoViiteExportOpintokokonaisuusDto>}
     * @memberof OpetussuunnitelmaKaikkiDto
     */
    opintokokonaisuudet?: Array<SisaltoViiteExportOpintokokonaisuusDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaKaikkiDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU',
    JULKAISTU = 'JULKAISTU'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaKaikkiDtoTyyppiEnum {
    POHJA = 'POHJA',
    OPS = 'OPS',
    OPSPOHJA = 'OPSPOHJA',
    YLEINEN = 'YLEINEN',
    YHTEINEN = 'YHTEINEN'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaKaikkiDtoJotpatyyppiEnum {
    VST = 'VST',
    MUU = 'MUU'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaKaikkiDtoJulkaisukieletEnum {
    FI = 'FI',
    SV = 'SV',
    SE = 'SE',
    RU = 'RU',
    EN = 'EN'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaKaikkiDtoKoulutustyyppiEnum {
    PERUSTUTKINTO = 'PERUSTUTKINTO',
    AMMATTITUTKINTO = 'AMMATTITUTKINTO',
    ERIKOISAMMATTITUTKINTO = 'ERIKOISAMMATTITUTKINTO',
    TELMA = 'TELMA',
    VALMA = 'VALMA',
    VAPAASIVISTYSTYO = 'VAPAASIVISTYSTYO',
    MAAHANMUUTTAJIENKOTOUTUMISKOULUTUS = 'MAAHANMUUTTAJIENKOTOUTUMISKOULUTUS',
    VAPAASIVISTYSTYOLUKUTAITO = 'VAPAASIVISTYSTYOLUKUTAITO',
    TUTKINTOONVALMENTAVA = 'TUTKINTOONVALMENTAVA'
}

/**
 * 
 * @export
 * @interface OpetussuunnitelmaKevytDto
 */
export interface OpetussuunnitelmaKevytDto {
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmaKevytDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetussuunnitelmaKevytDto
     */
    nimi?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface OpetussuunnitelmaLuontiDto
 */
export interface OpetussuunnitelmaLuontiDto {
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    tila?: OpetussuunnitelmaLuontiDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    tyyppi?: OpetussuunnitelmaLuontiDtoTyyppiEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {KoulutustoimijaBaseDto}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    koulutustoimija?: KoulutustoimijaBaseDto;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    luotu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {Reference}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    pohja?: Reference;
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    perusteId?: number;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    perusteDiaarinumero?: string;
    /**
     * 
     * @type {CachedPerusteBaseDto}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    peruste?: CachedPerusteBaseDto;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    jotpatyyppi?: OpetussuunnitelmaLuontiDtoJotpatyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    kommentti?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    julkaisukielet?: Array<OpetussuunnitelmaLuontiDtoJulkaisukieletEnum>;
    /**
     * 
     * @type {Array<LiiteDto>}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    liitteet?: Array<LiiteDto>;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    paatospaivamaara?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    voimaantulo?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    voimassaoloLoppuu?: Date;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    hyvaksyja?: string;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    paatosnumero?: string;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    suoritustapa?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    esikatseltavissa?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    tutkintonimikkeet?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    osaamisalat?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    koulutustyyppi?: OpetussuunnitelmaLuontiDtoKoulutustyyppiEnum;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    viimeisinJulkaisuAika?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    perustePaivitettyPvm?: Date;
    /**
     * 
     * @type {Array<OsaamisenArvioinninToteutussuunnitelmaDto>}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    osaamisenArvioinninToteutussuunnitelmat?: Array<OsaamisenArvioinninToteutussuunnitelmaDto>;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    oppilaitosTyyppiKoodiUri?: string;
    /**
     * 
     * @type {KoodistoKoodiDto}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    oppilaitosTyyppiKoodi?: KoodistoKoodiDto;
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    opsId?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    tutkinnonOsaKoodiIncludes?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    kopioiPohjanTiedot?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaLuontiDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU',
    JULKAISTU = 'JULKAISTU'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaLuontiDtoTyyppiEnum {
    POHJA = 'POHJA',
    OPS = 'OPS',
    OPSPOHJA = 'OPSPOHJA',
    YLEINEN = 'YLEINEN',
    YHTEINEN = 'YHTEINEN'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaLuontiDtoJotpatyyppiEnum {
    VST = 'VST',
    MUU = 'MUU'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaLuontiDtoJulkaisukieletEnum {
    FI = 'FI',
    SV = 'SV',
    SE = 'SE',
    RU = 'RU',
    EN = 'EN'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaLuontiDtoKoulutustyyppiEnum {
    PERUSTUTKINTO = 'PERUSTUTKINTO',
    AMMATTITUTKINTO = 'AMMATTITUTKINTO',
    ERIKOISAMMATTITUTKINTO = 'ERIKOISAMMATTITUTKINTO',
    TELMA = 'TELMA',
    VALMA = 'VALMA',
    VAPAASIVISTYSTYO = 'VAPAASIVISTYSTYO',
    MAAHANMUUTTAJIENKOTOUTUMISKOULUTUS = 'MAAHANMUUTTAJIENKOTOUTUMISKOULUTUS',
    VAPAASIVISTYSTYOLUKUTAITO = 'VAPAASIVISTYSTYOLUKUTAITO',
    TUTKINTOONVALMENTAVA = 'TUTKINTOONVALMENTAVA'
}

/**
 * 
 * @export
 * @interface OpetussuunnitelmaMuokkaustietoDto
 */
export interface OpetussuunnitelmaMuokkaustietoDto {
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmaMuokkaustietoDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetussuunnitelmaMuokkaustietoDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaMuokkaustietoDto
     */
    tapahtuma?: OpetussuunnitelmaMuokkaustietoDtoTapahtumaEnum;
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmaMuokkaustietoDto
     */
    perusteId?: number;
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmaMuokkaustietoDto
     */
    kohdeId?: number;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaMuokkaustietoDto
     */
    kohde?: OpetussuunnitelmaMuokkaustietoDtoKohdeEnum;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaMuokkaustietoDto
     */
    luotu?: Date;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaMuokkaustietoDto
     */
    muokkaaja?: string;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaMuokkaustietoDto
     */
    lisatieto?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OpetussuunnitelmaMuokkaustietoDto
     */
    poistettu?: boolean;
    /**
     * 
     * @type {KayttajanTietoDto}
     * @memberof OpetussuunnitelmaMuokkaustietoDto
     */
    kayttajanTieto?: KayttajanTietoDto;
}

/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaMuokkaustietoDtoTapahtumaEnum {
    LUONTI = 'LUONTI',
    PAIVITYS = 'PAIVITYS',
    PALAUTUS = 'PALAUTUS',
    POISTO = 'POISTO',
    JULKAISU = 'JULKAISU'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaMuokkaustietoDtoKohdeEnum {
    Root = 'root',
    Tiedot = 'tiedot',
    Viite = 'viite',
    Liitteet = 'liitteet',
    Liite = 'liite',
    Peruste = 'peruste',
    Opetussuunnitelma = 'opetussuunnitelma',
    Tekstikappale = 'tekstikappale',
    Tutkinnonosat = 'tutkinnonosat',
    TutkinnonosatPakolliset = 'tutkinnonosat_pakolliset',
    TutkinnonosatPaikalliset = 'tutkinnonosat_paikalliset',
    TutkinnonosatTuodut = 'tutkinnonosat_tuodut',
    ValmatelmaKoulutuksenosat = 'valmatelmaKoulutuksenosat',
    Tutkinnonosa = 'tutkinnonosa',
    Tosaryhma = 'tosaryhma',
    Suorituspolut = 'suorituspolut',
    Suorituspolku = 'suorituspolku',
    Osasuorituspolku = 'osasuorituspolku',
    Opintokokonaisuus = 'opintokokonaisuus',
    Koulutuksenosa = 'koulutuksenosa',
    Koulutuksenosat = 'koulutuksenosat',
    Laajaalainenosaaminen = 'laajaalainenosaaminen',
    KotoKielitaitotaso = 'koto_kielitaitotaso',
    KotoOpinto = 'koto_opinto',
    KotoLaajaalainenosaaminen = 'koto_laajaalainenosaaminen',
    OpetussuunnitelmaRakenne = 'opetussuunnitelma_rakenne',
    Linkki = 'linkki',
    PakollisetOsaalueet = 'pakolliset_osaalueet',
    ValinnaisetOsaalueet = 'valinnaiset_osaalueet',
    PaikallisetOsaalueet = 'paikalliset_osaalueet',
    Osaalue = 'osaalue',
    Osaamismerkki = 'osaamismerkki'
}

/**
 * 
 * @export
 * @interface OpetussuunnitelmaTilastoDto
 */
export interface OpetussuunnitelmaTilastoDto {
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    tila?: OpetussuunnitelmaTilastoDtoTilaEnum;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    paatospaivamaara?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    voimaantulo?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    luotu?: Date;
    /**
     * 
     * @type {KoulutustoimijaBaseDto}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    koulutustoimija?: KoulutustoimijaBaseDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    julkaisukielet?: Array<OpetussuunnitelmaTilastoDtoJulkaisukieletEnum>;
    /**
     * 
     * @type {Array<JulkaisuKevytDto>}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    julkaisut?: Array<JulkaisuKevytDto>;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    koulutustyyppi?: OpetussuunnitelmaTilastoDtoKoulutustyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    jotpatyyppi?: OpetussuunnitelmaTilastoDtoJotpatyyppiEnum;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    viimeisinTilaMuutosAika?: Date;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    tyyppi?: OpetussuunnitelmaTilastoDtoTyyppiEnum;
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    perusteId?: number;
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    julkaisuVuosi?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    perusteNimi?: { [key: string]: string; };
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    julkaistu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    ensijulkaisu?: Date;
}

/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaTilastoDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU',
    JULKAISTU = 'JULKAISTU'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaTilastoDtoJulkaisukieletEnum {
    FI = 'FI',
    SV = 'SV',
    SE = 'SE',
    RU = 'RU',
    EN = 'EN'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaTilastoDtoKoulutustyyppiEnum {
    PERUSTUTKINTO = 'PERUSTUTKINTO',
    AMMATTITUTKINTO = 'AMMATTITUTKINTO',
    ERIKOISAMMATTITUTKINTO = 'ERIKOISAMMATTITUTKINTO',
    TELMA = 'TELMA',
    VALMA = 'VALMA',
    VAPAASIVISTYSTYO = 'VAPAASIVISTYSTYO',
    MAAHANMUUTTAJIENKOTOUTUMISKOULUTUS = 'MAAHANMUUTTAJIENKOTOUTUMISKOULUTUS',
    VAPAASIVISTYSTYOLUKUTAITO = 'VAPAASIVISTYSTYOLUKUTAITO',
    TUTKINTOONVALMENTAVA = 'TUTKINTOONVALMENTAVA'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaTilastoDtoJotpatyyppiEnum {
    VST = 'VST',
    MUU = 'MUU'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaTilastoDtoTyyppiEnum {
    POHJA = 'POHJA',
    OPS = 'OPS',
    OPSPOHJA = 'OPSPOHJA',
    YLEINEN = 'YLEINEN',
    YHTEINEN = 'YHTEINEN'
}

/**
 * 
 * @export
 * @interface OpintokokonaisuusArviointiDto
 */
export interface OpintokokonaisuusArviointiDto {
    /**
     * 
     * @type {number}
     * @memberof OpintokokonaisuusArviointiDto
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OpintokokonaisuusArviointiDto
     */
    perusteesta?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpintokokonaisuusArviointiDto
     */
    arviointi?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface OpintokokonaisuusDto
 */
export interface OpintokokonaisuusDto {
    /**
     * 
     * @type {number}
     * @memberof OpintokokonaisuusDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpintokokonaisuusDto
     */
    kooditettuNimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof OpintokokonaisuusDto
     */
    koodi?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpintokokonaisuusDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof OpintokokonaisuusDto
     */
    laajuus?: number;
    /**
     * 
     * @type {string}
     * @memberof OpintokokonaisuusDto
     */
    laajuusYksikko?: OpintokokonaisuusDtoLaajuusYksikkoEnum;
    /**
     * 
     * @type {number}
     * @memberof OpintokokonaisuusDto
     */
    minimilaajuus?: number;
    /**
     * 
     * @type {string}
     * @memberof OpintokokonaisuusDto
     */
    tyyppi?: OpintokokonaisuusDtoTyyppiEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpintokokonaisuusDto
     */
    opetuksenTavoiteOtsikko?: { [key: string]: string; };
    /**
     * 
     * @type {Array<OpintokokonaisuusTavoiteDto>}
     * @memberof OpintokokonaisuusDto
     */
    tavoitteet?: Array<OpintokokonaisuusTavoiteDto>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpintokokonaisuusDto
     */
    tavoitteidenKuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpintokokonaisuusDto
     */
    keskeisetSisallot?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpintokokonaisuusDto
     */
    arvioinninKuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {OsaamismerkkiKappaleDto}
     * @memberof OpintokokonaisuusDto
     */
    osaamismerkkiKappale?: OsaamismerkkiKappaleDto;
    /**
     * 
     * @type {Array<OpintokokonaisuusArviointiDto>}
     * @memberof OpintokokonaisuusDto
     */
    arvioinnit?: Array<OpintokokonaisuusArviointiDto>;
    /**
     * 
     * @type {string}
     * @memberof OpintokokonaisuusDto
     */
    koodiArvo?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum OpintokokonaisuusDtoLaajuusYksikkoEnum {
    OPINTOVIIKKO = 'OPINTOVIIKKO',
    OPINTOPISTE = 'OPINTOPISTE',
    OSAAMISPISTE = 'OSAAMISPISTE',
    KURSSI = 'KURSSI',
    TUNTI = 'TUNTI',
    VIIKKO = 'VIIKKO',
    VUOSIVIIKKOTUNTI = 'VUOSIVIIKKOTUNTI',
    VUOSI = 'VUOSI'
}
/**
    * @export
    * @enum {string}
    */
export enum OpintokokonaisuusDtoTyyppiEnum {
    PERUSTEESTA = 'PERUSTEESTA',
    OMA = 'OMA'
}

/**
 * 
 * @export
 * @interface OpintokokonaisuusTavoiteDto
 */
export interface OpintokokonaisuusTavoiteDto {
    /**
     * 
     * @type {number}
     * @memberof OpintokokonaisuusTavoiteDto
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OpintokokonaisuusTavoiteDto
     */
    perusteesta?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OpintokokonaisuusTavoiteDto
     */
    tavoiteKoodi?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpintokokonaisuusTavoiteDto
     */
    tavoite?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface OrganisaatioHierarkiaDto
 */
export interface OrganisaatioHierarkiaDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OrganisaatioHierarkiaDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof OrganisaatioHierarkiaDto
     */
    oid?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisaatioHierarkiaDto
     */
    parentOid?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisaatioHierarkiaDto
     */
    parentOidPath?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisaatioHierarkiaDto
     */
    kotipaikkaUri?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrganisaatioHierarkiaDto
     */
    organisaatiotyypit?: Array<string>;
    /**
     * 
     * @type {Array<OrganisaatioHierarkiaDto>}
     * @memberof OrganisaatioHierarkiaDto
     */
    children?: Array<OrganisaatioHierarkiaDto>;
    /**
     * 
     * @type {string}
     * @memberof OrganisaatioHierarkiaDto
     */
    status?: OrganisaatioHierarkiaDtoStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum OrganisaatioHierarkiaDtoStatusEnum {
    AKTIIVINEN = 'AKTIIVINEN',
    PASSIIVINEN = 'PASSIIVINEN'
}

/**
 * 
 * @export
 * @interface OrganisaatioHistoriaLiitosDto
 */
export interface OrganisaatioHistoriaLiitosDto {
    /**
     * 
     * @type {OrganisaatioHierarkiaDto}
     * @memberof OrganisaatioHistoriaLiitosDto
     */
    organisaatio?: OrganisaatioHierarkiaDto;
    /**
     * 
     * @type {OrganisaatioHierarkiaDto}
     * @memberof OrganisaatioHistoriaLiitosDto
     */
    kohde?: OrganisaatioHierarkiaDto;
    /**
     * 
     * @type {Date}
     * @memberof OrganisaatioHistoriaLiitosDto
     */
    alkuPvm?: Date;
}
/**
 * 
 * @export
 * @interface OsaAlue
 */
export interface OsaAlue {
    /**
     * 
     * @type {number}
     * @memberof OsaAlue
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTeksti}
     * @memberof OsaAlue
     */
    nimi?: LokalisoituTeksti;
    /**
     * 
     * @type {LokalisoituTeksti}
     * @memberof OsaAlue
     */
    kuvaus?: LokalisoituTeksti;
    /**
     * 
     * @type {Array<Osaamistavoite>}
     * @memberof OsaAlue
     */
    osaamistavoitteet?: Array<Osaamistavoite>;
    /**
     * 
     * @type {Array<Tutkinnonosa>}
     * @memberof OsaAlue
     */
    tutkinnonOsat?: Array<Tutkinnonosa>;
    /**
     * 
     * @type {string}
     * @memberof OsaAlue
     */
    kieli?: OsaAlueKieliEnum;
    /**
     * 
     * @type {string}
     * @memberof OsaAlue
     */
    koodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof OsaAlue
     */
    koodiArvo?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum OsaAlueKieliEnum {
    FI = 'FI',
    SV = 'SV',
    SE = 'SE',
    RU = 'RU',
    EN = 'EN'
}

/**
 * 
 * @export
 * @interface OsaamisalaDto
 */
export interface OsaamisalaDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OsaamisalaDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof OsaamisalaDto
     */
    osaamisalakoodiArvo?: string;
    /**
     * 
     * @type {string}
     * @memberof OsaamisalaDto
     */
    osaamisalakoodiUri?: string;
}
/**
 * 
 * @export
 * @interface OsaamisenArvioinninToteutussuunnitelmaDto
 */
export interface OsaamisenArvioinninToteutussuunnitelmaDto {
    /**
     * 
     * @type {number}
     * @memberof OsaamisenArvioinninToteutussuunnitelmaDto
     */
    id?: number;
    /**
     * 
     * @type {OpetussuunnitelmaKevytDto}
     * @memberof OsaamisenArvioinninToteutussuunnitelmaDto
     */
    opetussuunnitelma?: OpetussuunnitelmaKevytDto;
    /**
     * 
     * @type {OpetussuunnitelmaKevytDto}
     * @memberof OsaamisenArvioinninToteutussuunnitelmaDto
     */
    oatOpetussuunnitelma?: OpetussuunnitelmaKevytDto;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OsaamisenArvioinninToteutussuunnitelmaDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OsaamisenArvioinninToteutussuunnitelmaDto
     */
    url?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface OsaamismerkkiKappaleDto
 */
export interface OsaamismerkkiKappaleDto {
    /**
     * 
     * @type {number}
     * @memberof OsaamismerkkiKappaleDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OsaamismerkkiKappaleDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {Array<OsaamismerkkiKoodiDto>}
     * @memberof OsaamismerkkiKappaleDto
     */
    osaamismerkkiKoodit?: Array<OsaamismerkkiKoodiDto>;
}
/**
 * 
 * @export
 * @interface OsaamismerkkiKoodiDto
 */
export interface OsaamismerkkiKoodiDto {
    /**
     * 
     * @type {number}
     * @memberof OsaamismerkkiKoodiDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OsaamismerkkiKoodiDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof OsaamismerkkiKoodiDto
     */
    koodi?: string;
    /**
     * 
     * @type {Date}
     * @memberof OsaamismerkkiKoodiDto
     */
    voimassaAlkuPvm?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OsaamismerkkiKoodiDto
     */
    voimassaLoppuPvm?: Date;
}
/**
 * 
 * @export
 * @interface Osaamistaso
 */
export interface Osaamistaso {
    /**
     * 
     * @type {number}
     * @memberof Osaamistaso
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTeksti}
     * @memberof Osaamistaso
     */
    otsikko?: LokalisoituTeksti;
}
/**
 * 
 * @export
 * @interface OsaamistasoDto
 */
export interface OsaamistasoDto {
    /**
     * 
     * @type {number}
     * @memberof OsaamistasoDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OsaamistasoDto
     */
    otsikko?: LokalisoituTekstiDto;
    /**
     * 
     * @type {KoodiDto}
     * @memberof OsaamistasoDto
     */
    koodi?: KoodiDto;
}
/**
 * 
 * @export
 * @interface OsaamistasonKriteeri
 */
export interface OsaamistasonKriteeri {
    /**
     * 
     * @type {number}
     * @memberof OsaamistasonKriteeri
     */
    id?: number;
    /**
     * 
     * @type {Osaamistaso}
     * @memberof OsaamistasonKriteeri
     */
    osaamistaso?: Osaamistaso;
    /**
     * 
     * @type {Array<LokalisoituTeksti>}
     * @memberof OsaamistasonKriteeri
     */
    kriteerit?: Array<LokalisoituTeksti>;
    /**
     * 
     * @type {Array<ArvioinninKohde>}
     * @memberof OsaamistasonKriteeri
     */
    arvioinninKohteet?: Array<ArvioinninKohde>;
}
/**
 * 
 * @export
 * @interface OsaamistasonKriteeriDto
 */
export interface OsaamistasonKriteeriDto {
    /**
     * 
     * @type {Array<{ [key: string]: string; }>}
     * @memberof OsaamistasonKriteeriDto
     */
    kriteerit?: Array<{ [key: string]: string; }>;
    /**
     * 
     * @type {Reference}
     * @memberof OsaamistasonKriteeriDto
     */
    _osaamistaso?: Reference;
}
/**
 * 
 * @export
 * @interface Osaamistavoite
 */
export interface Osaamistavoite {
    /**
     * 
     * @type {number}
     * @memberof Osaamistavoite
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTeksti}
     * @memberof Osaamistavoite
     */
    nimi?: LokalisoituTeksti;
    /**
     * 
     * @type {boolean}
     * @memberof Osaamistavoite
     */
    pakollinen?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Osaamistavoite
     */
    laajuus?: number;
    /**
     * 
     * @type {LokalisoituTeksti}
     * @memberof Osaamistavoite
     */
    tavoitteet?: LokalisoituTeksti;
    /**
     * 
     * @type {LokalisoituTeksti}
     * @memberof Osaamistavoite
     */
    tunnustaminen?: LokalisoituTeksti;
    /**
     * 
     * @type {Arviointi}
     * @memberof Osaamistavoite
     */
    arviointi?: Arviointi;
    /**
     * 
     * @type {Array<AmmattitaitovaatimuksenKohdealue>}
     * @memberof Osaamistavoite
     */
    ammattitaitovaatimuksetLista?: Array<AmmattitaitovaatimuksenKohdealue>;
    /**
     * 
     * @type {Osaamistavoite}
     * @memberof Osaamistavoite
     */
    esitieto?: Osaamistavoite;
    /**
     * 
     * @type {Array<OsaAlue>}
     * @memberof Osaamistavoite
     */
    osaAlueet?: Array<OsaAlue>;
    /**
     * 
     * @type {string}
     * @memberof Osaamistavoite
     */
    kieli?: OsaamistavoiteKieliEnum;
    /**
     * 
     * @type {string}
     * @memberof Osaamistavoite
     */
    koodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof Osaamistavoite
     */
    koodiArvo?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum OsaamistavoiteKieliEnum {
    FI = 'FI',
    SV = 'SV',
    SE = 'SE',
    RU = 'RU',
    EN = 'EN'
}

/**
 * 
 * @export
 * @interface Page
 */
export interface Page {
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    size?: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Page
     */
    content?: Array<object>;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    number?: number;
    /**
     * 
     * @type {Sort}
     * @memberof Page
     */
    sort?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof Page
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Page
     */
    last?: boolean;
    /**
     * 
     * @type {Pageable}
     * @memberof Page
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    numberOfElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Page
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageKoulutustoimijaJulkinenDto
 */
export interface PageKoulutustoimijaJulkinenDto {
    /**
     * 
     * @type {number}
     * @memberof PageKoulutustoimijaJulkinenDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageKoulutustoimijaJulkinenDto
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageKoulutustoimijaJulkinenDto
     */
    size?: number;
    /**
     * 
     * @type {Array<KoulutustoimijaJulkinenDto>}
     * @memberof PageKoulutustoimijaJulkinenDto
     */
    content?: Array<KoulutustoimijaJulkinenDto>;
    /**
     * 
     * @type {number}
     * @memberof PageKoulutustoimijaJulkinenDto
     */
    number?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageKoulutustoimijaJulkinenDto
     */
    sort?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PageKoulutustoimijaJulkinenDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageKoulutustoimijaJulkinenDto
     */
    last?: boolean;
    /**
     * 
     * @type {Pageable}
     * @memberof PageKoulutustoimijaJulkinenDto
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageKoulutustoimijaJulkinenDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageKoulutustoimijaJulkinenDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageOpetussuunnitelmaBaseDto
 */
export interface PageOpetussuunnitelmaBaseDto {
    /**
     * 
     * @type {number}
     * @memberof PageOpetussuunnitelmaBaseDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOpetussuunnitelmaBaseDto
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOpetussuunnitelmaBaseDto
     */
    size?: number;
    /**
     * 
     * @type {Array<OpetussuunnitelmaBaseDto>}
     * @memberof PageOpetussuunnitelmaBaseDto
     */
    content?: Array<OpetussuunnitelmaBaseDto>;
    /**
     * 
     * @type {number}
     * @memberof PageOpetussuunnitelmaBaseDto
     */
    number?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageOpetussuunnitelmaBaseDto
     */
    sort?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PageOpetussuunnitelmaBaseDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageOpetussuunnitelmaBaseDto
     */
    last?: boolean;
    /**
     * 
     * @type {Pageable}
     * @memberof PageOpetussuunnitelmaBaseDto
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageOpetussuunnitelmaBaseDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageOpetussuunnitelmaBaseDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageOpetussuunnitelmaDto
 */
export interface PageOpetussuunnitelmaDto {
    /**
     * 
     * @type {number}
     * @memberof PageOpetussuunnitelmaDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOpetussuunnitelmaDto
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOpetussuunnitelmaDto
     */
    size?: number;
    /**
     * 
     * @type {Array<OpetussuunnitelmaDto>}
     * @memberof PageOpetussuunnitelmaDto
     */
    content?: Array<OpetussuunnitelmaDto>;
    /**
     * 
     * @type {number}
     * @memberof PageOpetussuunnitelmaDto
     */
    number?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageOpetussuunnitelmaDto
     */
    sort?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PageOpetussuunnitelmaDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageOpetussuunnitelmaDto
     */
    last?: boolean;
    /**
     * 
     * @type {Pageable}
     * @memberof PageOpetussuunnitelmaDto
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageOpetussuunnitelmaDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageOpetussuunnitelmaDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageSisaltoviiteLaajaDto
 */
export interface PageSisaltoviiteLaajaDto {
    /**
     * 
     * @type {number}
     * @memberof PageSisaltoviiteLaajaDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageSisaltoviiteLaajaDto
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageSisaltoviiteLaajaDto
     */
    size?: number;
    /**
     * 
     * @type {Array<SisaltoviiteLaajaDto>}
     * @memberof PageSisaltoviiteLaajaDto
     */
    content?: Array<SisaltoviiteLaajaDto>;
    /**
     * 
     * @type {number}
     * @memberof PageSisaltoviiteLaajaDto
     */
    number?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageSisaltoviiteLaajaDto
     */
    sort?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PageSisaltoviiteLaajaDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageSisaltoviiteLaajaDto
     */
    last?: boolean;
    /**
     * 
     * @type {Pageable}
     * @memberof PageSisaltoviiteLaajaDto
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageSisaltoviiteLaajaDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageSisaltoviiteLaajaDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface Pageable
 */
export interface Pageable {
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    offset?: number;
    /**
     * 
     * @type {Sort}
     * @memberof Pageable
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    pageSize?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Pageable
     */
    unpaged?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Pageable
     */
    paged?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    pageNumber?: number;
}
/**
 * 
 * @export
 * @interface PaikallinenAmmattitaitovaatimus2019Dto
 */
export interface PaikallinenAmmattitaitovaatimus2019Dto {
    /**
     * 
     * @type {KoodiDto}
     * @memberof PaikallinenAmmattitaitovaatimus2019Dto
     */
    koodi?: KoodiDto;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PaikallinenAmmattitaitovaatimus2019Dto
     */
    vaatimus?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface PaikallinenAmmattitaitovaatimustenKohdealue2019Dto
 */
export interface PaikallinenAmmattitaitovaatimustenKohdealue2019Dto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PaikallinenAmmattitaitovaatimustenKohdealue2019Dto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {Array<PaikallinenAmmattitaitovaatimus2019Dto>}
     * @memberof PaikallinenAmmattitaitovaatimustenKohdealue2019Dto
     */
    vaatimukset?: Array<PaikallinenAmmattitaitovaatimus2019Dto>;
}
/**
 * 
 * @export
 * @interface PaikallisetAmmattitaitovaatimukset2019Dto
 */
export interface PaikallisetAmmattitaitovaatimukset2019Dto {
    /**
     * 
     * @type {number}
     * @memberof PaikallisetAmmattitaitovaatimukset2019Dto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PaikallisetAmmattitaitovaatimukset2019Dto
     */
    kohde?: { [key: string]: string; };
    /**
     * 
     * @type {Array<PaikallinenAmmattitaitovaatimus2019Dto>}
     * @memberof PaikallisetAmmattitaitovaatimukset2019Dto
     */
    vaatimukset?: Array<PaikallinenAmmattitaitovaatimus2019Dto>;
    /**
     * 
     * @type {Array<PaikallinenAmmattitaitovaatimustenKohdealue2019Dto>}
     * @memberof PaikallisetAmmattitaitovaatimukset2019Dto
     */
    kohdealueet?: Array<PaikallinenAmmattitaitovaatimustenKohdealue2019Dto>;
}
/**
 * 
 * @export
 * @interface PerusteBaseDto
 */
export interface PerusteBaseDto {
    /**
     * 
     * @type {number}
     * @memberof PerusteBaseDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PerusteBaseDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {PerusteVersionDto}
     * @memberof PerusteBaseDto
     */
    globalVersion?: PerusteVersionDto;
    /**
     * 
     * @type {string}
     * @memberof PerusteBaseDto
     */
    koulutustyyppi?: PerusteBaseDtoKoulutustyyppiEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof PerusteBaseDto
     */
    kielet?: Array<PerusteBaseDtoKieletEnum>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PerusteBaseDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof PerusteBaseDto
     */
    diaarinumero?: string;
    /**
     * 
     * @type {Date}
     * @memberof PerusteBaseDto
     */
    voimassaoloAlkaa?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteBaseDto
     */
    siirtymaPaattyy?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteBaseDto
     */
    voimassaoloLoppuu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteBaseDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof PerusteBaseDto
     */
    tila?: string;
    /**
     * 
     * @type {string}
     * @memberof PerusteBaseDto
     */
    tyyppi?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PerusteBaseDto
     */
    korvattavatDiaarinumerot?: Array<string>;
    /**
     * 
     * @type {Array<KoulutusDto>}
     * @memberof PerusteBaseDto
     */
    koulutukset?: Array<KoulutusDto>;
    /**
     * 
     * @type {Array<KoodiDto>}
     * @memberof PerusteBaseDto
     */
    osaamisalat?: Array<KoodiDto>;
    /**
     * 
     * @type {string}
     * @memberof PerusteBaseDto
     */
    toteutus?: PerusteBaseDtoToteutusEnum;
    /**
     * 
     * @type {Array<TutkintonimikeKoodiDto>}
     * @memberof PerusteBaseDto
     */
    tutkintonimikkeet?: Array<TutkintonimikeKoodiDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum PerusteBaseDtoKoulutustyyppiEnum {
    PERUSTUTKINTO = 'PERUSTUTKINTO',
    AMMATTITUTKINTO = 'AMMATTITUTKINTO',
    ERIKOISAMMATTITUTKINTO = 'ERIKOISAMMATTITUTKINTO',
    TELMA = 'TELMA',
    VALMA = 'VALMA',
    VAPAASIVISTYSTYO = 'VAPAASIVISTYSTYO',
    MAAHANMUUTTAJIENKOTOUTUMISKOULUTUS = 'MAAHANMUUTTAJIENKOTOUTUMISKOULUTUS',
    VAPAASIVISTYSTYOLUKUTAITO = 'VAPAASIVISTYSTYOLUKUTAITO',
    TUTKINTOONVALMENTAVA = 'TUTKINTOONVALMENTAVA'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteBaseDtoKieletEnum {
    FI = 'FI',
    SV = 'SV',
    SE = 'SE',
    RU = 'RU',
    EN = 'EN'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteBaseDtoToteutusEnum {
    YKSINKERTAINEN = 'YKSINKERTAINEN',
    PERUSOPETUS = 'PERUSOPETUS',
    LOPS = 'LOPS',
    AMMATILLINEN = 'AMMATILLINEN',
    TPO = 'TPO',
    LOPS2019 = 'LOPS2019',
    VAPAASIVISTYSTYO = 'VAPAASIVISTYSTYO',
    TUTKINTOONVALMENTAVA = 'TUTKINTOONVALMENTAVA',
    KOTOUTUMISKOULUTUS = 'KOTOUTUMISKOULUTUS'
}

/**
 * 
 * @export
 * @interface PerusteDto
 */
export interface PerusteDto {
    /**
     * 
     * @type {number}
     * @memberof PerusteDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PerusteDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {PerusteVersionDto}
     * @memberof PerusteDto
     */
    globalVersion?: PerusteVersionDto;
    /**
     * 
     * @type {string}
     * @memberof PerusteDto
     */
    koulutustyyppi?: PerusteDtoKoulutustyyppiEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof PerusteDto
     */
    kielet?: Array<PerusteDtoKieletEnum>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PerusteDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof PerusteDto
     */
    diaarinumero?: string;
    /**
     * 
     * @type {Date}
     * @memberof PerusteDto
     */
    voimassaoloAlkaa?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteDto
     */
    siirtymaPaattyy?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteDto
     */
    voimassaoloLoppuu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof PerusteDto
     */
    tila?: string;
    /**
     * 
     * @type {string}
     * @memberof PerusteDto
     */
    tyyppi?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PerusteDto
     */
    korvattavatDiaarinumerot?: Array<string>;
    /**
     * 
     * @type {Array<KoulutusDto>}
     * @memberof PerusteDto
     */
    koulutukset?: Array<KoulutusDto>;
    /**
     * 
     * @type {Array<KoodiDto>}
     * @memberof PerusteDto
     */
    osaamisalat?: Array<KoodiDto>;
    /**
     * 
     * @type {string}
     * @memberof PerusteDto
     */
    toteutus?: PerusteDtoToteutusEnum;
    /**
     * 
     * @type {Array<TutkintonimikeKoodiDto>}
     * @memberof PerusteDto
     */
    tutkintonimikkeet?: Array<TutkintonimikeKoodiDto>;
    /**
     * 
     * @type {Array<SuoritustapaDto>}
     * @memberof PerusteDto
     */
    suoritustavat?: Array<SuoritustapaDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum PerusteDtoKoulutustyyppiEnum {
    PERUSTUTKINTO = 'PERUSTUTKINTO',
    AMMATTITUTKINTO = 'AMMATTITUTKINTO',
    ERIKOISAMMATTITUTKINTO = 'ERIKOISAMMATTITUTKINTO',
    TELMA = 'TELMA',
    VALMA = 'VALMA',
    VAPAASIVISTYSTYO = 'VAPAASIVISTYSTYO',
    MAAHANMUUTTAJIENKOTOUTUMISKOULUTUS = 'MAAHANMUUTTAJIENKOTOUTUMISKOULUTUS',
    VAPAASIVISTYSTYOLUKUTAITO = 'VAPAASIVISTYSTYOLUKUTAITO',
    TUTKINTOONVALMENTAVA = 'TUTKINTOONVALMENTAVA'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteDtoKieletEnum {
    FI = 'FI',
    SV = 'SV',
    SE = 'SE',
    RU = 'RU',
    EN = 'EN'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteDtoToteutusEnum {
    YKSINKERTAINEN = 'YKSINKERTAINEN',
    PERUSOPETUS = 'PERUSOPETUS',
    LOPS = 'LOPS',
    AMMATILLINEN = 'AMMATILLINEN',
    TPO = 'TPO',
    LOPS2019 = 'LOPS2019',
    VAPAASIVISTYSTYO = 'VAPAASIVISTYSTYO',
    TUTKINTOONVALMENTAVA = 'TUTKINTOONVALMENTAVA',
    KOTOUTUMISKOULUTUS = 'KOTOUTUMISKOULUTUS'
}

/**
 * 
 * @export
 * @interface PerusteKevytDto
 */
export interface PerusteKevytDto {
    /**
     * 
     * @type {number}
     * @memberof PerusteKevytDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PerusteKevytDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof PerusteKevytDto
     */
    diaarinumero?: string;
    /**
     * 
     * @type {Date}
     * @memberof PerusteKevytDto
     */
    voimassaoloAlkaa?: Date;
    /**
     * 
     * @type {string}
     * @memberof PerusteKevytDto
     */
    koulutustyyppi?: PerusteKevytDtoKoulutustyyppiEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum PerusteKevytDtoKoulutustyyppiEnum {
    PERUSTUTKINTO = 'PERUSTUTKINTO',
    AMMATTITUTKINTO = 'AMMATTITUTKINTO',
    ERIKOISAMMATTITUTKINTO = 'ERIKOISAMMATTITUTKINTO',
    TELMA = 'TELMA',
    VALMA = 'VALMA',
    VAPAASIVISTYSTYO = 'VAPAASIVISTYSTYO',
    MAAHANMUUTTAJIENKOTOUTUMISKOULUTUS = 'MAAHANMUUTTAJIENKOTOUTUMISKOULUTUS',
    VAPAASIVISTYSTYOLUKUTAITO = 'VAPAASIVISTYSTYOLUKUTAITO',
    TUTKINTOONVALMENTAVA = 'TUTKINTOONVALMENTAVA'
}

/**
 * 
 * @export
 * @interface PerusteVersionDto
 */
export interface PerusteVersionDto {
    /**
     * 
     * @type {Date}
     * @memberof PerusteVersionDto
     */
    aikaleima?: Date;
}
/**
 * 
 * @export
 * @interface PerusteenOsaDto
 */
export interface PerusteenOsaDto {
    /**
     * 
     * @type {number}
     * @memberof PerusteenOsaDto
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof PerusteenOsaDto
     */
    luotu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteenOsaDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PerusteenOsaDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof PerusteenOsaDto
     */
    tila?: PerusteenOsaDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof PerusteenOsaDto
     */
    tunniste?: PerusteenOsaDtoTunnisteEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum PerusteenOsaDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteenOsaDtoTunnisteEnum {
    NORMAALI = 'NORMAALI',
    LAAJAALAINENOSAAMINEN = 'LAAJAALAINENOSAAMINEN',
    RAKENNE = 'RAKENNE'
}

/**
 * 
 * @export
 * @interface PoistettuDto
 */
export interface PoistettuDto {
    /**
     * 
     * @type {number}
     * @memberof PoistettuDto
     */
    numero?: number;
    /**
     * 
     * @type {Date}
     * @memberof PoistettuDto
     */
    pvm?: Date;
    /**
     * 
     * @type {string}
     * @memberof PoistettuDto
     */
    muokkaajaOid?: string;
    /**
     * 
     * @type {string}
     * @memberof PoistettuDto
     */
    kommentti?: string;
    /**
     * 
     * @type {number}
     * @memberof PoistettuDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PoistettuDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {Reference}
     * @memberof PoistettuDto
     */
    koulutustoimija?: Reference;
    /**
     * 
     * @type {Reference}
     * @memberof PoistettuDto
     */
    opetussuunnitelma?: Reference;
    /**
     * 
     * @type {number}
     * @memberof PoistettuDto
     */
    poistettu?: number;
    /**
     * 
     * @type {string}
     * @memberof PoistettuDto
     */
    tyyppi?: PoistettuDtoTyyppiEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum PoistettuDtoTyyppiEnum {
    TEKSTIKAPPALE = 'TEKSTIKAPPALE',
    TUTKINNONOSAT = 'TUTKINNONOSAT',
    TUTKINNONOSA = 'TUTKINNONOSA',
    TOSARYHMA = 'TOSARYHMA',
    SUORITUSPOLUT = 'SUORITUSPOLUT',
    SUORITUSPOLKU = 'SUORITUSPOLKU',
    OSASUORITUSPOLKU = 'OSASUORITUSPOLKU',
    OPINTOKOKONAISUUS = 'OPINTOKOKONAISUUS',
    KOULUTUKSENOSA = 'KOULUTUKSENOSA',
    KOULUTUKSENOSAT = 'KOULUTUKSENOSAT',
    LAAJAALAINENOSAAMINEN = 'LAAJAALAINENOSAAMINEN',
    KOTOKIELITAITOTASO = 'KOTO_KIELITAITOTASO',
    KOTOOPINTO = 'KOTO_OPINTO',
    KOTOLAAJAALAINENOSAAMINEN = 'KOTO_LAAJAALAINENOSAAMINEN',
    OSAAMISMERKKI = 'OSAAMISMERKKI',
    LINKKI = 'LINKKI'
}

/**
 * 
 * @export
 * @interface RakenneModuuliTunnisteDto
 */
export interface RakenneModuuliTunnisteDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof RakenneModuuliTunnisteDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {KoodiDto}
     * @memberof RakenneModuuliTunnisteDto
     */
    vieras?: KoodiDto;
    /**
     * 
     * @type {string}
     * @memberof RakenneModuuliTunnisteDto
     */
    tunniste?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RakenneModuuliTunnisteDto
     */
    pakollinen?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof RakenneModuuliTunnisteDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof RakenneModuuliTunnisteDto
     */
    rooli?: RakenneModuuliTunnisteDtoRooliEnum;
    /**
     * 
     * @type {MuodostumisSaantoDto}
     * @memberof RakenneModuuliTunnisteDto
     */
    muodostumisSaanto?: MuodostumisSaantoDto;
    /**
     * 
     * @type {OsaamisalaDto}
     * @memberof RakenneModuuliTunnisteDto
     */
    osaamisala?: OsaamisalaDto;
    /**
     * 
     * @type {KoodiDto}
     * @memberof RakenneModuuliTunnisteDto
     */
    tutkintonimike?: KoodiDto;
    /**
     * 
     * @type {Array<AbstractRakenneOsaDto>}
     * @memberof RakenneModuuliTunnisteDto
     */
    osat?: Array<AbstractRakenneOsaDto>;
    /**
     * 
     * @type {number}
     * @memberof RakenneModuuliTunnisteDto
     */
    versioId?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof RakenneModuuliTunnisteDto
     */
    koodit?: Array<string>;
}

/**
    * @export
    * @enum {string}
    */
export enum RakenneModuuliTunnisteDtoRooliEnum {
    NORMAALI = 'NORMAALI',
    VIRTUAALINEN = 'VIRTUAALINEN',
    OSAAMISALA = 'OSAAMISALA',
    TUTKINTONIMIKE = 'TUTKINTONIMIKE',
    VIERAS = 'VIERAS'
}

/**
 * 
 * @export
 * @interface Reference
 */
export interface Reference {
    /**
     * 
     * @type {number}
     * @memberof Reference
     */
    idLong?: number;
}
/**
 * 
 * @export
 * @interface Revision
 */
export interface Revision {
    /**
     * 
     * @type {number}
     * @memberof Revision
     */
    numero?: number;
    /**
     * 
     * @type {Date}
     * @memberof Revision
     */
    pvm?: Date;
    /**
     * 
     * @type {string}
     * @memberof Revision
     */
    muokkaajaOid?: string;
    /**
     * 
     * @type {string}
     * @memberof Revision
     */
    kommentti?: string;
}
/**
 * 
 * @export
 * @interface RevisionKayttajaDto
 */
export interface RevisionKayttajaDto {
    /**
     * 
     * @type {number}
     * @memberof RevisionKayttajaDto
     */
    numero?: number;
    /**
     * 
     * @type {Date}
     * @memberof RevisionKayttajaDto
     */
    pvm?: Date;
    /**
     * 
     * @type {string}
     * @memberof RevisionKayttajaDto
     */
    muokkaajaOid?: string;
    /**
     * 
     * @type {string}
     * @memberof RevisionKayttajaDto
     */
    kommentti?: string;
    /**
     * 
     * @type {KayttajanTietoDto}
     * @memberof RevisionKayttajaDto
     */
    kayttajanTieto?: KayttajanTietoDto;
}
/**
 * 
 * @export
 * @interface SisaltoViiteDto
 */
export interface SisaltoViiteDto {
    /**
     * 
     * @type {number}
     * @memberof SisaltoViiteDto
     */
    id?: number;
    /**
     * 
     * @type {TekstiKappaleDto}
     * @memberof SisaltoViiteDto
     */
    tekstiKappale?: TekstiKappaleDto;
    /**
     * 
     * @type {TekstiKappaleDto}
     * @memberof SisaltoViiteDto
     */
    pohjanTekstikappale?: TekstiKappaleDto;
    /**
     * 
     * @type {boolean}
     * @memberof SisaltoViiteDto
     */
    pakollinen?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SisaltoViiteDto
     */
    valmis?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SisaltoViiteDto
     */
    liikkumaton?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SisaltoViiteDto
     */
    naytaPohjanTeksti?: boolean;
    /**
     * 
     * @type {Reference}
     * @memberof SisaltoViiteDto
     */
    owner?: Reference;
    /**
     * 
     * @type {Reference}
     * @memberof SisaltoViiteDto
     */
    vanhempi?: Reference;
    /**
     * 
     * @type {CachedPerusteBaseDto}
     * @memberof SisaltoViiteDto
     */
    peruste?: CachedPerusteBaseDto;
    /**
     * 
     * @type {string}
     * @memberof SisaltoViiteDto
     */
    tyyppi?: SisaltoViiteDtoTyyppiEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof SisaltoViiteDto
     */
    ohjeteksti?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof SisaltoViiteDto
     */
    perusteteksti?: { [key: string]: string; };
    /**
     * 
     * @type {TutkinnonosaDto}
     * @memberof SisaltoViiteDto
     */
    tosa?: TutkinnonosaDto;
    /**
     * 
     * @type {SuorituspolkuDto}
     * @memberof SisaltoViiteDto
     */
    suorituspolku?: SuorituspolkuDto;
    /**
     * 
     * @type {OpintokokonaisuusDto}
     * @memberof SisaltoViiteDto
     */
    opintokokonaisuus?: OpintokokonaisuusDto;
    /**
     * 
     * @type {KoulutuksenOsaDto}
     * @memberof SisaltoViiteDto
     */
    koulutuksenosa?: KoulutuksenOsaDto;
    /**
     * 
     * @type {TuvaLaajaAlainenOsaaminenDto}
     * @memberof SisaltoViiteDto
     */
    tuvaLaajaAlainenOsaaminen?: TuvaLaajaAlainenOsaaminenDto;
    /**
     * 
     * @type {KotoKielitaitotasoDto}
     * @memberof SisaltoViiteDto
     */
    kotoKielitaitotaso?: KotoKielitaitotasoDto;
    /**
     * 
     * @type {KotoOpintoDto}
     * @memberof SisaltoViiteDto
     */
    kotoOpinto?: KotoOpintoDto;
    /**
     * 
     * @type {KotoLaajaAlainenOsaaminenDto}
     * @memberof SisaltoViiteDto
     */
    kotoLaajaAlainenOsaaminen?: KotoLaajaAlainenOsaaminenDto;
    /**
     * 
     * @type {number}
     * @memberof SisaltoViiteDto
     */
    perusteenOsaId?: number;
    /**
     * 
     * @type {string}
     * @memberof SisaltoViiteDto
     */
    kommentti?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SisaltoViiteDto
     */
    naytaPerusteenTeksti?: boolean;
    /**
     * 
     * @type {Array<OmaOsaAlueDto>}
     * @memberof SisaltoViiteDto
     */
    osaAlueet?: Array<OmaOsaAlueDto>;
    /**
     * 
     * @type {number}
     * @memberof SisaltoViiteDto
     */
    linkattuOps?: number;
    /**
     * 
     * @type {number}
     * @memberof SisaltoViiteDto
     */
    linkattuSisaltoViiteId?: number;
    /**
     * 
     * @type {number}
     * @memberof SisaltoViiteDto
     */
    linkattuPeruste?: number;
    /**
     * 
     * @type {string}
     * @memberof SisaltoViiteDto
     */
    linkattuTyyppi?: SisaltoViiteDtoLinkattuTyyppiEnum;
    /**
     * 
     * @type {OsaamismerkkiKappaleDto}
     * @memberof SisaltoViiteDto
     */
    osaamismerkkiKappale?: OsaamismerkkiKappaleDto;
    /**
     * 
     * @type {Reference}
     * @memberof SisaltoViiteDto
     */
    _tekstiKappale?: Reference;
}

/**
    * @export
    * @enum {string}
    */
export enum SisaltoViiteDtoTyyppiEnum {
    TEKSTIKAPPALE = 'TEKSTIKAPPALE',
    TUTKINNONOSAT = 'TUTKINNONOSAT',
    TUTKINNONOSA = 'TUTKINNONOSA',
    TOSARYHMA = 'TOSARYHMA',
    SUORITUSPOLUT = 'SUORITUSPOLUT',
    SUORITUSPOLKU = 'SUORITUSPOLKU',
    OSASUORITUSPOLKU = 'OSASUORITUSPOLKU',
    OPINTOKOKONAISUUS = 'OPINTOKOKONAISUUS',
    KOULUTUKSENOSA = 'KOULUTUKSENOSA',
    KOULUTUKSENOSAT = 'KOULUTUKSENOSAT',
    LAAJAALAINENOSAAMINEN = 'LAAJAALAINENOSAAMINEN',
    KOTOKIELITAITOTASO = 'KOTO_KIELITAITOTASO',
    KOTOOPINTO = 'KOTO_OPINTO',
    KOTOLAAJAALAINENOSAAMINEN = 'KOTO_LAAJAALAINENOSAAMINEN',
    OSAAMISMERKKI = 'OSAAMISMERKKI',
    LINKKI = 'LINKKI'
}
/**
    * @export
    * @enum {string}
    */
export enum SisaltoViiteDtoLinkattuTyyppiEnum {
    TEKSTIKAPPALE = 'TEKSTIKAPPALE',
    TUTKINNONOSAT = 'TUTKINNONOSAT',
    TUTKINNONOSA = 'TUTKINNONOSA',
    TOSARYHMA = 'TOSARYHMA',
    SUORITUSPOLUT = 'SUORITUSPOLUT',
    SUORITUSPOLKU = 'SUORITUSPOLKU',
    OSASUORITUSPOLKU = 'OSASUORITUSPOLKU',
    OPINTOKOKONAISUUS = 'OPINTOKOKONAISUUS',
    KOULUTUKSENOSA = 'KOULUTUKSENOSA',
    KOULUTUKSENOSAT = 'KOULUTUKSENOSAT',
    LAAJAALAINENOSAAMINEN = 'LAAJAALAINENOSAAMINEN',
    KOTOKIELITAITOTASO = 'KOTO_KIELITAITOTASO',
    KOTOOPINTO = 'KOTO_OPINTO',
    KOTOLAAJAALAINENOSAAMINEN = 'KOTO_LAAJAALAINENOSAAMINEN',
    OSAAMISMERKKI = 'OSAAMISMERKKI',
    LINKKI = 'LINKKI'
}

/**
 * 
 * @export
 * @interface SisaltoViiteExportDto
 */
export interface SisaltoViiteExportDto {
    /**
     * 
     * @type {number}
     * @memberof SisaltoViiteExportDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SisaltoViiteExportDto
     */
    tyyppi?: SisaltoViiteExportDtoTyyppiEnum;
    /**
     * 
     * @type {CachedPerusteBaseDto}
     * @memberof SisaltoViiteExportDto
     */
    peruste?: CachedPerusteBaseDto;
    /**
     * 
     * @type {number}
     * @memberof SisaltoViiteExportDto
     */
    linkattuOps?: number;
    /**
     * 
     * @type {number}
     * @memberof SisaltoViiteExportDto
     */
    linkattuSisaltoViiteId?: number;
    /**
     * 
     * @type {number}
     * @memberof SisaltoViiteExportDto
     */
    linkattuPerusteId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SisaltoViiteExportDto
     */
    naytaPohjanTeksti?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SisaltoViiteExportDto
     */
    naytaPerusteenTeksti?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof SisaltoViiteExportDto
     */
    perusteteksti?: { [key: string]: string; };
    /**
     * 
     * @type {TekstiKappaleDto}
     * @memberof SisaltoViiteExportDto
     */
    pohjanTekstikappale?: TekstiKappaleDto;
    /**
     * 
     * @type {TutkinnonosaExportDto}
     * @memberof SisaltoViiteExportDto
     */
    tosa?: TutkinnonosaExportDto;
    /**
     * 
     * @type {SuorituspolkuExportDto}
     * @memberof SisaltoViiteExportDto
     */
    suorituspolku?: SuorituspolkuExportDto;
    /**
     * 
     * @type {TekstiKappaleJulkinenDto}
     * @memberof SisaltoViiteExportDto
     */
    tekstiKappale?: TekstiKappaleJulkinenDto;
    /**
     * 
     * @type {Array<SisaltoViiteExportDto>}
     * @memberof SisaltoViiteExportDto
     */
    lapset?: Array<SisaltoViiteExportDto>;
    /**
     * 
     * @type {OpintokokonaisuusDto}
     * @memberof SisaltoViiteExportDto
     */
    opintokokonaisuus?: OpintokokonaisuusDto;
    /**
     * 
     * @type {KoulutuksenOsaDto}
     * @memberof SisaltoViiteExportDto
     */
    koulutuksenosa?: KoulutuksenOsaDto;
    /**
     * 
     * @type {TuvaLaajaAlainenOsaaminenDto}
     * @memberof SisaltoViiteExportDto
     */
    tuvaLaajaAlainenOsaaminen?: TuvaLaajaAlainenOsaaminenDto;
    /**
     * 
     * @type {KotoKielitaitotasoExportDto}
     * @memberof SisaltoViiteExportDto
     */
    kotoKielitaitotaso?: KotoKielitaitotasoExportDto;
    /**
     * 
     * @type {KotoOpintoExportDto}
     * @memberof SisaltoViiteExportDto
     */
    kotoOpinto?: KotoOpintoExportDto;
    /**
     * 
     * @type {KotoLaajaAlainenOsaaminenExportDto}
     * @memberof SisaltoViiteExportDto
     */
    kotoLaajaAlainenOsaaminen?: KotoLaajaAlainenOsaaminenExportDto;
    /**
     * 
     * @type {OsaamismerkkiKappaleDto}
     * @memberof SisaltoViiteExportDto
     */
    osaamismerkkiKappale?: OsaamismerkkiKappaleDto;
    /**
     * 
     * @type {number}
     * @memberof SisaltoViiteExportDto
     */
    perusteenOsaId?: number;
    /**
     * 
     * @type {Array<OmaOsaAlueExportDto>}
     * @memberof SisaltoViiteExportDto
     */
    osaAlueet?: Array<OmaOsaAlueExportDto>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof SisaltoViiteExportDto
     */
    nimi?: { [key: string]: string; };
}

/**
    * @export
    * @enum {string}
    */
export enum SisaltoViiteExportDtoTyyppiEnum {
    TEKSTIKAPPALE = 'TEKSTIKAPPALE',
    TUTKINNONOSAT = 'TUTKINNONOSAT',
    TUTKINNONOSA = 'TUTKINNONOSA',
    TOSARYHMA = 'TOSARYHMA',
    SUORITUSPOLUT = 'SUORITUSPOLUT',
    SUORITUSPOLKU = 'SUORITUSPOLKU',
    OSASUORITUSPOLKU = 'OSASUORITUSPOLKU',
    OPINTOKOKONAISUUS = 'OPINTOKOKONAISUUS',
    KOULUTUKSENOSA = 'KOULUTUKSENOSA',
    KOULUTUKSENOSAT = 'KOULUTUKSENOSAT',
    LAAJAALAINENOSAAMINEN = 'LAAJAALAINENOSAAMINEN',
    KOTOKIELITAITOTASO = 'KOTO_KIELITAITOTASO',
    KOTOOPINTO = 'KOTO_OPINTO',
    KOTOLAAJAALAINENOSAAMINEN = 'KOTO_LAAJAALAINENOSAAMINEN',
    OSAAMISMERKKI = 'OSAAMISMERKKI',
    LINKKI = 'LINKKI'
}

/**
 * 
 * @export
 * @interface SisaltoViiteExportOpintokokonaisuusDto
 */
export interface SisaltoViiteExportOpintokokonaisuusDto {
    /**
     * 
     * @type {number}
     * @memberof SisaltoViiteExportOpintokokonaisuusDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SisaltoViiteExportOpintokokonaisuusDto
     */
    tyyppi?: SisaltoViiteExportOpintokokonaisuusDtoTyyppiEnum;
    /**
     * 
     * @type {CachedPerusteBaseDto}
     * @memberof SisaltoViiteExportOpintokokonaisuusDto
     */
    peruste?: CachedPerusteBaseDto;
    /**
     * 
     * @type {number}
     * @memberof SisaltoViiteExportOpintokokonaisuusDto
     */
    linkattuOps?: number;
    /**
     * 
     * @type {number}
     * @memberof SisaltoViiteExportOpintokokonaisuusDto
     */
    linkattuSisaltoViiteId?: number;
    /**
     * 
     * @type {number}
     * @memberof SisaltoViiteExportOpintokokonaisuusDto
     */
    linkattuPerusteId?: number;
    /**
     * 
     * @type {OpintokokonaisuusDto}
     * @memberof SisaltoViiteExportOpintokokonaisuusDto
     */
    opintokokonaisuus?: OpintokokonaisuusDto;
}

/**
    * @export
    * @enum {string}
    */
export enum SisaltoViiteExportOpintokokonaisuusDtoTyyppiEnum {
    TEKSTIKAPPALE = 'TEKSTIKAPPALE',
    TUTKINNONOSAT = 'TUTKINNONOSAT',
    TUTKINNONOSA = 'TUTKINNONOSA',
    TOSARYHMA = 'TOSARYHMA',
    SUORITUSPOLUT = 'SUORITUSPOLUT',
    SUORITUSPOLKU = 'SUORITUSPOLKU',
    OSASUORITUSPOLKU = 'OSASUORITUSPOLKU',
    OPINTOKOKONAISUUS = 'OPINTOKOKONAISUUS',
    KOULUTUKSENOSA = 'KOULUTUKSENOSA',
    KOULUTUKSENOSAT = 'KOULUTUKSENOSAT',
    LAAJAALAINENOSAAMINEN = 'LAAJAALAINENOSAAMINEN',
    KOTOKIELITAITOTASO = 'KOTO_KIELITAITOTASO',
    KOTOOPINTO = 'KOTO_OPINTO',
    KOTOLAAJAALAINENOSAAMINEN = 'KOTO_LAAJAALAINENOSAAMINEN',
    OSAAMISMERKKI = 'OSAAMISMERKKI',
    LINKKI = 'LINKKI'
}

/**
 * 
 * @export
 * @interface SisaltoViiteKevytDto
 */
export interface SisaltoViiteKevytDto {
    /**
     * 
     * @type {number}
     * @memberof SisaltoViiteKevytDto
     */
    id?: number;
    /**
     * 
     * @type {TekstiKappaleKevytDto}
     * @memberof SisaltoViiteKevytDto
     */
    tekstiKappale?: TekstiKappaleKevytDto;
    /**
     * 
     * @type {TutkinnonOsaKevytDto}
     * @memberof SisaltoViiteKevytDto
     */
    tosa?: TutkinnonOsaKevytDto;
    /**
     * 
     * @type {string}
     * @memberof SisaltoViiteKevytDto
     */
    tyyppi?: SisaltoViiteKevytDtoTyyppiEnum;
    /**
     * 
     * @type {boolean}
     * @memberof SisaltoViiteKevytDto
     */
    pakollinen?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SisaltoViiteKevytDto
     */
    valmis?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SisaltoViiteKevytDto
     */
    liikkumaton?: boolean;
    /**
     * 
     * @type {Reference}
     * @memberof SisaltoViiteKevytDto
     */
    vanhempi?: Reference;
    /**
     * 
     * @type {CachedPerusteBaseDto}
     * @memberof SisaltoViiteKevytDto
     */
    peruste?: CachedPerusteBaseDto;
    /**
     * 
     * @type {Array<Reference>}
     * @memberof SisaltoViiteKevytDto
     */
    lapset?: Array<Reference>;
    /**
     * 
     * @type {KoulutuksenOsaKevytDto}
     * @memberof SisaltoViiteKevytDto
     */
    koulutuksenosa?: KoulutuksenOsaKevytDto;
    /**
     * 
     * @type {TuvaLaajaAlainenOsaaminenDto}
     * @memberof SisaltoViiteKevytDto
     */
    tuvaLaajaAlainenOsaaminen?: TuvaLaajaAlainenOsaaminenDto;
    /**
     * 
     * @type {string}
     * @memberof SisaltoViiteKevytDto
     */
    linkattuTyyppi?: SisaltoViiteKevytDtoLinkattuTyyppiEnum;
    /**
     * 
     * @type {OpintokokonaisuusDto}
     * @memberof SisaltoViiteKevytDto
     */
    opintokokonaisuus?: OpintokokonaisuusDto;
    /**
     * 
     * @type {number}
     * @memberof SisaltoViiteKevytDto
     */
    perusteenOsaId?: number;
    /**
     * 
     * @type {Array<OmaOsaAlueKevytDto>}
     * @memberof SisaltoViiteKevytDto
     */
    osaAlueet?: Array<OmaOsaAlueKevytDto>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof SisaltoViiteKevytDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {Reference}
     * @memberof SisaltoViiteKevytDto
     */
    _tekstiKappale?: Reference;
}

/**
    * @export
    * @enum {string}
    */
export enum SisaltoViiteKevytDtoTyyppiEnum {
    TEKSTIKAPPALE = 'TEKSTIKAPPALE',
    TUTKINNONOSAT = 'TUTKINNONOSAT',
    TUTKINNONOSA = 'TUTKINNONOSA',
    TOSARYHMA = 'TOSARYHMA',
    SUORITUSPOLUT = 'SUORITUSPOLUT',
    SUORITUSPOLKU = 'SUORITUSPOLKU',
    OSASUORITUSPOLKU = 'OSASUORITUSPOLKU',
    OPINTOKOKONAISUUS = 'OPINTOKOKONAISUUS',
    KOULUTUKSENOSA = 'KOULUTUKSENOSA',
    KOULUTUKSENOSAT = 'KOULUTUKSENOSAT',
    LAAJAALAINENOSAAMINEN = 'LAAJAALAINENOSAAMINEN',
    KOTOKIELITAITOTASO = 'KOTO_KIELITAITOTASO',
    KOTOOPINTO = 'KOTO_OPINTO',
    KOTOLAAJAALAINENOSAAMINEN = 'KOTO_LAAJAALAINENOSAAMINEN',
    OSAAMISMERKKI = 'OSAAMISMERKKI',
    LINKKI = 'LINKKI'
}
/**
    * @export
    * @enum {string}
    */
export enum SisaltoViiteKevytDtoLinkattuTyyppiEnum {
    TEKSTIKAPPALE = 'TEKSTIKAPPALE',
    TUTKINNONOSAT = 'TUTKINNONOSAT',
    TUTKINNONOSA = 'TUTKINNONOSA',
    TOSARYHMA = 'TOSARYHMA',
    SUORITUSPOLUT = 'SUORITUSPOLUT',
    SUORITUSPOLKU = 'SUORITUSPOLKU',
    OSASUORITUSPOLKU = 'OSASUORITUSPOLKU',
    OPINTOKOKONAISUUS = 'OPINTOKOKONAISUUS',
    KOULUTUKSENOSA = 'KOULUTUKSENOSA',
    KOULUTUKSENOSAT = 'KOULUTUKSENOSAT',
    LAAJAALAINENOSAAMINEN = 'LAAJAALAINENOSAAMINEN',
    KOTOKIELITAITOTASO = 'KOTO_KIELITAITOTASO',
    KOTOOPINTO = 'KOTO_OPINTO',
    KOTOLAAJAALAINENOSAAMINEN = 'KOTO_LAAJAALAINENOSAAMINEN',
    OSAAMISMERKKI = 'OSAAMISMERKKI',
    LINKKI = 'LINKKI'
}

/**
 * 
 * @export
 * @interface SisaltoViitePaikallinenIntegrationDto
 */
export interface SisaltoViitePaikallinenIntegrationDto {
    /**
     * 
     * @type {number}
     * @memberof SisaltoViitePaikallinenIntegrationDto
     */
    id?: number;
    /**
     * 
     * @type {TekstiKappaleNimiDto}
     * @memberof SisaltoViitePaikallinenIntegrationDto
     */
    tekstiKappale?: TekstiKappaleNimiDto;
    /**
     * 
     * @type {TutkinnonOsaIntegrationDto}
     * @memberof SisaltoViitePaikallinenIntegrationDto
     */
    tosa?: TutkinnonOsaIntegrationDto;
    /**
     * 
     * @type {Reference}
     * @memberof SisaltoViitePaikallinenIntegrationDto
     */
    owner?: Reference;
}
/**
 * 
 * @export
 * @interface SisaltoViiteRakenneDto
 */
export interface SisaltoViiteRakenneDto {
    /**
     * 
     * @type {number}
     * @memberof SisaltoViiteRakenneDto
     */
    id?: number;
    /**
     * 
     * @type {Reference}
     * @memberof SisaltoViiteRakenneDto
     */
    vanhempi?: Reference;
    /**
     * 
     * @type {Array<SisaltoViiteRakenneDto>}
     * @memberof SisaltoViiteRakenneDto
     */
    lapset?: Array<SisaltoViiteRakenneDto>;
}
/**
 * 
 * @export
 * @interface SisaltoViiteSijaintiDto
 */
export interface SisaltoViiteSijaintiDto {
    /**
     * 
     * @type {number}
     * @memberof SisaltoViiteSijaintiDto
     */
    id?: number;
    /**
     * 
     * @type {TekstiKappaleNimiDto}
     * @memberof SisaltoViiteSijaintiDto
     */
    tekstiKappale?: TekstiKappaleNimiDto;
    /**
     * 
     * @type {OpetussuunnitelmaBaseDto}
     * @memberof SisaltoViiteSijaintiDto
     */
    owner?: OpetussuunnitelmaBaseDto;
    /**
     * 
     * @type {TutkinnonOsaSijaintiDto}
     * @memberof SisaltoViiteSijaintiDto
     */
    tosa?: TutkinnonOsaSijaintiDto;
}
/**
 * 
 * @export
 * @interface SisaltoViiteTutkinnonosaKevytDto
 */
export interface SisaltoViiteTutkinnonosaKevytDto {
    /**
     * 
     * @type {number}
     * @memberof SisaltoViiteTutkinnonosaKevytDto
     */
    id?: number;
    /**
     * 
     * @type {TekstiKappaleDto}
     * @memberof SisaltoViiteTutkinnonosaKevytDto
     */
    tekstiKappale?: TekstiKappaleDto;
    /**
     * 
     * @type {TutkinnonOsaKevytDto}
     * @memberof SisaltoViiteTutkinnonosaKevytDto
     */
    tosa?: TutkinnonOsaKevytDto;
    /**
     * 
     * @type {number}
     * @memberof SisaltoViiteTutkinnonosaKevytDto
     */
    linkattuPeruste?: number;
}
/**
 * 
 * @export
 * @interface SisaltoviiteLaajaDto
 */
export interface SisaltoviiteLaajaDto {
    /**
     * 
     * @type {number}
     * @memberof SisaltoviiteLaajaDto
     */
    id?: number;
    /**
     * 
     * @type {TekstiKappaleDto}
     * @memberof SisaltoviiteLaajaDto
     */
    tekstiKappale?: TekstiKappaleDto;
    /**
     * 
     * @type {string}
     * @memberof SisaltoviiteLaajaDto
     */
    tyyppi?: SisaltoviiteLaajaDtoTyyppiEnum;
    /**
     * 
     * @type {CachedPerusteBaseDto}
     * @memberof SisaltoviiteLaajaDto
     */
    peruste?: CachedPerusteBaseDto;
    /**
     * 
     * @type {TutkinnonosaDto}
     * @memberof SisaltoviiteLaajaDto
     */
    tosa?: TutkinnonosaDto;
    /**
     * 
     * @type {SuorituspolkuDto}
     * @memberof SisaltoviiteLaajaDto
     */
    suorituspolku?: SuorituspolkuDto;
    /**
     * 
     * @type {OpintokokonaisuusDto}
     * @memberof SisaltoviiteLaajaDto
     */
    opintokokonaisuus?: OpintokokonaisuusDto;
    /**
     * 
     * @type {KoulutuksenOsaDto}
     * @memberof SisaltoviiteLaajaDto
     */
    koulutuksenosa?: KoulutuksenOsaDto;
    /**
     * 
     * @type {TuvaLaajaAlainenOsaaminenDto}
     * @memberof SisaltoviiteLaajaDto
     */
    tuvaLaajaAlainenOsaaminen?: TuvaLaajaAlainenOsaaminenDto;
    /**
     * 
     * @type {KotoKielitaitotasoDto}
     * @memberof SisaltoviiteLaajaDto
     */
    kotoKielitaitotaso?: KotoKielitaitotasoDto;
    /**
     * 
     * @type {KotoOpintoDto}
     * @memberof SisaltoviiteLaajaDto
     */
    kotoOpinto?: KotoOpintoDto;
    /**
     * 
     * @type {KotoLaajaAlainenOsaaminenDto}
     * @memberof SisaltoviiteLaajaDto
     */
    kotoLaajaAlainenOsaaminen?: KotoLaajaAlainenOsaaminenDto;
    /**
     * 
     * @type {number}
     * @memberof SisaltoviiteLaajaDto
     */
    perusteenOsaId?: number;
    /**
     * 
     * @type {OpetussuunnitelmaDto}
     * @memberof SisaltoviiteLaajaDto
     */
    opetussuunnitelma?: OpetussuunnitelmaDto;
}

/**
    * @export
    * @enum {string}
    */
export enum SisaltoviiteLaajaDtoTyyppiEnum {
    TEKSTIKAPPALE = 'TEKSTIKAPPALE',
    TUTKINNONOSAT = 'TUTKINNONOSAT',
    TUTKINNONOSA = 'TUTKINNONOSA',
    TOSARYHMA = 'TOSARYHMA',
    SUORITUSPOLUT = 'SUORITUSPOLUT',
    SUORITUSPOLKU = 'SUORITUSPOLKU',
    OSASUORITUSPOLKU = 'OSASUORITUSPOLKU',
    OPINTOKOKONAISUUS = 'OPINTOKOKONAISUUS',
    KOULUTUKSENOSA = 'KOULUTUKSENOSA',
    KOULUTUKSENOSAT = 'KOULUTUKSENOSAT',
    LAAJAALAINENOSAAMINEN = 'LAAJAALAINENOSAAMINEN',
    KOTOKIELITAITOTASO = 'KOTO_KIELITAITOTASO',
    KOTOOPINTO = 'KOTO_OPINTO',
    KOTOLAAJAALAINENOSAAMINEN = 'KOTO_LAAJAALAINENOSAAMINEN',
    OSAAMISMERKKI = 'OSAAMISMERKKI',
    LINKKI = 'LINKKI'
}

/**
 * 
 * @export
 * @interface SisaltoviiteOpintokokonaisuusExternalDto
 */
export interface SisaltoviiteOpintokokonaisuusExternalDto {
    /**
     * 
     * @type {number}
     * @memberof SisaltoviiteOpintokokonaisuusExternalDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof SisaltoviiteOpintokokonaisuusExternalDto
     */
    opetussuunnitelmaId?: number;
}
/**
 * 
 * @export
 * @interface Sort
 */
export interface Sort {
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    unsorted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    sorted?: boolean;
}
/**
 * 
 * @export
 * @interface SuorituspolkuDto
 */
export interface SuorituspolkuDto {
    /**
     * 
     * @type {number}
     * @memberof SuorituspolkuDto
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof SuorituspolkuDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof SuorituspolkuDto
     */
    naytaKuvausJulkisesti?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SuorituspolkuDto
     */
    piilotaPerusteenTeksti?: boolean;
    /**
     * 
     * @type {Array<SuorituspolkuRiviDto>}
     * @memberof SuorituspolkuDto
     */
    rivit?: Array<SuorituspolkuRiviDto>;
    /**
     * 
     * @type {number}
     * @memberof SuorituspolkuDto
     */
    osasuorituspolkuLaajuus?: number;
}
/**
 * 
 * @export
 * @interface SuorituspolkuExportDto
 */
export interface SuorituspolkuExportDto {
    /**
     * 
     * @type {number}
     * @memberof SuorituspolkuExportDto
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof SuorituspolkuExportDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {number}
     * @memberof SuorituspolkuExportDto
     */
    osasuorituspolkuLaajuus?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SuorituspolkuExportDto
     */
    piilotaPerusteenTeksti?: boolean;
    /**
     * 
     * @type {Array<SuorituspolkuRiviDto>}
     * @memberof SuorituspolkuExportDto
     */
    rivit?: Array<SuorituspolkuRiviDto>;
}
/**
 * 
 * @export
 * @interface SuorituspolkuRakenneDto
 */
export interface SuorituspolkuRakenneDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof SuorituspolkuRakenneDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {KoodiDto}
     * @memberof SuorituspolkuRakenneDto
     */
    vieras?: KoodiDto;
    /**
     * 
     * @type {string}
     * @memberof SuorituspolkuRakenneDto
     */
    tunniste?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SuorituspolkuRakenneDto
     */
    pakollinen?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof SuorituspolkuRakenneDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof SuorituspolkuRakenneDto
     */
    rooli?: SuorituspolkuRakenneDtoRooliEnum;
    /**
     * 
     * @type {MuodostumisSaantoDto}
     * @memberof SuorituspolkuRakenneDto
     */
    muodostumisSaanto?: MuodostumisSaantoDto;
    /**
     * 
     * @type {OsaamisalaDto}
     * @memberof SuorituspolkuRakenneDto
     */
    osaamisala?: OsaamisalaDto;
    /**
     * 
     * @type {KoodiDto}
     * @memberof SuorituspolkuRakenneDto
     */
    tutkintonimike?: KoodiDto;
    /**
     * 
     * @type {Array<AbstractRakenneOsaDto>}
     * @memberof SuorituspolkuRakenneDto
     */
    osat?: Array<AbstractRakenneOsaDto>;
    /**
     * 
     * @type {number}
     * @memberof SuorituspolkuRakenneDto
     */
    versioId?: number;
    /**
     * 
     * @type {SuorituspolkuRiviDto}
     * @memberof SuorituspolkuRakenneDto
     */
    paikallinenKuvaus?: SuorituspolkuRiviDto;
    /**
     * 
     * @type {number}
     * @memberof SuorituspolkuRakenneDto
     */
    sisaltoviiteId?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum SuorituspolkuRakenneDtoRooliEnum {
    NORMAALI = 'NORMAALI',
    VIRTUAALINEN = 'VIRTUAALINEN',
    OSAAMISALA = 'OSAAMISALA',
    TUTKINTONIMIKE = 'TUTKINTONIMIKE',
    VIERAS = 'VIERAS'
}

/**
 * 
 * @export
 * @interface SuorituspolkuRiviDto
 */
export interface SuorituspolkuRiviDto {
    /**
     * 
     * @type {number}
     * @memberof SuorituspolkuRiviDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof SuorituspolkuRiviDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {Array<string>}
     * @memberof SuorituspolkuRiviDto
     */
    koodit?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SuorituspolkuRiviDto
     */
    rakennemoduuli?: string;
    /**
     * 
     * @type {number}
     * @memberof SuorituspolkuRiviDto
     */
    jrno?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SuorituspolkuRiviDto
     */
    piilotettu?: boolean;
}
/**
 * 
 * @export
 * @interface SuoritustapaDto
 */
export interface SuoritustapaDto {
    /**
     * 
     * @type {string}
     * @memberof SuoritustapaDto
     */
    suoritustapakoodi?: SuoritustapaDtoSuoritustapakoodiEnum;
    /**
     * 
     * @type {string}
     * @memberof SuoritustapaDto
     */
    laajuusYksikko?: SuoritustapaDtoLaajuusYksikkoEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SuoritustapaDtoSuoritustapakoodiEnum {
    OPS = 'OPS',
    NAYTTO = 'NAYTTO',
    REFORMI = 'REFORMI',
    PERUSOPETUS = 'PERUSOPETUS',
    LISAOPETUS = 'LISAOPETUS',
    VARHAISKASVATUS = 'VARHAISKASVATUS',
    ESIOPETUS = 'ESIOPETUS',
    LUKIOKOULUTUS = 'LUKIOKOULUTUS'
}
/**
    * @export
    * @enum {string}
    */
export enum SuoritustapaDtoLaajuusYksikkoEnum {
    OPINTOVIIKKO = 'OPINTOVIIKKO',
    OPINTOPISTE = 'OPINTOPISTE',
    OSAAMISPISTE = 'OSAAMISPISTE',
    KURSSI = 'KURSSI',
    TUNTI = 'TUNTI',
    VIIKKO = 'VIIKKO',
    VUOSIVIIKKOTUNTI = 'VUOSIVIIKKOTUNTI',
    VUOSI = 'VUOSI'
}

/**
 * 
 * @export
 * @interface TekstiKappaleDto
 */
export interface TekstiKappaleDto {
    /**
     * 
     * @type {number}
     * @memberof TekstiKappaleDto
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof TekstiKappaleDto
     */
    luotu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TekstiKappaleDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof TekstiKappaleDto
     */
    muokkaaja?: string;
    /**
     * 
     * @type {string}
     * @memberof TekstiKappaleDto
     */
    muokkaajanNimi?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TekstiKappaleDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TekstiKappaleDto
     */
    teksti?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof TekstiKappaleDto
     */
    tunniste?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TekstiKappaleDto
     */
    pakollinen?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TekstiKappaleDto
     */
    valmis?: boolean;
}
/**
 * 
 * @export
 * @interface TekstiKappaleJulkinenDto
 */
export interface TekstiKappaleJulkinenDto {
    /**
     * 
     * @type {number}
     * @memberof TekstiKappaleJulkinenDto
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof TekstiKappaleJulkinenDto
     */
    luotu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TekstiKappaleJulkinenDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TekstiKappaleJulkinenDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TekstiKappaleJulkinenDto
     */
    teksti?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof TekstiKappaleJulkinenDto
     */
    tunniste?: string;
}
/**
 * 
 * @export
 * @interface TekstiKappaleKevytDto
 */
export interface TekstiKappaleKevytDto {
    /**
     * 
     * @type {number}
     * @memberof TekstiKappaleKevytDto
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof TekstiKappaleKevytDto
     */
    luotu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TekstiKappaleKevytDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof TekstiKappaleKevytDto
     */
    muokkaaja?: string;
    /**
     * 
     * @type {string}
     * @memberof TekstiKappaleKevytDto
     */
    muokkaajanNimi?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TekstiKappaleKevytDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof TekstiKappaleKevytDto
     */
    tila?: TekstiKappaleKevytDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof TekstiKappaleKevytDto
     */
    tunniste?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TekstiKappaleKevytDto
     */
    pakollinen?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TekstiKappaleKevytDto
     */
    valmis?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum TekstiKappaleKevytDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU',
    JULKAISTU = 'JULKAISTU'
}

/**
 * 
 * @export
 * @interface TekstiKappaleNimiDto
 */
export interface TekstiKappaleNimiDto {
    /**
     * 
     * @type {number}
     * @memberof TekstiKappaleNimiDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TekstiKappaleNimiDto
     */
    nimi?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface Tekstiosa
 */
export interface Tekstiosa {
    /**
     * 
     * @type {number}
     * @memberof Tekstiosa
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTeksti}
     * @memberof Tekstiosa
     */
    otsikko?: LokalisoituTeksti;
    /**
     * 
     * @type {LokalisoituTeksti}
     * @memberof Tekstiosa
     */
    teksti?: LokalisoituTeksti;
}
/**
 * 
 * @export
 * @interface TekstiosaDto
 */
export interface TekstiosaDto {
    /**
     * 
     * @type {number}
     * @memberof TekstiosaDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TekstiosaDto
     */
    otsikko?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TekstiosaDto
     */
    teksti?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface TiedoteDto
 */
export interface TiedoteDto {
    /**
     * 
     * @type {number}
     * @memberof TiedoteDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TiedoteDto
     */
    otsikko?: string;
    /**
     * 
     * @type {string}
     * @memberof TiedoteDto
     */
    teksti?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TiedoteDto
     */
    julkinen?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiedoteDto
     */
    tarkea?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof TiedoteDto
     */
    luotu?: Date;
    /**
     * 
     * @type {string}
     * @memberof TiedoteDto
     */
    luoja?: string;
    /**
     * 
     * @type {Date}
     * @memberof TiedoteDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof TiedoteDto
     */
    muokkaaja?: string;
}
/**
 * 
 * @export
 * @interface TutkinnonOsaIntegrationDto
 */
export interface TutkinnonOsaIntegrationDto {
    /**
     * 
     * @type {number}
     * @memberof TutkinnonOsaIntegrationDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TutkinnonOsaIntegrationDto
     */
    tyyppi?: TutkinnonOsaIntegrationDtoTyyppiEnum;
    /**
     * 
     * @type {OmaTutkinnonosaIntegrationDto}
     * @memberof TutkinnonOsaIntegrationDto
     */
    omatutkinnonosa?: OmaTutkinnonosaIntegrationDto;
}

/**
    * @export
    * @enum {string}
    */
export enum TutkinnonOsaIntegrationDtoTyyppiEnum {
    PERUSTEESTA = 'PERUSTEESTA',
    OMA = 'OMA',
    VIERAS = 'VIERAS',
    YHTEINEN = 'YHTEINEN'
}

/**
 * 
 * @export
 * @interface TutkinnonOsaKevytDto
 */
export interface TutkinnonOsaKevytDto {
    /**
     * 
     * @type {string}
     * @memberof TutkinnonOsaKevytDto
     */
    tyyppi?: TutkinnonOsaKevytDtoTyyppiEnum;
    /**
     * 
     * @type {OmaTutkinnonosaKevytDto}
     * @memberof TutkinnonOsaKevytDto
     */
    omatutkinnonosa?: OmaTutkinnonosaKevytDto;
    /**
     * 
     * @type {Date}
     * @memberof TutkinnonOsaKevytDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {number}
     * @memberof TutkinnonOsaKevytDto
     */
    perusteentutkinnonosa?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum TutkinnonOsaKevytDtoTyyppiEnum {
    PERUSTEESTA = 'PERUSTEESTA',
    OMA = 'OMA',
    VIERAS = 'VIERAS',
    YHTEINEN = 'YHTEINEN'
}

/**
 * 
 * @export
 * @interface TutkinnonOsaSijaintiDto
 */
export interface TutkinnonOsaSijaintiDto {
    /**
     * 
     * @type {OmaTutkinnonosaSuorituspolkuDto}
     * @memberof TutkinnonOsaSijaintiDto
     */
    omatutkinnonosa?: OmaTutkinnonosaSuorituspolkuDto;
}
/**
 * 
 * @export
 * @interface TutkinnonOsaSuoritustapaDto
 */
export interface TutkinnonOsaSuoritustapaDto {
    /**
     * 
     * @type {number}
     * @memberof TutkinnonOsaSuoritustapaDto
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof TutkinnonOsaSuoritustapaDto
     */
    luotu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TutkinnonOsaSuoritustapaDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TutkinnonOsaSuoritustapaDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof TutkinnonOsaSuoritustapaDto
     */
    tila?: TutkinnonOsaSuoritustapaDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof TutkinnonOsaSuoritustapaDto
     */
    tunniste?: TutkinnonOsaSuoritustapaDtoTunnisteEnum;
    /**
     * 
     * @type {string}
     * @memberof TutkinnonOsaSuoritustapaDto
     */
    osanTyyppi?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TutkinnonOsaSuoritustapaDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof TutkinnonOsaSuoritustapaDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof TutkinnonOsaSuoritustapaDto
     */
    koodiArvo?: string;
    /**
     * 
     * @type {string}
     * @memberof TutkinnonOsaSuoritustapaDto
     */
    tyyppi?: TutkinnonOsaSuoritustapaDtoTyyppiEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum TutkinnonOsaSuoritustapaDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU'
}
/**
    * @export
    * @enum {string}
    */
export enum TutkinnonOsaSuoritustapaDtoTunnisteEnum {
    NORMAALI = 'NORMAALI',
    LAAJAALAINENOSAAMINEN = 'LAAJAALAINENOSAAMINEN',
    RAKENNE = 'RAKENNE'
}
/**
    * @export
    * @enum {string}
    */
export enum TutkinnonOsaSuoritustapaDtoTyyppiEnum {
    NORMAALI = 'NORMAALI',
    REFORMITUTKE2 = 'REFORMI_TUTKE2',
    TUTKE2 = 'TUTKE2'
}

/**
 * 
 * @export
 * @interface Tutkinnonosa
 */
export interface Tutkinnonosa {
    /**
     * 
     * @type {Date}
     * @memberof Tutkinnonosa
     */
    luotu?: Date;
    /**
     * 
     * @type {string}
     * @memberof Tutkinnonosa
     */
    luoja?: string;
    /**
     * 
     * @type {Date}
     * @memberof Tutkinnonosa
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof Tutkinnonosa
     */
    muokkaaja?: string;
    /**
     * 
     * @type {number}
     * @memberof Tutkinnonosa
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Tutkinnonosa
     */
    tyyppi?: TutkinnonosaTyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof Tutkinnonosa
     */
    koodi?: string;
    /**
     * 
     * @type {number}
     * @memberof Tutkinnonosa
     */
    perusteentutkinnonosa?: number;
    /**
     * 
     * @type {LokalisoituTeksti}
     * @memberof Tutkinnonosa
     */
    osaamisenOsoittaminen?: LokalisoituTeksti;
    /**
     * 
     * @type {OmaTutkinnonosa}
     * @memberof Tutkinnonosa
     */
    omatutkinnonosa?: OmaTutkinnonosa;
    /**
     * 
     * @type {VierasTutkinnonosa}
     * @memberof Tutkinnonosa
     */
    vierastutkinnonosa?: VierasTutkinnonosa;
    /**
     * 
     * @type {Array<TutkinnonosaToteutus>}
     * @memberof Tutkinnonosa
     */
    toteutukset?: Array<TutkinnonosaToteutus>;
    /**
     * 
     * @type {Array<VapaaTeksti>}
     * @memberof Tutkinnonosa
     */
    vapaat?: Array<VapaaTeksti>;
}

/**
    * @export
    * @enum {string}
    */
export enum TutkinnonosaTyyppiEnum {
    PERUSTEESTA = 'PERUSTEESTA',
    OMA = 'OMA',
    VIERAS = 'VIERAS',
    YHTEINEN = 'YHTEINEN'
}

/**
 * 
 * @export
 * @interface TutkinnonosaDto
 */
export interface TutkinnonosaDto {
    /**
     * 
     * @type {number}
     * @memberof TutkinnonosaDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TutkinnonosaDto
     */
    tyyppi?: TutkinnonosaDtoTyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof TutkinnonosaDto
     */
    koodi?: string;
    /**
     * 
     * @type {Date}
     * @memberof TutkinnonosaDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {VierasTutkinnonosaDto}
     * @memberof TutkinnonosaDto
     */
    vierastutkinnonosa?: VierasTutkinnonosaDto;
    /**
     * 
     * @type {Array<TutkinnonosaToteutusDto>}
     * @memberof TutkinnonosaDto
     */
    toteutukset?: Array<TutkinnonosaToteutusDto>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TutkinnonosaDto
     */
    osaamisenOsoittaminen?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof TutkinnonosaDto
     */
    perusteentutkinnonosa?: number;
    /**
     * 
     * @type {Array<VapaaTekstiDto>}
     * @memberof TutkinnonosaDto
     */
    vapaat?: Array<VapaaTekstiDto>;
    /**
     * 
     * @type {OmaTutkinnonosaDto}
     * @memberof TutkinnonosaDto
     */
    omatutkinnonosa?: OmaTutkinnonosaDto;
}

/**
    * @export
    * @enum {string}
    */
export enum TutkinnonosaDtoTyyppiEnum {
    PERUSTEESTA = 'PERUSTEESTA',
    OMA = 'OMA',
    VIERAS = 'VIERAS',
    YHTEINEN = 'YHTEINEN'
}

/**
 * 
 * @export
 * @interface TutkinnonosaExportDto
 */
export interface TutkinnonosaExportDto {
    /**
     * 
     * @type {number}
     * @memberof TutkinnonosaExportDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TutkinnonosaExportDto
     */
    tyyppi?: TutkinnonosaExportDtoTyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof TutkinnonosaExportDto
     */
    koodi?: string;
    /**
     * 
     * @type {Date}
     * @memberof TutkinnonosaExportDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {VierasTutkinnonosaDto}
     * @memberof TutkinnonosaExportDto
     */
    vierastutkinnonosa?: VierasTutkinnonosaDto;
    /**
     * 
     * @type {Array<TutkinnonosaToteutusDto>}
     * @memberof TutkinnonosaExportDto
     */
    toteutukset?: Array<TutkinnonosaToteutusDto>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TutkinnonosaExportDto
     */
    osaamisenOsoittaminen?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof TutkinnonosaExportDto
     */
    perusteentutkinnonosa?: number;
    /**
     * 
     * @type {Array<VapaaTekstiDto>}
     * @memberof TutkinnonosaExportDto
     */
    vapaat?: Array<VapaaTekstiDto>;
    /**
     * 
     * @type {OmaTutkinnonosaExportDto}
     * @memberof TutkinnonosaExportDto
     */
    omatutkinnonosa?: OmaTutkinnonosaExportDto;
}

/**
    * @export
    * @enum {string}
    */
export enum TutkinnonosaExportDtoTyyppiEnum {
    PERUSTEESTA = 'PERUSTEESTA',
    OMA = 'OMA',
    VIERAS = 'VIERAS',
    YHTEINEN = 'YHTEINEN'
}

/**
 * 
 * @export
 * @interface TutkinnonosaToteutus
 */
export interface TutkinnonosaToteutus {
    /**
     * 
     * @type {Date}
     * @memberof TutkinnonosaToteutus
     */
    luotu?: Date;
    /**
     * 
     * @type {string}
     * @memberof TutkinnonosaToteutus
     */
    luoja?: string;
    /**
     * 
     * @type {Date}
     * @memberof TutkinnonosaToteutus
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof TutkinnonosaToteutus
     */
    muokkaaja?: string;
    /**
     * 
     * @type {number}
     * @memberof TutkinnonosaToteutus
     */
    id?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof TutkinnonosaToteutus
     */
    koodit?: Array<string>;
    /**
     * 
     * @type {Tutkinnonosa}
     * @memberof TutkinnonosaToteutus
     */
    tutkinnonosa?: Tutkinnonosa;
    /**
     * 
     * @type {LokalisoituTeksti}
     * @memberof TutkinnonosaToteutus
     */
    otsikko?: LokalisoituTeksti;
    /**
     * 
     * @type {Tekstiosa}
     * @memberof TutkinnonosaToteutus
     */
    tavatjaymparisto?: Tekstiosa;
    /**
     * 
     * @type {Tekstiosa}
     * @memberof TutkinnonosaToteutus
     */
    arvioinnista?: Tekstiosa;
    /**
     * 
     * @type {Array<VapaaTeksti>}
     * @memberof TutkinnonosaToteutus
     */
    vapaat?: Array<VapaaTeksti>;
    /**
     * 
     * @type {boolean}
     * @memberof TutkinnonosaToteutus
     */
    oletustoteutus?: boolean;
}
/**
 * 
 * @export
 * @interface TutkinnonosaToteutusDto
 */
export interface TutkinnonosaToteutusDto {
    /**
     * 
     * @type {number}
     * @memberof TutkinnonosaToteutusDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TutkinnonosaToteutusDto
     */
    otsikko?: { [key: string]: string; };
    /**
     * 
     * @type {TekstiosaDto}
     * @memberof TutkinnonosaToteutusDto
     */
    tavatjaymparisto?: TekstiosaDto;
    /**
     * 
     * @type {TekstiosaDto}
     * @memberof TutkinnonosaToteutusDto
     */
    arvioinnista?: TekstiosaDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof TutkinnonosaToteutusDto
     */
    koodit?: Array<string>;
    /**
     * 
     * @type {Array<VapaaTekstiDto>}
     * @memberof TutkinnonosaToteutusDto
     */
    vapaat?: Array<VapaaTekstiDto>;
    /**
     * 
     * @type {boolean}
     * @memberof TutkinnonosaToteutusDto
     */
    oletustoteutus?: boolean;
}
/**
 * 
 * @export
 * @interface TutkintonimikeKoodiDto
 */
export interface TutkintonimikeKoodiDto {
    /**
     * 
     * @type {string}
     * @memberof TutkintonimikeKoodiDto
     */
    tutkinnonOsaUri?: string;
    /**
     * 
     * @type {string}
     * @memberof TutkintonimikeKoodiDto
     */
    tutkinnonOsaArvo?: string;
    /**
     * 
     * @type {string}
     * @memberof TutkintonimikeKoodiDto
     */
    osaamisalaUri?: string;
    /**
     * 
     * @type {string}
     * @memberof TutkintonimikeKoodiDto
     */
    osaamisalaArvo?: string;
    /**
     * 
     * @type {string}
     * @memberof TutkintonimikeKoodiDto
     */
    tutkintonimikeUri?: string;
}
/**
 * 
 * @export
 * @interface TuvaLaajaAlainenOsaaminenDto
 */
export interface TuvaLaajaAlainenOsaaminenDto {
    /**
     * 
     * @type {string}
     * @memberof TuvaLaajaAlainenOsaaminenDto
     */
    nimiKoodi?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TuvaLaajaAlainenOsaaminenDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TuvaLaajaAlainenOsaaminenDto
     */
    teksti?: { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof TuvaLaajaAlainenOsaaminenDto
     */
    liite?: boolean;
}
/**
 * 
 * @export
 * @interface Validointi
 */
export interface Validointi {
    /**
     * 
     * @type {string}
     * @memberof Validointi
     */
    kategoria?: ValidointiKategoriaEnum;
    /**
     * 
     * @type {Array<Virhe>}
     * @memberof Validointi
     */
    virheet?: Array<Virhe>;
    /**
     * 
     * @type {Array<Virhe>}
     * @memberof Validointi
     */
    huomautukset?: Array<Virhe>;
    /**
     * 
     * @type {Array<Virhe>}
     * @memberof Validointi
     */
    huomiot?: Array<Virhe>;
}

/**
    * @export
    * @enum {string}
    */
export enum ValidointiKategoriaEnum {
    OPETUSSUUNNITELMA = 'OPETUSSUUNNITELMA',
    SISALTO = 'SISALTO'
}

/**
 * 
 * @export
 * @interface VanhentunutPohjaperusteDto
 */
export interface VanhentunutPohjaperusteDto {
    /**
     * 
     * @type {OpetussuunnitelmaBaseDto}
     * @memberof VanhentunutPohjaperusteDto
     */
    opetussuunnitelma?: OpetussuunnitelmaBaseDto;
    /**
     * 
     * @type {PerusteBaseDto}
     * @memberof VanhentunutPohjaperusteDto
     */
    perusteUusi?: PerusteBaseDto;
    /**
     * 
     * @type {PerusteBaseDto}
     * @memberof VanhentunutPohjaperusteDto
     */
    perusteVanha?: PerusteBaseDto;
    /**
     * 
     * @type {Date}
     * @memberof VanhentunutPohjaperusteDto
     */
    edellinenPaivitys?: Date;
    /**
     * 
     * @type {Date}
     * @memberof VanhentunutPohjaperusteDto
     */
    perustePaivittynyt?: Date;
}
/**
 * 
 * @export
 * @interface VapaaTeksti
 */
export interface VapaaTeksti {
    /**
     * 
     * @type {Date}
     * @memberof VapaaTeksti
     */
    luotu?: Date;
    /**
     * 
     * @type {string}
     * @memberof VapaaTeksti
     */
    luoja?: string;
    /**
     * 
     * @type {Date}
     * @memberof VapaaTeksti
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof VapaaTeksti
     */
    muokkaaja?: string;
    /**
     * 
     * @type {number}
     * @memberof VapaaTeksti
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTeksti}
     * @memberof VapaaTeksti
     */
    nimi?: LokalisoituTeksti;
    /**
     * 
     * @type {LokalisoituTeksti}
     * @memberof VapaaTeksti
     */
    teksti?: LokalisoituTeksti;
}
/**
 * 
 * @export
 * @interface VapaaTekstiDto
 */
export interface VapaaTekstiDto {
    /**
     * 
     * @type {number}
     * @memberof VapaaTekstiDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof VapaaTekstiDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof VapaaTekstiDto
     */
    teksti?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface VierasTutkinnonosa
 */
export interface VierasTutkinnonosa {
    /**
     * 
     * @type {Date}
     * @memberof VierasTutkinnonosa
     */
    luotu?: Date;
    /**
     * 
     * @type {string}
     * @memberof VierasTutkinnonosa
     */
    luoja?: string;
    /**
     * 
     * @type {Date}
     * @memberof VierasTutkinnonosa
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof VierasTutkinnonosa
     */
    muokkaaja?: string;
    /**
     * 
     * @type {number}
     * @memberof VierasTutkinnonosa
     */
    id?: number;
    /**
     * 
     * @type {CachedPeruste}
     * @memberof VierasTutkinnonosa
     */
    cperuste: CachedPeruste;
    /**
     * 
     * @type {number}
     * @memberof VierasTutkinnonosa
     */
    perusteId: number;
    /**
     * 
     * @type {number}
     * @memberof VierasTutkinnonosa
     */
    tosaId: number;
}
/**
 * 
 * @export
 * @interface VierasTutkinnonosaDto
 */
export interface VierasTutkinnonosaDto {
    /**
     * 
     * @type {number}
     * @memberof VierasTutkinnonosaDto
     */
    id?: number;
    /**
     * 
     * @type {Reference}
     * @memberof VierasTutkinnonosaDto
     */
    cperuste?: Reference;
    /**
     * 
     * @type {number}
     * @memberof VierasTutkinnonosaDto
     */
    perusteId?: number;
    /**
     * 
     * @type {number}
     * @memberof VierasTutkinnonosaDto
     */
    tosaId?: number;
}
/**
 * 
 * @export
 * @interface Virhe
 */
export interface Virhe {
    /**
     * 
     * @type {string}
     * @memberof Virhe
     */
    kuvaus?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Virhe
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {NavigationNodeDto}
     * @memberof Virhe
     */
    navigationNode?: NavigationNodeDto;
}

/**
 * AikataulutApi - axios parameter creator
 * @export
 */
export const AikataulutApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} ktId 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmanAikataulut(ktId: number, opsId: number, options: any = {}): RequestArgs {
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getOpetussuunnitelmanAikataulut.');
            }
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getOpetussuunnitelmanAikataulut.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelma/{opsId}/aikataulu`
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)))
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} ktId 
         * @param {number} opsId 
         * @param {Array<OpetussuunnitelmaAikatauluDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOpetussuunnitelmanAikataulut(ktId: number, opsId: number, body?: Array<OpetussuunnitelmaAikatauluDto>, options: any = {}): RequestArgs {
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling updateOpetussuunnitelmanAikataulut.');
            }
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling updateOpetussuunnitelmanAikataulut.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelma/{opsId}/aikataulu`
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)))
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AikataulutApi - functional programming interface
 * @export
 */
export const AikataulutApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} ktId 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmanAikataulut(ktId: number, opsId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpetussuunnitelmaAikatauluDto>> {
            const localVarAxiosArgs = AikataulutApiAxiosParamCreator(configuration).getOpetussuunnitelmanAikataulut(ktId, opsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} ktId 
         * @param {number} opsId 
         * @param {Array<OpetussuunnitelmaAikatauluDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOpetussuunnitelmanAikataulut(ktId: number, opsId: number, body?: Array<OpetussuunnitelmaAikatauluDto>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpetussuunnitelmaAikatauluDto>> {
            const localVarAxiosArgs = AikataulutApiAxiosParamCreator(configuration).updateOpetussuunnitelmanAikataulut(ktId, opsId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AikataulutApi - factory interface
 * @export
 */
export const AikataulutApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} ktId 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmanAikataulut(ktId: number, opsId: number, options?: any): AxiosPromise<Array<OpetussuunnitelmaAikatauluDto>> {
            return AikataulutApiFp(configuration).getOpetussuunnitelmanAikataulut(ktId, opsId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} ktId 
         * @param {number} opsId 
         * @param {Array<OpetussuunnitelmaAikatauluDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOpetussuunnitelmanAikataulut(ktId: number, opsId: number, body?: Array<OpetussuunnitelmaAikatauluDto>, options?: any): AxiosPromise<Array<OpetussuunnitelmaAikatauluDto>> {
            return AikataulutApiFp(configuration).updateOpetussuunnitelmanAikataulut(ktId, opsId, body, options)(axios, basePath);
        },
    };
};

/**
 * AikataulutApi - object-oriented interface
 * @export
 * @class AikataulutApi
 * @extends {BaseAPI}
 */
export class AikataulutApi extends BaseAPI {
    /**
     * 
     * @param {number} ktId 
     * @param {number} opsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AikataulutApi
     */
    public getOpetussuunnitelmanAikataulut(ktId: number, opsId: number, options?: any) {
        return AikataulutApiFp(this.configuration).getOpetussuunnitelmanAikataulut(ktId, opsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} ktId 
     * @param {number} opsId 
     * @param {Array<OpetussuunnitelmaAikatauluDto>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AikataulutApi
     */
    public updateOpetussuunnitelmanAikataulut(ktId: number, opsId: number, body?: Array<OpetussuunnitelmaAikatauluDto>, options?: any) {
        return AikataulutApiFp(this.configuration).updateOpetussuunnitelmanAikataulut(ktId, opsId, body, options)(this.axios, this.basePath);
    }

}


/**
 * ArviointiasteikotApi - axios parameter creator
 * @export
 */
export const ArviointiasteikotApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllArviointiasteikot(options: any = {}): RequestArgs {
            const localVarPath = `/api/arviointiasteikot`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArviointiasteikko(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getArviointiasteikko.');
            }
            const localVarPath = `/api/arviointiasteikot/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArviointiasteikko(options: any = {}): RequestArgs {
            const localVarPath = `/api/arviointiasteikot/update`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArviointiasteikotApi - functional programming interface
 * @export
 */
export const ArviointiasteikotApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllArviointiasteikot(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ArviointiasteikkoDto>> {
            const localVarAxiosArgs = ArviointiasteikotApiAxiosParamCreator(configuration).getAllArviointiasteikot(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArviointiasteikko(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArviointiasteikkoDto> {
            const localVarAxiosArgs = ArviointiasteikotApiAxiosParamCreator(configuration).getArviointiasteikko(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArviointiasteikko(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ArviointiasteikotApiAxiosParamCreator(configuration).updateArviointiasteikko(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ArviointiasteikotApi - factory interface
 * @export
 */
export const ArviointiasteikotApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllArviointiasteikot(options?: any): AxiosPromise<Array<ArviointiasteikkoDto>> {
            return ArviointiasteikotApiFp(configuration).getAllArviointiasteikot(options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArviointiasteikko(id: number, options?: any): AxiosPromise<ArviointiasteikkoDto> {
            return ArviointiasteikotApiFp(configuration).getArviointiasteikko(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArviointiasteikko(options?: any): AxiosPromise<void> {
            return ArviointiasteikotApiFp(configuration).updateArviointiasteikko(options)(axios, basePath);
        },
    };
};

/**
 * ArviointiasteikotApi - object-oriented interface
 * @export
 * @class ArviointiasteikotApi
 * @extends {BaseAPI}
 */
export class ArviointiasteikotApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArviointiasteikotApi
     */
    public getAllArviointiasteikot(options?: any) {
        return ArviointiasteikotApiFp(this.configuration).getAllArviointiasteikot(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArviointiasteikotApi
     */
    public getArviointiasteikko(id: number, options?: any) {
        return ArviointiasteikotApiFp(this.configuration).getArviointiasteikko(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArviointiasteikotApi
     */
    public updateArviointiasteikko(options?: any) {
        return ArviointiasteikotApiFp(this.configuration).updateArviointiasteikko(options)(this.axios, this.basePath);
    }

}


/**
 * DokumentitApi - axios parameter creator
 * @export
 */
export const DokumentitApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {string} tyyppi 
         * @param {string} kieli 
         * @param {string} ktId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDokumenttiImage(opsId: number, tyyppi: string, kieli: string, ktId: string, file: any, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling addDokumenttiImage.');
            }
            // verify required parameter 'tyyppi' is not null or undefined
            if (tyyppi === null || tyyppi === undefined) {
                throw new RequiredError('tyyppi','Required parameter tyyppi was null or undefined when calling addDokumenttiImage.');
            }
            // verify required parameter 'kieli' is not null or undefined
            if (kieli === null || kieli === undefined) {
                throw new RequiredError('kieli','Required parameter kieli was null or undefined when calling addDokumenttiImage.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling addDokumenttiImage.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling addDokumenttiImage.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/dokumentti/kuva`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            if (tyyppi !== undefined) {
                localVarQueryParameter['tyyppi'] = tyyppi;
            }

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} kieli 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDokumentti(opsId: number, kieli: string, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling createDokumentti.');
            }
            // verify required parameter 'kieli' is not null or undefined
            if (kieli === null || kieli === undefined) {
                throw new RequiredError('kieli','Required parameter kieli was null or undefined when calling createDokumentti.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling createDokumentti.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/dokumentti`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} tyyppi 
         * @param {string} kieli 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDokumenttiImage(opsId: number, tyyppi: string, kieli: string, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling deleteDokumenttiImage.');
            }
            // verify required parameter 'tyyppi' is not null or undefined
            if (tyyppi === null || tyyppi === undefined) {
                throw new RequiredError('tyyppi','Required parameter tyyppi was null or undefined when calling deleteDokumenttiImage.');
            }
            // verify required parameter 'kieli' is not null or undefined
            if (kieli === null || kieli === undefined) {
                throw new RequiredError('kieli','Required parameter kieli was null or undefined when calling deleteDokumenttiImage.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling deleteDokumenttiImage.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/dokumentti/kuva`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tyyppi !== undefined) {
                localVarQueryParameter['tyyppi'] = tyyppi;
            }

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} tyyppi 
         * @param {string} kieli 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDokumenttiImage(opsId: number, tyyppi: string, kieli: string, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getDokumenttiImage.');
            }
            // verify required parameter 'tyyppi' is not null or undefined
            if (tyyppi === null || tyyppi === undefined) {
                throw new RequiredError('tyyppi','Required parameter tyyppi was null or undefined when calling getDokumenttiImage.');
            }
            // verify required parameter 'kieli' is not null or undefined
            if (kieli === null || kieli === undefined) {
                throw new RequiredError('kieli','Required parameter kieli was null or undefined when calling getDokumenttiImage.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getDokumenttiImage.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/dokumentti/kuva`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tyyppi !== undefined) {
                localVarQueryParameter['tyyppi'] = tyyppi;
            }

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} kieli 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDokumenttiKuva(opsId: number, kieli: string, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getDokumenttiKuva.');
            }
            // verify required parameter 'kieli' is not null or undefined
            if (kieli === null || kieli === undefined) {
                throw new RequiredError('kieli','Required parameter kieli was null or undefined when calling getDokumenttiKuva.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getDokumenttiKuva.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/dokumentti/dokumenttikuva`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} kieli 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestDokumentti(opsId: number, kieli: string, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getLatestDokumentti.');
            }
            // verify required parameter 'kieli' is not null or undefined
            if (kieli === null || kieli === undefined) {
                throw new RequiredError('kieli','Required parameter kieli was null or undefined when calling getLatestDokumentti.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getLatestDokumentti.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/dokumentti/latest`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} kieli 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestValmisDokumentti(opsId: number, kieli: string, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getLatestValmisDokumentti.');
            }
            // verify required parameter 'kieli' is not null or undefined
            if (kieli === null || kieli === undefined) {
                throw new RequiredError('kieli','Required parameter kieli was null or undefined when calling getLatestValmisDokumentti.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getLatestValmisDokumentti.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/dokumentti`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} kieli 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryDokumenttiTila(opsId: number, kieli: string, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling queryDokumenttiTila.');
            }
            // verify required parameter 'kieli' is not null or undefined
            if (kieli === null || kieli === undefined) {
                throw new RequiredError('kieli','Required parameter kieli was null or undefined when calling queryDokumenttiTila.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling queryDokumenttiTila.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/dokumentti/tila`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {string} kieli 
         * @param {string} ktId 
         * @param {DokumenttiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDokumentti(opsId: number, id: number, kieli: string, ktId: string, body?: DokumenttiDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling updateDokumentti.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateDokumentti.');
            }
            // verify required parameter 'kieli' is not null or undefined
            if (kieli === null || kieli === undefined) {
                throw new RequiredError('kieli','Required parameter kieli was null or undefined when calling updateDokumentti.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling updateDokumentti.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/dokumentti/{id}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DokumentitApi - functional programming interface
 * @export
 */
export const DokumentitApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {string} tyyppi 
         * @param {string} kieli 
         * @param {string} ktId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDokumenttiImage(opsId: number, tyyppi: string, kieli: string, ktId: string, file: any, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = DokumentitApiAxiosParamCreator(configuration).addDokumenttiImage(opsId, tyyppi, kieli, ktId, file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} kieli 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDokumentti(opsId: number, kieli: string, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DokumenttiDto> {
            const localVarAxiosArgs = DokumentitApiAxiosParamCreator(configuration).createDokumentti(opsId, kieli, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} tyyppi 
         * @param {string} kieli 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDokumenttiImage(opsId: number, tyyppi: string, kieli: string, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = DokumentitApiAxiosParamCreator(configuration).deleteDokumenttiImage(opsId, tyyppi, kieli, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} tyyppi 
         * @param {string} kieli 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDokumenttiImage(opsId: number, tyyppi: string, kieli: string, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
            const localVarAxiosArgs = DokumentitApiAxiosParamCreator(configuration).getDokumenttiImage(opsId, tyyppi, kieli, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} kieli 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDokumenttiKuva(opsId: number, kieli: string, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DokumenttiKuvaDto> {
            const localVarAxiosArgs = DokumentitApiAxiosParamCreator(configuration).getDokumenttiKuva(opsId, kieli, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} kieli 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestDokumentti(opsId: number, kieli: string, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DokumenttiDto> {
            const localVarAxiosArgs = DokumentitApiAxiosParamCreator(configuration).getLatestDokumentti(opsId, kieli, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} kieli 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestValmisDokumentti(opsId: number, kieli: string, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = DokumentitApiAxiosParamCreator(configuration).getLatestValmisDokumentti(opsId, kieli, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} kieli 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryDokumenttiTila(opsId: number, kieli: string, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DokumenttiDto> {
            const localVarAxiosArgs = DokumentitApiAxiosParamCreator(configuration).queryDokumenttiTila(opsId, kieli, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {string} kieli 
         * @param {string} ktId 
         * @param {DokumenttiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDokumentti(opsId: number, id: number, kieli: string, ktId: string, body?: DokumenttiDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DokumenttiDto> {
            const localVarAxiosArgs = DokumentitApiAxiosParamCreator(configuration).updateDokumentti(opsId, id, kieli, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DokumentitApi - factory interface
 * @export
 */
export const DokumentitApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {string} tyyppi 
         * @param {string} kieli 
         * @param {string} ktId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDokumenttiImage(opsId: number, tyyppi: string, kieli: string, ktId: string, file: any, options?: any): AxiosPromise<object> {
            return DokumentitApiFp(configuration).addDokumenttiImage(opsId, tyyppi, kieli, ktId, file, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} kieli 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDokumentti(opsId: number, kieli: string, ktId: string, body?: number, options?: any): AxiosPromise<DokumenttiDto> {
            return DokumentitApiFp(configuration).createDokumentti(opsId, kieli, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} tyyppi 
         * @param {string} kieli 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDokumenttiImage(opsId: number, tyyppi: string, kieli: string, ktId: string, body?: number, options?: any): AxiosPromise<object> {
            return DokumentitApiFp(configuration).deleteDokumenttiImage(opsId, tyyppi, kieli, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} tyyppi 
         * @param {string} kieli 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDokumenttiImage(opsId: number, tyyppi: string, kieli: string, ktId: string, body?: number, options?: any): AxiosPromise<Array<string>> {
            return DokumentitApiFp(configuration).getDokumenttiImage(opsId, tyyppi, kieli, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} kieli 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDokumenttiKuva(opsId: number, kieli: string, ktId: string, body?: number, options?: any): AxiosPromise<DokumenttiKuvaDto> {
            return DokumentitApiFp(configuration).getDokumenttiKuva(opsId, kieli, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} kieli 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestDokumentti(opsId: number, kieli: string, ktId: string, body?: number, options?: any): AxiosPromise<DokumenttiDto> {
            return DokumentitApiFp(configuration).getLatestDokumentti(opsId, kieli, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} kieli 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestValmisDokumentti(opsId: number, kieli: string, ktId: string, body?: number, options?: any): AxiosPromise<object> {
            return DokumentitApiFp(configuration).getLatestValmisDokumentti(opsId, kieli, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} kieli 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryDokumenttiTila(opsId: number, kieli: string, ktId: string, body?: number, options?: any): AxiosPromise<DokumenttiDto> {
            return DokumentitApiFp(configuration).queryDokumenttiTila(opsId, kieli, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {string} kieli 
         * @param {string} ktId 
         * @param {DokumenttiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDokumentti(opsId: number, id: number, kieli: string, ktId: string, body?: DokumenttiDto, options?: any): AxiosPromise<DokumenttiDto> {
            return DokumentitApiFp(configuration).updateDokumentti(opsId, id, kieli, ktId, body, options)(axios, basePath);
        },
    };
};

/**
 * DokumentitApi - object-oriented interface
 * @export
 * @class DokumentitApi
 * @extends {BaseAPI}
 */
export class DokumentitApi extends BaseAPI {
    /**
     * 
     * @param {number} opsId 
     * @param {string} tyyppi 
     * @param {string} kieli 
     * @param {string} ktId 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentitApi
     */
    public addDokumenttiImage(opsId: number, tyyppi: string, kieli: string, ktId: string, file: any, options?: any) {
        return DokumentitApiFp(this.configuration).addDokumenttiImage(opsId, tyyppi, kieli, ktId, file, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} kieli 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentitApi
     */
    public createDokumentti(opsId: number, kieli: string, ktId: string, body?: number, options?: any) {
        return DokumentitApiFp(this.configuration).createDokumentti(opsId, kieli, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} tyyppi 
     * @param {string} kieli 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentitApi
     */
    public deleteDokumenttiImage(opsId: number, tyyppi: string, kieli: string, ktId: string, body?: number, options?: any) {
        return DokumentitApiFp(this.configuration).deleteDokumenttiImage(opsId, tyyppi, kieli, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} tyyppi 
     * @param {string} kieli 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentitApi
     */
    public getDokumenttiImage(opsId: number, tyyppi: string, kieli: string, ktId: string, body?: number, options?: any) {
        return DokumentitApiFp(this.configuration).getDokumenttiImage(opsId, tyyppi, kieli, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} kieli 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentitApi
     */
    public getDokumenttiKuva(opsId: number, kieli: string, ktId: string, body?: number, options?: any) {
        return DokumentitApiFp(this.configuration).getDokumenttiKuva(opsId, kieli, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} kieli 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentitApi
     */
    public getLatestDokumentti(opsId: number, kieli: string, ktId: string, body?: number, options?: any) {
        return DokumentitApiFp(this.configuration).getLatestDokumentti(opsId, kieli, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} kieli 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentitApi
     */
    public getLatestValmisDokumentti(opsId: number, kieli: string, ktId: string, body?: number, options?: any) {
        return DokumentitApiFp(this.configuration).getLatestValmisDokumentti(opsId, kieli, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} kieli 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentitApi
     */
    public queryDokumenttiTila(opsId: number, kieli: string, ktId: string, body?: number, options?: any) {
        return DokumentitApiFp(this.configuration).queryDokumenttiTila(opsId, kieli, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} id 
     * @param {string} kieli 
     * @param {string} ktId 
     * @param {DokumenttiDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentitApi
     */
    public updateDokumentti(opsId: number, id: number, kieli: string, ktId: string, body?: DokumenttiDto, options?: any) {
        return DokumentitApiFp(this.configuration).updateDokumentti(opsId, id, kieli, ktId, body, options)(this.axios, this.basePath);
    }

}


/**
 * ExternalApi - axios parameter creator
 * @export
 */
export const ExternalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Url parametreiksi voi antaa opetussuunnitelman id:n lisäksi erilaisia opetussuunnitelman rakenteen osia ja id-kenttien arvoja. Esim. /opetussuunnitelma/8505691/tutkinnonOsat/7283253/tosa antaa opetussuunnitelman (id: 8505691) tutkinnon osien tietueen (id: 7283253).
         * @summary Opetussuunnitelman tietojen haku tarkalla sisältörakenteella
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaDynamicQuery(opsId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getOpetussuunnitelmaDynamicQuery.');
            }
            const localVarPath = `/api/external/opetussuunnitelma/{opsId}/**`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Opetussuunnitelman tietojen haku
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicOpetussuunnitelma(opsId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getPublicOpetussuunnitelma.');
            }
            const localVarPath = `/api/external/opetussuunnitelma/{opsId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Opetussuunnitelman tietojen haku koulutustoimijan ja opetussuunnitelman id:llä
         * @param {number} koulutustoimijaId 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicOpetussuunnitelmaKoulutustoimija(koulutustoimijaId: number, opsId: number, options: any = {}): RequestArgs {
            // verify required parameter 'koulutustoimijaId' is not null or undefined
            if (koulutustoimijaId === null || koulutustoimijaId === undefined) {
                throw new RequiredError('koulutustoimijaId','Required parameter koulutustoimijaId was null or undefined when calling getPublicOpetussuunnitelmaKoulutustoimija.');
            }
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getPublicOpetussuunnitelmaKoulutustoimija.');
            }
            const localVarPath = `/api/external/opetussuunnitelma/{koulutustoimijaId}/{opsId}`
                .replace(`{${"koulutustoimijaId"}}`, encodeURIComponent(String(koulutustoimijaId)))
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [perusteenDiaarinumero] 
         * @param {number} [perusteId] 
         * @param {string} [organisaatio] 
         * @param {Array<string>} [tyyppi] 
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {string} [nimi] 
         * @param {string} [kieli] 
         * @param {string} [oppilaitosTyyppiKoodiUri] 
         * @param {Array<string>} [koulutustyyppi] 
         * @param {boolean} [tuleva] 
         * @param {boolean} [voimassaolo] 
         * @param {boolean} [poistunut] 
         * @param {Array<string>} [jotpatyyppi] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicOpetussuunnitelmat(perusteenDiaarinumero?: string, perusteId?: number, organisaatio?: string, tyyppi?: Array<string>, sivu?: number, sivukoko?: number, nimi?: string, kieli?: string, oppilaitosTyyppiKoodiUri?: string, koulutustyyppi?: Array<string>, tuleva?: boolean, voimassaolo?: boolean, poistunut?: boolean, jotpatyyppi?: Array<string>, options: any = {}): RequestArgs {
            const localVarPath = `/api/external/opetussuunnitelmat`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (perusteenDiaarinumero !== undefined) {
                localVarQueryParameter['perusteenDiaarinumero'] = perusteenDiaarinumero;
            }

            if (perusteId !== undefined) {
                localVarQueryParameter['perusteId'] = perusteId;
            }

            if (organisaatio !== undefined) {
                localVarQueryParameter['organisaatio'] = organisaatio;
            }

            if (tyyppi) {
                localVarQueryParameter['tyyppi'] = tyyppi;
            }

            if (sivu !== undefined) {
                localVarQueryParameter['sivu'] = sivu;
            }

            if (sivukoko !== undefined) {
                localVarQueryParameter['sivukoko'] = sivukoko;
            }

            if (nimi !== undefined) {
                localVarQueryParameter['nimi'] = nimi;
            }

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }

            if (oppilaitosTyyppiKoodiUri !== undefined) {
                localVarQueryParameter['oppilaitosTyyppiKoodiUri'] = oppilaitosTyyppiKoodiUri;
            }

            if (koulutustyyppi) {
                localVarQueryParameter['koulutustyyppi'] = koulutustyyppi;
            }

            if (tuleva !== undefined) {
                localVarQueryParameter['tuleva'] = tuleva;
            }

            if (voimassaolo !== undefined) {
                localVarQueryParameter['voimassaolo'] = voimassaolo;
            }

            if (poistunut !== undefined) {
                localVarQueryParameter['poistunut'] = poistunut;
            }

            if (jotpatyyppi) {
                localVarQueryParameter['jotpatyyppi'] = jotpatyyppi;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Opintokokonaisuuden haku opintokokonaisuuden koodin arvolla
         * @param {string} koodiArvo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicOpintokokonaisuusKoodilla(koodiArvo: string, options: any = {}): RequestArgs {
            // verify required parameter 'koodiArvo' is not null or undefined
            if (koodiArvo === null || koodiArvo === undefined) {
                throw new RequiredError('koodiArvo','Required parameter koodiArvo was null or undefined when calling getPublicOpintokokonaisuusKoodilla.');
            }
            const localVarPath = `/api/external/opintokokonaisuus/{koodiArvo}`
                .replace(`{${"koodiArvo"}}`, encodeURIComponent(String(koodiArvo)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExternalApi - functional programming interface
 * @export
 */
export const ExternalApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Url parametreiksi voi antaa opetussuunnitelman id:n lisäksi erilaisia opetussuunnitelman rakenteen osia ja id-kenttien arvoja. Esim. /opetussuunnitelma/8505691/tutkinnonOsat/7283253/tosa antaa opetussuunnitelman (id: 8505691) tutkinnon osien tietueen (id: 7283253).
         * @summary Opetussuunnitelman tietojen haku tarkalla sisältörakenteella
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaDynamicQuery(opsId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpetussuunnitelmaKaikkiDto> {
            const localVarAxiosArgs = ExternalApiAxiosParamCreator(configuration).getOpetussuunnitelmaDynamicQuery(opsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Opetussuunnitelman tietojen haku
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicOpetussuunnitelma(opsId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpetussuunnitelmaKaikkiDto> {
            const localVarAxiosArgs = ExternalApiAxiosParamCreator(configuration).getPublicOpetussuunnitelma(opsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Opetussuunnitelman tietojen haku koulutustoimijan ja opetussuunnitelman id:llä
         * @param {number} koulutustoimijaId 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicOpetussuunnitelmaKoulutustoimija(koulutustoimijaId: number, opsId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpetussuunnitelmaKaikkiDto> {
            const localVarAxiosArgs = ExternalApiAxiosParamCreator(configuration).getPublicOpetussuunnitelmaKoulutustoimija(koulutustoimijaId, opsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [perusteenDiaarinumero] 
         * @param {number} [perusteId] 
         * @param {string} [organisaatio] 
         * @param {Array<string>} [tyyppi] 
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {string} [nimi] 
         * @param {string} [kieli] 
         * @param {string} [oppilaitosTyyppiKoodiUri] 
         * @param {Array<string>} [koulutustyyppi] 
         * @param {boolean} [tuleva] 
         * @param {boolean} [voimassaolo] 
         * @param {boolean} [poistunut] 
         * @param {Array<string>} [jotpatyyppi] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicOpetussuunnitelmat(perusteenDiaarinumero?: string, perusteId?: number, organisaatio?: string, tyyppi?: Array<string>, sivu?: number, sivukoko?: number, nimi?: string, kieli?: string, oppilaitosTyyppiKoodiUri?: string, koulutustyyppi?: Array<string>, tuleva?: boolean, voimassaolo?: boolean, poistunut?: boolean, jotpatyyppi?: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOpetussuunnitelmaDto> {
            const localVarAxiosArgs = ExternalApiAxiosParamCreator(configuration).getPublicOpetussuunnitelmat(perusteenDiaarinumero, perusteId, organisaatio, tyyppi, sivu, sivukoko, nimi, kieli, oppilaitosTyyppiKoodiUri, koulutustyyppi, tuleva, voimassaolo, poistunut, jotpatyyppi, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Opintokokonaisuuden haku opintokokonaisuuden koodin arvolla
         * @param {string} koodiArvo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicOpintokokonaisuusKoodilla(koodiArvo: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SisaltoviiteOpintokokonaisuusExternalDto> {
            const localVarAxiosArgs = ExternalApiAxiosParamCreator(configuration).getPublicOpintokokonaisuusKoodilla(koodiArvo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ExternalApi - factory interface
 * @export
 */
export const ExternalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Url parametreiksi voi antaa opetussuunnitelman id:n lisäksi erilaisia opetussuunnitelman rakenteen osia ja id-kenttien arvoja. Esim. /opetussuunnitelma/8505691/tutkinnonOsat/7283253/tosa antaa opetussuunnitelman (id: 8505691) tutkinnon osien tietueen (id: 7283253).
         * @summary Opetussuunnitelman tietojen haku tarkalla sisältörakenteella
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaDynamicQuery(opsId: number, options?: any): AxiosPromise<OpetussuunnitelmaKaikkiDto> {
            return ExternalApiFp(configuration).getOpetussuunnitelmaDynamicQuery(opsId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Opetussuunnitelman tietojen haku
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicOpetussuunnitelma(opsId: number, options?: any): AxiosPromise<OpetussuunnitelmaKaikkiDto> {
            return ExternalApiFp(configuration).getPublicOpetussuunnitelma(opsId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Opetussuunnitelman tietojen haku koulutustoimijan ja opetussuunnitelman id:llä
         * @param {number} koulutustoimijaId 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicOpetussuunnitelmaKoulutustoimija(koulutustoimijaId: number, opsId: number, options?: any): AxiosPromise<OpetussuunnitelmaKaikkiDto> {
            return ExternalApiFp(configuration).getPublicOpetussuunnitelmaKoulutustoimija(koulutustoimijaId, opsId, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [perusteenDiaarinumero] 
         * @param {number} [perusteId] 
         * @param {string} [organisaatio] 
         * @param {Array<string>} [tyyppi] 
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {string} [nimi] 
         * @param {string} [kieli] 
         * @param {string} [oppilaitosTyyppiKoodiUri] 
         * @param {Array<string>} [koulutustyyppi] 
         * @param {boolean} [tuleva] 
         * @param {boolean} [voimassaolo] 
         * @param {boolean} [poistunut] 
         * @param {Array<string>} [jotpatyyppi] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicOpetussuunnitelmat(perusteenDiaarinumero?: string, perusteId?: number, organisaatio?: string, tyyppi?: Array<string>, sivu?: number, sivukoko?: number, nimi?: string, kieli?: string, oppilaitosTyyppiKoodiUri?: string, koulutustyyppi?: Array<string>, tuleva?: boolean, voimassaolo?: boolean, poistunut?: boolean, jotpatyyppi?: Array<string>, options?: any): AxiosPromise<PageOpetussuunnitelmaDto> {
            return ExternalApiFp(configuration).getPublicOpetussuunnitelmat(perusteenDiaarinumero, perusteId, organisaatio, tyyppi, sivu, sivukoko, nimi, kieli, oppilaitosTyyppiKoodiUri, koulutustyyppi, tuleva, voimassaolo, poistunut, jotpatyyppi, options)(axios, basePath);
        },
        /**
         * 
         * @summary Opintokokonaisuuden haku opintokokonaisuuden koodin arvolla
         * @param {string} koodiArvo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicOpintokokonaisuusKoodilla(koodiArvo: string, options?: any): AxiosPromise<SisaltoviiteOpintokokonaisuusExternalDto> {
            return ExternalApiFp(configuration).getPublicOpintokokonaisuusKoodilla(koodiArvo, options)(axios, basePath);
        },
    };
};

/**
 * ExternalApi - object-oriented interface
 * @export
 * @class ExternalApi
 * @extends {BaseAPI}
 */
export class ExternalApi extends BaseAPI {
    /**
     * Url parametreiksi voi antaa opetussuunnitelman id:n lisäksi erilaisia opetussuunnitelman rakenteen osia ja id-kenttien arvoja. Esim. /opetussuunnitelma/8505691/tutkinnonOsat/7283253/tosa antaa opetussuunnitelman (id: 8505691) tutkinnon osien tietueen (id: 7283253).
     * @summary Opetussuunnitelman tietojen haku tarkalla sisältörakenteella
     * @param {number} opsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApi
     */
    public getOpetussuunnitelmaDynamicQuery(opsId: number, options?: any) {
        return ExternalApiFp(this.configuration).getOpetussuunnitelmaDynamicQuery(opsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Opetussuunnitelman tietojen haku
     * @param {number} opsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApi
     */
    public getPublicOpetussuunnitelma(opsId: number, options?: any) {
        return ExternalApiFp(this.configuration).getPublicOpetussuunnitelma(opsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Opetussuunnitelman tietojen haku koulutustoimijan ja opetussuunnitelman id:llä
     * @param {number} koulutustoimijaId 
     * @param {number} opsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApi
     */
    public getPublicOpetussuunnitelmaKoulutustoimija(koulutustoimijaId: number, opsId: number, options?: any) {
        return ExternalApiFp(this.configuration).getPublicOpetussuunnitelmaKoulutustoimija(koulutustoimijaId, opsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [perusteenDiaarinumero] 
     * @param {number} [perusteId] 
     * @param {string} [organisaatio] 
     * @param {Array<string>} [tyyppi] 
     * @param {number} [sivu] 
     * @param {number} [sivukoko] 
     * @param {string} [nimi] 
     * @param {string} [kieli] 
     * @param {string} [oppilaitosTyyppiKoodiUri] 
     * @param {Array<string>} [koulutustyyppi] 
     * @param {boolean} [tuleva] 
     * @param {boolean} [voimassaolo] 
     * @param {boolean} [poistunut] 
     * @param {Array<string>} [jotpatyyppi] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApi
     */
    public getPublicOpetussuunnitelmat(perusteenDiaarinumero?: string, perusteId?: number, organisaatio?: string, tyyppi?: Array<string>, sivu?: number, sivukoko?: number, nimi?: string, kieli?: string, oppilaitosTyyppiKoodiUri?: string, koulutustyyppi?: Array<string>, tuleva?: boolean, voimassaolo?: boolean, poistunut?: boolean, jotpatyyppi?: Array<string>, options?: any) {
        return ExternalApiFp(this.configuration).getPublicOpetussuunnitelmat(perusteenDiaarinumero, perusteId, organisaatio, tyyppi, sivu, sivukoko, nimi, kieli, oppilaitosTyyppiKoodiUri, koulutustyyppi, tuleva, voimassaolo, poistunut, jotpatyyppi, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Opintokokonaisuuden haku opintokokonaisuuden koodin arvolla
     * @param {string} koodiArvo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApi
     */
    public getPublicOpintokokonaisuusKoodilla(koodiArvo: string, options?: any) {
        return ExternalApiFp(this.configuration).getPublicOpintokokonaisuusKoodilla(koodiArvo, options)(this.axios, this.basePath);
    }

}


/**
 * JulkaisutApi - axios parameter creator
 * @export
 */
export const JulkaisutApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {number} revision 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aktivoiJulkaisu(opsId: number, revision: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling aktivoiJulkaisu.');
            }
            // verify required parameter 'revision' is not null or undefined
            if (revision === null || revision === undefined) {
                throw new RequiredError('revision','Required parameter revision was null or undefined when calling aktivoiJulkaisu.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling aktivoiJulkaisu.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/aktivoi/{revision}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"revision"}}`, encodeURIComponent(String(revision)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaisut(opsId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getJulkaisut.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getJulkaisut.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/julkaisut`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        julkaisemattomiaMuutoksia(opsId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling julkaisemattomiaMuutoksia.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling julkaisemattomiaMuutoksia.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/julkaisu/muutoksia`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {JulkaisuBaseDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teeJulkaisu(opsId: number, ktId: string, body?: JulkaisuBaseDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling teeJulkaisu.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling teeJulkaisu.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/julkaisu`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viimeisinJulkaisuTila(opsId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling viimeisinJulkaisuTila.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling viimeisinJulkaisuTila.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/viimeisinjulkaisutila`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JulkaisutApi - functional programming interface
 * @export
 */
export const JulkaisutApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {number} revision 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aktivoiJulkaisu(opsId: number, revision: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<JulkaisuBaseDto> {
            const localVarAxiosArgs = JulkaisutApiAxiosParamCreator(configuration).aktivoiJulkaisu(opsId, revision, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaisut(opsId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JulkaisuBaseDto>> {
            const localVarAxiosArgs = JulkaisutApiAxiosParamCreator(configuration).getJulkaisut(opsId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        julkaisemattomiaMuutoksia(opsId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean> {
            const localVarAxiosArgs = JulkaisutApiAxiosParamCreator(configuration).julkaisemattomiaMuutoksia(opsId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {JulkaisuBaseDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teeJulkaisu(opsId: number, ktId: string, body?: JulkaisuBaseDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = JulkaisutApiAxiosParamCreator(configuration).teeJulkaisu(opsId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viimeisinJulkaisuTila(opsId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = JulkaisutApiAxiosParamCreator(configuration).viimeisinJulkaisuTila(opsId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * JulkaisutApi - factory interface
 * @export
 */
export const JulkaisutApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {number} revision 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aktivoiJulkaisu(opsId: number, revision: number, ktId: string, body?: number, options?: any): AxiosPromise<JulkaisuBaseDto> {
            return JulkaisutApiFp(configuration).aktivoiJulkaisu(opsId, revision, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaisut(opsId: number, ktId: string, body?: number, options?: any): AxiosPromise<Array<JulkaisuBaseDto>> {
            return JulkaisutApiFp(configuration).getJulkaisut(opsId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        julkaisemattomiaMuutoksia(opsId: number, ktId: string, body?: number, options?: any): AxiosPromise<boolean> {
            return JulkaisutApiFp(configuration).julkaisemattomiaMuutoksia(opsId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {JulkaisuBaseDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teeJulkaisu(opsId: number, ktId: string, body?: JulkaisuBaseDto, options?: any): AxiosPromise<void> {
            return JulkaisutApiFp(configuration).teeJulkaisu(opsId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viimeisinJulkaisuTila(opsId: number, ktId: string, body?: number, options?: any): AxiosPromise<string> {
            return JulkaisutApiFp(configuration).viimeisinJulkaisuTila(opsId, ktId, body, options)(axios, basePath);
        },
    };
};

/**
 * JulkaisutApi - object-oriented interface
 * @export
 * @class JulkaisutApi
 * @extends {BaseAPI}
 */
export class JulkaisutApi extends BaseAPI {
    /**
     * 
     * @param {number} opsId 
     * @param {number} revision 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkaisutApi
     */
    public aktivoiJulkaisu(opsId: number, revision: number, ktId: string, body?: number, options?: any) {
        return JulkaisutApiFp(this.configuration).aktivoiJulkaisu(opsId, revision, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkaisutApi
     */
    public getJulkaisut(opsId: number, ktId: string, body?: number, options?: any) {
        return JulkaisutApiFp(this.configuration).getJulkaisut(opsId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkaisutApi
     */
    public julkaisemattomiaMuutoksia(opsId: number, ktId: string, body?: number, options?: any) {
        return JulkaisutApiFp(this.configuration).julkaisemattomiaMuutoksia(opsId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} ktId 
     * @param {JulkaisuBaseDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkaisutApi
     */
    public teeJulkaisu(opsId: number, ktId: string, body?: JulkaisuBaseDto, options?: any) {
        return JulkaisutApiFp(this.configuration).teeJulkaisu(opsId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkaisutApi
     */
    public viimeisinJulkaisuTila(opsId: number, ktId: string, body?: number, options?: any) {
        return JulkaisutApiFp(this.configuration).viimeisinJulkaisuTila(opsId, ktId, body, options)(this.axios, this.basePath);
    }

}


/**
 * JulkinenApi - axios parameter creator
 * @export
 */
export const JulkinenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<string>} koulutustyypit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findKoulutusatyypinKoulutustoimijat(koulutustyypit: Array<string>, options: any = {}): RequestArgs {
            // verify required parameter 'koulutustyypit' is not null or undefined
            if (koulutustyypit === null || koulutustyypit === undefined) {
                throw new RequiredError('koulutustyypit','Required parameter koulutustyypit was null or undefined when calling findKoulutusatyypinKoulutustoimijat.');
            }
            const localVarPath = `/api/julkinen/koulutustoimijat/koulutystyypilla`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (koulutustyypit) {
                localVarQueryParameter['koulutustyypit'] = koulutustyypit;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {string} [nimi] 
         * @param {boolean} [organisaatioRyhma] 
         * @param {Array<string>} [koulutustyyppi] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findKoulutustoimijat(sivu?: number, sivukoko?: number, nimi?: string, organisaatioRyhma?: boolean, koulutustyyppi?: Array<string>, options: any = {}): RequestArgs {
            const localVarPath = `/api/julkinen/koulutustoimijat`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sivu !== undefined) {
                localVarQueryParameter['sivu'] = sivu;
            }

            if (sivukoko !== undefined) {
                localVarQueryParameter['sivukoko'] = sivukoko;
            }

            if (nimi !== undefined) {
                localVarQueryParameter['nimi'] = nimi;
            }

            if (organisaatioRyhma !== undefined) {
                localVarQueryParameter['organisaatioRyhma'] = organisaatioRyhma;
            }

            if (koulutustyyppi) {
                localVarQueryParameter['koulutustyyppi'] = koulutustyyppi;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [perusteenDiaarinumero] 
         * @param {number} [perusteId] 
         * @param {string} [organisaatio] 
         * @param {Array<string>} [tyyppi] 
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {string} [nimi] 
         * @param {string} [kieli] 
         * @param {boolean} [organisaatioRyhma] 
         * @param {string} [oppilaitosTyyppiKoodiUri] 
         * @param {Array<string>} [koulutustyyppi] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOpetussuunnitelmat(perusteenDiaarinumero?: string, perusteId?: number, organisaatio?: string, tyyppi?: Array<string>, sivu?: number, sivukoko?: number, nimi?: string, kieli?: string, organisaatioRyhma?: boolean, oppilaitosTyyppiKoodiUri?: string, koulutustyyppi?: Array<string>, options: any = {}): RequestArgs {
            const localVarPath = `/api/julkinen/opetussuunnitelmat`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (perusteenDiaarinumero !== undefined) {
                localVarQueryParameter['perusteenDiaarinumero'] = perusteenDiaarinumero;
            }

            if (perusteId !== undefined) {
                localVarQueryParameter['perusteId'] = perusteId;
            }

            if (organisaatio !== undefined) {
                localVarQueryParameter['organisaatio'] = organisaatio;
            }

            if (tyyppi) {
                localVarQueryParameter['tyyppi'] = tyyppi;
            }

            if (sivu !== undefined) {
                localVarQueryParameter['sivu'] = sivu;
            }

            if (sivukoko !== undefined) {
                localVarQueryParameter['sivukoko'] = sivukoko;
            }

            if (nimi !== undefined) {
                localVarQueryParameter['nimi'] = nimi;
            }

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }

            if (organisaatioRyhma !== undefined) {
                localVarQueryParameter['organisaatioRyhma'] = organisaatioRyhma;
            }

            if (oppilaitosTyyppiKoodiUri !== undefined) {
                localVarQueryParameter['oppilaitosTyyppiKoodiUri'] = oppilaitosTyyppiKoodiUri;
            }

            if (koulutustyyppi) {
                localVarQueryParameter['koulutustyyppi'] = koulutustyyppi;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [perusteenDiaarinumero] 
         * @param {number} [perusteId] 
         * @param {string} [organisaatio] 
         * @param {Array<string>} [tyyppi] 
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {string} [nimi] 
         * @param {string} [kieli] 
         * @param {string} [oppilaitosTyyppiKoodiUri] 
         * @param {Array<string>} [koulutustyyppi] 
         * @param {boolean} [tuleva] 
         * @param {boolean} [voimassaolo] 
         * @param {boolean} [poistunut] 
         * @param {Array<string>} [jotpatyyppi] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOpetussuunnitelmatJulkaisut(perusteenDiaarinumero?: string, perusteId?: number, organisaatio?: string, tyyppi?: Array<string>, sivu?: number, sivukoko?: number, nimi?: string, kieli?: string, oppilaitosTyyppiKoodiUri?: string, koulutustyyppi?: Array<string>, tuleva?: boolean, voimassaolo?: boolean, poistunut?: boolean, jotpatyyppi?: Array<string>, options: any = {}): RequestArgs {
            const localVarPath = `/api/julkinen/opetussuunnitelmat/julkaisut`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (perusteenDiaarinumero !== undefined) {
                localVarQueryParameter['perusteenDiaarinumero'] = perusteenDiaarinumero;
            }

            if (perusteId !== undefined) {
                localVarQueryParameter['perusteId'] = perusteId;
            }

            if (organisaatio !== undefined) {
                localVarQueryParameter['organisaatio'] = organisaatio;
            }

            if (tyyppi) {
                localVarQueryParameter['tyyppi'] = tyyppi;
            }

            if (sivu !== undefined) {
                localVarQueryParameter['sivu'] = sivu;
            }

            if (sivukoko !== undefined) {
                localVarQueryParameter['sivukoko'] = sivukoko;
            }

            if (nimi !== undefined) {
                localVarQueryParameter['nimi'] = nimi;
            }

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }

            if (oppilaitosTyyppiKoodiUri !== undefined) {
                localVarQueryParameter['oppilaitosTyyppiKoodiUri'] = oppilaitosTyyppiKoodiUri;
            }

            if (koulutustyyppi) {
                localVarQueryParameter['koulutustyyppi'] = koulutustyyppi;
            }

            if (tuleva !== undefined) {
                localVarQueryParameter['tuleva'] = tuleva;
            }

            if (voimassaolo !== undefined) {
                localVarQueryParameter['voimassaolo'] = voimassaolo;
            }

            if (poistunut !== undefined) {
                localVarQueryParameter['poistunut'] = poistunut;
            }

            if (jotpatyyppi) {
                localVarQueryParameter['jotpatyyppi'] = jotpatyyppi;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOpetussuunnitelmat(ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getAllOpetussuunnitelmat.');
            }
            const localVarPath = `/api/julkinen/koulutustoimijat/{ktId}/opetussuunnitelmat`
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} koodi 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByKoodi(koodi: string, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'koodi' is not null or undefined
            if (koodi === null || koodi === undefined) {
                throw new RequiredError('koodi','Required parameter koodi was null or undefined when calling getByKoodi.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getByKoodi.');
            }
            const localVarPath = `/api/julkinen/koulutustoimijat/{ktId}/koodi/{koodi}`
                .replace(`{${"koodi"}}`, encodeURIComponent(String(koodi)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} kieli 
         * @param {number} dokumenttiId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDokumentti(opsId: number, kieli: string, dokumenttiId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getDokumentti.');
            }
            // verify required parameter 'kieli' is not null or undefined
            if (kieli === null || kieli === undefined) {
                throw new RequiredError('kieli','Required parameter kieli was null or undefined when calling getDokumentti.');
            }
            // verify required parameter 'dokumenttiId' is not null or undefined
            if (dokumenttiId === null || dokumenttiId === undefined) {
                throw new RequiredError('dokumenttiId','Required parameter dokumenttiId was null or undefined when calling getDokumentti.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getDokumentti.');
            }
            const localVarPath = `/api/julkinen/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/dokumentti`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }

            if (dokumenttiId !== undefined) {
                localVarQueryParameter['dokumenttiId'] = dokumenttiId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} kieli 
         * @param {string} ktId 
         * @param {number} [revision] 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaistuDokumentti(opsId: number, kieli: string, ktId: string, revision?: number, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getJulkaistuDokumentti.');
            }
            // verify required parameter 'kieli' is not null or undefined
            if (kieli === null || kieli === undefined) {
                throw new RequiredError('kieli','Required parameter kieli was null or undefined when calling getJulkaistuDokumentti.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getJulkaistuDokumentti.');
            }
            const localVarPath = `/api/julkinen/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/dokumentti/julkaistu`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }

            if (revision !== undefined) {
                localVarQueryParameter['revision'] = revision;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkisetTiedotteet(ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getJulkisetTiedotteet.');
            }
            const localVarPath = `/api/julkinen/koulutustoimijat/{ktId}/tiedotteet`
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKaikkiJulkaistutOpetussuunnitelmat(options: any = {}): RequestArgs {
            const localVarPath = `/api/julkinen/kaikkijulkaistut`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} ktId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutustoimijaByKtId(ktId: number, options: any = {}): RequestArgs {
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getKoulutustoimijaByKtId.');
            }
            const localVarPath = `/api/julkinen/koulutustoimijat/{ktId}`
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ktOid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutustoimijaByOid(ktOid: string, options: any = {}): RequestArgs {
            // verify required parameter 'ktOid' is not null or undefined
            if (ktOid === null || ktOid === undefined) {
                throw new RequiredError('ktOid','Required parameter ktOid was null or undefined when calling getKoulutustoimijaByOid.');
            }
            const localVarPath = `/api/julkinen/koulutustoimijat/org/{ktOid}`
                .replace(`{${"ktOid"}}`, encodeURIComponent(String(ktOid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {boolean} [esikatselu] 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaJulkaistuSisalto(opsId: number, ktId: string, esikatselu?: boolean, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getOpetussuunnitelmaJulkaistuSisalto.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getOpetussuunnitelmaJulkaistuSisalto.');
            }
            const localVarPath = `/api/julkinen/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/julkaisu`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (esikatselu !== undefined) {
                localVarQueryParameter['esikatselu'] = esikatselu;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaJulkinen(opsId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getOpetussuunnitelmaJulkinen.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getOpetussuunnitelmaJulkinen.');
            }
            const localVarPath = `/api/julkinen/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaKaikki(opsId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getOpetussuunnitelmaKaikki.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getOpetussuunnitelmaKaikki.');
            }
            const localVarPath = `/api/julkinen/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/kaikki`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaOtsikot(opsId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getOpetussuunnitelmaOtsikot.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getOpetussuunnitelmaOtsikot.');
            }
            const localVarPath = `/api/julkinen/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/otsikot`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} svId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaTekstit(opsId: number, svId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getOpetussuunnitelmaTekstit.');
            }
            // verify required parameter 'svId' is not null or undefined
            if (svId === null || svId === undefined) {
                throw new RequiredError('svId','Required parameter svId was null or undefined when calling getOpetussuunnitelmaTekstit.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getOpetussuunnitelmaTekstit.');
            }
            const localVarPath = `/api/julkinen/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/tekstit/{svId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"svId"}}`, encodeURIComponent(String(svId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmanToimija(opsId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getOpetussuunnitelmanToimija.');
            }
            const localVarPath = `/api/julkinen/opetussuunnitelmat/{opsId}/koulutustoimija`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} perusteenDiaarinumero 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenOpetussuunnitelmat(perusteenDiaarinumero: string, options: any = {}): RequestArgs {
            // verify required parameter 'perusteenDiaarinumero' is not null or undefined
            if (perusteenDiaarinumero === null || perusteenDiaarinumero === undefined) {
                throw new RequiredError('perusteenDiaarinumero','Required parameter perusteenDiaarinumero was null or undefined when calling getPerusteenOpetussuunnitelmat.');
            }
            const localVarPath = `/api/julkinen/perusteenopetussuunnitelmat`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (perusteenDiaarinumero !== undefined) {
                localVarQueryParameter['perusteenDiaarinumero'] = perusteenDiaarinumero;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSisaltoViiteByKoodi(koodi: string, options: any = {}): RequestArgs {
            // verify required parameter 'koodi' is not null or undefined
            if (koodi === null || koodi === undefined) {
                throw new RequiredError('koodi','Required parameter koodi was null or undefined when calling getSisaltoViiteByKoodi.');
            }
            const localVarPath = `/api/julkinen/koodi/{koodi}`
                .replace(`{${"koodi"}}`, encodeURIComponent(String(koodi)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsa(koodi: string, options: any = {}): RequestArgs {
            // verify required parameter 'koodi' is not null or undefined
            if (koodi === null || koodi === undefined) {
                throw new RequiredError('koodi','Required parameter koodi was null or undefined when calling getTutkinnonOsa.');
            }
            const localVarPath = `/api/julkinen/tutkinnonosat/{koodi}`
                .replace(`{${"koodi"}}`, encodeURIComponent(String(koodi)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVstYksilollisetOppilaitostyypit(options: any = {}): RequestArgs {
            const localVarPath = `/api/julkinen/koulutustoimija/vst/oppilaitostyypi`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} kieli 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryDokumentti(opsId: number, kieli: string, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling queryDokumentti.');
            }
            // verify required parameter 'kieli' is not null or undefined
            if (kieli === null || kieli === undefined) {
                throw new RequiredError('kieli','Required parameter kieli was null or undefined when calling queryDokumentti.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling queryDokumentti.');
            }
            const localVarPath = `/api/julkinen/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/dokumentti/tila`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JulkinenApi - functional programming interface
 * @export
 */
export const JulkinenApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<string>} koulutustyypit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findKoulutusatyypinKoulutustoimijat(koulutustyypit: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KoulutustoimijaJulkinenDto>> {
            const localVarAxiosArgs = JulkinenApiAxiosParamCreator(configuration).findKoulutusatyypinKoulutustoimijat(koulutustyypit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {string} [nimi] 
         * @param {boolean} [organisaatioRyhma] 
         * @param {Array<string>} [koulutustyyppi] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findKoulutustoimijat(sivu?: number, sivukoko?: number, nimi?: string, organisaatioRyhma?: boolean, koulutustyyppi?: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageKoulutustoimijaJulkinenDto> {
            const localVarAxiosArgs = JulkinenApiAxiosParamCreator(configuration).findKoulutustoimijat(sivu, sivukoko, nimi, organisaatioRyhma, koulutustyyppi, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [perusteenDiaarinumero] 
         * @param {number} [perusteId] 
         * @param {string} [organisaatio] 
         * @param {Array<string>} [tyyppi] 
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {string} [nimi] 
         * @param {string} [kieli] 
         * @param {boolean} [organisaatioRyhma] 
         * @param {string} [oppilaitosTyyppiKoodiUri] 
         * @param {Array<string>} [koulutustyyppi] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOpetussuunnitelmat(perusteenDiaarinumero?: string, perusteId?: number, organisaatio?: string, tyyppi?: Array<string>, sivu?: number, sivukoko?: number, nimi?: string, kieli?: string, organisaatioRyhma?: boolean, oppilaitosTyyppiKoodiUri?: string, koulutustyyppi?: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOpetussuunnitelmaDto> {
            const localVarAxiosArgs = JulkinenApiAxiosParamCreator(configuration).findOpetussuunnitelmat(perusteenDiaarinumero, perusteId, organisaatio, tyyppi, sivu, sivukoko, nimi, kieli, organisaatioRyhma, oppilaitosTyyppiKoodiUri, koulutustyyppi, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [perusteenDiaarinumero] 
         * @param {number} [perusteId] 
         * @param {string} [organisaatio] 
         * @param {Array<string>} [tyyppi] 
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {string} [nimi] 
         * @param {string} [kieli] 
         * @param {string} [oppilaitosTyyppiKoodiUri] 
         * @param {Array<string>} [koulutustyyppi] 
         * @param {boolean} [tuleva] 
         * @param {boolean} [voimassaolo] 
         * @param {boolean} [poistunut] 
         * @param {Array<string>} [jotpatyyppi] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOpetussuunnitelmatJulkaisut(perusteenDiaarinumero?: string, perusteId?: number, organisaatio?: string, tyyppi?: Array<string>, sivu?: number, sivukoko?: number, nimi?: string, kieli?: string, oppilaitosTyyppiKoodiUri?: string, koulutustyyppi?: Array<string>, tuleva?: boolean, voimassaolo?: boolean, poistunut?: boolean, jotpatyyppi?: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOpetussuunnitelmaDto> {
            const localVarAxiosArgs = JulkinenApiAxiosParamCreator(configuration).findOpetussuunnitelmatJulkaisut(perusteenDiaarinumero, perusteId, organisaatio, tyyppi, sivu, sivukoko, nimi, kieli, oppilaitosTyyppiKoodiUri, koulutustyyppi, tuleva, voimassaolo, poistunut, jotpatyyppi, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOpetussuunnitelmat(ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpetussuunnitelmaDto>> {
            const localVarAxiosArgs = JulkinenApiAxiosParamCreator(configuration).getAllOpetussuunnitelmat(ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} koodi 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByKoodi(koodi: string, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SisaltoViiteSijaintiDto>> {
            const localVarAxiosArgs = JulkinenApiAxiosParamCreator(configuration).getByKoodi(koodi, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} kieli 
         * @param {number} dokumenttiId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDokumentti(opsId: number, kieli: string, dokumenttiId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = JulkinenApiAxiosParamCreator(configuration).getDokumentti(opsId, kieli, dokumenttiId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} kieli 
         * @param {string} ktId 
         * @param {number} [revision] 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaistuDokumentti(opsId: number, kieli: string, ktId: string, revision?: number, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DokumenttiDto> {
            const localVarAxiosArgs = JulkinenApiAxiosParamCreator(configuration).getJulkaistuDokumentti(opsId, kieli, ktId, revision, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkisetTiedotteet(ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TiedoteDto>> {
            const localVarAxiosArgs = JulkinenApiAxiosParamCreator(configuration).getJulkisetTiedotteet(ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKaikkiJulkaistutOpetussuunnitelmat(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpetussuunnitelmaDto>> {
            const localVarAxiosArgs = JulkinenApiAxiosParamCreator(configuration).getKaikkiJulkaistutOpetussuunnitelmat(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} ktId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutustoimijaByKtId(ktId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KoulutustoimijaJulkinenDto> {
            const localVarAxiosArgs = JulkinenApiAxiosParamCreator(configuration).getKoulutustoimijaByKtId(ktId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} ktOid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutustoimijaByOid(ktOid: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KoulutustoimijaJulkinenDto> {
            const localVarAxiosArgs = JulkinenApiAxiosParamCreator(configuration).getKoulutustoimijaByOid(ktOid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {boolean} [esikatselu] 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaJulkaistuSisalto(opsId: number, ktId: string, esikatselu?: boolean, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpetussuunnitelmaKaikkiDto> {
            const localVarAxiosArgs = JulkinenApiAxiosParamCreator(configuration).getOpetussuunnitelmaJulkaistuSisalto(opsId, ktId, esikatselu, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaJulkinen(opsId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpetussuunnitelmaDto> {
            const localVarAxiosArgs = JulkinenApiAxiosParamCreator(configuration).getOpetussuunnitelmaJulkinen(opsId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaKaikki(opsId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpetussuunnitelmaKaikkiDto> {
            const localVarAxiosArgs = JulkinenApiAxiosParamCreator(configuration).getOpetussuunnitelmaKaikki(opsId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaOtsikot(opsId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SisaltoViiteKevytDto>> {
            const localVarAxiosArgs = JulkinenApiAxiosParamCreator(configuration).getOpetussuunnitelmaOtsikot(opsId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} svId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaTekstit(opsId: number, svId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Matala> {
            const localVarAxiosArgs = JulkinenApiAxiosParamCreator(configuration).getOpetussuunnitelmaTekstit(opsId, svId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmanToimija(opsId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KoulutustoimijaJulkinenDto> {
            const localVarAxiosArgs = JulkinenApiAxiosParamCreator(configuration).getOpetussuunnitelmanToimija(opsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} perusteenDiaarinumero 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenOpetussuunnitelmat(perusteenDiaarinumero: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpetussuunnitelmaJulkinenDto>> {
            const localVarAxiosArgs = JulkinenApiAxiosParamCreator(configuration).getPerusteenOpetussuunnitelmat(perusteenDiaarinumero, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSisaltoViiteByKoodi(koodi: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SisaltoViiteSijaintiDto>> {
            const localVarAxiosArgs = JulkinenApiAxiosParamCreator(configuration).getSisaltoViiteByKoodi(koodi, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsa(koodi: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SisaltoViiteDto> {
            const localVarAxiosArgs = JulkinenApiAxiosParamCreator(configuration).getTutkinnonOsa(koodi, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVstYksilollisetOppilaitostyypit(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KoodistoKoodiDto>> {
            const localVarAxiosArgs = JulkinenApiAxiosParamCreator(configuration).getVstYksilollisetOppilaitostyypit(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} kieli 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryDokumentti(opsId: number, kieli: string, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DokumenttiDto> {
            const localVarAxiosArgs = JulkinenApiAxiosParamCreator(configuration).queryDokumentti(opsId, kieli, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * JulkinenApi - factory interface
 * @export
 */
export const JulkinenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {Array<string>} koulutustyypit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findKoulutusatyypinKoulutustoimijat(koulutustyypit: Array<string>, options?: any): AxiosPromise<Array<KoulutustoimijaJulkinenDto>> {
            return JulkinenApiFp(configuration).findKoulutusatyypinKoulutustoimijat(koulutustyypit, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {string} [nimi] 
         * @param {boolean} [organisaatioRyhma] 
         * @param {Array<string>} [koulutustyyppi] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findKoulutustoimijat(sivu?: number, sivukoko?: number, nimi?: string, organisaatioRyhma?: boolean, koulutustyyppi?: Array<string>, options?: any): AxiosPromise<PageKoulutustoimijaJulkinenDto> {
            return JulkinenApiFp(configuration).findKoulutustoimijat(sivu, sivukoko, nimi, organisaatioRyhma, koulutustyyppi, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [perusteenDiaarinumero] 
         * @param {number} [perusteId] 
         * @param {string} [organisaatio] 
         * @param {Array<string>} [tyyppi] 
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {string} [nimi] 
         * @param {string} [kieli] 
         * @param {boolean} [organisaatioRyhma] 
         * @param {string} [oppilaitosTyyppiKoodiUri] 
         * @param {Array<string>} [koulutustyyppi] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOpetussuunnitelmat(perusteenDiaarinumero?: string, perusteId?: number, organisaatio?: string, tyyppi?: Array<string>, sivu?: number, sivukoko?: number, nimi?: string, kieli?: string, organisaatioRyhma?: boolean, oppilaitosTyyppiKoodiUri?: string, koulutustyyppi?: Array<string>, options?: any): AxiosPromise<PageOpetussuunnitelmaDto> {
            return JulkinenApiFp(configuration).findOpetussuunnitelmat(perusteenDiaarinumero, perusteId, organisaatio, tyyppi, sivu, sivukoko, nimi, kieli, organisaatioRyhma, oppilaitosTyyppiKoodiUri, koulutustyyppi, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [perusteenDiaarinumero] 
         * @param {number} [perusteId] 
         * @param {string} [organisaatio] 
         * @param {Array<string>} [tyyppi] 
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {string} [nimi] 
         * @param {string} [kieli] 
         * @param {string} [oppilaitosTyyppiKoodiUri] 
         * @param {Array<string>} [koulutustyyppi] 
         * @param {boolean} [tuleva] 
         * @param {boolean} [voimassaolo] 
         * @param {boolean} [poistunut] 
         * @param {Array<string>} [jotpatyyppi] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOpetussuunnitelmatJulkaisut(perusteenDiaarinumero?: string, perusteId?: number, organisaatio?: string, tyyppi?: Array<string>, sivu?: number, sivukoko?: number, nimi?: string, kieli?: string, oppilaitosTyyppiKoodiUri?: string, koulutustyyppi?: Array<string>, tuleva?: boolean, voimassaolo?: boolean, poistunut?: boolean, jotpatyyppi?: Array<string>, options?: any): AxiosPromise<PageOpetussuunnitelmaDto> {
            return JulkinenApiFp(configuration).findOpetussuunnitelmatJulkaisut(perusteenDiaarinumero, perusteId, organisaatio, tyyppi, sivu, sivukoko, nimi, kieli, oppilaitosTyyppiKoodiUri, koulutustyyppi, tuleva, voimassaolo, poistunut, jotpatyyppi, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOpetussuunnitelmat(ktId: string, body?: number, options?: any): AxiosPromise<Array<OpetussuunnitelmaDto>> {
            return JulkinenApiFp(configuration).getAllOpetussuunnitelmat(ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} koodi 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByKoodi(koodi: string, ktId: string, body?: number, options?: any): AxiosPromise<Array<SisaltoViiteSijaintiDto>> {
            return JulkinenApiFp(configuration).getByKoodi(koodi, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} kieli 
         * @param {number} dokumenttiId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDokumentti(opsId: number, kieli: string, dokumenttiId: number, ktId: string, body?: number, options?: any): AxiosPromise<object> {
            return JulkinenApiFp(configuration).getDokumentti(opsId, kieli, dokumenttiId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} kieli 
         * @param {string} ktId 
         * @param {number} [revision] 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaistuDokumentti(opsId: number, kieli: string, ktId: string, revision?: number, body?: number, options?: any): AxiosPromise<DokumenttiDto> {
            return JulkinenApiFp(configuration).getJulkaistuDokumentti(opsId, kieli, ktId, revision, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkisetTiedotteet(ktId: string, body?: number, options?: any): AxiosPromise<Array<TiedoteDto>> {
            return JulkinenApiFp(configuration).getJulkisetTiedotteet(ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKaikkiJulkaistutOpetussuunnitelmat(options?: any): AxiosPromise<Array<OpetussuunnitelmaDto>> {
            return JulkinenApiFp(configuration).getKaikkiJulkaistutOpetussuunnitelmat(options)(axios, basePath);
        },
        /**
         * 
         * @param {number} ktId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutustoimijaByKtId(ktId: number, options?: any): AxiosPromise<KoulutustoimijaJulkinenDto> {
            return JulkinenApiFp(configuration).getKoulutustoimijaByKtId(ktId, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} ktOid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutustoimijaByOid(ktOid: string, options?: any): AxiosPromise<KoulutustoimijaJulkinenDto> {
            return JulkinenApiFp(configuration).getKoulutustoimijaByOid(ktOid, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {boolean} [esikatselu] 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaJulkaistuSisalto(opsId: number, ktId: string, esikatselu?: boolean, body?: number, options?: any): AxiosPromise<OpetussuunnitelmaKaikkiDto> {
            return JulkinenApiFp(configuration).getOpetussuunnitelmaJulkaistuSisalto(opsId, ktId, esikatselu, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaJulkinen(opsId: number, ktId: string, body?: number, options?: any): AxiosPromise<OpetussuunnitelmaDto> {
            return JulkinenApiFp(configuration).getOpetussuunnitelmaJulkinen(opsId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaKaikki(opsId: number, ktId: string, body?: number, options?: any): AxiosPromise<OpetussuunnitelmaKaikkiDto> {
            return JulkinenApiFp(configuration).getOpetussuunnitelmaKaikki(opsId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaOtsikot(opsId: number, ktId: string, body?: number, options?: any): AxiosPromise<Array<SisaltoViiteKevytDto>> {
            return JulkinenApiFp(configuration).getOpetussuunnitelmaOtsikot(opsId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} svId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaTekstit(opsId: number, svId: number, ktId: string, body?: number, options?: any): AxiosPromise<Matala> {
            return JulkinenApiFp(configuration).getOpetussuunnitelmaTekstit(opsId, svId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmanToimija(opsId: number, options?: any): AxiosPromise<KoulutustoimijaJulkinenDto> {
            return JulkinenApiFp(configuration).getOpetussuunnitelmanToimija(opsId, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} perusteenDiaarinumero 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenOpetussuunnitelmat(perusteenDiaarinumero: string, options?: any): AxiosPromise<Array<OpetussuunnitelmaJulkinenDto>> {
            return JulkinenApiFp(configuration).getPerusteenOpetussuunnitelmat(perusteenDiaarinumero, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSisaltoViiteByKoodi(koodi: string, options?: any): AxiosPromise<Array<SisaltoViiteSijaintiDto>> {
            return JulkinenApiFp(configuration).getSisaltoViiteByKoodi(koodi, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsa(koodi: string, options?: any): AxiosPromise<SisaltoViiteDto> {
            return JulkinenApiFp(configuration).getTutkinnonOsa(koodi, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVstYksilollisetOppilaitostyypit(options?: any): AxiosPromise<Array<KoodistoKoodiDto>> {
            return JulkinenApiFp(configuration).getVstYksilollisetOppilaitostyypit(options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} kieli 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryDokumentti(opsId: number, kieli: string, ktId: string, body?: number, options?: any): AxiosPromise<DokumenttiDto> {
            return JulkinenApiFp(configuration).queryDokumentti(opsId, kieli, ktId, body, options)(axios, basePath);
        },
    };
};

/**
 * JulkinenApi - object-oriented interface
 * @export
 * @class JulkinenApi
 * @extends {BaseAPI}
 */
export class JulkinenApi extends BaseAPI {
    /**
     * 
     * @param {Array<string>} koulutustyypit 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkinenApi
     */
    public findKoulutusatyypinKoulutustoimijat(koulutustyypit: Array<string>, options?: any) {
        return JulkinenApiFp(this.configuration).findKoulutusatyypinKoulutustoimijat(koulutustyypit, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} [sivu] 
     * @param {number} [sivukoko] 
     * @param {string} [nimi] 
     * @param {boolean} [organisaatioRyhma] 
     * @param {Array<string>} [koulutustyyppi] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkinenApi
     */
    public findKoulutustoimijat(sivu?: number, sivukoko?: number, nimi?: string, organisaatioRyhma?: boolean, koulutustyyppi?: Array<string>, options?: any) {
        return JulkinenApiFp(this.configuration).findKoulutustoimijat(sivu, sivukoko, nimi, organisaatioRyhma, koulutustyyppi, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [perusteenDiaarinumero] 
     * @param {number} [perusteId] 
     * @param {string} [organisaatio] 
     * @param {Array<string>} [tyyppi] 
     * @param {number} [sivu] 
     * @param {number} [sivukoko] 
     * @param {string} [nimi] 
     * @param {string} [kieli] 
     * @param {boolean} [organisaatioRyhma] 
     * @param {string} [oppilaitosTyyppiKoodiUri] 
     * @param {Array<string>} [koulutustyyppi] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkinenApi
     */
    public findOpetussuunnitelmat(perusteenDiaarinumero?: string, perusteId?: number, organisaatio?: string, tyyppi?: Array<string>, sivu?: number, sivukoko?: number, nimi?: string, kieli?: string, organisaatioRyhma?: boolean, oppilaitosTyyppiKoodiUri?: string, koulutustyyppi?: Array<string>, options?: any) {
        return JulkinenApiFp(this.configuration).findOpetussuunnitelmat(perusteenDiaarinumero, perusteId, organisaatio, tyyppi, sivu, sivukoko, nimi, kieli, organisaatioRyhma, oppilaitosTyyppiKoodiUri, koulutustyyppi, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [perusteenDiaarinumero] 
     * @param {number} [perusteId] 
     * @param {string} [organisaatio] 
     * @param {Array<string>} [tyyppi] 
     * @param {number} [sivu] 
     * @param {number} [sivukoko] 
     * @param {string} [nimi] 
     * @param {string} [kieli] 
     * @param {string} [oppilaitosTyyppiKoodiUri] 
     * @param {Array<string>} [koulutustyyppi] 
     * @param {boolean} [tuleva] 
     * @param {boolean} [voimassaolo] 
     * @param {boolean} [poistunut] 
     * @param {Array<string>} [jotpatyyppi] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkinenApi
     */
    public findOpetussuunnitelmatJulkaisut(perusteenDiaarinumero?: string, perusteId?: number, organisaatio?: string, tyyppi?: Array<string>, sivu?: number, sivukoko?: number, nimi?: string, kieli?: string, oppilaitosTyyppiKoodiUri?: string, koulutustyyppi?: Array<string>, tuleva?: boolean, voimassaolo?: boolean, poistunut?: boolean, jotpatyyppi?: Array<string>, options?: any) {
        return JulkinenApiFp(this.configuration).findOpetussuunnitelmatJulkaisut(perusteenDiaarinumero, perusteId, organisaatio, tyyppi, sivu, sivukoko, nimi, kieli, oppilaitosTyyppiKoodiUri, koulutustyyppi, tuleva, voimassaolo, poistunut, jotpatyyppi, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkinenApi
     */
    public getAllOpetussuunnitelmat(ktId: string, body?: number, options?: any) {
        return JulkinenApiFp(this.configuration).getAllOpetussuunnitelmat(ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} koodi 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkinenApi
     */
    public getByKoodi(koodi: string, ktId: string, body?: number, options?: any) {
        return JulkinenApiFp(this.configuration).getByKoodi(koodi, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} kieli 
     * @param {number} dokumenttiId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkinenApi
     */
    public getDokumentti(opsId: number, kieli: string, dokumenttiId: number, ktId: string, body?: number, options?: any) {
        return JulkinenApiFp(this.configuration).getDokumentti(opsId, kieli, dokumenttiId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} kieli 
     * @param {string} ktId 
     * @param {number} [revision] 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkinenApi
     */
    public getJulkaistuDokumentti(opsId: number, kieli: string, ktId: string, revision?: number, body?: number, options?: any) {
        return JulkinenApiFp(this.configuration).getJulkaistuDokumentti(opsId, kieli, ktId, revision, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkinenApi
     */
    public getJulkisetTiedotteet(ktId: string, body?: number, options?: any) {
        return JulkinenApiFp(this.configuration).getJulkisetTiedotteet(ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkinenApi
     */
    public getKaikkiJulkaistutOpetussuunnitelmat(options?: any) {
        return JulkinenApiFp(this.configuration).getKaikkiJulkaistutOpetussuunnitelmat(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} ktId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkinenApi
     */
    public getKoulutustoimijaByKtId(ktId: number, options?: any) {
        return JulkinenApiFp(this.configuration).getKoulutustoimijaByKtId(ktId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} ktOid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkinenApi
     */
    public getKoulutustoimijaByOid(ktOid: string, options?: any) {
        return JulkinenApiFp(this.configuration).getKoulutustoimijaByOid(ktOid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} ktId 
     * @param {boolean} [esikatselu] 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkinenApi
     */
    public getOpetussuunnitelmaJulkaistuSisalto(opsId: number, ktId: string, esikatselu?: boolean, body?: number, options?: any) {
        return JulkinenApiFp(this.configuration).getOpetussuunnitelmaJulkaistuSisalto(opsId, ktId, esikatselu, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkinenApi
     */
    public getOpetussuunnitelmaJulkinen(opsId: number, ktId: string, body?: number, options?: any) {
        return JulkinenApiFp(this.configuration).getOpetussuunnitelmaJulkinen(opsId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkinenApi
     */
    public getOpetussuunnitelmaKaikki(opsId: number, ktId: string, body?: number, options?: any) {
        return JulkinenApiFp(this.configuration).getOpetussuunnitelmaKaikki(opsId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkinenApi
     */
    public getOpetussuunnitelmaOtsikot(opsId: number, ktId: string, body?: number, options?: any) {
        return JulkinenApiFp(this.configuration).getOpetussuunnitelmaOtsikot(opsId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} svId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkinenApi
     */
    public getOpetussuunnitelmaTekstit(opsId: number, svId: number, ktId: string, body?: number, options?: any) {
        return JulkinenApiFp(this.configuration).getOpetussuunnitelmaTekstit(opsId, svId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkinenApi
     */
    public getOpetussuunnitelmanToimija(opsId: number, options?: any) {
        return JulkinenApiFp(this.configuration).getOpetussuunnitelmanToimija(opsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} perusteenDiaarinumero 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkinenApi
     */
    public getPerusteenOpetussuunnitelmat(perusteenDiaarinumero: string, options?: any) {
        return JulkinenApiFp(this.configuration).getPerusteenOpetussuunnitelmat(perusteenDiaarinumero, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} koodi 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkinenApi
     */
    public getSisaltoViiteByKoodi(koodi: string, options?: any) {
        return JulkinenApiFp(this.configuration).getSisaltoViiteByKoodi(koodi, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} koodi 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkinenApi
     */
    public getTutkinnonOsa(koodi: string, options?: any) {
        return JulkinenApiFp(this.configuration).getTutkinnonOsa(koodi, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkinenApi
     */
    public getVstYksilollisetOppilaitostyypit(options?: any) {
        return JulkinenApiFp(this.configuration).getVstYksilollisetOppilaitostyypit(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} kieli 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkinenApi
     */
    public queryDokumentti(opsId: number, kieli: string, ktId: string, body?: number, options?: any) {
        return JulkinenApiFp(this.configuration).queryDokumentti(opsId, kieli, ktId, body, options)(this.axios, this.basePath);
    }

}


/**
 * KayttajaApi - axios parameter creator
 * @export
 */
export const KayttajaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSuosikki(opsId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling addSuosikki.');
            }
            const localVarPath = `/api/kayttaja/suosikki/{opsId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(options: any = {}): RequestArgs {
            const localVarPath = `/api/kayttaja`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKayttaja(options: any = {}): RequestArgs {
            const localVarPath = `/api/kayttaja/tiedot`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [app] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKayttajanEtusivu(app?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/kayttaja/etusivu`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (app !== undefined) {
                localVarQueryParameter['app'] = app;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [app] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutustoimijat(app?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/kayttaja/koulutustoimijat`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (app !== undefined) {
                localVarQueryParameter['app'] = app;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrSaveKayttaja(oid: string, options: any = {}): RequestArgs {
            // verify required parameter 'oid' is not null or undefined
            if (oid === null || oid === undefined) {
                throw new RequiredError('oid','Required parameter oid was null or undefined when calling getOrSaveKayttaja.');
            }
            const localVarPath = `/api/kayttaja/{oid}`
                .replace(`{${"oid"}}`, encodeURIComponent(String(oid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [app] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisaatiot(app?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/kayttaja/organisaatiot`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (app !== undefined) {
                localVarQueryParameter['app'] = app;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSuosikki(opsId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling removeSuosikki.');
            }
            const localVarPath = `/api/kayttaja/suosikki/{opsId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [app] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKoulutustoimijat(app?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/kayttaja/koulutustoimijat`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (app !== undefined) {
                localVarQueryParameter['app'] = app;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KayttajaApi - functional programming interface
 * @export
 */
export const KayttajaApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSuosikki(opsId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = KayttajaApiAxiosParamCreator(configuration).addSuosikki(opsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KayttajanTietoDto> {
            const localVarAxiosArgs = KayttajaApiAxiosParamCreator(configuration).get(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKayttaja(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KayttajaDto> {
            const localVarAxiosArgs = KayttajaApiAxiosParamCreator(configuration).getKayttaja(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [app] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKayttajanEtusivu(app?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EtusivuDto> {
            const localVarAxiosArgs = KayttajaApiAxiosParamCreator(configuration).getKayttajanEtusivu(app, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [app] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutustoimijat(app?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KoulutustoimijaBaseDto>> {
            const localVarAxiosArgs = KayttajaApiAxiosParamCreator(configuration).getKoulutustoimijat(app, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} oid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrSaveKayttaja(oid: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KayttajaDto> {
            const localVarAxiosArgs = KayttajaApiAxiosParamCreator(configuration).getOrSaveKayttaja(oid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [app] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisaatiot(app?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
            const localVarAxiosArgs = KayttajaApiAxiosParamCreator(configuration).getOrganisaatiot(app, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSuosikki(opsId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = KayttajaApiAxiosParamCreator(configuration).removeSuosikki(opsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [app] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKoulutustoimijat(app?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = KayttajaApiAxiosParamCreator(configuration).updateKoulutustoimijat(app, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * KayttajaApi - factory interface
 * @export
 */
export const KayttajaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSuosikki(opsId: number, options?: any): AxiosPromise<void> {
            return KayttajaApiFp(configuration).addSuosikki(opsId, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(options?: any): AxiosPromise<KayttajanTietoDto> {
            return KayttajaApiFp(configuration).get(options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKayttaja(options?: any): AxiosPromise<KayttajaDto> {
            return KayttajaApiFp(configuration).getKayttaja(options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [app] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKayttajanEtusivu(app?: string, options?: any): AxiosPromise<EtusivuDto> {
            return KayttajaApiFp(configuration).getKayttajanEtusivu(app, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [app] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutustoimijat(app?: string, options?: any): AxiosPromise<Array<KoulutustoimijaBaseDto>> {
            return KayttajaApiFp(configuration).getKoulutustoimijat(app, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} oid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrSaveKayttaja(oid: string, options?: any): AxiosPromise<KayttajaDto> {
            return KayttajaApiFp(configuration).getOrSaveKayttaja(oid, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [app] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisaatiot(app?: string, options?: any): AxiosPromise<Array<string>> {
            return KayttajaApiFp(configuration).getOrganisaatiot(app, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSuosikki(opsId: number, options?: any): AxiosPromise<void> {
            return KayttajaApiFp(configuration).removeSuosikki(opsId, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [app] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKoulutustoimijat(app?: string, options?: any): AxiosPromise<void> {
            return KayttajaApiFp(configuration).updateKoulutustoimijat(app, options)(axios, basePath);
        },
    };
};

/**
 * KayttajaApi - object-oriented interface
 * @export
 * @class KayttajaApi
 * @extends {BaseAPI}
 */
export class KayttajaApi extends BaseAPI {
    /**
     * 
     * @param {number} opsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KayttajaApi
     */
    public addSuosikki(opsId: number, options?: any) {
        return KayttajaApiFp(this.configuration).addSuosikki(opsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KayttajaApi
     */
    public get(options?: any) {
        return KayttajaApiFp(this.configuration).get(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KayttajaApi
     */
    public getKayttaja(options?: any) {
        return KayttajaApiFp(this.configuration).getKayttaja(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [app] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KayttajaApi
     */
    public getKayttajanEtusivu(app?: string, options?: any) {
        return KayttajaApiFp(this.configuration).getKayttajanEtusivu(app, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [app] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KayttajaApi
     */
    public getKoulutustoimijat(app?: string, options?: any) {
        return KayttajaApiFp(this.configuration).getKoulutustoimijat(app, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} oid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KayttajaApi
     */
    public getOrSaveKayttaja(oid: string, options?: any) {
        return KayttajaApiFp(this.configuration).getOrSaveKayttaja(oid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [app] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KayttajaApi
     */
    public getOrganisaatiot(app?: string, options?: any) {
        return KayttajaApiFp(this.configuration).getOrganisaatiot(app, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KayttajaApi
     */
    public removeSuosikki(opsId: number, options?: any) {
        return KayttajaApiFp(this.configuration).removeSuosikki(opsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [app] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KayttajaApi
     */
    public updateKoulutustoimijat(app?: string, options?: any) {
        return KayttajaApiFp(this.configuration).updateKoulutustoimijat(app, options)(this.axios, this.basePath);
    }

}


/**
 * KayttajaoikeudetApi - axios parameter creator
 * @export
 */
export const KayttajaoikeudetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [app] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutustoimijaOikeudet(app?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/kayttaja/koulutustoimijaoikeudet`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (app !== undefined) {
                localVarQueryParameter['app'] = app;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [app] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOikeudet(app?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/kayttaja/organisaatiooikeudet`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (app !== undefined) {
                localVarQueryParameter['app'] = app;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTyoryhmat(options: any = {}): RequestArgs {
            const localVarPath = `/api/kayttaja/oikeudet`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KayttajaoikeudetApi - functional programming interface
 * @export
 */
export const KayttajaoikeudetApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [app] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutustoimijaOikeudet(app?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<KoulutustoimijaBaseDto>; }> {
            const localVarAxiosArgs = KayttajaoikeudetApiAxiosParamCreator(configuration).getKoulutustoimijaOikeudet(app, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [app] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOikeudet(app?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<number>; }> {
            const localVarAxiosArgs = KayttajaoikeudetApiAxiosParamCreator(configuration).getOikeudet(app, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTyoryhmat(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KayttajaoikeusDto>> {
            const localVarAxiosArgs = KayttajaoikeudetApiAxiosParamCreator(configuration).getTyoryhmat(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * KayttajaoikeudetApi - factory interface
 * @export
 */
export const KayttajaoikeudetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [app] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutustoimijaOikeudet(app?: string, options?: any): AxiosPromise<{ [key: string]: Array<KoulutustoimijaBaseDto>; }> {
            return KayttajaoikeudetApiFp(configuration).getKoulutustoimijaOikeudet(app, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [app] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOikeudet(app?: string, options?: any): AxiosPromise<{ [key: string]: Array<number>; }> {
            return KayttajaoikeudetApiFp(configuration).getOikeudet(app, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTyoryhmat(options?: any): AxiosPromise<Array<KayttajaoikeusDto>> {
            return KayttajaoikeudetApiFp(configuration).getTyoryhmat(options)(axios, basePath);
        },
    };
};

/**
 * KayttajaoikeudetApi - object-oriented interface
 * @export
 * @class KayttajaoikeudetApi
 * @extends {BaseAPI}
 */
export class KayttajaoikeudetApi extends BaseAPI {
    /**
     * 
     * @param {string} [app] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KayttajaoikeudetApi
     */
    public getKoulutustoimijaOikeudet(app?: string, options?: any) {
        return KayttajaoikeudetApiFp(this.configuration).getKoulutustoimijaOikeudet(app, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [app] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KayttajaoikeudetApi
     */
    public getOikeudet(app?: string, options?: any) {
        return KayttajaoikeudetApiFp(this.configuration).getOikeudet(app, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KayttajaoikeudetApi
     */
    public getTyoryhmat(options?: any) {
        return KayttajaoikeudetApiFp(this.configuration).getTyoryhmat(options)(this.axios, this.basePath);
    }

}


/**
 * KoodistotApi - axios parameter creator
 * @export
 */
export const KoodistotApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoodiAlarelaatio(koodi: string, options: any = {}): RequestArgs {
            // verify required parameter 'koodi' is not null or undefined
            if (koodi === null || koodi === undefined) {
                throw new RequiredError('koodi','Required parameter koodi was null or undefined when calling getKoodiAlarelaatio.');
            }
            const localVarPath = `/api/koodisto/relaatio/sisaltyy-alakoodit/{koodi}`
                .replace(`{${"koodi"}}`, encodeURIComponent(String(koodi)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoodiYlarelaatio(koodi: string, options: any = {}): RequestArgs {
            // verify required parameter 'koodi' is not null or undefined
            if (koodi === null || koodi === undefined) {
                throw new RequiredError('koodi','Required parameter koodi was null or undefined when calling getKoodiYlarelaatio.');
            }
            const localVarPath = `/api/koodisto/relaatio/sisaltyy-ylakoodit/{koodi}`
                .replace(`{${"koodi"}}`, encodeURIComponent(String(koodi)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} koodisto 
         * @param {string} [haku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoodistoKaikki(koodisto: string, haku?: string, options: any = {}): RequestArgs {
            // verify required parameter 'koodisto' is not null or undefined
            if (koodisto === null || koodisto === undefined) {
                throw new RequiredError('koodisto','Required parameter koodisto was null or undefined when calling getKoodistoKaikki.');
            }
            const localVarPath = `/api/koodisto/{koodisto}`
                .replace(`{${"koodisto"}}`, encodeURIComponent(String(koodisto)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (haku !== undefined) {
                localVarQueryParameter['haku'] = haku;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} koodiUri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoodistoKoodiByUri(koodiUri: string, options: any = {}): RequestArgs {
            // verify required parameter 'koodiUri' is not null or undefined
            if (koodiUri === null || koodiUri === undefined) {
                throw new RequiredError('koodiUri','Required parameter koodiUri was null or undefined when calling getKoodistoKoodiByUri.');
            }
            const localVarPath = `/api/koodisto/uri/{koodiUri}`
                .replace(`{${"koodiUri"}}`, encodeURIComponent(String(koodiUri)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} koodisto 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoodistoYksi(koodisto: string, koodi: string, options: any = {}): RequestArgs {
            // verify required parameter 'koodisto' is not null or undefined
            if (koodisto === null || koodisto === undefined) {
                throw new RequiredError('koodisto','Required parameter koodisto was null or undefined when calling getKoodistoYksi.');
            }
            // verify required parameter 'koodi' is not null or undefined
            if (koodi === null || koodi === undefined) {
                throw new RequiredError('koodi','Required parameter koodi was null or undefined when calling getKoodistoYksi.');
            }
            const localVarPath = `/api/koodisto/{koodisto}/{koodi}`
                .replace(`{${"koodisto"}}`, encodeURIComponent(String(koodisto)))
                .replace(`{${"koodi"}}`, encodeURIComponent(String(koodi)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} koodisto 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryKoodist(koodisto: string, query: string, options: any = {}): RequestArgs {
            // verify required parameter 'koodisto' is not null or undefined
            if (koodisto === null || koodisto === undefined) {
                throw new RequiredError('koodisto','Required parameter koodisto was null or undefined when calling queryKoodist.');
            }
            // verify required parameter 'query' is not null or undefined
            if (query === null || query === undefined) {
                throw new RequiredError('query','Required parameter query was null or undefined when calling queryKoodist.');
            }
            const localVarPath = `/api/koodisto/{koodisto}/haku/{query}`
                .replace(`{${"koodisto"}}`, encodeURIComponent(String(koodisto)))
                .replace(`{${"query"}}`, encodeURIComponent(String(query)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KoodistotApi - functional programming interface
 * @export
 */
export const KoodistotApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoodiAlarelaatio(koodi: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KoodistoKoodiDto>> {
            const localVarAxiosArgs = KoodistotApiAxiosParamCreator(configuration).getKoodiAlarelaatio(koodi, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoodiYlarelaatio(koodi: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KoodistoKoodiDto>> {
            const localVarAxiosArgs = KoodistotApiAxiosParamCreator(configuration).getKoodiYlarelaatio(koodi, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} koodisto 
         * @param {string} [haku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoodistoKaikki(koodisto: string, haku?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KoodistoKoodiDto>> {
            const localVarAxiosArgs = KoodistotApiAxiosParamCreator(configuration).getKoodistoKaikki(koodisto, haku, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} koodiUri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoodistoKoodiByUri(koodiUri: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KoodistoKoodiDto> {
            const localVarAxiosArgs = KoodistotApiAxiosParamCreator(configuration).getKoodistoKoodiByUri(koodiUri, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} koodisto 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoodistoYksi(koodisto: string, koodi: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KoodistoKoodiDto> {
            const localVarAxiosArgs = KoodistotApiAxiosParamCreator(configuration).getKoodistoYksi(koodisto, koodi, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} koodisto 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryKoodist(koodisto: string, query: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KoodistoKoodiDto>> {
            const localVarAxiosArgs = KoodistotApiAxiosParamCreator(configuration).queryKoodist(koodisto, query, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * KoodistotApi - factory interface
 * @export
 */
export const KoodistotApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoodiAlarelaatio(koodi: string, options?: any): AxiosPromise<Array<KoodistoKoodiDto>> {
            return KoodistotApiFp(configuration).getKoodiAlarelaatio(koodi, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoodiYlarelaatio(koodi: string, options?: any): AxiosPromise<Array<KoodistoKoodiDto>> {
            return KoodistotApiFp(configuration).getKoodiYlarelaatio(koodi, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} koodisto 
         * @param {string} [haku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoodistoKaikki(koodisto: string, haku?: string, options?: any): AxiosPromise<Array<KoodistoKoodiDto>> {
            return KoodistotApiFp(configuration).getKoodistoKaikki(koodisto, haku, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} koodiUri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoodistoKoodiByUri(koodiUri: string, options?: any): AxiosPromise<KoodistoKoodiDto> {
            return KoodistotApiFp(configuration).getKoodistoKoodiByUri(koodiUri, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} koodisto 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoodistoYksi(koodisto: string, koodi: string, options?: any): AxiosPromise<KoodistoKoodiDto> {
            return KoodistotApiFp(configuration).getKoodistoYksi(koodisto, koodi, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} koodisto 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryKoodist(koodisto: string, query: string, options?: any): AxiosPromise<Array<KoodistoKoodiDto>> {
            return KoodistotApiFp(configuration).queryKoodist(koodisto, query, options)(axios, basePath);
        },
    };
};

/**
 * KoodistotApi - object-oriented interface
 * @export
 * @class KoodistotApi
 * @extends {BaseAPI}
 */
export class KoodistotApi extends BaseAPI {
    /**
     * 
     * @param {string} koodi 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KoodistotApi
     */
    public getKoodiAlarelaatio(koodi: string, options?: any) {
        return KoodistotApiFp(this.configuration).getKoodiAlarelaatio(koodi, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} koodi 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KoodistotApi
     */
    public getKoodiYlarelaatio(koodi: string, options?: any) {
        return KoodistotApiFp(this.configuration).getKoodiYlarelaatio(koodi, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} koodisto 
     * @param {string} [haku] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KoodistotApi
     */
    public getKoodistoKaikki(koodisto: string, haku?: string, options?: any) {
        return KoodistotApiFp(this.configuration).getKoodistoKaikki(koodisto, haku, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} koodiUri 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KoodistotApi
     */
    public getKoodistoKoodiByUri(koodiUri: string, options?: any) {
        return KoodistotApiFp(this.configuration).getKoodistoKoodiByUri(koodiUri, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} koodisto 
     * @param {string} koodi 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KoodistotApi
     */
    public getKoodistoYksi(koodisto: string, koodi: string, options?: any) {
        return KoodistotApiFp(this.configuration).getKoodistoYksi(koodisto, koodi, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} koodisto 
     * @param {string} query 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KoodistotApi
     */
    public queryKoodist(koodisto: string, query: string, options?: any) {
        return KoodistotApiFp(this.configuration).queryKoodist(koodisto, query, options)(this.axios, this.basePath);
    }

}


/**
 * KoulutustoimijatApi - axios parameter creator
 * @export
 */
export const KoulutustoimijatApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<string>} koulutustyypit 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEtusivu(koulutustyypit: Array<string>, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'koulutustyypit' is not null or undefined
            if (koulutustyypit === null || koulutustyypit === undefined) {
                throw new RequiredError('koulutustyypit','Required parameter koulutustyypit was null or undefined when calling getEtusivu.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getEtusivu.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/etusivu`
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (koulutustyypit) {
                localVarQueryParameter['koulutustyypit'] = koulutustyypit;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHierarkia(ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getHierarkia.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/hierarkia`
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} app 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKaikkiKayttajat(app: string, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'app' is not null or undefined
            if (app === null || app === undefined) {
                throw new RequiredError('app','Required parameter app was null or undefined when calling getKaikkiKayttajat.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getKaikkiKayttajat.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/kaikkiKayttajat`
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (app !== undefined) {
                localVarQueryParameter['app'] = app;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutuksenJarjestajat(options: any = {}): RequestArgs {
            const localVarPath = `/api/koulutustoimijat/koulutuksenjarjestajat`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutustoimija(ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getKoulutustoimija.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}`
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} koodi 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutustoimijaByKoodi(koodi: string, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'koodi' is not null or undefined
            if (koodi === null || koodi === undefined) {
                throw new RequiredError('koodi','Required parameter koodi was null or undefined when calling getKoulutustoimijaByKoodi.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getKoulutustoimijaByKoodi.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/koodi/{koodi}`
                .replace(`{${"koodi"}}`, encodeURIComponent(String(koodi)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} kayttajaOid 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutustoimijaKayttaja(kayttajaOid: string, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'kayttajaOid' is not null or undefined
            if (kayttajaOid === null || kayttajaOid === undefined) {
                throw new RequiredError('kayttajaOid','Required parameter kayttajaOid was null or undefined when calling getKoulutustoimijaKayttaja.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getKoulutustoimijaKayttaja.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/kayttajat/{kayttajaOid}`
                .replace(`{${"kayttajaOid"}}`, encodeURIComponent(String(kayttajaOid)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} app 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutustoimijaKayttajat(app: string, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'app' is not null or undefined
            if (app === null || app === undefined) {
                throw new RequiredError('app','Required parameter app was null or undefined when calling getKoulutustoimijaKayttajat.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getKoulutustoimijaKayttajat.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/kayttajat`
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (app !== undefined) {
                localVarQueryParameter['app'] = app;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOmatYstavat(ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getOmatYstavat.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/ystavat`
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisaatioHistoriaLiitokset(ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getOrganisaatioHistoriaLiitokset.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/historialiitokset`
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaikallisetTutkinnonosat(ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getPaikallisetTutkinnonosat.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/tutkinnonosat`
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPyynnot(ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getPyynnot.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/ystavapyynnot`
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYhteistyoKoulutustoimijat(ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getYhteistyoKoulutustoimijat.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/yhteistyo`
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} app 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYstavaOrganisaatioKayttajat(app: string, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'app' is not null or undefined
            if (app === null || app === undefined) {
                throw new RequiredError('app','Required parameter app was null or undefined when calling getYstavaOrganisaatioKayttajat.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getYstavaOrganisaatioKayttajat.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/ystavaOrganisaatioKayttajat`
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (app !== undefined) {
                localVarQueryParameter['app'] = app;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} vierasKtId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hylkaaYhteistyopyynto(vierasKtId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'vierasKtId' is not null or undefined
            if (vierasKtId === null || vierasKtId === undefined) {
                throw new RequiredError('vierasKtId','Required parameter vierasKtId was null or undefined when calling hylkaaYhteistyopyynto.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling hylkaaYhteistyopyynto.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/hylkaa/{vierasKtId}`
                .replace(`{${"vierasKtId"}}`, encodeURIComponent(String(vierasKtId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ktId 
         * @param {KoulutustoimijaDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKoulutustoimija(ktId: string, body?: KoulutustoimijaDto, options: any = {}): RequestArgs {
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling updateKoulutustoimija.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}`
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KoulutustoimijatApi - functional programming interface
 * @export
 */
export const KoulutustoimijatApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<string>} koulutustyypit 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEtusivu(koulutustyypit: Array<string>, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EtusivuDto> {
            const localVarAxiosArgs = KoulutustoimijatApiAxiosParamCreator(configuration).getEtusivu(koulutustyypit, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHierarkia(ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisaatioHierarkiaDto> {
            const localVarAxiosArgs = KoulutustoimijatApiAxiosParamCreator(configuration).getHierarkia(ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} app 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKaikkiKayttajat(app: string, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KayttajaKtoDto>> {
            const localVarAxiosArgs = KoulutustoimijatApiAxiosParamCreator(configuration).getKaikkiKayttajat(app, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutuksenJarjestajat(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KoulutustoimijaDto>> {
            const localVarAxiosArgs = KoulutustoimijatApiAxiosParamCreator(configuration).getKoulutuksenJarjestajat(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutustoimija(ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KoulutustoimijaDto> {
            const localVarAxiosArgs = KoulutustoimijatApiAxiosParamCreator(configuration).getKoulutustoimija(ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} koodi 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutustoimijaByKoodi(koodi: string, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SisaltoViiteSijaintiDto>> {
            const localVarAxiosArgs = KoulutustoimijatApiAxiosParamCreator(configuration).getKoulutustoimijaByKoodi(koodi, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} kayttajaOid 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutustoimijaKayttaja(kayttajaOid: string, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KayttajanTietoDto> {
            const localVarAxiosArgs = KoulutustoimijatApiAxiosParamCreator(configuration).getKoulutustoimijaKayttaja(kayttajaOid, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} app 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutustoimijaKayttajat(app: string, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KayttajaKtoDto>> {
            const localVarAxiosArgs = KoulutustoimijatApiAxiosParamCreator(configuration).getKoulutustoimijaKayttajat(app, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOmatYstavat(ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KoulutustoimijaYstavaDto>> {
            const localVarAxiosArgs = KoulutustoimijatApiAxiosParamCreator(configuration).getOmatYstavat(ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisaatioHistoriaLiitokset(ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganisaatioHistoriaLiitosDto>> {
            const localVarAxiosArgs = KoulutustoimijatApiAxiosParamCreator(configuration).getOrganisaatioHistoriaLiitokset(ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaikallisetTutkinnonosat(ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SisaltoViitePaikallinenIntegrationDto>> {
            const localVarAxiosArgs = KoulutustoimijatApiAxiosParamCreator(configuration).getPaikallisetTutkinnonosat(ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPyynnot(ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KoulutustoimijaBaseDto>> {
            const localVarAxiosArgs = KoulutustoimijatApiAxiosParamCreator(configuration).getPyynnot(ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYhteistyoKoulutustoimijat(ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KoulutustoimijaBaseDto>> {
            const localVarAxiosArgs = KoulutustoimijatApiAxiosParamCreator(configuration).getYhteistyoKoulutustoimijat(ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} app 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYstavaOrganisaatioKayttajat(app: string, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KayttajaKtoDto>> {
            const localVarAxiosArgs = KoulutustoimijatApiAxiosParamCreator(configuration).getYstavaOrganisaatioKayttajat(app, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} vierasKtId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hylkaaYhteistyopyynto(vierasKtId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = KoulutustoimijatApiAxiosParamCreator(configuration).hylkaaYhteistyopyynto(vierasKtId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} ktId 
         * @param {KoulutustoimijaDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKoulutustoimija(ktId: string, body?: KoulutustoimijaDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KoulutustoimijaDto> {
            const localVarAxiosArgs = KoulutustoimijatApiAxiosParamCreator(configuration).updateKoulutustoimija(ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * KoulutustoimijatApi - factory interface
 * @export
 */
export const KoulutustoimijatApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {Array<string>} koulutustyypit 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEtusivu(koulutustyypit: Array<string>, ktId: string, body?: number, options?: any): AxiosPromise<EtusivuDto> {
            return KoulutustoimijatApiFp(configuration).getEtusivu(koulutustyypit, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHierarkia(ktId: string, body?: number, options?: any): AxiosPromise<OrganisaatioHierarkiaDto> {
            return KoulutustoimijatApiFp(configuration).getHierarkia(ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} app 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKaikkiKayttajat(app: string, ktId: string, body?: number, options?: any): AxiosPromise<Array<KayttajaKtoDto>> {
            return KoulutustoimijatApiFp(configuration).getKaikkiKayttajat(app, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutuksenJarjestajat(options?: any): AxiosPromise<Array<KoulutustoimijaDto>> {
            return KoulutustoimijatApiFp(configuration).getKoulutuksenJarjestajat(options)(axios, basePath);
        },
        /**
         * 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutustoimija(ktId: string, body?: number, options?: any): AxiosPromise<KoulutustoimijaDto> {
            return KoulutustoimijatApiFp(configuration).getKoulutustoimija(ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} koodi 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutustoimijaByKoodi(koodi: string, ktId: string, body?: number, options?: any): AxiosPromise<Array<SisaltoViiteSijaintiDto>> {
            return KoulutustoimijatApiFp(configuration).getKoulutustoimijaByKoodi(koodi, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} kayttajaOid 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutustoimijaKayttaja(kayttajaOid: string, ktId: string, body?: number, options?: any): AxiosPromise<KayttajanTietoDto> {
            return KoulutustoimijatApiFp(configuration).getKoulutustoimijaKayttaja(kayttajaOid, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} app 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutustoimijaKayttajat(app: string, ktId: string, body?: number, options?: any): AxiosPromise<Array<KayttajaKtoDto>> {
            return KoulutustoimijatApiFp(configuration).getKoulutustoimijaKayttajat(app, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOmatYstavat(ktId: string, body?: number, options?: any): AxiosPromise<Array<KoulutustoimijaYstavaDto>> {
            return KoulutustoimijatApiFp(configuration).getOmatYstavat(ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisaatioHistoriaLiitokset(ktId: string, body?: number, options?: any): AxiosPromise<Array<OrganisaatioHistoriaLiitosDto>> {
            return KoulutustoimijatApiFp(configuration).getOrganisaatioHistoriaLiitokset(ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaikallisetTutkinnonosat(ktId: string, body?: number, options?: any): AxiosPromise<Array<SisaltoViitePaikallinenIntegrationDto>> {
            return KoulutustoimijatApiFp(configuration).getPaikallisetTutkinnonosat(ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPyynnot(ktId: string, body?: number, options?: any): AxiosPromise<Array<KoulutustoimijaBaseDto>> {
            return KoulutustoimijatApiFp(configuration).getPyynnot(ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYhteistyoKoulutustoimijat(ktId: string, body?: number, options?: any): AxiosPromise<Array<KoulutustoimijaBaseDto>> {
            return KoulutustoimijatApiFp(configuration).getYhteistyoKoulutustoimijat(ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} app 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYstavaOrganisaatioKayttajat(app: string, ktId: string, body?: number, options?: any): AxiosPromise<Array<KayttajaKtoDto>> {
            return KoulutustoimijatApiFp(configuration).getYstavaOrganisaatioKayttajat(app, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} vierasKtId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hylkaaYhteistyopyynto(vierasKtId: number, ktId: string, body?: number, options?: any): AxiosPromise<void> {
            return KoulutustoimijatApiFp(configuration).hylkaaYhteistyopyynto(vierasKtId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} ktId 
         * @param {KoulutustoimijaDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKoulutustoimija(ktId: string, body?: KoulutustoimijaDto, options?: any): AxiosPromise<KoulutustoimijaDto> {
            return KoulutustoimijatApiFp(configuration).updateKoulutustoimija(ktId, body, options)(axios, basePath);
        },
    };
};

/**
 * KoulutustoimijatApi - object-oriented interface
 * @export
 * @class KoulutustoimijatApi
 * @extends {BaseAPI}
 */
export class KoulutustoimijatApi extends BaseAPI {
    /**
     * 
     * @param {Array<string>} koulutustyypit 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KoulutustoimijatApi
     */
    public getEtusivu(koulutustyypit: Array<string>, ktId: string, body?: number, options?: any) {
        return KoulutustoimijatApiFp(this.configuration).getEtusivu(koulutustyypit, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KoulutustoimijatApi
     */
    public getHierarkia(ktId: string, body?: number, options?: any) {
        return KoulutustoimijatApiFp(this.configuration).getHierarkia(ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} app 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KoulutustoimijatApi
     */
    public getKaikkiKayttajat(app: string, ktId: string, body?: number, options?: any) {
        return KoulutustoimijatApiFp(this.configuration).getKaikkiKayttajat(app, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KoulutustoimijatApi
     */
    public getKoulutuksenJarjestajat(options?: any) {
        return KoulutustoimijatApiFp(this.configuration).getKoulutuksenJarjestajat(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KoulutustoimijatApi
     */
    public getKoulutustoimija(ktId: string, body?: number, options?: any) {
        return KoulutustoimijatApiFp(this.configuration).getKoulutustoimija(ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} koodi 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KoulutustoimijatApi
     */
    public getKoulutustoimijaByKoodi(koodi: string, ktId: string, body?: number, options?: any) {
        return KoulutustoimijatApiFp(this.configuration).getKoulutustoimijaByKoodi(koodi, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} kayttajaOid 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KoulutustoimijatApi
     */
    public getKoulutustoimijaKayttaja(kayttajaOid: string, ktId: string, body?: number, options?: any) {
        return KoulutustoimijatApiFp(this.configuration).getKoulutustoimijaKayttaja(kayttajaOid, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} app 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KoulutustoimijatApi
     */
    public getKoulutustoimijaKayttajat(app: string, ktId: string, body?: number, options?: any) {
        return KoulutustoimijatApiFp(this.configuration).getKoulutustoimijaKayttajat(app, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KoulutustoimijatApi
     */
    public getOmatYstavat(ktId: string, body?: number, options?: any) {
        return KoulutustoimijatApiFp(this.configuration).getOmatYstavat(ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KoulutustoimijatApi
     */
    public getOrganisaatioHistoriaLiitokset(ktId: string, body?: number, options?: any) {
        return KoulutustoimijatApiFp(this.configuration).getOrganisaatioHistoriaLiitokset(ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KoulutustoimijatApi
     */
    public getPaikallisetTutkinnonosat(ktId: string, body?: number, options?: any) {
        return KoulutustoimijatApiFp(this.configuration).getPaikallisetTutkinnonosat(ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KoulutustoimijatApi
     */
    public getPyynnot(ktId: string, body?: number, options?: any) {
        return KoulutustoimijatApiFp(this.configuration).getPyynnot(ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KoulutustoimijatApi
     */
    public getYhteistyoKoulutustoimijat(ktId: string, body?: number, options?: any) {
        return KoulutustoimijatApiFp(this.configuration).getYhteistyoKoulutustoimijat(ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} app 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KoulutustoimijatApi
     */
    public getYstavaOrganisaatioKayttajat(app: string, ktId: string, body?: number, options?: any) {
        return KoulutustoimijatApiFp(this.configuration).getYstavaOrganisaatioKayttajat(app, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} vierasKtId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KoulutustoimijatApi
     */
    public hylkaaYhteistyopyynto(vierasKtId: number, ktId: string, body?: number, options?: any) {
        return KoulutustoimijatApiFp(this.configuration).hylkaaYhteistyopyynto(vierasKtId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} ktId 
     * @param {KoulutustoimijaDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KoulutustoimijatApi
     */
    public updateKoulutustoimija(ktId: string, body?: KoulutustoimijaDto, options?: any) {
        return KoulutustoimijatApiFp(this.configuration).updateKoulutustoimija(ktId, body, options)(this.axios, this.basePath);
    }

}


/**
 * LiitetiedostotApi - axios parameter creator
 * @export
 */
export const LiitetiedostotApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {string} id 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImage(opsId: number, id: string, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling deleteImage.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteImage.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling deleteImage.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/kuvat/{id}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllImages(opsId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getAllImages.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getAllImages.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/kuvat`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} fileName 
         * @param {string} [If_None_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImage(opsId: number, fileName: string, If_None_Match?: string, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getImage.');
            }
            // verify required parameter 'fileName' is not null or undefined
            if (fileName === null || fileName === undefined) {
                throw new RequiredError('fileName','Required parameter fileName was null or undefined when calling getImage.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/kuvat/{fileName}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"fileName"}}`, encodeURIComponent(String(fileName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (If_None_Match !== undefined && If_None_Match !== null) {
                localVarHeaderParameter['If-None-Match'] = String(If_None_Match);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} fileName 
         * @param {string} ktId 
         * @param {string} [If_None_Match] 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutustoimjaImage(opsId: number, fileName: string, ktId: string, If_None_Match?: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getKoulutustoimjaImage.');
            }
            // verify required parameter 'fileName' is not null or undefined
            if (fileName === null || fileName === undefined) {
                throw new RequiredError('fileName','Required parameter fileName was null or undefined when calling getKoulutustoimjaImage.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getKoulutustoimjaImage.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/kuvat/{fileName}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"fileName"}}`, encodeURIComponent(String(fileName)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (If_None_Match !== undefined && If_None_Match !== null) {
                localVarHeaderParameter['If-None-Match'] = String(If_None_Match);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} nimi 
         * @param {Ref} file 
         * @param {number} width 
         * @param {number} height 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upload(opsId: number, nimi: string, file: Ref, width: number, height: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling upload.');
            }
            // verify required parameter 'nimi' is not null or undefined
            if (nimi === null || nimi === undefined) {
                throw new RequiredError('nimi','Required parameter nimi was null or undefined when calling upload.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling upload.');
            }
            // verify required parameter 'width' is not null or undefined
            if (width === null || width === undefined) {
                throw new RequiredError('width','Required parameter width was null or undefined when calling upload.');
            }
            // verify required parameter 'height' is not null or undefined
            if (height === null || height === undefined) {
                throw new RequiredError('height','Required parameter height was null or undefined when calling upload.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling upload.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/kuvat`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nimi !== undefined) {
                localVarQueryParameter['nimi'] = nimi;
            }

            if (file !== undefined) {
                localVarQueryParameter['file'] = file;
            }

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LiitetiedostotApi - functional programming interface
 * @export
 */
export const LiitetiedostotApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {string} id 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImage(opsId: number, id: string, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = LiitetiedostotApiAxiosParamCreator(configuration).deleteImage(opsId, id, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllImages(opsId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LiiteDto>> {
            const localVarAxiosArgs = LiitetiedostotApiAxiosParamCreator(configuration).getAllImages(opsId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} fileName 
         * @param {string} [If_None_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImage(opsId: number, fileName: string, If_None_Match?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = LiitetiedostotApiAxiosParamCreator(configuration).getImage(opsId, fileName, If_None_Match, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} fileName 
         * @param {string} ktId 
         * @param {string} [If_None_Match] 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutustoimjaImage(opsId: number, fileName: string, ktId: string, If_None_Match?: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = LiitetiedostotApiAxiosParamCreator(configuration).getKoulutustoimjaImage(opsId, fileName, ktId, If_None_Match, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} nimi 
         * @param {Ref} file 
         * @param {number} width 
         * @param {number} height 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upload(opsId: number, nimi: string, file: Ref, width: number, height: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = LiitetiedostotApiAxiosParamCreator(configuration).upload(opsId, nimi, file, width, height, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LiitetiedostotApi - factory interface
 * @export
 */
export const LiitetiedostotApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {string} id 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImage(opsId: number, id: string, ktId: string, body?: number, options?: any): AxiosPromise<void> {
            return LiitetiedostotApiFp(configuration).deleteImage(opsId, id, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllImages(opsId: number, ktId: string, body?: number, options?: any): AxiosPromise<Array<LiiteDto>> {
            return LiitetiedostotApiFp(configuration).getAllImages(opsId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} fileName 
         * @param {string} [If_None_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImage(opsId: number, fileName: string, If_None_Match?: string, options?: any): AxiosPromise<void> {
            return LiitetiedostotApiFp(configuration).getImage(opsId, fileName, If_None_Match, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} fileName 
         * @param {string} ktId 
         * @param {string} [If_None_Match] 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutustoimjaImage(opsId: number, fileName: string, ktId: string, If_None_Match?: string, body?: number, options?: any): AxiosPromise<void> {
            return LiitetiedostotApiFp(configuration).getKoulutustoimjaImage(opsId, fileName, ktId, If_None_Match, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} nimi 
         * @param {Ref} file 
         * @param {number} width 
         * @param {number} height 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upload(opsId: number, nimi: string, file: Ref, width: number, height: number, ktId: string, body?: number, options?: any): AxiosPromise<string> {
            return LiitetiedostotApiFp(configuration).upload(opsId, nimi, file, width, height, ktId, body, options)(axios, basePath);
        },
    };
};

/**
 * LiitetiedostotApi - object-oriented interface
 * @export
 * @class LiitetiedostotApi
 * @extends {BaseAPI}
 */
export class LiitetiedostotApi extends BaseAPI {
    /**
     * 
     * @param {number} opsId 
     * @param {string} id 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiitetiedostotApi
     */
    public deleteImage(opsId: number, id: string, ktId: string, body?: number, options?: any) {
        return LiitetiedostotApiFp(this.configuration).deleteImage(opsId, id, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiitetiedostotApi
     */
    public getAllImages(opsId: number, ktId: string, body?: number, options?: any) {
        return LiitetiedostotApiFp(this.configuration).getAllImages(opsId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} fileName 
     * @param {string} [If_None_Match] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiitetiedostotApi
     */
    public getImage(opsId: number, fileName: string, If_None_Match?: string, options?: any) {
        return LiitetiedostotApiFp(this.configuration).getImage(opsId, fileName, If_None_Match, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} fileName 
     * @param {string} ktId 
     * @param {string} [If_None_Match] 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiitetiedostotApi
     */
    public getKoulutustoimjaImage(opsId: number, fileName: string, ktId: string, If_None_Match?: string, body?: number, options?: any) {
        return LiitetiedostotApiFp(this.configuration).getKoulutustoimjaImage(opsId, fileName, ktId, If_None_Match, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} nimi 
     * @param {Ref} file 
     * @param {number} width 
     * @param {number} height 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiitetiedostotApi
     */
    public upload(opsId: number, nimi: string, file: Ref, width: number, height: number, ktId: string, body?: number, options?: any) {
        return LiitetiedostotApiFp(this.configuration).upload(opsId, nimi, file, width, height, ktId, body, options)(this.axios, this.basePath);
    }

}


/**
 * MaintenanceApi - axios parameter creator
 * @export
 */
export const MaintenanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} cache 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearCache(cache: string, options: any = {}): RequestArgs {
            // verify required parameter 'cache' is not null or undefined
            if (cache === null || cache === undefined) {
                throw new RequiredError('cache','Required parameter cache was null or undefined when calling clearCache.');
            }
            const localVarPath = `/api/maintenance/cacheclear/{cache}`
                .replace(`{${"cache"}}`, encodeURIComponent(String(cache)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paivitaKoulutustoimijaOppilaitostyypit(options: any = {}): RequestArgs {
            const localVarPath = `/api/maintenance/paivita/koulutustoimija/oppilaitostyyppi`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        poistaJulkaisut(opsId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling poistaJulkaisut.');
            }
            const localVarPath = `/api/maintenance/poistajulkaisut/{opsId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [julkaisekaikki] 
         * @param {Array<string>} [koulutustyypit] 
         * @param {string} [opstyyppi] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teeJulkaisut(julkaisekaikki?: boolean, koulutustyypit?: Array<string>, opstyyppi?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/maintenance/julkaisut`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (julkaisekaikki !== undefined) {
                localVarQueryParameter['julkaisekaikki'] = julkaisekaikki;
            }

            if (koulutustyypit) {
                localVarQueryParameter['koulutustyypit'] = koulutustyypit;
            }

            if (opstyyppi !== undefined) {
                localVarQueryParameter['opstyyppi'] = opstyyppi;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MaintenanceApi - functional programming interface
 * @export
 */
export const MaintenanceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} cache 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearCache(cache: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = MaintenanceApiAxiosParamCreator(configuration).clearCache(cache, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paivitaKoulutustoimijaOppilaitostyypit(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = MaintenanceApiAxiosParamCreator(configuration).paivitaKoulutustoimijaOppilaitostyypit(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        poistaJulkaisut(opsId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = MaintenanceApiAxiosParamCreator(configuration).poistaJulkaisut(opsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {boolean} [julkaisekaikki] 
         * @param {Array<string>} [koulutustyypit] 
         * @param {string} [opstyyppi] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teeJulkaisut(julkaisekaikki?: boolean, koulutustyypit?: Array<string>, opstyyppi?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = MaintenanceApiAxiosParamCreator(configuration).teeJulkaisut(julkaisekaikki, koulutustyypit, opstyyppi, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MaintenanceApi - factory interface
 * @export
 */
export const MaintenanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} cache 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearCache(cache: string, options?: any): AxiosPromise<void> {
            return MaintenanceApiFp(configuration).clearCache(cache, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paivitaKoulutustoimijaOppilaitostyypit(options?: any): AxiosPromise<void> {
            return MaintenanceApiFp(configuration).paivitaKoulutustoimijaOppilaitostyypit(options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        poistaJulkaisut(opsId: number, options?: any): AxiosPromise<void> {
            return MaintenanceApiFp(configuration).poistaJulkaisut(opsId, options)(axios, basePath);
        },
        /**
         * 
         * @param {boolean} [julkaisekaikki] 
         * @param {Array<string>} [koulutustyypit] 
         * @param {string} [opstyyppi] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teeJulkaisut(julkaisekaikki?: boolean, koulutustyypit?: Array<string>, opstyyppi?: string, options?: any): AxiosPromise<void> {
            return MaintenanceApiFp(configuration).teeJulkaisut(julkaisekaikki, koulutustyypit, opstyyppi, options)(axios, basePath);
        },
    };
};

/**
 * MaintenanceApi - object-oriented interface
 * @export
 * @class MaintenanceApi
 * @extends {BaseAPI}
 */
export class MaintenanceApi extends BaseAPI {
    /**
     * 
     * @param {string} cache 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public clearCache(cache: string, options?: any) {
        return MaintenanceApiFp(this.configuration).clearCache(cache, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public paivitaKoulutustoimijaOppilaitostyypit(options?: any) {
        return MaintenanceApiFp(this.configuration).paivitaKoulutustoimijaOppilaitostyypit(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public poistaJulkaisut(opsId: number, options?: any) {
        return MaintenanceApiFp(this.configuration).poistaJulkaisut(opsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {boolean} [julkaisekaikki] 
     * @param {Array<string>} [koulutustyypit] 
     * @param {string} [opstyyppi] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public teeJulkaisut(julkaisekaikki?: boolean, koulutustyypit?: Array<string>, opstyyppi?: string, options?: any) {
        return MaintenanceApiFp(this.configuration).teeJulkaisut(julkaisekaikki, koulutustyypit, opstyyppi, options)(this.axios, this.basePath);
    }

}


/**
 * MuokkaustietoApi - axios parameter creator
 * @export
 */
export const MuokkaustietoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} ktId 
         * @param {number} opsId 
         * @param {number} [viimeisinLuomisaika] 
         * @param {number} [lukumaara] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenMuokkausTiedotWithLuomisaika(ktId: number, opsId: number, viimeisinLuomisaika?: number, lukumaara?: number, options: any = {}): RequestArgs {
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getPerusteenMuokkausTiedotWithLuomisaika.');
            }
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getPerusteenMuokkausTiedotWithLuomisaika.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelma/{opsId}/muokkaustieto`
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)))
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (viimeisinLuomisaika !== undefined) {
                localVarQueryParameter['viimeisinLuomisaika'] = viimeisinLuomisaika;
            }

            if (lukumaara !== undefined) {
                localVarQueryParameter['lukumaara'] = lukumaara;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MuokkaustietoApi - functional programming interface
 * @export
 */
export const MuokkaustietoApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} ktId 
         * @param {number} opsId 
         * @param {number} [viimeisinLuomisaika] 
         * @param {number} [lukumaara] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenMuokkausTiedotWithLuomisaika(ktId: number, opsId: number, viimeisinLuomisaika?: number, lukumaara?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpetussuunnitelmaMuokkaustietoDto>> {
            const localVarAxiosArgs = MuokkaustietoApiAxiosParamCreator(configuration).getPerusteenMuokkausTiedotWithLuomisaika(ktId, opsId, viimeisinLuomisaika, lukumaara, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MuokkaustietoApi - factory interface
 * @export
 */
export const MuokkaustietoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} ktId 
         * @param {number} opsId 
         * @param {number} [viimeisinLuomisaika] 
         * @param {number} [lukumaara] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenMuokkausTiedotWithLuomisaika(ktId: number, opsId: number, viimeisinLuomisaika?: number, lukumaara?: number, options?: any): AxiosPromise<Array<OpetussuunnitelmaMuokkaustietoDto>> {
            return MuokkaustietoApiFp(configuration).getPerusteenMuokkausTiedotWithLuomisaika(ktId, opsId, viimeisinLuomisaika, lukumaara, options)(axios, basePath);
        },
    };
};

/**
 * MuokkaustietoApi - object-oriented interface
 * @export
 * @class MuokkaustietoApi
 * @extends {BaseAPI}
 */
export class MuokkaustietoApi extends BaseAPI {
    /**
     * 
     * @param {number} ktId 
     * @param {number} opsId 
     * @param {number} [viimeisinLuomisaika] 
     * @param {number} [lukumaara] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MuokkaustietoApi
     */
    public getPerusteenMuokkausTiedotWithLuomisaika(ktId: number, opsId: number, viimeisinLuomisaika?: number, lukumaara?: number, options?: any) {
        return MuokkaustietoApiFp(this.configuration).getPerusteenMuokkausTiedotWithLuomisaika(ktId, opsId, viimeisinLuomisaika, lukumaara, options)(this.axios, this.basePath);
    }

}


/**
 * OhjeetApi - axios parameter creator
 * @export
 */
export const OhjeetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {OhjeDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOhje(body?: OhjeDto, options: any = {}): RequestArgs {
            const localVarPath = `/api/ohjeet`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {OhjeDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editOhje(id: number, body?: OhjeDto, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling editOhje.');
            }
            const localVarPath = `/api/ohjeet/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [toteutus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOhjeet(toteutus?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/ohjeet`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (toteutus !== undefined) {
                localVarQueryParameter['toteutus'] = toteutus;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOhje(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling removeOhje.');
            }
            const localVarPath = `/api/ohjeet/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OhjeetApi - functional programming interface
 * @export
 */
export const OhjeetApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {OhjeDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOhje(body?: OhjeDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OhjeDto> {
            const localVarAxiosArgs = OhjeetApiAxiosParamCreator(configuration).addOhje(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {OhjeDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editOhje(id: number, body?: OhjeDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OhjeDto> {
            const localVarAxiosArgs = OhjeetApiAxiosParamCreator(configuration).editOhje(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [toteutus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOhjeet(toteutus?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OhjeDto>> {
            const localVarAxiosArgs = OhjeetApiAxiosParamCreator(configuration).getOhjeet(toteutus, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOhje(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = OhjeetApiAxiosParamCreator(configuration).removeOhje(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OhjeetApi - factory interface
 * @export
 */
export const OhjeetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {OhjeDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOhje(body?: OhjeDto, options?: any): AxiosPromise<OhjeDto> {
            return OhjeetApiFp(configuration).addOhje(body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {OhjeDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editOhje(id: number, body?: OhjeDto, options?: any): AxiosPromise<OhjeDto> {
            return OhjeetApiFp(configuration).editOhje(id, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [toteutus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOhjeet(toteutus?: string, options?: any): AxiosPromise<Array<OhjeDto>> {
            return OhjeetApiFp(configuration).getOhjeet(toteutus, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOhje(id: number, options?: any): AxiosPromise<void> {
            return OhjeetApiFp(configuration).removeOhje(id, options)(axios, basePath);
        },
    };
};

/**
 * OhjeetApi - object-oriented interface
 * @export
 * @class OhjeetApi
 * @extends {BaseAPI}
 */
export class OhjeetApi extends BaseAPI {
    /**
     * 
     * @param {OhjeDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OhjeetApi
     */
    public addOhje(body?: OhjeDto, options?: any) {
        return OhjeetApiFp(this.configuration).addOhje(body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {OhjeDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OhjeetApi
     */
    public editOhje(id: number, body?: OhjeDto, options?: any) {
        return OhjeetApiFp(this.configuration).editOhje(id, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [toteutus] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OhjeetApi
     */
    public getOhjeet(toteutus?: string, options?: any) {
        return OhjeetApiFp(this.configuration).getOhjeet(toteutus, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OhjeetApi
     */
    public removeOhje(id: number, options?: any) {
        return OhjeetApiFp(this.configuration).removeOhje(id, options)(this.axios, this.basePath);
    }

}


/**
 * OpetussuunnitelmatApi - axios parameter creator
 * @export
 */
export const OpetussuunnitelmatApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} ktId 
         * @param {OpetussuunnitelmaLuontiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOpetussuunnitelma(ktId: string, body?: OpetussuunnitelmaLuontiDto, options: any = {}): RequestArgs {
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling addOpetussuunnitelma.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat`
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOpetussuunnitelmatBaseSivutettu(ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getAllOpetussuunnitelmatBaseSivutettu.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat`
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOpetussuunnitelmatSivutettu(ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getAllOpetussuunnitelmatSivutettu.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/info`
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ktId 
         * @param {Array<string>} [koulutustyypit] 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOtherOrgsOpetussuunnitelmat(ktId: string, koulutustyypit?: Array<string>, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getAllOtherOrgsOpetussuunnitelmat.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/ystavien`
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (koulutustyypit) {
                localVarQueryParameter['koulutustyypit'] = koulutustyypit;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKaikkiOpetussuunnitelmat(ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getKaikkiOpetussuunnitelmat.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/kaikki`
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ktId 
         * @param {Array<string>} [koulutustyypit] 
         * @param {'POHJA' | 'OPS' | 'OPSPOHJA' | 'YLEINEN' | 'YHTEINEN'} [tyyppi] 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutustoimijaOpetussuunnitelmat(ktId: string, koulutustyypit?: Array<string>, tyyppi?: 'POHJA' | 'OPS' | 'OPSPOHJA' | 'YLEINEN' | 'YHTEINEN', body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getKoulutustoimijaOpetussuunnitelmat.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/koulutustoimija`
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (koulutustyypit) {
                localVarQueryParameter['koulutustyypit'] = koulutustyypit;
            }

            if (tyyppi !== undefined) {
                localVarQueryParameter['tyyppi'] = tyyppi;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelma(opsId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getOpetussuunnitelma.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getOpetussuunnitelma.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaKevyt(opsId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getOpetussuunnitelmaKevyt.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getOpetussuunnitelmaKevyt.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/kevyt`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaNavigation(opsId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getOpetussuunnitelmaNavigation.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getOpetussuunnitelmaNavigation.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/navigaatio`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaNavigationJulkinen(opsId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getOpetussuunnitelmaNavigationJulkinen.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getOpetussuunnitelmaNavigationJulkinen.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/navigaatio/julkinen`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {boolean} [esikatselu] 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaNavigationPublic(opsId: number, ktId: string, esikatselu?: boolean, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getOpetussuunnitelmaNavigationPublic.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getOpetussuunnitelmaNavigationPublic.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/navigaatio/public`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (esikatselu !== undefined) {
                localVarQueryParameter['esikatselu'] = esikatselu;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaOikeudet(opsId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getOpetussuunnitelmaOikeudet.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getOpetussuunnitelmaOikeudet.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/oikeudet`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaPeruste(opsId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getOpetussuunnitelmaPeruste.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getOpetussuunnitelmaPeruste.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/peruste`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaPohjaKevyt(opsId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getOpetussuunnitelmaPohjaKevyt.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getOpetussuunnitelmaPohjaKevyt.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/pohja/kevyt`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} revId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaRevision(opsId: number, revId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getOpetussuunnitelmaRevision.');
            }
            // verify required parameter 'revId' is not null or undefined
            if (revId === null || revId === undefined) {
                throw new RequiredError('revId','Required parameter revId was null or undefined when calling getOpetussuunnitelmaRevision.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getOpetussuunnitelmaRevision.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/versiot/{revId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"revId"}}`, encodeURIComponent(String(revId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaRevisions(opsId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getOpetussuunnitelmaRevisions.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getOpetussuunnitelmaRevisions.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/versiot`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} koulutustyypit 
         * @param {string} ktId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaTilastot(koulutustyypit: Array<string>, ktId: string, options: any = {}): RequestArgs {
            // verify required parameter 'koulutustyypit' is not null or undefined
            if (koulutustyypit === null || koulutustyypit === undefined) {
                throw new RequiredError('koulutustyypit','Required parameter koulutustyypit was null or undefined when calling getOpetussuunnitelmaTilastot.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getOpetussuunnitelmaTilastot.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/tilastot`
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (koulutustyypit) {
                localVarQueryParameter['koulutustyypit'] = koulutustyypit;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organisaatioid 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmatOrganisaatioista(organisaatioid: string, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'organisaatioid' is not null or undefined
            if (organisaatioid === null || organisaatioid === undefined) {
                throw new RequiredError('organisaatioid','Required parameter organisaatioid was null or undefined when calling getOpetussuunnitelmatOrganisaatioista.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getOpetussuunnitelmatOrganisaatioista.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/organisaatio/{organisaatioid}`
                .replace(`{${"organisaatioid"}}`, encodeURIComponent(String(organisaatioid)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} koulutustyypit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOphOpsPohjat(koulutustyypit: Array<string>, options: any = {}): RequestArgs {
            // verify required parameter 'koulutustyypit' is not null or undefined
            if (koulutustyypit === null || koulutustyypit === undefined) {
                throw new RequiredError('koulutustyypit','Required parameter koulutustyypit was null or undefined when calling getOphOpsPohjat.');
            }
            const localVarPath = `/api/opetussuunnitelmat/opspohjat`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (koulutustyypit) {
                localVarQueryParameter['koulutustyypit'] = koulutustyypit;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} koulutustyypit 
         * @param {Array<string>} tilat 
         * @param {string} tyyppi 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpsPohjat(koulutustyypit: Array<string>, tilat: Array<string>, tyyppi: string, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'koulutustyypit' is not null or undefined
            if (koulutustyypit === null || koulutustyypit === undefined) {
                throw new RequiredError('koulutustyypit','Required parameter koulutustyypit was null or undefined when calling getOpsPohjat.');
            }
            // verify required parameter 'tilat' is not null or undefined
            if (tilat === null || tilat === undefined) {
                throw new RequiredError('tilat','Required parameter tilat was null or undefined when calling getOpsPohjat.');
            }
            // verify required parameter 'tyyppi' is not null or undefined
            if (tyyppi === null || tyyppi === undefined) {
                throw new RequiredError('tyyppi','Required parameter tyyppi was null or undefined when calling getOpsPohjat.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getOpsPohjat.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/pohjat`
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (koulutustyypit) {
                localVarQueryParameter['koulutustyypit'] = koulutustyypit;
            }

            if (tilat) {
                localVarQueryParameter['tilat'] = tilat;
            }

            if (tyyppi !== undefined) {
                localVarQueryParameter['tyyppi'] = tyyppi;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaivitettavaOpetussuunnitelma(opsId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getPaivitettavaOpetussuunnitelma.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getPaivitettavaOpetussuunnitelma.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/vanhentuneet/{opsId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaivitettavatOpetussuunnitelmat(ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getPaivitettavatOpetussuunnitelmat.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/vanhentuneet`
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteRakenneNakyvat(opsId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getPerusteRakenneNakyvat.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getPerusteRakenneNakyvat.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/suorituspolku`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPohjat(options: any = {}): RequestArgs {
            const localVarPath = `/api/opetussuunnitelmat/pohjat`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoistetutSisaltoviitteet(opsId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getPoistetutSisaltoviitteet.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getPoistetutSisaltoviitteet.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/poistetut`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paivitaOpetussuunnitelmanPeruste(opsId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling paivitaOpetussuunnitelmanPeruste.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling paivitaOpetussuunnitelmanPeruste.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/paivita`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} poistettuId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        palautaOpetussuunnitelmaSisaltoviite(opsId: number, poistettuId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling palautaOpetussuunnitelmaSisaltoviite.');
            }
            // verify required parameter 'poistettuId' is not null or undefined
            if (poistettuId === null || poistettuId === undefined) {
                throw new RequiredError('poistettuId','Required parameter poistettuId was null or undefined when calling palautaOpetussuunnitelmaSisaltoviite.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling palautaOpetussuunnitelmaSisaltoviite.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/poistetut/{poistettuId}/palauta`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"poistettuId"}}`, encodeURIComponent(String(poistettuId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} revId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertOpetussuunnitelmaToRevision(opsId: number, revId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling revertOpetussuunnitelmaToRevision.');
            }
            // verify required parameter 'revId' is not null or undefined
            if (revId === null || revId === undefined) {
                throw new RequiredError('revId','Required parameter revId was null or undefined when calling revertOpetussuunnitelmaToRevision.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling revertOpetussuunnitelmaToRevision.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/version/{revId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"revId"}}`, encodeURIComponent(String(revId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {OpetussuunnitelmaDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOpetussuunnitelma(opsId: number, ktId: string, body?: OpetussuunnitelmaDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling updateOpetussuunnitelma.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling updateOpetussuunnitelma.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {KoulutustoimijaBaseDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOpetussuunnitelmaKoulutustoimija(opsId: number, ktId: string, body?: KoulutustoimijaBaseDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling updateOpetussuunnitelmaKoulutustoimija.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling updateOpetussuunnitelmaKoulutustoimija.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/siirra`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOpetussuunnitelmaKoulutustoimijaPassivoidusta(opsId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling updateOpetussuunnitelmaKoulutustoimijaPassivoidusta.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling updateOpetussuunnitelmaKoulutustoimijaPassivoidusta.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/siirraPassivoidusta`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} kayttajaId 
         * @param {string} ktId 
         * @param {KayttajaoikeusDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOpetussuunnitelmaOikeus(opsId: number, kayttajaId: number, ktId: string, body?: KayttajaoikeusDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling updateOpetussuunnitelmaOikeus.');
            }
            // verify required parameter 'kayttajaId' is not null or undefined
            if (kayttajaId === null || kayttajaId === undefined) {
                throw new RequiredError('kayttajaId','Required parameter kayttajaId was null or undefined when calling updateOpetussuunnitelmaOikeus.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling updateOpetussuunnitelmaOikeus.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/oikeudet/{kayttajaId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"kayttajaId"}}`, encodeURIComponent(String(kayttajaId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {'LUONNOS' | 'VALMIS' | 'POISTETTU' | 'JULKAISTU'} tila 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOpetussuunnitelmaTila(opsId: number, tila: 'LUONNOS' | 'VALMIS' | 'POISTETTU' | 'JULKAISTU', ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling updateOpetussuunnitelmaTila.');
            }
            // verify required parameter 'tila' is not null or undefined
            if (tila === null || tila === undefined) {
                throw new RequiredError('tila','Required parameter tila was null or undefined when calling updateOpetussuunnitelmaTila.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling updateOpetussuunnitelmaTila.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/tila/{tila}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"tila"}}`, encodeURIComponent(String(tila)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validoiOpetussuunnitelma(opsId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling validoiOpetussuunnitelma.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling validoiOpetussuunnitelma.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/validoi`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpetussuunnitelmatApi - functional programming interface
 * @export
 */
export const OpetussuunnitelmatApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} ktId 
         * @param {OpetussuunnitelmaLuontiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOpetussuunnitelma(ktId: string, body?: OpetussuunnitelmaLuontiDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpetussuunnitelmaBaseDto> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).addOpetussuunnitelma(ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOpetussuunnitelmatBaseSivutettu(ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOpetussuunnitelmaBaseDto> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getAllOpetussuunnitelmatBaseSivutettu(ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOpetussuunnitelmatSivutettu(ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOpetussuunnitelmaDto> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getAllOpetussuunnitelmatSivutettu(ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} ktId 
         * @param {Array<string>} [koulutustyypit] 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOtherOrgsOpetussuunnitelmat(ktId: string, koulutustyypit?: Array<string>, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpetussuunnitelmaDto>> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getAllOtherOrgsOpetussuunnitelmat(ktId, koulutustyypit, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKaikkiOpetussuunnitelmat(ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOpetussuunnitelmaDto> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getKaikkiOpetussuunnitelmat(ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} ktId 
         * @param {Array<string>} [koulutustyypit] 
         * @param {'POHJA' | 'OPS' | 'OPSPOHJA' | 'YLEINEN' | 'YHTEINEN'} [tyyppi] 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutustoimijaOpetussuunnitelmat(ktId: string, koulutustyypit?: Array<string>, tyyppi?: 'POHJA' | 'OPS' | 'OPSPOHJA' | 'YLEINEN' | 'YHTEINEN', body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpetussuunnitelmaDto>> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getKoulutustoimijaOpetussuunnitelmat(ktId, koulutustyypit, tyyppi, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelma(opsId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpetussuunnitelmaDto> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getOpetussuunnitelma(opsId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaKevyt(opsId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpetussuunnitelmaBaseDto> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getOpetussuunnitelmaKevyt(opsId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaNavigation(opsId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NavigationNodeDto> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getOpetussuunnitelmaNavigation(opsId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaNavigationJulkinen(opsId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NavigationNodeDto> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getOpetussuunnitelmaNavigationJulkinen(opsId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {boolean} [esikatselu] 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaNavigationPublic(opsId: number, ktId: string, esikatselu?: boolean, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NavigationNodeDto> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getOpetussuunnitelmaNavigationPublic(opsId, ktId, esikatselu, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaOikeudet(opsId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KayttajaoikeusDto>> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getOpetussuunnitelmaOikeudet(opsId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaPeruste(opsId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getOpetussuunnitelmaPeruste(opsId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaPohjaKevyt(opsId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpetussuunnitelmaBaseDto> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getOpetussuunnitelmaPohjaKevyt(opsId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} revId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaRevision(opsId: number, revId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getOpetussuunnitelmaRevision(opsId, revId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaRevisions(opsId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Revision>> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getOpetussuunnitelmaRevisions(opsId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<string>} koulutustyypit 
         * @param {string} ktId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaTilastot(koulutustyypit: Array<string>, ktId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpetussuunnitelmaTilastoDto>> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getOpetussuunnitelmaTilastot(koulutustyypit, ktId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} organisaatioid 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmatOrganisaatioista(organisaatioid: string, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpetussuunnitelmaDto>> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getOpetussuunnitelmatOrganisaatioista(organisaatioid, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<string>} koulutustyypit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOphOpsPohjat(koulutustyypit: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpetussuunnitelmaBaseDto>> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getOphOpsPohjat(koulutustyypit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<string>} koulutustyypit 
         * @param {Array<string>} tilat 
         * @param {string} tyyppi 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpsPohjat(koulutustyypit: Array<string>, tilat: Array<string>, tyyppi: string, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpetussuunnitelmaBaseDto>> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getOpsPohjat(koulutustyypit, tilat, tyyppi, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaivitettavaOpetussuunnitelma(opsId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VanhentunutPohjaperusteDto> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getPaivitettavaOpetussuunnitelma(opsId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaivitettavatOpetussuunnitelmat(ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VanhentunutPohjaperusteDto>> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getPaivitettavatOpetussuunnitelmat(ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteRakenneNakyvat(opsId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RakenneModuuliTunnisteDto>> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getPerusteRakenneNakyvat(opsId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPohjat(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpetussuunnitelmaBaseDto>> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getPohjat(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoistetutSisaltoviitteet(opsId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PoistettuDto>> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getPoistetutSisaltoviitteet(opsId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paivitaOpetussuunnitelmanPeruste(opsId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).paivitaOpetussuunnitelmanPeruste(opsId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} poistettuId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        palautaOpetussuunnitelmaSisaltoviite(opsId: number, poistettuId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SisaltoViiteDto> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).palautaOpetussuunnitelmaSisaltoviite(opsId, poistettuId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} revId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertOpetussuunnitelmaToRevision(opsId: number, revId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).revertOpetussuunnitelmaToRevision(opsId, revId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {OpetussuunnitelmaDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOpetussuunnitelma(opsId: number, ktId: string, body?: OpetussuunnitelmaDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpetussuunnitelmaDto> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).updateOpetussuunnitelma(opsId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {KoulutustoimijaBaseDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOpetussuunnitelmaKoulutustoimija(opsId: number, ktId: string, body?: KoulutustoimijaBaseDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpetussuunnitelmaDto> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).updateOpetussuunnitelmaKoulutustoimija(opsId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOpetussuunnitelmaKoulutustoimijaPassivoidusta(opsId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpetussuunnitelmaDto> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).updateOpetussuunnitelmaKoulutustoimijaPassivoidusta(opsId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} kayttajaId 
         * @param {string} ktId 
         * @param {KayttajaoikeusDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOpetussuunnitelmaOikeus(opsId: number, kayttajaId: number, ktId: string, body?: KayttajaoikeusDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KayttajaoikeusDto> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).updateOpetussuunnitelmaOikeus(opsId, kayttajaId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {'LUONNOS' | 'VALMIS' | 'POISTETTU' | 'JULKAISTU'} tila 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOpetussuunnitelmaTila(opsId: number, tila: 'LUONNOS' | 'VALMIS' | 'POISTETTU' | 'JULKAISTU', ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpetussuunnitelmaBaseDto> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).updateOpetussuunnitelmaTila(opsId, tila, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validoiOpetussuunnitelma(opsId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Validointi>> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).validoiOpetussuunnitelma(opsId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OpetussuunnitelmatApi - factory interface
 * @export
 */
export const OpetussuunnitelmatApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} ktId 
         * @param {OpetussuunnitelmaLuontiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOpetussuunnitelma(ktId: string, body?: OpetussuunnitelmaLuontiDto, options?: any): AxiosPromise<OpetussuunnitelmaBaseDto> {
            return OpetussuunnitelmatApiFp(configuration).addOpetussuunnitelma(ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOpetussuunnitelmatBaseSivutettu(ktId: string, body?: number, options?: any): AxiosPromise<PageOpetussuunnitelmaBaseDto> {
            return OpetussuunnitelmatApiFp(configuration).getAllOpetussuunnitelmatBaseSivutettu(ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOpetussuunnitelmatSivutettu(ktId: string, body?: number, options?: any): AxiosPromise<PageOpetussuunnitelmaDto> {
            return OpetussuunnitelmatApiFp(configuration).getAllOpetussuunnitelmatSivutettu(ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} ktId 
         * @param {Array<string>} [koulutustyypit] 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOtherOrgsOpetussuunnitelmat(ktId: string, koulutustyypit?: Array<string>, body?: number, options?: any): AxiosPromise<Array<OpetussuunnitelmaDto>> {
            return OpetussuunnitelmatApiFp(configuration).getAllOtherOrgsOpetussuunnitelmat(ktId, koulutustyypit, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKaikkiOpetussuunnitelmat(ktId: string, body?: number, options?: any): AxiosPromise<PageOpetussuunnitelmaDto> {
            return OpetussuunnitelmatApiFp(configuration).getKaikkiOpetussuunnitelmat(ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} ktId 
         * @param {Array<string>} [koulutustyypit] 
         * @param {'POHJA' | 'OPS' | 'OPSPOHJA' | 'YLEINEN' | 'YHTEINEN'} [tyyppi] 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutustoimijaOpetussuunnitelmat(ktId: string, koulutustyypit?: Array<string>, tyyppi?: 'POHJA' | 'OPS' | 'OPSPOHJA' | 'YLEINEN' | 'YHTEINEN', body?: number, options?: any): AxiosPromise<Array<OpetussuunnitelmaDto>> {
            return OpetussuunnitelmatApiFp(configuration).getKoulutustoimijaOpetussuunnitelmat(ktId, koulutustyypit, tyyppi, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelma(opsId: number, ktId: string, body?: number, options?: any): AxiosPromise<OpetussuunnitelmaDto> {
            return OpetussuunnitelmatApiFp(configuration).getOpetussuunnitelma(opsId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaKevyt(opsId: number, ktId: string, body?: number, options?: any): AxiosPromise<OpetussuunnitelmaBaseDto> {
            return OpetussuunnitelmatApiFp(configuration).getOpetussuunnitelmaKevyt(opsId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaNavigation(opsId: number, ktId: string, body?: number, options?: any): AxiosPromise<NavigationNodeDto> {
            return OpetussuunnitelmatApiFp(configuration).getOpetussuunnitelmaNavigation(opsId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaNavigationJulkinen(opsId: number, ktId: string, body?: number, options?: any): AxiosPromise<NavigationNodeDto> {
            return OpetussuunnitelmatApiFp(configuration).getOpetussuunnitelmaNavigationJulkinen(opsId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {boolean} [esikatselu] 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaNavigationPublic(opsId: number, ktId: string, esikatselu?: boolean, body?: number, options?: any): AxiosPromise<NavigationNodeDto> {
            return OpetussuunnitelmatApiFp(configuration).getOpetussuunnitelmaNavigationPublic(opsId, ktId, esikatselu, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaOikeudet(opsId: number, ktId: string, body?: number, options?: any): AxiosPromise<Array<KayttajaoikeusDto>> {
            return OpetussuunnitelmatApiFp(configuration).getOpetussuunnitelmaOikeudet(opsId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaPeruste(opsId: number, ktId: string, body?: number, options?: any): AxiosPromise<object> {
            return OpetussuunnitelmatApiFp(configuration).getOpetussuunnitelmaPeruste(opsId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaPohjaKevyt(opsId: number, ktId: string, body?: number, options?: any): AxiosPromise<OpetussuunnitelmaBaseDto> {
            return OpetussuunnitelmatApiFp(configuration).getOpetussuunnitelmaPohjaKevyt(opsId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} revId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaRevision(opsId: number, revId: number, ktId: string, body?: number, options?: any): AxiosPromise<object> {
            return OpetussuunnitelmatApiFp(configuration).getOpetussuunnitelmaRevision(opsId, revId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaRevisions(opsId: number, ktId: string, body?: number, options?: any): AxiosPromise<Array<Revision>> {
            return OpetussuunnitelmatApiFp(configuration).getOpetussuunnitelmaRevisions(opsId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {Array<string>} koulutustyypit 
         * @param {string} ktId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaTilastot(koulutustyypit: Array<string>, ktId: string, options?: any): AxiosPromise<Array<OpetussuunnitelmaTilastoDto>> {
            return OpetussuunnitelmatApiFp(configuration).getOpetussuunnitelmaTilastot(koulutustyypit, ktId, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} organisaatioid 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmatOrganisaatioista(organisaatioid: string, ktId: string, body?: number, options?: any): AxiosPromise<Array<OpetussuunnitelmaDto>> {
            return OpetussuunnitelmatApiFp(configuration).getOpetussuunnitelmatOrganisaatioista(organisaatioid, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {Array<string>} koulutustyypit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOphOpsPohjat(koulutustyypit: Array<string>, options?: any): AxiosPromise<Array<OpetussuunnitelmaBaseDto>> {
            return OpetussuunnitelmatApiFp(configuration).getOphOpsPohjat(koulutustyypit, options)(axios, basePath);
        },
        /**
         * 
         * @param {Array<string>} koulutustyypit 
         * @param {Array<string>} tilat 
         * @param {string} tyyppi 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpsPohjat(koulutustyypit: Array<string>, tilat: Array<string>, tyyppi: string, ktId: string, body?: number, options?: any): AxiosPromise<Array<OpetussuunnitelmaBaseDto>> {
            return OpetussuunnitelmatApiFp(configuration).getOpsPohjat(koulutustyypit, tilat, tyyppi, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaivitettavaOpetussuunnitelma(opsId: number, ktId: string, body?: number, options?: any): AxiosPromise<VanhentunutPohjaperusteDto> {
            return OpetussuunnitelmatApiFp(configuration).getPaivitettavaOpetussuunnitelma(opsId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaivitettavatOpetussuunnitelmat(ktId: string, body?: number, options?: any): AxiosPromise<Array<VanhentunutPohjaperusteDto>> {
            return OpetussuunnitelmatApiFp(configuration).getPaivitettavatOpetussuunnitelmat(ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteRakenneNakyvat(opsId: number, ktId: string, body?: number, options?: any): AxiosPromise<Array<RakenneModuuliTunnisteDto>> {
            return OpetussuunnitelmatApiFp(configuration).getPerusteRakenneNakyvat(opsId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPohjat(options?: any): AxiosPromise<Array<OpetussuunnitelmaBaseDto>> {
            return OpetussuunnitelmatApiFp(configuration).getPohjat(options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoistetutSisaltoviitteet(opsId: number, ktId: string, body?: number, options?: any): AxiosPromise<Array<PoistettuDto>> {
            return OpetussuunnitelmatApiFp(configuration).getPoistetutSisaltoviitteet(opsId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paivitaOpetussuunnitelmanPeruste(opsId: number, ktId: string, body?: number, options?: any): AxiosPromise<void> {
            return OpetussuunnitelmatApiFp(configuration).paivitaOpetussuunnitelmanPeruste(opsId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} poistettuId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        palautaOpetussuunnitelmaSisaltoviite(opsId: number, poistettuId: number, ktId: string, body?: number, options?: any): AxiosPromise<SisaltoViiteDto> {
            return OpetussuunnitelmatApiFp(configuration).palautaOpetussuunnitelmaSisaltoviite(opsId, poistettuId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} revId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertOpetussuunnitelmaToRevision(opsId: number, revId: number, ktId: string, body?: number, options?: any): AxiosPromise<object> {
            return OpetussuunnitelmatApiFp(configuration).revertOpetussuunnitelmaToRevision(opsId, revId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {OpetussuunnitelmaDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOpetussuunnitelma(opsId: number, ktId: string, body?: OpetussuunnitelmaDto, options?: any): AxiosPromise<OpetussuunnitelmaDto> {
            return OpetussuunnitelmatApiFp(configuration).updateOpetussuunnitelma(opsId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {KoulutustoimijaBaseDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOpetussuunnitelmaKoulutustoimija(opsId: number, ktId: string, body?: KoulutustoimijaBaseDto, options?: any): AxiosPromise<OpetussuunnitelmaDto> {
            return OpetussuunnitelmatApiFp(configuration).updateOpetussuunnitelmaKoulutustoimija(opsId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOpetussuunnitelmaKoulutustoimijaPassivoidusta(opsId: number, ktId: string, body?: number, options?: any): AxiosPromise<OpetussuunnitelmaDto> {
            return OpetussuunnitelmatApiFp(configuration).updateOpetussuunnitelmaKoulutustoimijaPassivoidusta(opsId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} kayttajaId 
         * @param {string} ktId 
         * @param {KayttajaoikeusDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOpetussuunnitelmaOikeus(opsId: number, kayttajaId: number, ktId: string, body?: KayttajaoikeusDto, options?: any): AxiosPromise<KayttajaoikeusDto> {
            return OpetussuunnitelmatApiFp(configuration).updateOpetussuunnitelmaOikeus(opsId, kayttajaId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {'LUONNOS' | 'VALMIS' | 'POISTETTU' | 'JULKAISTU'} tila 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOpetussuunnitelmaTila(opsId: number, tila: 'LUONNOS' | 'VALMIS' | 'POISTETTU' | 'JULKAISTU', ktId: string, body?: number, options?: any): AxiosPromise<OpetussuunnitelmaBaseDto> {
            return OpetussuunnitelmatApiFp(configuration).updateOpetussuunnitelmaTila(opsId, tila, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validoiOpetussuunnitelma(opsId: number, ktId: string, body?: number, options?: any): AxiosPromise<Array<Validointi>> {
            return OpetussuunnitelmatApiFp(configuration).validoiOpetussuunnitelma(opsId, ktId, body, options)(axios, basePath);
        },
    };
};

/**
 * OpetussuunnitelmatApi - object-oriented interface
 * @export
 * @class OpetussuunnitelmatApi
 * @extends {BaseAPI}
 */
export class OpetussuunnitelmatApi extends BaseAPI {
    /**
     * 
     * @param {string} ktId 
     * @param {OpetussuunnitelmaLuontiDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public addOpetussuunnitelma(ktId: string, body?: OpetussuunnitelmaLuontiDto, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).addOpetussuunnitelma(ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getAllOpetussuunnitelmatBaseSivutettu(ktId: string, body?: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getAllOpetussuunnitelmatBaseSivutettu(ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getAllOpetussuunnitelmatSivutettu(ktId: string, body?: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getAllOpetussuunnitelmatSivutettu(ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} ktId 
     * @param {Array<string>} [koulutustyypit] 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getAllOtherOrgsOpetussuunnitelmat(ktId: string, koulutustyypit?: Array<string>, body?: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getAllOtherOrgsOpetussuunnitelmat(ktId, koulutustyypit, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getKaikkiOpetussuunnitelmat(ktId: string, body?: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getKaikkiOpetussuunnitelmat(ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} ktId 
     * @param {Array<string>} [koulutustyypit] 
     * @param {'POHJA' | 'OPS' | 'OPSPOHJA' | 'YLEINEN' | 'YHTEINEN'} [tyyppi] 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getKoulutustoimijaOpetussuunnitelmat(ktId: string, koulutustyypit?: Array<string>, tyyppi?: 'POHJA' | 'OPS' | 'OPSPOHJA' | 'YLEINEN' | 'YHTEINEN', body?: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getKoulutustoimijaOpetussuunnitelmat(ktId, koulutustyypit, tyyppi, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getOpetussuunnitelma(opsId: number, ktId: string, body?: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getOpetussuunnitelma(opsId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getOpetussuunnitelmaKevyt(opsId: number, ktId: string, body?: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getOpetussuunnitelmaKevyt(opsId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getOpetussuunnitelmaNavigation(opsId: number, ktId: string, body?: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getOpetussuunnitelmaNavigation(opsId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getOpetussuunnitelmaNavigationJulkinen(opsId: number, ktId: string, body?: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getOpetussuunnitelmaNavigationJulkinen(opsId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} ktId 
     * @param {boolean} [esikatselu] 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getOpetussuunnitelmaNavigationPublic(opsId: number, ktId: string, esikatselu?: boolean, body?: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getOpetussuunnitelmaNavigationPublic(opsId, ktId, esikatselu, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getOpetussuunnitelmaOikeudet(opsId: number, ktId: string, body?: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getOpetussuunnitelmaOikeudet(opsId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getOpetussuunnitelmaPeruste(opsId: number, ktId: string, body?: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getOpetussuunnitelmaPeruste(opsId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getOpetussuunnitelmaPohjaKevyt(opsId: number, ktId: string, body?: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getOpetussuunnitelmaPohjaKevyt(opsId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} revId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getOpetussuunnitelmaRevision(opsId: number, revId: number, ktId: string, body?: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getOpetussuunnitelmaRevision(opsId, revId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getOpetussuunnitelmaRevisions(opsId: number, ktId: string, body?: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getOpetussuunnitelmaRevisions(opsId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {Array<string>} koulutustyypit 
     * @param {string} ktId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getOpetussuunnitelmaTilastot(koulutustyypit: Array<string>, ktId: string, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getOpetussuunnitelmaTilastot(koulutustyypit, ktId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} organisaatioid 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getOpetussuunnitelmatOrganisaatioista(organisaatioid: string, ktId: string, body?: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getOpetussuunnitelmatOrganisaatioista(organisaatioid, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {Array<string>} koulutustyypit 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getOphOpsPohjat(koulutustyypit: Array<string>, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getOphOpsPohjat(koulutustyypit, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {Array<string>} koulutustyypit 
     * @param {Array<string>} tilat 
     * @param {string} tyyppi 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getOpsPohjat(koulutustyypit: Array<string>, tilat: Array<string>, tyyppi: string, ktId: string, body?: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getOpsPohjat(koulutustyypit, tilat, tyyppi, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getPaivitettavaOpetussuunnitelma(opsId: number, ktId: string, body?: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getPaivitettavaOpetussuunnitelma(opsId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getPaivitettavatOpetussuunnitelmat(ktId: string, body?: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getPaivitettavatOpetussuunnitelmat(ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getPerusteRakenneNakyvat(opsId: number, ktId: string, body?: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getPerusteRakenneNakyvat(opsId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getPohjat(options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getPohjat(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getPoistetutSisaltoviitteet(opsId: number, ktId: string, body?: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getPoistetutSisaltoviitteet(opsId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public paivitaOpetussuunnitelmanPeruste(opsId: number, ktId: string, body?: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).paivitaOpetussuunnitelmanPeruste(opsId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} poistettuId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public palautaOpetussuunnitelmaSisaltoviite(opsId: number, poistettuId: number, ktId: string, body?: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).palautaOpetussuunnitelmaSisaltoviite(opsId, poistettuId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} revId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public revertOpetussuunnitelmaToRevision(opsId: number, revId: number, ktId: string, body?: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).revertOpetussuunnitelmaToRevision(opsId, revId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} ktId 
     * @param {OpetussuunnitelmaDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public updateOpetussuunnitelma(opsId: number, ktId: string, body?: OpetussuunnitelmaDto, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).updateOpetussuunnitelma(opsId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} ktId 
     * @param {KoulutustoimijaBaseDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public updateOpetussuunnitelmaKoulutustoimija(opsId: number, ktId: string, body?: KoulutustoimijaBaseDto, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).updateOpetussuunnitelmaKoulutustoimija(opsId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public updateOpetussuunnitelmaKoulutustoimijaPassivoidusta(opsId: number, ktId: string, body?: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).updateOpetussuunnitelmaKoulutustoimijaPassivoidusta(opsId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} kayttajaId 
     * @param {string} ktId 
     * @param {KayttajaoikeusDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public updateOpetussuunnitelmaOikeus(opsId: number, kayttajaId: number, ktId: string, body?: KayttajaoikeusDto, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).updateOpetussuunnitelmaOikeus(opsId, kayttajaId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {'LUONNOS' | 'VALMIS' | 'POISTETTU' | 'JULKAISTU'} tila 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public updateOpetussuunnitelmaTila(opsId: number, tila: 'LUONNOS' | 'VALMIS' | 'POISTETTU' | 'JULKAISTU', ktId: string, body?: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).updateOpetussuunnitelmaTila(opsId, tila, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public validoiOpetussuunnitelma(opsId: number, ktId: string, body?: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).validoiOpetussuunnitelma(opsId, ktId, body, options)(this.axios, this.basePath);
    }

}


/**
 * OsaAlueApi - axios parameter creator
 * @export
 */
export const OsaAlueApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        haeOletusOsaAlueToteutukset(opsId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling haeOletusOsaAlueToteutukset.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling haeOletusOsaAlueToteutukset.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/osaalue/oletustoteutukset`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OsaAlueApi - functional programming interface
 * @export
 */
export const OsaAlueApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        haeOletusOsaAlueToteutukset(opsId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OletusToteutusDto>> {
            const localVarAxiosArgs = OsaAlueApiAxiosParamCreator(configuration).haeOletusOsaAlueToteutukset(opsId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OsaAlueApi - factory interface
 * @export
 */
export const OsaAlueApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        haeOletusOsaAlueToteutukset(opsId: number, ktId: string, body?: number, options?: any): AxiosPromise<Array<OletusToteutusDto>> {
            return OsaAlueApiFp(configuration).haeOletusOsaAlueToteutukset(opsId, ktId, body, options)(axios, basePath);
        },
    };
};

/**
 * OsaAlueApi - object-oriented interface
 * @export
 * @class OsaAlueApi
 * @extends {BaseAPI}
 */
export class OsaAlueApi extends BaseAPI {
    /**
     * 
     * @param {number} opsId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsaAlueApi
     */
    public haeOletusOsaAlueToteutukset(opsId: number, ktId: string, body?: number, options?: any) {
        return OsaAlueApiFp(this.configuration).haeOletusOsaAlueToteutukset(opsId, ktId, body, options)(this.axios, this.basePath);
    }

}


/**
 * PerusteetApi - axios parameter creator
 * @export
 */
export const PerusteetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opetussuunnitelmaId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutuskoodillaKorvaavaPeruste(opetussuunnitelmaId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opetussuunnitelmaId' is not null or undefined
            if (opetussuunnitelmaId === null || opetussuunnitelmaId === undefined) {
                throw new RequiredError('opetussuunnitelmaId','Required parameter opetussuunnitelmaId was null or undefined when calling getKoulutuskoodillaKorvaavaPeruste.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getKoulutuskoodillaKorvaavaPeruste.');
            }
            const localVarPath = `/api/perusteet/koulutustoimija/{ktId}/opetussuunnitelma/{opetussuunnitelmaId}/koulutuskoodillakorvaava`
                .replace(`{${"opetussuunnitelmaId"}}`, encodeURIComponent(String(opetussuunnitelmaId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} diaarinumero 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmat(diaarinumero: string, options: any = {}): RequestArgs {
            // verify required parameter 'diaarinumero' is not null or undefined
            if (diaarinumero === null || diaarinumero === undefined) {
                throw new RequiredError('diaarinumero','Required parameter diaarinumero was null or undefined when calling getOpetussuunnitelmat.');
            }
            const localVarPath = `/api/perusteet/opetussuunnitelmat`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (diaarinumero !== undefined) {
                localVarQueryParameter['diaarinumero'] = diaarinumero;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeruste(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPeruste.');
            }
            const localVarPath = `/api/perusteet/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteAll(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPerusteAll.');
            }
            const localVarPath = `/api/perusteet/{id}/kaikki`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteByPerusteId(perusteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getPerusteByPerusteId.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusteesta`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} st 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteRakenne(id: number, st: string, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPerusteRakenne.');
            }
            // verify required parameter 'st' is not null or undefined
            if (st === null || st === undefined) {
                throw new RequiredError('st','Required parameter st was null or undefined when calling getPerusteRakenne.');
            }
            const localVarPath = `/api/perusteet/{id}/suoritustavat/{st}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"st"}}`, encodeURIComponent(String(st)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteRakenteet(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPerusteRakenteet.');
            }
            const localVarPath = `/api/perusteet/{id}/suoritustavat`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} tosaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteTutkinnonOsa(id: number, tosaId: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPerusteTutkinnonOsa.');
            }
            // verify required parameter 'tosaId' is not null or undefined
            if (tosaId === null || tosaId === undefined) {
                throw new RequiredError('tosaId','Required parameter tosaId was null or undefined when calling getPerusteTutkinnonOsa.');
            }
            const localVarPath = `/api/perusteet/{id}/tutkinnonosat/{tosaId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tosaId"}}`, encodeURIComponent(String(tosaId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteTutkinnonOsat(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPerusteTutkinnonOsat.');
            }
            const localVarPath = `/api/perusteet/{id}/tutkinnonosat`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteTutkinnonOsatKevyt(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPerusteTutkinnonOsatKevyt.');
            }
            const localVarPath = `/api/perusteet/{id}/suorituspolkuosat`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} perusteenosaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenOsa(perusteId: number, perusteenosaId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getPerusteenOsa.');
            }
            // verify required parameter 'perusteenosaId' is not null or undefined
            if (perusteenosaId === null || perusteenosaId === undefined) {
                throw new RequiredError('perusteenosaId','Required parameter perusteenosaId was null or undefined when calling getPerusteenOsa.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusteenosa/{perusteenosaId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"perusteenosaId"}}`, encodeURIComponent(String(perusteenosaId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteet(options: any = {}): RequestArgs {
            const localVarPath = `/api/perusteet`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} st 
         * @param {number} tosaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsaViite(id: number, st: string, tosaId: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getTutkinnonOsaViite.');
            }
            // verify required parameter 'st' is not null or undefined
            if (st === null || st === undefined) {
                throw new RequiredError('st','Required parameter st was null or undefined when calling getTutkinnonOsaViite.');
            }
            // verify required parameter 'tosaId' is not null or undefined
            if (tosaId === null || tosaId === undefined) {
                throw new RequiredError('tosaId','Required parameter tosaId was null or undefined when calling getTutkinnonOsaViite.');
            }
            const localVarPath = `/api/perusteet/{id}/suoritustavat/{st}/tutkinnonosat/{tosaId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"st"}}`, encodeURIComponent(String(st)))
                .replace(`{${"tosaId"}}`, encodeURIComponent(String(tosaId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} st 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsaViitteet(id: number, st: string, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getTutkinnonOsaViitteet.');
            }
            // verify required parameter 'st' is not null or undefined
            if (st === null || st === undefined) {
                throw new RequiredError('st','Required parameter st was null or undefined when calling getTutkinnonOsaViitteet.');
            }
            const localVarPath = `/api/perusteet/{id}/suoritustavat/{st}/tutkinnonosat`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"st"}}`, encodeURIComponent(String(st)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} params 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        haePerusteista(params: object, options: any = {}): RequestArgs {
            // verify required parameter 'params' is not null or undefined
            if (params === null || params === undefined) {
                throw new RequiredError('params','Required parameter params was null or undefined when calling haePerusteista.');
            }
            const localVarPath = `/api/perusteet/haku`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (params !== undefined) {
                localVarQueryParameter['params'] = params;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PerusteetApi - functional programming interface
 * @export
 */
export const PerusteetApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opetussuunnitelmaId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutuskoodillaKorvaavaPeruste(opetussuunnitelmaId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerusteDto> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getKoulutuskoodillaKorvaavaPeruste(opetussuunnitelmaId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} diaarinumero 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmat(diaarinumero: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpetussuunnitelmaBaseDto>> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getOpetussuunnitelmat(diaarinumero, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeruste(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerusteDto> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getPeruste(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteAll(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getPerusteAll(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteByPerusteId(perusteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getPerusteByPerusteId(perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} st 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteRakenne(id: number, st: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getPerusteRakenne(id, st, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteRakenteet(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getPerusteRakenteet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} tosaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteTutkinnonOsa(id: number, tosaId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getPerusteTutkinnonOsa(id, tosaId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteTutkinnonOsat(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getPerusteTutkinnonOsat(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteTutkinnonOsatKevyt(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TutkinnonOsaSuoritustapaDto>> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getPerusteTutkinnonOsatKevyt(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} perusteenosaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenOsa(perusteId: number, perusteenosaId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerusteenOsaDto> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getPerusteenOsa(perusteId, perusteenosaId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PerusteDto>> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getPerusteet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} st 
         * @param {number} tosaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsaViite(id: number, st: string, tosaId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getTutkinnonOsaViite(id, st, tosaId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} st 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsaViitteet(id: number, st: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getTutkinnonOsaViitteet(id, st, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {object} params 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        haePerusteista(params: object, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).haePerusteista(params, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PerusteetApi - factory interface
 * @export
 */
export const PerusteetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} opetussuunnitelmaId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutuskoodillaKorvaavaPeruste(opetussuunnitelmaId: number, ktId: string, body?: number, options?: any): AxiosPromise<PerusteDto> {
            return PerusteetApiFp(configuration).getKoulutuskoodillaKorvaavaPeruste(opetussuunnitelmaId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} diaarinumero 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmat(diaarinumero: string, options?: any): AxiosPromise<Array<OpetussuunnitelmaBaseDto>> {
            return PerusteetApiFp(configuration).getOpetussuunnitelmat(diaarinumero, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeruste(id: number, options?: any): AxiosPromise<PerusteDto> {
            return PerusteetApiFp(configuration).getPeruste(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteAll(id: number, options?: any): AxiosPromise<object> {
            return PerusteetApiFp(configuration).getPerusteAll(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteByPerusteId(perusteId: number, options?: any): AxiosPromise<object> {
            return PerusteetApiFp(configuration).getPerusteByPerusteId(perusteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} st 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteRakenne(id: number, st: string, options?: any): AxiosPromise<object> {
            return PerusteetApiFp(configuration).getPerusteRakenne(id, st, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteRakenteet(id: number, options?: any): AxiosPromise<object> {
            return PerusteetApiFp(configuration).getPerusteRakenteet(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} tosaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteTutkinnonOsa(id: number, tosaId: number, options?: any): AxiosPromise<object> {
            return PerusteetApiFp(configuration).getPerusteTutkinnonOsa(id, tosaId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteTutkinnonOsat(id: number, options?: any): AxiosPromise<object> {
            return PerusteetApiFp(configuration).getPerusteTutkinnonOsat(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteTutkinnonOsatKevyt(id: number, options?: any): AxiosPromise<Array<TutkinnonOsaSuoritustapaDto>> {
            return PerusteetApiFp(configuration).getPerusteTutkinnonOsatKevyt(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} perusteenosaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenOsa(perusteId: number, perusteenosaId: number, options?: any): AxiosPromise<PerusteenOsaDto> {
            return PerusteetApiFp(configuration).getPerusteenOsa(perusteId, perusteenosaId, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteet(options?: any): AxiosPromise<Array<PerusteDto>> {
            return PerusteetApiFp(configuration).getPerusteet(options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} st 
         * @param {number} tosaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsaViite(id: number, st: string, tosaId: number, options?: any): AxiosPromise<object> {
            return PerusteetApiFp(configuration).getTutkinnonOsaViite(id, st, tosaId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} st 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsaViitteet(id: number, st: string, options?: any): AxiosPromise<object> {
            return PerusteetApiFp(configuration).getTutkinnonOsaViitteet(id, st, options)(axios, basePath);
        },
        /**
         * 
         * @param {object} params 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        haePerusteista(params: object, options?: any): AxiosPromise<object> {
            return PerusteetApiFp(configuration).haePerusteista(params, options)(axios, basePath);
        },
    };
};

/**
 * PerusteetApi - object-oriented interface
 * @export
 * @class PerusteetApi
 * @extends {BaseAPI}
 */
export class PerusteetApi extends BaseAPI {
    /**
     * 
     * @param {number} opetussuunnitelmaId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getKoulutuskoodillaKorvaavaPeruste(opetussuunnitelmaId: number, ktId: string, body?: number, options?: any) {
        return PerusteetApiFp(this.configuration).getKoulutuskoodillaKorvaavaPeruste(opetussuunnitelmaId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} diaarinumero 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getOpetussuunnitelmat(diaarinumero: string, options?: any) {
        return PerusteetApiFp(this.configuration).getOpetussuunnitelmat(diaarinumero, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getPeruste(id: number, options?: any) {
        return PerusteetApiFp(this.configuration).getPeruste(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getPerusteAll(id: number, options?: any) {
        return PerusteetApiFp(this.configuration).getPerusteAll(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getPerusteByPerusteId(perusteId: number, options?: any) {
        return PerusteetApiFp(this.configuration).getPerusteByPerusteId(perusteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {string} st 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getPerusteRakenne(id: number, st: string, options?: any) {
        return PerusteetApiFp(this.configuration).getPerusteRakenne(id, st, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getPerusteRakenteet(id: number, options?: any) {
        return PerusteetApiFp(this.configuration).getPerusteRakenteet(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {number} tosaId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getPerusteTutkinnonOsa(id: number, tosaId: number, options?: any) {
        return PerusteetApiFp(this.configuration).getPerusteTutkinnonOsa(id, tosaId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getPerusteTutkinnonOsat(id: number, options?: any) {
        return PerusteetApiFp(this.configuration).getPerusteTutkinnonOsat(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getPerusteTutkinnonOsatKevyt(id: number, options?: any) {
        return PerusteetApiFp(this.configuration).getPerusteTutkinnonOsatKevyt(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} perusteenosaId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getPerusteenOsa(perusteId: number, perusteenosaId: number, options?: any) {
        return PerusteetApiFp(this.configuration).getPerusteenOsa(perusteId, perusteenosaId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getPerusteet(options?: any) {
        return PerusteetApiFp(this.configuration).getPerusteet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {string} st 
     * @param {number} tosaId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getTutkinnonOsaViite(id: number, st: string, tosaId: number, options?: any) {
        return PerusteetApiFp(this.configuration).getTutkinnonOsaViite(id, st, tosaId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {string} st 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getTutkinnonOsaViitteet(id: number, st: string, options?: any) {
        return PerusteetApiFp(this.configuration).getTutkinnonOsaViitteet(id, st, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {object} params 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public haePerusteista(params: object, options?: any) {
        return PerusteetApiFp(this.configuration).haePerusteista(params, options)(this.axios, this.basePath);
    }

}


/**
 * SisaltoviiteLukkoApi - axios parameter creator
 * @export
 */
export const SisaltoviiteLukkoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} ktId 
         * @param {number} opsId 
         * @param {number} svId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkLock(ktId: number, opsId: number, svId: number, options: any = {}): RequestArgs {
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling checkLock.');
            }
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling checkLock.');
            }
            // verify required parameter 'svId' is not null or undefined
            if (svId === null || svId === undefined) {
                throw new RequiredError('svId','Required parameter svId was null or undefined when calling checkLock.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/tekstit/{svId}/lukko`
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)))
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"svId"}}`, encodeURIComponent(String(svId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} ktId 
         * @param {number} opsId 
         * @param {number} svId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLock(ktId: number, opsId: number, svId: number, options: any = {}): RequestArgs {
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getLock.');
            }
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getLock.');
            }
            // verify required parameter 'svId' is not null or undefined
            if (svId === null || svId === undefined) {
                throw new RequiredError('svId','Required parameter svId was null or undefined when calling getLock.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/tekstit/{svId}/lukko/check`
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)))
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"svId"}}`, encodeURIComponent(String(svId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} ktId 
         * @param {number} opsId 
         * @param {number} svId 
         * @param {string} [If_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lock(ktId: number, opsId: number, svId: number, If_Match?: string, options: any = {}): RequestArgs {
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling lock.');
            }
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling lock.');
            }
            // verify required parameter 'svId' is not null or undefined
            if (svId === null || svId === undefined) {
                throw new RequiredError('svId','Required parameter svId was null or undefined when calling lock.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/tekstit/{svId}/lukko`
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)))
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"svId"}}`, encodeURIComponent(String(svId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (If_Match !== undefined && If_Match !== null) {
                localVarHeaderParameter['If-Match'] = String(If_Match);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} ktId 
         * @param {number} opsId 
         * @param {number} svId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlock(ktId: number, opsId: number, svId: number, options: any = {}): RequestArgs {
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling unlock.');
            }
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling unlock.');
            }
            // verify required parameter 'svId' is not null or undefined
            if (svId === null || svId === undefined) {
                throw new RequiredError('svId','Required parameter svId was null or undefined when calling unlock.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/tekstit/{svId}/lukko`
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)))
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"svId"}}`, encodeURIComponent(String(svId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SisaltoviiteLukkoApi - functional programming interface
 * @export
 */
export const SisaltoviiteLukkoApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} ktId 
         * @param {number} opsId 
         * @param {number} svId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkLock(ktId: number, opsId: number, svId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LukkoDto> {
            const localVarAxiosArgs = SisaltoviiteLukkoApiAxiosParamCreator(configuration).checkLock(ktId, opsId, svId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} ktId 
         * @param {number} opsId 
         * @param {number} svId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLock(ktId: number, opsId: number, svId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LukkoDto> {
            const localVarAxiosArgs = SisaltoviiteLukkoApiAxiosParamCreator(configuration).getLock(ktId, opsId, svId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} ktId 
         * @param {number} opsId 
         * @param {number} svId 
         * @param {string} [If_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lock(ktId: number, opsId: number, svId: number, If_Match?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LukkoDto> {
            const localVarAxiosArgs = SisaltoviiteLukkoApiAxiosParamCreator(configuration).lock(ktId, opsId, svId, If_Match, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} ktId 
         * @param {number} opsId 
         * @param {number} svId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlock(ktId: number, opsId: number, svId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SisaltoviiteLukkoApiAxiosParamCreator(configuration).unlock(ktId, opsId, svId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SisaltoviiteLukkoApi - factory interface
 * @export
 */
export const SisaltoviiteLukkoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} ktId 
         * @param {number} opsId 
         * @param {number} svId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkLock(ktId: number, opsId: number, svId: number, options?: any): AxiosPromise<LukkoDto> {
            return SisaltoviiteLukkoApiFp(configuration).checkLock(ktId, opsId, svId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} ktId 
         * @param {number} opsId 
         * @param {number} svId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLock(ktId: number, opsId: number, svId: number, options?: any): AxiosPromise<LukkoDto> {
            return SisaltoviiteLukkoApiFp(configuration).getLock(ktId, opsId, svId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} ktId 
         * @param {number} opsId 
         * @param {number} svId 
         * @param {string} [If_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lock(ktId: number, opsId: number, svId: number, If_Match?: string, options?: any): AxiosPromise<LukkoDto> {
            return SisaltoviiteLukkoApiFp(configuration).lock(ktId, opsId, svId, If_Match, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} ktId 
         * @param {number} opsId 
         * @param {number} svId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlock(ktId: number, opsId: number, svId: number, options?: any): AxiosPromise<void> {
            return SisaltoviiteLukkoApiFp(configuration).unlock(ktId, opsId, svId, options)(axios, basePath);
        },
    };
};

/**
 * SisaltoviiteLukkoApi - object-oriented interface
 * @export
 * @class SisaltoviiteLukkoApi
 * @extends {BaseAPI}
 */
export class SisaltoviiteLukkoApi extends BaseAPI {
    /**
     * 
     * @param {number} ktId 
     * @param {number} opsId 
     * @param {number} svId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SisaltoviiteLukkoApi
     */
    public checkLock(ktId: number, opsId: number, svId: number, options?: any) {
        return SisaltoviiteLukkoApiFp(this.configuration).checkLock(ktId, opsId, svId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} ktId 
     * @param {number} opsId 
     * @param {number} svId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SisaltoviiteLukkoApi
     */
    public getLock(ktId: number, opsId: number, svId: number, options?: any) {
        return SisaltoviiteLukkoApiFp(this.configuration).getLock(ktId, opsId, svId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} ktId 
     * @param {number} opsId 
     * @param {number} svId 
     * @param {string} [If_Match] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SisaltoviiteLukkoApi
     */
    public lock(ktId: number, opsId: number, svId: number, If_Match?: string, options?: any) {
        return SisaltoviiteLukkoApiFp(this.configuration).lock(ktId, opsId, svId, If_Match, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} ktId 
     * @param {number} opsId 
     * @param {number} svId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SisaltoviiteLukkoApi
     */
    public unlock(ktId: number, opsId: number, svId: number, options?: any) {
        return SisaltoviiteLukkoApiFp(this.configuration).unlock(ktId, opsId, svId, options)(this.axios, this.basePath);
    }

}


/**
 * SisaltoviitteetApi - axios parameter creator
 * @export
 */
export const SisaltoviitteetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {number} svId 
         * @param {string} ktId 
         * @param {Matala} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTekstiKappaleLapsi(opsId: number, svId: number, ktId: string, body?: Matala, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling addTekstiKappaleLapsi.');
            }
            // verify required parameter 'svId' is not null or undefined
            if (svId === null || svId === undefined) {
                throw new RequiredError('svId','Required parameter svId was null or undefined when calling addTekstiKappaleLapsi.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling addTekstiKappaleLapsi.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/tekstit/{svId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"svId"}}`, encodeURIComponent(String(svId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {Array<number>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyMultipleSisaltoviite(opsId: number, ktId: string, body?: Array<number>, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling copyMultipleSisaltoviite.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling copyMultipleSisaltoviite.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/lisaa`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} tyyppi 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmanPohjanSisaltoviitteet(opsId: number, tyyppi: string, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getOpetussuunnitelmanPohjanSisaltoviitteet.');
            }
            // verify required parameter 'tyyppi' is not null or undefined
            if (tyyppi === null || tyyppi === undefined) {
                throw new RequiredError('tyyppi','Required parameter tyyppi was null or undefined when calling getOpetussuunnitelmanPohjanSisaltoviitteet.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getOpetussuunnitelmanPohjanSisaltoviitteet.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/pohjansisaltoviitteet/{tyyppi}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"tyyppi"}}`, encodeURIComponent(String(tyyppi)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOtsikot(opsId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getOtsikot.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getOtsikot.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/otsikot`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} svId 
         * @param {number} revId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSisaltoviiteRevision(opsId: number, svId: number, revId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getSisaltoviiteRevision.');
            }
            // verify required parameter 'svId' is not null or undefined
            if (svId === null || svId === undefined) {
                throw new RequiredError('svId','Required parameter svId was null or undefined when calling getSisaltoviiteRevision.');
            }
            // verify required parameter 'revId' is not null or undefined
            if (revId === null || revId === undefined) {
                throw new RequiredError('revId','Required parameter revId was null or undefined when calling getSisaltoviiteRevision.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getSisaltoviiteRevision.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/tekstit/{svId}/versiot/{revId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"svId"}}`, encodeURIComponent(String(svId)))
                .replace(`{${"revId"}}`, encodeURIComponent(String(revId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} svId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSisaltoviiteRevisions(opsId: number, svId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getSisaltoviiteRevisions.');
            }
            // verify required parameter 'svId' is not null or undefined
            if (svId === null || svId === undefined) {
                throw new RequiredError('svId','Required parameter svId was null or undefined when calling getSisaltoviiteRevisions.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getSisaltoviiteRevisions.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/tekstit/{svId}/versiot`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"svId"}}`, encodeURIComponent(String(svId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} svId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSisaltoviiteTekstit(opsId: number, svId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getSisaltoviiteTekstit.');
            }
            // verify required parameter 'svId' is not null or undefined
            if (svId === null || svId === undefined) {
                throw new RequiredError('svId','Required parameter svId was null or undefined when calling getSisaltoviiteTekstit.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getSisaltoviiteTekstit.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/tekstit/{svId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"svId"}}`, encodeURIComponent(String(svId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} tyyppi 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSisaltoviitteeTyypilla(opsId: number, tyyppi: string, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getSisaltoviitteeTyypilla.');
            }
            // verify required parameter 'tyyppi' is not null or undefined
            if (tyyppi === null || tyyppi === undefined) {
                throw new RequiredError('tyyppi','Required parameter tyyppi was null or undefined when calling getSisaltoviitteeTyypilla.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getSisaltoviitteeTyypilla.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/sisaltoviitteet/{tyyppi}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"tyyppi"}}`, encodeURIComponent(String(tyyppi)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSisaltoviitteet(opsId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getSisaltoviitteet.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getSisaltoviitteet.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/sisaltoviitteet`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuorituspolut(opsId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getSuorituspolut.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getSuorituspolut.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/suorituspolut`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuorituspolutRakenteella(opsId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getSuorituspolutRakenteella.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getSuorituspolutRakenteella.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/polut`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonosat(opsId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getTutkinnonosat.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getTutkinnonosat.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/tutkinnonosat`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonosatKevyt(opsId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getTutkinnonosatKevyt.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling getTutkinnonosatKevyt.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/tutkinnonosat/kevyt`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kopioiLinkattuSisalto(opsId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling kopioiLinkattuSisalto.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling kopioiLinkattuSisalto.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/kopioiLinkattu`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {Array<number>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkkaaUusiSisalto(opsId: number, ktId: string, body?: Array<number>, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling linkkaaUusiSisalto.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling linkkaaUusiSisalto.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/linkkaa`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} svId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSisaltoViite(opsId: number, svId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling removeSisaltoViite.');
            }
            // verify required parameter 'svId' is not null or undefined
            if (svId === null || svId === undefined) {
                throw new RequiredError('svId','Required parameter svId was null or undefined when calling removeSisaltoViite.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling removeSisaltoViite.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/tekstit/{svId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"svId"}}`, encodeURIComponent(String(svId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {Array<number>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSisaltoViitteet(opsId: number, ktId: string, body?: Array<number>, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling removeSisaltoViitteet.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling removeSisaltoViitteet.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/removeViitteet`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} svId 
         * @param {string} ktId 
         * @param {SisaltoViiteRakenneDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSisaltoViiteRakenne(opsId: number, svId: number, ktId: string, body?: SisaltoViiteRakenneDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling updateSisaltoViiteRakenne.');
            }
            // verify required parameter 'svId' is not null or undefined
            if (svId === null || svId === undefined) {
                throw new RequiredError('svId','Required parameter svId was null or undefined when calling updateSisaltoViiteRakenne.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling updateSisaltoViiteRakenne.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/tekstit/{svId}/rakenne`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"svId"}}`, encodeURIComponent(String(svId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} svId 
         * @param {string} ktId 
         * @param {SisaltoViiteDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTekstiKappaleViite(opsId: number, svId: number, ktId: string, body?: SisaltoViiteDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling updateTekstiKappaleViite.');
            }
            // verify required parameter 'svId' is not null or undefined
            if (svId === null || svId === undefined) {
                throw new RequiredError('svId','Required parameter svId was null or undefined when calling updateTekstiKappaleViite.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling updateTekstiKappaleViite.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/tekstit/{svId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"svId"}}`, encodeURIComponent(String(svId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SisaltoviitteetApi - functional programming interface
 * @export
 */
export const SisaltoviitteetApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {number} svId 
         * @param {string} ktId 
         * @param {Matala} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTekstiKappaleLapsi(opsId: number, svId: number, ktId: string, body?: Matala, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Matala> {
            const localVarAxiosArgs = SisaltoviitteetApiAxiosParamCreator(configuration).addTekstiKappaleLapsi(opsId, svId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {Array<number>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyMultipleSisaltoviite(opsId: number, ktId: string, body?: Array<number>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SisaltoviitteetApiAxiosParamCreator(configuration).copyMultipleSisaltoviite(opsId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} tyyppi 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmanPohjanSisaltoviitteet(opsId: number, tyyppi: string, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SisaltoViiteDto>> {
            const localVarAxiosArgs = SisaltoviitteetApiAxiosParamCreator(configuration).getOpetussuunnitelmanPohjanSisaltoviitteet(opsId, tyyppi, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOtsikot(opsId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SisaltoViiteKevytDto>> {
            const localVarAxiosArgs = SisaltoviitteetApiAxiosParamCreator(configuration).getOtsikot(opsId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} svId 
         * @param {number} revId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSisaltoviiteRevision(opsId: number, svId: number, revId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SisaltoViiteDto> {
            const localVarAxiosArgs = SisaltoviitteetApiAxiosParamCreator(configuration).getSisaltoviiteRevision(opsId, svId, revId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} svId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSisaltoviiteRevisions(opsId: number, svId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RevisionKayttajaDto>> {
            const localVarAxiosArgs = SisaltoviitteetApiAxiosParamCreator(configuration).getSisaltoviiteRevisions(opsId, svId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} svId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSisaltoviiteTekstit(opsId: number, svId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Matala> {
            const localVarAxiosArgs = SisaltoviitteetApiAxiosParamCreator(configuration).getSisaltoviiteTekstit(opsId, svId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} tyyppi 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSisaltoviitteeTyypilla(opsId: number, tyyppi: string, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SisaltoViiteDto>> {
            const localVarAxiosArgs = SisaltoviitteetApiAxiosParamCreator(configuration).getSisaltoviitteeTyypilla(opsId, tyyppi, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSisaltoviitteet(opsId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageSisaltoviiteLaajaDto> {
            const localVarAxiosArgs = SisaltoviitteetApiAxiosParamCreator(configuration).getSisaltoviitteet(opsId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuorituspolut(opsId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SisaltoViiteDto>> {
            const localVarAxiosArgs = SisaltoviitteetApiAxiosParamCreator(configuration).getSuorituspolut(opsId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuorituspolutRakenteella(opsId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SuorituspolkuRakenneDto>> {
            const localVarAxiosArgs = SisaltoviitteetApiAxiosParamCreator(configuration).getSuorituspolutRakenteella(opsId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonosat(opsId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SisaltoViiteDto>> {
            const localVarAxiosArgs = SisaltoviitteetApiAxiosParamCreator(configuration).getTutkinnonosat(opsId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonosatKevyt(opsId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SisaltoViiteTutkinnonosaKevytDto>> {
            const localVarAxiosArgs = SisaltoviitteetApiAxiosParamCreator(configuration).getTutkinnonosatKevyt(opsId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kopioiLinkattuSisalto(opsId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SisaltoViiteDto> {
            const localVarAxiosArgs = SisaltoviitteetApiAxiosParamCreator(configuration).kopioiLinkattuSisalto(opsId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {Array<number>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkkaaUusiSisalto(opsId: number, ktId: string, body?: Array<number>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SisaltoviitteetApiAxiosParamCreator(configuration).linkkaaUusiSisalto(opsId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} svId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSisaltoViite(opsId: number, svId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SisaltoviitteetApiAxiosParamCreator(configuration).removeSisaltoViite(opsId, svId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {Array<number>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSisaltoViitteet(opsId: number, ktId: string, body?: Array<number>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SisaltoviitteetApiAxiosParamCreator(configuration).removeSisaltoViitteet(opsId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} svId 
         * @param {string} ktId 
         * @param {SisaltoViiteRakenneDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSisaltoViiteRakenne(opsId: number, svId: number, ktId: string, body?: SisaltoViiteRakenneDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SisaltoviitteetApiAxiosParamCreator(configuration).updateSisaltoViiteRakenne(opsId, svId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} svId 
         * @param {string} ktId 
         * @param {SisaltoViiteDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTekstiKappaleViite(opsId: number, svId: number, ktId: string, body?: SisaltoViiteDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SisaltoviitteetApiAxiosParamCreator(configuration).updateTekstiKappaleViite(opsId, svId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SisaltoviitteetApi - factory interface
 * @export
 */
export const SisaltoviitteetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {number} svId 
         * @param {string} ktId 
         * @param {Matala} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTekstiKappaleLapsi(opsId: number, svId: number, ktId: string, body?: Matala, options?: any): AxiosPromise<Matala> {
            return SisaltoviitteetApiFp(configuration).addTekstiKappaleLapsi(opsId, svId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {Array<number>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyMultipleSisaltoviite(opsId: number, ktId: string, body?: Array<number>, options?: any): AxiosPromise<void> {
            return SisaltoviitteetApiFp(configuration).copyMultipleSisaltoviite(opsId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} tyyppi 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmanPohjanSisaltoviitteet(opsId: number, tyyppi: string, ktId: string, body?: number, options?: any): AxiosPromise<Array<SisaltoViiteDto>> {
            return SisaltoviitteetApiFp(configuration).getOpetussuunnitelmanPohjanSisaltoviitteet(opsId, tyyppi, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOtsikot(opsId: number, ktId: string, body?: number, options?: any): AxiosPromise<Array<SisaltoViiteKevytDto>> {
            return SisaltoviitteetApiFp(configuration).getOtsikot(opsId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} svId 
         * @param {number} revId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSisaltoviiteRevision(opsId: number, svId: number, revId: number, ktId: string, body?: number, options?: any): AxiosPromise<SisaltoViiteDto> {
            return SisaltoviitteetApiFp(configuration).getSisaltoviiteRevision(opsId, svId, revId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} svId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSisaltoviiteRevisions(opsId: number, svId: number, ktId: string, body?: number, options?: any): AxiosPromise<Array<RevisionKayttajaDto>> {
            return SisaltoviitteetApiFp(configuration).getSisaltoviiteRevisions(opsId, svId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} svId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSisaltoviiteTekstit(opsId: number, svId: number, ktId: string, body?: number, options?: any): AxiosPromise<Matala> {
            return SisaltoviitteetApiFp(configuration).getSisaltoviiteTekstit(opsId, svId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} tyyppi 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSisaltoviitteeTyypilla(opsId: number, tyyppi: string, ktId: string, body?: number, options?: any): AxiosPromise<Array<SisaltoViiteDto>> {
            return SisaltoviitteetApiFp(configuration).getSisaltoviitteeTyypilla(opsId, tyyppi, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSisaltoviitteet(opsId: number, ktId: string, body?: number, options?: any): AxiosPromise<PageSisaltoviiteLaajaDto> {
            return SisaltoviitteetApiFp(configuration).getSisaltoviitteet(opsId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuorituspolut(opsId: number, ktId: string, body?: number, options?: any): AxiosPromise<Array<SisaltoViiteDto>> {
            return SisaltoviitteetApiFp(configuration).getSuorituspolut(opsId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuorituspolutRakenteella(opsId: number, ktId: string, body?: number, options?: any): AxiosPromise<Array<SuorituspolkuRakenneDto>> {
            return SisaltoviitteetApiFp(configuration).getSuorituspolutRakenteella(opsId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonosat(opsId: number, ktId: string, body?: number, options?: any): AxiosPromise<Array<SisaltoViiteDto>> {
            return SisaltoviitteetApiFp(configuration).getTutkinnonosat(opsId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonosatKevyt(opsId: number, ktId: string, body?: number, options?: any): AxiosPromise<Array<SisaltoViiteTutkinnonosaKevytDto>> {
            return SisaltoviitteetApiFp(configuration).getTutkinnonosatKevyt(opsId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kopioiLinkattuSisalto(opsId: number, ktId: string, body?: number, options?: any): AxiosPromise<SisaltoViiteDto> {
            return SisaltoviitteetApiFp(configuration).kopioiLinkattuSisalto(opsId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {Array<number>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkkaaUusiSisalto(opsId: number, ktId: string, body?: Array<number>, options?: any): AxiosPromise<void> {
            return SisaltoviitteetApiFp(configuration).linkkaaUusiSisalto(opsId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} svId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSisaltoViite(opsId: number, svId: number, ktId: string, body?: number, options?: any): AxiosPromise<void> {
            return SisaltoviitteetApiFp(configuration).removeSisaltoViite(opsId, svId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {Array<number>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSisaltoViitteet(opsId: number, ktId: string, body?: Array<number>, options?: any): AxiosPromise<void> {
            return SisaltoviitteetApiFp(configuration).removeSisaltoViitteet(opsId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} svId 
         * @param {string} ktId 
         * @param {SisaltoViiteRakenneDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSisaltoViiteRakenne(opsId: number, svId: number, ktId: string, body?: SisaltoViiteRakenneDto, options?: any): AxiosPromise<void> {
            return SisaltoviitteetApiFp(configuration).updateSisaltoViiteRakenne(opsId, svId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} svId 
         * @param {string} ktId 
         * @param {SisaltoViiteDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTekstiKappaleViite(opsId: number, svId: number, ktId: string, body?: SisaltoViiteDto, options?: any): AxiosPromise<void> {
            return SisaltoviitteetApiFp(configuration).updateTekstiKappaleViite(opsId, svId, ktId, body, options)(axios, basePath);
        },
    };
};

/**
 * SisaltoviitteetApi - object-oriented interface
 * @export
 * @class SisaltoviitteetApi
 * @extends {BaseAPI}
 */
export class SisaltoviitteetApi extends BaseAPI {
    /**
     * 
     * @param {number} opsId 
     * @param {number} svId 
     * @param {string} ktId 
     * @param {Matala} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SisaltoviitteetApi
     */
    public addTekstiKappaleLapsi(opsId: number, svId: number, ktId: string, body?: Matala, options?: any) {
        return SisaltoviitteetApiFp(this.configuration).addTekstiKappaleLapsi(opsId, svId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} ktId 
     * @param {Array<number>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SisaltoviitteetApi
     */
    public copyMultipleSisaltoviite(opsId: number, ktId: string, body?: Array<number>, options?: any) {
        return SisaltoviitteetApiFp(this.configuration).copyMultipleSisaltoviite(opsId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} tyyppi 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SisaltoviitteetApi
     */
    public getOpetussuunnitelmanPohjanSisaltoviitteet(opsId: number, tyyppi: string, ktId: string, body?: number, options?: any) {
        return SisaltoviitteetApiFp(this.configuration).getOpetussuunnitelmanPohjanSisaltoviitteet(opsId, tyyppi, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SisaltoviitteetApi
     */
    public getOtsikot(opsId: number, ktId: string, body?: number, options?: any) {
        return SisaltoviitteetApiFp(this.configuration).getOtsikot(opsId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} svId 
     * @param {number} revId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SisaltoviitteetApi
     */
    public getSisaltoviiteRevision(opsId: number, svId: number, revId: number, ktId: string, body?: number, options?: any) {
        return SisaltoviitteetApiFp(this.configuration).getSisaltoviiteRevision(opsId, svId, revId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} svId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SisaltoviitteetApi
     */
    public getSisaltoviiteRevisions(opsId: number, svId: number, ktId: string, body?: number, options?: any) {
        return SisaltoviitteetApiFp(this.configuration).getSisaltoviiteRevisions(opsId, svId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} svId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SisaltoviitteetApi
     */
    public getSisaltoviiteTekstit(opsId: number, svId: number, ktId: string, body?: number, options?: any) {
        return SisaltoviitteetApiFp(this.configuration).getSisaltoviiteTekstit(opsId, svId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} tyyppi 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SisaltoviitteetApi
     */
    public getSisaltoviitteeTyypilla(opsId: number, tyyppi: string, ktId: string, body?: number, options?: any) {
        return SisaltoviitteetApiFp(this.configuration).getSisaltoviitteeTyypilla(opsId, tyyppi, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SisaltoviitteetApi
     */
    public getSisaltoviitteet(opsId: number, ktId: string, body?: number, options?: any) {
        return SisaltoviitteetApiFp(this.configuration).getSisaltoviitteet(opsId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SisaltoviitteetApi
     */
    public getSuorituspolut(opsId: number, ktId: string, body?: number, options?: any) {
        return SisaltoviitteetApiFp(this.configuration).getSuorituspolut(opsId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SisaltoviitteetApi
     */
    public getSuorituspolutRakenteella(opsId: number, ktId: string, body?: number, options?: any) {
        return SisaltoviitteetApiFp(this.configuration).getSuorituspolutRakenteella(opsId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SisaltoviitteetApi
     */
    public getTutkinnonosat(opsId: number, ktId: string, body?: number, options?: any) {
        return SisaltoviitteetApiFp(this.configuration).getTutkinnonosat(opsId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SisaltoviitteetApi
     */
    public getTutkinnonosatKevyt(opsId: number, ktId: string, body?: number, options?: any) {
        return SisaltoviitteetApiFp(this.configuration).getTutkinnonosatKevyt(opsId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SisaltoviitteetApi
     */
    public kopioiLinkattuSisalto(opsId: number, ktId: string, body?: number, options?: any) {
        return SisaltoviitteetApiFp(this.configuration).kopioiLinkattuSisalto(opsId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} ktId 
     * @param {Array<number>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SisaltoviitteetApi
     */
    public linkkaaUusiSisalto(opsId: number, ktId: string, body?: Array<number>, options?: any) {
        return SisaltoviitteetApiFp(this.configuration).linkkaaUusiSisalto(opsId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} svId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SisaltoviitteetApi
     */
    public removeSisaltoViite(opsId: number, svId: number, ktId: string, body?: number, options?: any) {
        return SisaltoviitteetApiFp(this.configuration).removeSisaltoViite(opsId, svId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} ktId 
     * @param {Array<number>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SisaltoviitteetApi
     */
    public removeSisaltoViitteet(opsId: number, ktId: string, body?: Array<number>, options?: any) {
        return SisaltoviitteetApiFp(this.configuration).removeSisaltoViitteet(opsId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} svId 
     * @param {string} ktId 
     * @param {SisaltoViiteRakenneDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SisaltoviitteetApi
     */
    public updateSisaltoViiteRakenne(opsId: number, svId: number, ktId: string, body?: SisaltoViiteRakenneDto, options?: any) {
        return SisaltoviitteetApiFp(this.configuration).updateSisaltoViiteRakenne(opsId, svId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} svId 
     * @param {string} ktId 
     * @param {SisaltoViiteDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SisaltoviitteetApi
     */
    public updateTekstiKappaleViite(opsId: number, svId: number, ktId: string, body?: SisaltoViiteDto, options?: any) {
        return SisaltoviitteetApiFp(this.configuration).updateTekstiKappaleViite(opsId, svId, ktId, body, options)(this.axios, this.basePath);
    }

}


/**
 * TutkinnonosaApi - axios parameter creator
 * @export
 */
export const TutkinnonosaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        haeOletusTutkinnonosaToteutukset(opsId: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling haeOletusTutkinnonosaToteutukset.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling haeOletusTutkinnonosaToteutukset.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/tutkinnonosat/oletustoteutukset`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        haeTutkinnonosa(opsId: number, id: number, ktId: string, body?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling haeTutkinnonosa.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling haeTutkinnonosa.');
            }
            // verify required parameter 'ktId' is not null or undefined
            if (ktId === null || ktId === undefined) {
                throw new RequiredError('ktId','Required parameter ktId was null or undefined when calling haeTutkinnonosa.');
            }
            const localVarPath = `/api/koulutustoimijat/{ktId}/opetussuunnitelmat/{opsId}/tutkinnonosat/viite/{id}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"ktId"}}`, encodeURIComponent(String(ktId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TutkinnonosaApi - functional programming interface
 * @export
 */
export const TutkinnonosaApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        haeOletusTutkinnonosaToteutukset(opsId: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OletusToteutusDto>> {
            const localVarAxiosArgs = TutkinnonosaApiAxiosParamCreator(configuration).haeOletusTutkinnonosaToteutukset(opsId, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        haeTutkinnonosa(opsId: number, id: number, ktId: string, body?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tutkinnonosa> {
            const localVarAxiosArgs = TutkinnonosaApiAxiosParamCreator(configuration).haeTutkinnonosa(opsId, id, ktId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TutkinnonosaApi - factory interface
 * @export
 */
export const TutkinnonosaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        haeOletusTutkinnonosaToteutukset(opsId: number, ktId: string, body?: number, options?: any): AxiosPromise<Array<OletusToteutusDto>> {
            return TutkinnonosaApiFp(configuration).haeOletusTutkinnonosaToteutukset(opsId, ktId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {string} ktId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        haeTutkinnonosa(opsId: number, id: number, ktId: string, body?: number, options?: any): AxiosPromise<Tutkinnonosa> {
            return TutkinnonosaApiFp(configuration).haeTutkinnonosa(opsId, id, ktId, body, options)(axios, basePath);
        },
    };
};

/**
 * TutkinnonosaApi - object-oriented interface
 * @export
 * @class TutkinnonosaApi
 * @extends {BaseAPI}
 */
export class TutkinnonosaApi extends BaseAPI {
    /**
     * 
     * @param {number} opsId 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutkinnonosaApi
     */
    public haeOletusTutkinnonosaToteutukset(opsId: number, ktId: string, body?: number, options?: any) {
        return TutkinnonosaApiFp(this.configuration).haeOletusTutkinnonosaToteutukset(opsId, ktId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} id 
     * @param {string} ktId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutkinnonosaApi
     */
    public haeTutkinnonosa(opsId: number, id: number, ktId: string, body?: number, options?: any) {
        return TutkinnonosaApiFp(this.configuration).haeTutkinnonosa(opsId, id, ktId, body, options)(this.axios, this.basePath);
    }

}


/**
 * UlkopuolisetApi - axios parameter creator
 * @export
 */
export const UlkopuolisetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alarelaatio(koodi: string, options: any = {}): RequestArgs {
            // verify required parameter 'koodi' is not null or undefined
            if (koodi === null || koodi === undefined) {
                throw new RequiredError('koodi','Required parameter koodi was null or undefined when calling alarelaatio.');
            }
            const localVarPath = `/api/ulkopuoliset/koodisto/relaatio/sisaltyy-alakoodit/{koodi}`
                .replace(`{${"koodi"}}`, encodeURIComponent(String(koodi)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneerisetArvioinnit(options: any = {}): RequestArgs {
            const localVarPath = `/api/ulkopuoliset/geneerisetArvioinnit`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaistutPerusteet(options: any = {}): RequestArgs {
            const localVarPath = `/api/ulkopuoliset/julkaistutperusteet`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [koulutustyyppi] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaistutPerusteetKevyt(koulutustyyppi?: Array<string>, options: any = {}): RequestArgs {
            const localVarPath = `/api/ulkopuoliset/julkaistutperusteet/kevyt`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (koulutustyyppi) {
                localVarQueryParameter['koulutustyyppi'] = koulutustyyppi;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKayttajatiedot(oid: string, options: any = {}): RequestArgs {
            // verify required parameter 'oid' is not null or undefined
            if (oid === null || oid === undefined) {
                throw new RequiredError('oid','Required parameter oid was null or undefined when calling getKayttajatiedot.');
            }
            const localVarPath = `/api/ulkopuoliset/kayttajatiedot/{oid}`
                .replace(`{${"oid"}}`, encodeURIComponent(String(oid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [jalkeen] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTiedotteet(jalkeen?: number, options: any = {}): RequestArgs {
            const localVarPath = `/api/ulkopuoliset/tiedotteet`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (jalkeen !== undefined) {
                localVarQueryParameter['jalkeen'] = jalkeen;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary tiedotteiden haku
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {Array<string>} [kieli] tiedotteen kieli
         * @param {string} [nimi] hae nimellä
         * @param {number} [perusteId] hae perusteeseen liitetyt tiedotteet
         * @param {boolean} [perusteeton] hae perusteettomat tiedotteet
         * @param {boolean} [julkinen] hae julkiset tiedotteet
         * @param {boolean} [yleinen] hae yleiset tiedotteet
         * @param {Array<number>} [perusteIds] tiedotteen perusteiden
         * @param {Array<string>} [tiedoteJulkaisuPaikka] tiedotteen julkaisupaikat
         * @param {Array<string>} [koulutusTyyppi] tiedotteen koulutustyypit
         * @param {string} [jarjestys] tiedotteen jarjestys
         * @param {boolean} [jarjestysNouseva] tiedotteen jarjestyksen suunta
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTiedotteetHaku(sivu?: number, sivukoko?: number, kieli?: Array<string>, nimi?: string, perusteId?: number, perusteeton?: boolean, julkinen?: boolean, yleinen?: boolean, perusteIds?: Array<number>, tiedoteJulkaisuPaikka?: Array<string>, koulutusTyyppi?: Array<string>, jarjestys?: string, jarjestysNouseva?: boolean, options: any = {}): RequestArgs {
            const localVarPath = `/api/ulkopuoliset/tiedotteet/haku`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sivu !== undefined) {
                localVarQueryParameter['sivu'] = sivu;
            }

            if (sivukoko !== undefined) {
                localVarQueryParameter['sivukoko'] = sivukoko;
            }

            if (kieli) {
                localVarQueryParameter['kieli'] = kieli;
            }

            if (nimi !== undefined) {
                localVarQueryParameter['nimi'] = nimi;
            }

            if (perusteId !== undefined) {
                localVarQueryParameter['perusteId'] = perusteId;
            }

            if (perusteeton !== undefined) {
                localVarQueryParameter['perusteeton'] = perusteeton;
            }

            if (julkinen !== undefined) {
                localVarQueryParameter['julkinen'] = julkinen;
            }

            if (yleinen !== undefined) {
                localVarQueryParameter['yleinen'] = yleinen;
            }

            if (perusteIds) {
                localVarQueryParameter['perusteIds'] = perusteIds;
            }

            if (tiedoteJulkaisuPaikka) {
                localVarQueryParameter['tiedoteJulkaisuPaikka'] = tiedoteJulkaisuPaikka;
            }

            if (koulutusTyyppi) {
                localVarQueryParameter['koulutusTyyppi'] = koulutusTyyppi;
            }

            if (jarjestys !== undefined) {
                localVarQueryParameter['jarjestys'] = jarjestys;
            }

            if (jarjestysNouseva !== undefined) {
                localVarQueryParameter['jarjestysNouseva'] = jarjestysNouseva;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} koodisto 
         * @param {string} [haku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kaikki(koodisto: string, haku?: string, options: any = {}): RequestArgs {
            // verify required parameter 'koodisto' is not null or undefined
            if (koodisto === null || koodisto === undefined) {
                throw new RequiredError('koodisto','Required parameter koodisto was null or undefined when calling kaikki.');
            }
            const localVarPath = `/api/ulkopuoliset/koodisto/{koodisto}`
                .replace(`{${"koodisto"}}`, encodeURIComponent(String(koodisto)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (haku !== undefined) {
                localVarQueryParameter['haku'] = haku;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} koodisto 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        yksi(koodisto: string, koodi: string, options: any = {}): RequestArgs {
            // verify required parameter 'koodisto' is not null or undefined
            if (koodisto === null || koodisto === undefined) {
                throw new RequiredError('koodisto','Required parameter koodisto was null or undefined when calling yksi.');
            }
            // verify required parameter 'koodi' is not null or undefined
            if (koodi === null || koodi === undefined) {
                throw new RequiredError('koodi','Required parameter koodi was null or undefined when calling yksi.');
            }
            const localVarPath = `/api/ulkopuoliset/koodisto/{koodisto}/{koodi}`
                .replace(`{${"koodisto"}}`, encodeURIComponent(String(koodisto)))
                .replace(`{${"koodi"}}`, encodeURIComponent(String(koodi)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ylarelaatio(koodi: string, options: any = {}): RequestArgs {
            // verify required parameter 'koodi' is not null or undefined
            if (koodi === null || koodi === undefined) {
                throw new RequiredError('koodi','Required parameter koodi was null or undefined when calling ylarelaatio.');
            }
            const localVarPath = `/api/ulkopuoliset/koodisto/relaatio/sisaltyy-ylakoodit/{koodi}`
                .replace(`{${"koodi"}}`, encodeURIComponent(String(koodi)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UlkopuolisetApi - functional programming interface
 * @export
 */
export const UlkopuolisetApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alarelaatio(koodi: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KoodistoKoodiDto>> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).alarelaatio(koodi, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneerisetArvioinnit(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).getGeneerisetArvioinnit(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaistutPerusteet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PerusteDto>> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).getJulkaistutPerusteet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<string>} [koulutustyyppi] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaistutPerusteetKevyt(koulutustyyppi?: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PerusteKevytDto>> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).getJulkaistutPerusteetKevyt(koulutustyyppi, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} oid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKayttajatiedot(oid: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KayttajanTietoDto> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).getKayttajatiedot(oid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [jalkeen] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTiedotteet(jalkeen?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).getTiedotteet(jalkeen, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary tiedotteiden haku
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {Array<string>} [kieli] tiedotteen kieli
         * @param {string} [nimi] hae nimellä
         * @param {number} [perusteId] hae perusteeseen liitetyt tiedotteet
         * @param {boolean} [perusteeton] hae perusteettomat tiedotteet
         * @param {boolean} [julkinen] hae julkiset tiedotteet
         * @param {boolean} [yleinen] hae yleiset tiedotteet
         * @param {Array<number>} [perusteIds] tiedotteen perusteiden
         * @param {Array<string>} [tiedoteJulkaisuPaikka] tiedotteen julkaisupaikat
         * @param {Array<string>} [koulutusTyyppi] tiedotteen koulutustyypit
         * @param {string} [jarjestys] tiedotteen jarjestys
         * @param {boolean} [jarjestysNouseva] tiedotteen jarjestyksen suunta
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTiedotteetHaku(sivu?: number, sivukoko?: number, kieli?: Array<string>, nimi?: string, perusteId?: number, perusteeton?: boolean, julkinen?: boolean, yleinen?: boolean, perusteIds?: Array<number>, tiedoteJulkaisuPaikka?: Array<string>, koulutusTyyppi?: Array<string>, jarjestys?: string, jarjestysNouseva?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).getTiedotteetHaku(sivu, sivukoko, kieli, nimi, perusteId, perusteeton, julkinen, yleinen, perusteIds, tiedoteJulkaisuPaikka, koulutusTyyppi, jarjestys, jarjestysNouseva, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} koodisto 
         * @param {string} [haku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kaikki(koodisto: string, haku?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KoodistoKoodiDto>> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).kaikki(koodisto, haku, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} koodisto 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        yksi(koodisto: string, koodi: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KoodistoKoodiDto> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).yksi(koodisto, koodi, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ylarelaatio(koodi: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KoodistoKoodiDto>> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).ylarelaatio(koodi, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UlkopuolisetApi - factory interface
 * @export
 */
export const UlkopuolisetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alarelaatio(koodi: string, options?: any): AxiosPromise<Array<KoodistoKoodiDto>> {
            return UlkopuolisetApiFp(configuration).alarelaatio(koodi, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneerisetArvioinnit(options?: any): AxiosPromise<object> {
            return UlkopuolisetApiFp(configuration).getGeneerisetArvioinnit(options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaistutPerusteet(options?: any): AxiosPromise<Array<PerusteDto>> {
            return UlkopuolisetApiFp(configuration).getJulkaistutPerusteet(options)(axios, basePath);
        },
        /**
         * 
         * @param {Array<string>} [koulutustyyppi] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaistutPerusteetKevyt(koulutustyyppi?: Array<string>, options?: any): AxiosPromise<Array<PerusteKevytDto>> {
            return UlkopuolisetApiFp(configuration).getJulkaistutPerusteetKevyt(koulutustyyppi, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} oid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKayttajatiedot(oid: string, options?: any): AxiosPromise<KayttajanTietoDto> {
            return UlkopuolisetApiFp(configuration).getKayttajatiedot(oid, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} [jalkeen] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTiedotteet(jalkeen?: number, options?: any): AxiosPromise<object> {
            return UlkopuolisetApiFp(configuration).getTiedotteet(jalkeen, options)(axios, basePath);
        },
        /**
         * 
         * @summary tiedotteiden haku
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {Array<string>} [kieli] tiedotteen kieli
         * @param {string} [nimi] hae nimellä
         * @param {number} [perusteId] hae perusteeseen liitetyt tiedotteet
         * @param {boolean} [perusteeton] hae perusteettomat tiedotteet
         * @param {boolean} [julkinen] hae julkiset tiedotteet
         * @param {boolean} [yleinen] hae yleiset tiedotteet
         * @param {Array<number>} [perusteIds] tiedotteen perusteiden
         * @param {Array<string>} [tiedoteJulkaisuPaikka] tiedotteen julkaisupaikat
         * @param {Array<string>} [koulutusTyyppi] tiedotteen koulutustyypit
         * @param {string} [jarjestys] tiedotteen jarjestys
         * @param {boolean} [jarjestysNouseva] tiedotteen jarjestyksen suunta
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTiedotteetHaku(sivu?: number, sivukoko?: number, kieli?: Array<string>, nimi?: string, perusteId?: number, perusteeton?: boolean, julkinen?: boolean, yleinen?: boolean, perusteIds?: Array<number>, tiedoteJulkaisuPaikka?: Array<string>, koulutusTyyppi?: Array<string>, jarjestys?: string, jarjestysNouseva?: boolean, options?: any): AxiosPromise<object> {
            return UlkopuolisetApiFp(configuration).getTiedotteetHaku(sivu, sivukoko, kieli, nimi, perusteId, perusteeton, julkinen, yleinen, perusteIds, tiedoteJulkaisuPaikka, koulutusTyyppi, jarjestys, jarjestysNouseva, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} koodisto 
         * @param {string} [haku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kaikki(koodisto: string, haku?: string, options?: any): AxiosPromise<Array<KoodistoKoodiDto>> {
            return UlkopuolisetApiFp(configuration).kaikki(koodisto, haku, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} koodisto 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        yksi(koodisto: string, koodi: string, options?: any): AxiosPromise<KoodistoKoodiDto> {
            return UlkopuolisetApiFp(configuration).yksi(koodisto, koodi, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ylarelaatio(koodi: string, options?: any): AxiosPromise<Array<KoodistoKoodiDto>> {
            return UlkopuolisetApiFp(configuration).ylarelaatio(koodi, options)(axios, basePath);
        },
    };
};

/**
 * UlkopuolisetApi - object-oriented interface
 * @export
 * @class UlkopuolisetApi
 * @extends {BaseAPI}
 */
export class UlkopuolisetApi extends BaseAPI {
    /**
     * 
     * @param {string} koodi 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public alarelaatio(koodi: string, options?: any) {
        return UlkopuolisetApiFp(this.configuration).alarelaatio(koodi, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public getGeneerisetArvioinnit(options?: any) {
        return UlkopuolisetApiFp(this.configuration).getGeneerisetArvioinnit(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public getJulkaistutPerusteet(options?: any) {
        return UlkopuolisetApiFp(this.configuration).getJulkaistutPerusteet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {Array<string>} [koulutustyyppi] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public getJulkaistutPerusteetKevyt(koulutustyyppi?: Array<string>, options?: any) {
        return UlkopuolisetApiFp(this.configuration).getJulkaistutPerusteetKevyt(koulutustyyppi, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} oid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public getKayttajatiedot(oid: string, options?: any) {
        return UlkopuolisetApiFp(this.configuration).getKayttajatiedot(oid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} [jalkeen] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public getTiedotteet(jalkeen?: number, options?: any) {
        return UlkopuolisetApiFp(this.configuration).getTiedotteet(jalkeen, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary tiedotteiden haku
     * @param {number} [sivu] 
     * @param {number} [sivukoko] 
     * @param {Array<string>} [kieli] tiedotteen kieli
     * @param {string} [nimi] hae nimellä
     * @param {number} [perusteId] hae perusteeseen liitetyt tiedotteet
     * @param {boolean} [perusteeton] hae perusteettomat tiedotteet
     * @param {boolean} [julkinen] hae julkiset tiedotteet
     * @param {boolean} [yleinen] hae yleiset tiedotteet
     * @param {Array<number>} [perusteIds] tiedotteen perusteiden
     * @param {Array<string>} [tiedoteJulkaisuPaikka] tiedotteen julkaisupaikat
     * @param {Array<string>} [koulutusTyyppi] tiedotteen koulutustyypit
     * @param {string} [jarjestys] tiedotteen jarjestys
     * @param {boolean} [jarjestysNouseva] tiedotteen jarjestyksen suunta
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public getTiedotteetHaku(sivu?: number, sivukoko?: number, kieli?: Array<string>, nimi?: string, perusteId?: number, perusteeton?: boolean, julkinen?: boolean, yleinen?: boolean, perusteIds?: Array<number>, tiedoteJulkaisuPaikka?: Array<string>, koulutusTyyppi?: Array<string>, jarjestys?: string, jarjestysNouseva?: boolean, options?: any) {
        return UlkopuolisetApiFp(this.configuration).getTiedotteetHaku(sivu, sivukoko, kieli, nimi, perusteId, perusteeton, julkinen, yleinen, perusteIds, tiedoteJulkaisuPaikka, koulutusTyyppi, jarjestys, jarjestysNouseva, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} koodisto 
     * @param {string} [haku] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public kaikki(koodisto: string, haku?: string, options?: any) {
        return UlkopuolisetApiFp(this.configuration).kaikki(koodisto, haku, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} koodisto 
     * @param {string} koodi 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public yksi(koodisto: string, koodi: string, options?: any) {
        return UlkopuolisetApiFp(this.configuration).yksi(koodisto, koodi, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} koodi 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public ylarelaatio(koodi: string, options?: any) {
        return UlkopuolisetApiFp(this.configuration).ylarelaatio(koodi, options)(this.axios, this.basePath);
    }

}


