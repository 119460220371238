
import { Vue, Component, Prop } from 'vue-property-decorator';
import EpLinkki from '@shared/components/EpLinkki/EpLinkki.vue';

@Component({
  components: {
    EpLinkki,
  },
})
export default class InfoTile extends Vue {
  @Prop({ required: true })
  private header!: any;

  @Prop({ required: false })
  private text!: any;

  @Prop({ required: false })
  private translatedText!: any;

  @Prop({ required: false })
  private link!: any;

  @Prop({ required: false })
  private route!: any;

  @Prop({ required: true })
  private linkText!: any;

  get infoLink() {
    return this.$kaanna(this.link);
  }
}
