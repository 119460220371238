
import _ from 'lodash';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Kielet } from '@shared/stores/kieli';
import EpButton from '@shared/components/EpButton/EpButton.vue';
import PerusteRakenneOsa from '@/components/EpAmmatillinen/PerusteRakenneOsa.vue';
import EpSearch from '@shared/components/forms/EpSearch.vue';

@Component({
  components: {
    PerusteRakenneOsa,
    EpButton,
    EpSearch,
  },
})
export default class EpPerusteRakenne extends Vue {
  @Prop({ required: true })
  private rakenneOsat!: any[];

  private naytaRakenteet = false;
  private naytaKuvaukset = false;
  private query = '';

  get filteredRakenneOsat() {
    return this.filterRakenneOsat(this.rakenneOsat);
  }

  private filterRakenneOsat(osat: any[]) {
    return _.chain(osat)
      .map(osa => {
        return {
          ...osa,
          osat: this.filterRakenneOsat(osa.osat),
        };
      })
      .filter(osa => {
        let nimi = osa.tutkinnonosa?.tutkinnonOsa?.nimi || osa.tutkinnonosa?.nimi || osa.nimi || osa.tutkinnonosa?.perusteenTutkinnonosa?.nimi;
        return _.size(osa.osat) > 0 || (nimi && Kielet.search(this.query, nimi));
      })
      .map(osa => {
        return {
          ...osa,
          osat: _.size(osa.osat) > 0 ? osa.osat : this.flattenedRakenneOsat[osa.tunniste].osat,
        };
      })
      .value();
  }

  get flattenedRakenneOsat() {
    return _.keyBy(this.flattenRakenneOsat(this.rakenneOsat), 'tunniste');
  }

  private flattenRakenneOsat(osat: any[]) {
    return _.chain(osat)
      .map(osa => {
        return [
          osa,
          ...this.flattenRakenneOsat(osa.osat),
        ];
      })
      .flatMap()
      .value();
  }

  get rakenneOsaSuljeTeksti() {
    if (!this.naytaRakenteet) {
      return 'avaa-kaikki';
    }
    else {
      return 'sulje-kaikki';
    }
  }

  get rakenneOsaKuvasTeksti() {
    if (!this.naytaKuvaukset) {
      return 'nayta-ryhmien-kuvaukset';
    }
    else {
      return 'piilota-ryhmien-kuvaukset';
    }
  }

  toggleRakenne() {
    this.naytaRakenteet = !this.naytaRakenteet;
    _.forEach(this.$refs.rakenneosa, (rakenneosa: any) => rakenneosa.toggleRakenne(this.naytaRakenteet));
  }

  toggleKuvaukset() {
    this.naytaKuvaukset = !this.naytaKuvaukset;
    _.forEach(this.$refs.rakenneosa, (rakenneosa: any) => rakenneosa.toggleKuvaus(this.naytaKuvaukset));
  }

  get laajuustyyppi() {
    const laajuustyypit = _.chain(this.flattenRakenneOsat(this.rakenneOsat))
      .map(osa => {
        return {
          ..._.pickBy(osa.muodostumisSaanto, _.identity),
        };
      })
      .filter(muodostumisSaanto => !_.isEmpty(muodostumisSaanto))
      .map(muodostumisSaanto => _.keys(muodostumisSaanto))
      .flatMap()
      .uniq()
      .value();

    if (_.size(laajuustyypit) === 0 || _.size(laajuustyypit) > 1) {
      return '';
    }

    return _.includes(laajuustyypit, 'laajuus') ? this.$t('osaamispiste') : this.$t('kpl');
  }
}
