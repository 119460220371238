
import { PerusteDataStore } from '@/stores/PerusteDataStore';
import { PerusteenOsaStore } from '@/stores/PerusteenOsaStore';
import * as _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import EpContentViewer from '@shared/components/EpContentViewer/EpContentViewer.vue';
import EpCollapse from '@shared/components/EpCollapse/EpCollapse.vue';
import EpOsaAlue from '@shared/components/EpOsaamiskokonaisuus/EpOsaAlue.vue';
import EpMaterialIcon from '@shared/components/EpMaterialIcon/EpMaterialIcon.vue';

@Component({
  components: {
    EpSpinner,
    EpContentViewer,
    EpCollapse,
    EpOsaAlue,
    EpMaterialIcon,
  },
})
export default class RouteOsaamiskokonaisuusPaaAlue extends Vue {
  @Prop({ required: true })
  private perusteDataStore!: PerusteDataStore;

  @Prop({ required: true })
  private perusteenOsaStore!: PerusteenOsaStore;

  private selectedTasot: any[] = ['varhaiskasvatus', 'esiopetus', 'vuosiluokka_12', 'vuosiluokka_3456', 'vuosiluokka_789'];

  get current() {
    return this.perusteDataStore.current || null;
  }

  get perusteenOsa(): any {
    return this.perusteenOsaStore.perusteenOsa;
  }

  get termit() {
    return this.perusteDataStore.termit;
  }

  get kuvat() {
    return this.perusteDataStore.kuvat;
  }

  get currentRoute() {
    return this.perusteDataStore.currentRoute;
  }

  get osaAlueet() {
    return _.map(this.perusteenOsa.osaAlueet, osaAlue => {
      return {
        ...osaAlue,
        tasokuvaukset: _.filter(osaAlue.tasokuvaukset, tasokuvaus => this.selectedTaso(tasokuvaus.taso)),
      };
    });
  }

  get tasot() {
    return _.chain(this.perusteenOsa.osaAlueet)
      .map('tasokuvaukset')
      .flatMap()
      .map(tasokuvaus => _.toLower(tasokuvaus.taso))
      .uniq()
      .value();
  }

  selectedTaso(taso) {
    return _.find(this.selectedTasot, selected => selected === _.toLower(taso));
  }
}
