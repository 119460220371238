
import { Component, Prop, Vue } from 'vue-property-decorator';
import _ from 'lodash';
import { KoodistoSelectStore } from '../EpKoodistoSelect/KoodistoSelectStore';
import { Koodisto, TermiDto } from '@shared/api/eperusteet';
import draggable from 'vuedraggable';
import EpButton from '@shared/components/EpButton/EpButton.vue';
import EpKoodistoSelect from '@shared/components/EpKoodistoSelect/EpKoodistoSelect.vue';
import EpInput from '@shared/components/forms/EpInput.vue';
import { IKasiteHandler } from '../EpContent/KasiteHandler';
import { IKuvaHandler } from '../EpContent/KuvaHandler';
import EpContent from '@shared/components/EpContent/EpContent.vue';
import { LiiteDtoWrapper } from '@shared/tyypit';
import EpContentViewer from '@shared/components/EpContentViewer/EpContentViewer.vue';
import EpMaterialIcon from '@shared/components/EpMaterialIcon/EpMaterialIcon.vue';

enum TaitotasoTyyppi {
  opintokokonaisuus = 'opintokokonaisuus',
  kielitaitotaso = 'kielitaitotaso',
}

@Component({
  components: {
    EpKoodistoSelect,
    EpButton,
    draggable,
    EpInput,
    EpContent,
    EpContentViewer,
    EpMaterialIcon,
  },
})
export default class EpKotoTaitotasot extends Vue {
  @Prop({ required: true })
  private value!: any[];

  @Prop({ required: false, default: false, type: Boolean })
  private isEditing!: boolean;

  @Prop({ required: false })
  private kasiteHandler!: IKasiteHandler;

  @Prop({ required: false })
  private kuvaHandler!: IKuvaHandler;

  @Prop({ required: false })
  private taitotasoTyyppi!: TaitotasoTyyppi;

  @Prop({ required: false, type: Array })
  private termit!: TermiDto[];

  @Prop({ required: false, type: Array })
  private kuvat!: LiiteDtoWrapper[];

  get taitotasot() {
    return this.value;
  }

  get isOpintokokonaisuus() {
    return this.taitotasoTyyppi === TaitotasoTyyppi.opintokokonaisuus;
  }

  set taitotasot(value) {
    this.$emit('input', value);
  }

  private readonly koodisto = new KoodistoSelectStore({
    koodisto: 'kotoutumiskoulutustavoitteet',
    async query(query: string, sivu = 0, koodisto: string) {
      const { data } = (await Koodisto.kaikkiSivutettuna(koodisto, query, {
        params: {
          sivu,
          sivukoko: 10,
        },
      }));
      return data as any;
    },
  });

  lisaaTaitotaso() {
    this.taitotasot = [
      ...this.taitotasot,
      {},
    ];
  }

  poistaTaitotaso(taitotaso) {
    this.taitotasot = _.filter(this.taitotasot, rivi => rivi !== taitotaso);
  }

  get defaultDragOptions() {
    return {
      animation: 300,
      emptyInsertThreshold: 10,
      handle: '.order-handle',
      ghostClass: 'dragged',
      forceFallback: true,
    };
  }

  get taitotasotOptions() {
    return {
      ...this.defaultDragOptions,
      group: {
        name: 'taitotasot',
      },
    };
  }

  get tavoitteetOtsikko() {
    if (this.taitotasoTyyppi === TaitotasoTyyppi.kielitaitotaso) {
      return this.$t('tavoitteet');
    }

    return this.$t('tavoitteet-ja-sisallot');
  }

  taitotasoOtsikko(taitotaso) {
    if (this.taitotasoTyyppi === TaitotasoTyyppi.kielitaitotaso) {
      return this.$kaanna(taitotaso.nimi.nimi);
    }

    if (taitotaso.tyoelamaOpintoMinimiLaajuus || taitotaso.tyoelamaOpintoMaksimiLaajuus) {
      const laajuus = this.getLaajuusteksti(taitotaso.tyoelamaOpintoMinimiLaajuus, taitotaso.tyoelamaOpintoMaksimiLaajuus);
      return `${this.$kaanna(taitotaso.nimi.nimi)}, ${laajuus} ${this.$t('op')}`;
    }

    return this.$kaanna(taitotaso.nimi.nimi);
  }

  private getLaajuusteksti(minimi, maksimi) {
    if (!minimi) {
      return maksimi || '';
    }

    if (!maksimi) {
      return `${(this.$t('vahintaan'))} ${minimi}`;
    }

    return `${minimi} - ${maksimi}`;
  }

  get sisalto() {
    return this.tyyppiSisalto[this.taitotasoTyyppi];
  }

  get tyyppiSisalto() {
    return {
      [TaitotasoTyyppi.opintokokonaisuus]: {
        'koodisto-otsikko': 'opintokokonaisuuden-nimi',
        'lisaa-taitotaso': 'lisaa-opintokokonaisuus',
        'poista-taitotaso': 'poista-opintokokonaisuus',
        keskeisetsisallot: [
          {
            object: 'opiskelijanTyoelamataidot',
          },
        ],
      },
      [TaitotasoTyyppi.kielitaitotaso]: {
        'koodisto-otsikko': 'kielitaitotason-nimi',
        'lisaa-taitotaso': 'lisaa-kielitaitotaso',
        'poista-taitotaso': 'poista-kielitaitotaso',
        keskeisetsisallot: [
          {
            otsikko: 'suullinen-vastaanottaminen',
            object: 'suullinenVastaanottaminen',
          },
          {
            otsikko: 'suullinen-tuottaminen',
            object: 'suullinenTuottaminen',
          },
          {
            otsikko: 'vuorovaikutus-ja-mediaatio',
            object: 'vuorovaikutusJaMediaatio',
          },
        ],
      },
    };
  }

  get keskeisetSisallot() {
    return [
      {
        otsikko: 'kielenkayttotarkoitus',
        object: 'kielenkayttotarkoitus',
      },
      {
        otsikko: 'aihealueet',
        object: 'aihealueet',
      },
      {
        otsikko: 'viestintataidot',
        object: 'viestintataidot',
      },
      {
        otsikko: 'opiskelijan-taidot',
        object: 'opiskelijantaidot',
      },
      {
        object: 'opiskelijanTyoelamataidot',
      },
      {
        otsikko: 'suullinen-vastaanottaminen',
        object: 'suullinenVastaanottaminen',
      },
      {
        otsikko: 'suullinen-tuottaminen',
        object: 'suullinenTuottaminen',
      },
      {
        otsikko: 'vuorovaikutus-ja-mediaatio',
        object: 'vuorovaikutusJaMediaatio',
      },
    ];
  }
}
