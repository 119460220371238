
import _ from 'lodash';
import { Vue, Component, Prop } from 'vue-property-decorator';
import EpMaterialIcon from '@shared/components/EpMaterialIcon/EpMaterialIcon.vue';
import { JulkaistutKoulutustyypitStore } from '@/stores/JulkaistutKoulutustyypitStore';
import { TietoapalvelustaStore } from '@/stores/TietoapalvelustaStore';
import Sticky from 'vue-sticky-directive';
import { kansallisetOsaamismerkitRoute, koulutustyyppiLinks, navigoitavatKoulutustyyppiRyhmat, navigoitavatMuutRyhmat, ophMaarayksetRoute, otherLinks } from '@/utils/navigointi';
import { RawLocation, VueRouter } from 'vue-router/types/router';
import { Route } from 'vue-router';
import { Kielet } from '@shared/stores/kieli';
import logo from '@assets/img/banners/opintopolku/logo.svg';
import { createLogger } from '@shared/utils/logger';
import { digitaalinenOsaaminen } from '@shared/utils/perusteet';
import { OsaamismerkitStore } from '@/stores/OsaamismerkitStore';
const logger = createLogger('EpJulkinenSidenav');

@Component({
  directives: {
    Sticky,
  },
  components: {
    EpMaterialIcon,
  },
})
export default class EpJulkinenSidenav extends Vue {
  @Prop({ required: true })
  private julkaistutKoulutustyypitStore!: JulkaistutKoulutustyypitStore;

  @Prop({ required: true })
  private tietoapalvelustaStore!: TietoapalvelustaStore;

  @Prop({ required: true })
  private osaamismerkitStore!: OsaamismerkitStore;

  private active: boolean = false;

  get koulutustyyppiItems() {
    return navigoitavatKoulutustyyppiRyhmat(this.julkaistutKoulutustyypitStore.julkaistutKoulutustyypit.value as any);
  }

  get otherItems() {
    return navigoitavatMuutRyhmat(this.osaamismerkitStore.kategoriat.value as any, this.digitaalinenOsaaminenPeruste);
  }

  get muutLinkit() {
    return [
      ...(this.tietoapalvelusta ? [this.tietoapalvelusta] : []),
      ...otherLinks(),
    ];
  }

  get tietoapalvelusta() {
    return this.tietoapalvelustaStore.tietoapalvelusta.value;
  }

  get digitaalinenOsaaminenPeruste() {
    return _.first(this.julkaistutKoulutustyypitStore.digitaalinenOsaaminen.value);
  }

  get isActive() {
    return this.active;
  }

  set isActive(value) {
    this.active = value;
  }

  async valitseKieli(kieli) {
    const router: VueRouter = this.$router;
    const current: Route = router.currentRoute;

    try {
      await router.replace({
        name: current.name,
        params: {
          ...current.params,
          lang: kieli || this.$i18n.fallbackLocale,
        },
      } as RawLocation);
    }
    catch (e: any) {
      if (e.name === 'NavigationDuplicated') {
        logger.warn('Uusi kieli on sama kuin nykyinen');
      }
      else {
        throw e;
      }
    }
  }

  get sisaltoKieli() {
    return Kielet.getSisaltoKieli.value;
  }

  get kielet() {
    return [
      'fi',
      'sv',
      'en',
    ];
  }

  get navImage() {
    return logo;
  }

  get icon() {
    return this.isActive ? 'close' : 'menu';
  }

  closeSidebar() {
    this.active = false;
  }
}
