
import { SisaltoViiteExportDto } from '@shared/api/amosaa';
import * as _ from 'lodash';
import { Prop, Component, Vue } from 'vue-property-decorator';
import EpContentViewer from '@shared/components/EpContentViewer/EpContentViewer.vue';
import EpKotoTaitotasot from '@shared/components/EpKotoTaitotasot/EpKotoTaitotasot.vue';
import { ToteutussuunnitelmaDataStore } from '@/stores/ToteutussuunnitelmaDataStore';

@Component({
  components: {
    EpContentViewer,
    EpKotoTaitotasot,
  },
})
export default class EpToteutussuunnitelmaKotoOpintoSisalto extends Vue {
  @Prop({ required: true })
  private opetussuunnitelmaDataStore!: ToteutussuunnitelmaDataStore;

  @Prop({ required: true })
  private sisaltoviite!: SisaltoViiteExportDto;

  @Prop({ required: true })
  private kuvat!: any[];

  @Prop({ required: true })
  private sisaltoViiteSisalto!: 'kotoKielitaitotaso' | 'kotoOpinto';

  get taitotasoTyyppi() {
    return this.sisaltoViiteSisalto === 'kotoKielitaitotaso' ? 'kielitaitotaso' : 'opintokokonaisuus';
  }

  get perusteenOsa() {
    return this.sisaltoviite[this.sisaltoViiteSisalto]!.perusteenOsa;
  }

  get kotoTaitotasotByUri() {
    return _.keyBy(this.sisaltoviite[this.sisaltoViiteSisalto]!.taitotasot, 'koodiUri');
  }

  get laajaAlaisetOsaamiset() {
    return this.sisaltoviite[this.sisaltoViiteSisalto]!.laajaAlaisetOsaamiset;
  }

  get perusteenLaajaAlaisetOsaamiset() {
    return _.get(this.opetussuunnitelmaDataStore.getJulkaistuPerusteSisalto({ 'osanTyyppi': 'koto_laajaalainenosaaminen' }), 'osaamisAlueet');
  }

  get perusteenLaotByUri() {
    return _.keyBy(this.perusteenLaajaAlaisetOsaamiset, 'koodi.uri');
  }
}
