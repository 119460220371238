
import * as _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import TavoitteenSisaltoalueet from './TavoitteenSisaltoalueet.vue';

@Component({
  components: {
    TavoitteenSisaltoalueet,
  },
})
export default class OppiaineenVuosiluokkaTiivistetty extends Vue {
  @Prop({ required: true })
  private oppiaineJaTavoitteet!: any;

  get oppiaine() {
    return this.oppiaineJaTavoitteet.oppiaine;
  }

  get tavoitteet() {
    return _.map(this.oppiaineJaTavoitteet.vuosiluokka.tavoitteet, tavoite => {
      return {
        ...tavoite,
        tavoite: this.$kaanna(tavoite.tavoite)
          .replace('<p>', '')
          .replace('</p>', ''),
      };
    });
  }

  get sisaltoalueet() {
    return _.chain(this.tavoitteet)
      .map('sisaltoalueet')
      .flatten()
      .filter('nimi')
      .uniqBy('nimi')
      .value();
  }

  get tavoitealueet() {
    return _.chain(this.tavoitteet)
      .map('kohdealueet')
      .flatten()
      .uniqBy('nimi')
      .value();
  }

  get tavoitteetAlueilla() {
    if (_.size(this.tavoitealueet) > 0) {
      return [
        ..._.map(this.tavoitealueet, tavoitealue => {
          return {
            nimi: tavoitealue.nimi,
            tavoitteet: _.filter(this.tavoitteet, tavoite => _.find(tavoite.kohdealueet, { nimi: tavoitealue.nimi })),
          };
        }),
      ];
    }
    else {
      return [{ nimi: '', tavoitteet: this.tavoitteet }];
    }
  }

  selectOppiaine(oppiaine) {
    this.$emit('selectOppiaine', oppiaine);
  }
}
