
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  koulutustyyppiTheme,
  koulutustyyppiThemeColor,
  calculateVisibleColor,
  kouluturtyyppiRyhmat,
} from '@shared/utils/perusteet';
import { MurupolkuOsa } from '@/tyypit';
import EpMaterialIcon from '@shared/components/EpMaterialIcon/EpMaterialIcon.vue';
import _ from 'lodash';

@Component({
  components: {
    EpMaterialIcon,
  },
})
export default class EpHeader extends Vue {
  @Prop({ required: true })
  private murupolku!: MurupolkuOsa[];

  @Prop({ required: false, type: String })
  private koulutustyyppi!: string;

  @Prop({ required: false, type: String })
  private tyyppi!: string;

  get murupolkuFiltered() {
    return _.filter(this.murupolku, (muru) => muru.label && muru.type !== 'root');
  }

  get theme() {
    if (this.koulutustyyppi) {
      return 'koulutustyyppi-' + koulutustyyppiTheme(this.koulutustyyppi);
    }
    else if (this.routeKoulutustyyppi && _.includes(kouluturtyyppiRyhmat, this.routeKoulutustyyppi)) {
      return 'koulutustyyppi-' + this.routeKoulutustyyppi;
    }
    if (this.tyyppi) {
      return 'tyyppi-' + this.tyyppi;
    }
  }

  get routeKoulutustyyppi() {
    return this.$route?.params?.koulutustyyppi;
  }

  get bgColor() {
    return koulutustyyppiThemeColor(this.koulutustyyppi || this.tyyppi || this.routeKoulutustyyppi);
  }

  get textColor() {
    return calculateVisibleColor(this.bgColor, 125);
  }

  get isBlack() {
    return this.textColor === 'black';
  }

  get style() {
    return {
      color: this.isBlack ? '#001A58' : '#fff',
    };
  }
}
