
import _ from 'lodash';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import EpCollapse from '@shared/components/EpCollapse/EpCollapse.vue';
import EpButton from '@shared/components/EpButton/EpButton.vue';
import EpContentViewer from '@shared/components/EpContentViewer/EpContentViewer.vue';
import { PerusteDataStore } from '@/stores/PerusteDataStore';
import EpArvioinninkohteetTable from '@shared/components/EpArvioinninkohteetTable/EpArvioinninkohteetTable.vue';

@Component({
  components: {
    EpCollapse,
    EpButton,
    EpContentViewer,
    EpArvioinninkohteetTable,
  },
})
export default class RouteAipeOppiaine extends Vue {
  @Prop({ required: true })
  private perusteDataStore!: PerusteDataStore;

  get oppiaineId() {
    return _.toNumber(this.$route.params.oppiaineId);
  }

  get oppiaine() {
    return this.perusteDataStore.getJulkaistuPerusteSisalto({ id: this.oppiaineId });
  }

  get vaiheId() {
    return _.toNumber(this.$route.params.vaiheId);
  }

  get vaihe() {
    return this.perusteDataStore.getJulkaistuPerusteSisalto({ id: this.vaiheId });
  }

  get oppimaarat() {
    if (this.oppiaine.oppimaarat) {
      return _.map(this.oppiaine.oppimaarat, oppimaara => {
        return {
          ...oppimaara,
          route: { name: 'aipeoppiaine', params: { oppiaineId: _.toString(oppimaara.id) } },
        };
      });
    }
  }

  get kurssit() {
    if (this.oppiaine) {
      return _.map(this.oppiaine.kurssit, kurssi => {
        return {
          ...kurssi,
          route: { name: 'aipekurssi', params: { kurssiId: _.toString(kurssi.id) } },
        };
      });
    }
  }

  get tavoitteet() {
    if (this.oppiaine) {
      return _.map(this.oppiaine.tavoitteet, (tavoite: any) => {
        return {
          ...tavoite,
          kohdealue: this.kohdealueetById[_.head(tavoite.kohdealueet) as any],
          laajaalaisetosaamiset: _.map(tavoite.laajattavoitteet, lao => this.laajaAlaisetOsaamisetById[lao as any]),
          kohdeTeksti: this.arvioinninKohteenTeksti(tavoite),
        };
      });
    }
  }

  arvioinninKohteenTeksti(tavoite) {
    const hyvanOsaamisenArvio = _.find(tavoite.arvioinninkohteet, (arvioinninkohde: any) => arvioinninkohde.arvosana === 8);

    if (hyvanOsaamisenArvio && !_.isEmpty(hyvanOsaamisenArvio.arvioinninKohde)) {
      return hyvanOsaamisenArvio.arvioinninKohde;
    }

    return tavoite.arvioinninKuvaus;
  };

  get laajaAlaisetOsaamisetById() {
    return _.keyBy(this.perusteDataStore.getJulkaistuPerusteSisalto('aipe.laajaalaisetosaamiset'), 'id');
  }

  get kohdealueetById() {
    if (this.vaihe) {
      return _.keyBy(this.vaihe.opetuksenKohdealueet, 'id');
    }
    else {
      return {};
    }
  }

  get kurssi() {
    return this.$route.params.kurssiId;
  }

  get fields() {
    return [{
      key: 'nimi',
      thStyle: {
        display: 'none',
      },
    }];
  }

  toggleTavoite() {
    _.forEach(this.$refs.tavoitecollapse, (tavoite: any) => tavoite.toggle());
  }

  get kuvat() {
    return this.perusteDataStore.kuvat;
  }
}

