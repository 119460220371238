
import { Component, Vue, Prop } from 'vue-property-decorator';
import _ from 'lodash';
import { rakenneNodecolor } from '@shared/utils/perusterakenne';
import EpMaterialIcon from '@shared/components//EpMaterialIcon/EpMaterialIcon.vue';

@Component({
  components: {
    EpMaterialIcon,
  },
  name: 'PerusteRakenneOsa',
})
export default class PerusteRakenneOsa extends Vue {
  @Prop({ required: true })
  private rakenneosa: any;

  private naytaRakenne: boolean = false;
  private naytaKuvaus: boolean = false;

  @Prop({ required: false, default: false })
  private eiVanhempaa!: boolean;

  @Prop({ required: false, default: false })
  private viimeinen!: boolean;

  pakollinen() {
    return (this.rakenneosa.rooli === 'määritelty' && this.$kaanna(this.rakenneosa.nimi) === this.$t('rakenne-moduuli-pakollinen')) || this.rakenneosa.pakollinen;
  }

  toggleKuvaus(naytaKuvaus) {
    if (naytaKuvaus) {
      this.naytaKuvaus = naytaKuvaus;
      this.$nextTick(() => {
        _.forEach(this.$refs.rakenneosa, (rakenneosa: any) => rakenneosa.toggleKuvaus(this.naytaKuvaus));
      });
    }
    else {
      this.naytaKuvaus = !this.naytaKuvaus;
    }
  }

  toggleRakenne(naytaRakenne) {
    if (naytaRakenne) {
      this.naytaRakenne = naytaRakenne;
      this.$nextTick(() => {
        _.forEach(this.$refs.rakenneosa, (rakenneosa: any) => rakenneosa.toggleRakenne(this.naytaRakenne));
      });
    }
    else {
      this.naytaRakenne = !this.naytaRakenne;
    }
  }

  get osat() {
    return _.chain(this.rakenneosa.osat)
      .map((osa: any) => {
        return {
          ...osa,
          pakollinen: this.pakollinen(),
        };
      })
      .value();
  }

  get laajuus() {
    if (this.rakenneosa.muodostumisSaanto) {
      const tyyppi = this.rakenneosa.muodostumisSaanto.laajuus || this.rakenneosa.muodostumisSaanto.koko;

      if (!_.isNil(tyyppi.minimi) && !_.isNil(tyyppi.maksimi) && tyyppi.minimi !== tyyppi.maksimi) {
        return tyyppi.minimi + ' - ' + tyyppi.maksimi;
      }

      return tyyppi.minimi || tyyppi.maksimi || '';
    }

    if (this.rakenneosa.tutkinnonosa?.perusteenTutkinnonosaViite?.laajuus) {
      return this.rakenneosa.tutkinnonosa.perusteenTutkinnonosaViite.laajuus;
    }

    if (this.rakenneosa.tutkinnonosa?.laajuus) {
      return this.rakenneosa.tutkinnonosa.laajuus;
    }

    if (this.rakenneosa.tutkinnonosa?.tosa?.omatutkinnonosa?.laajuus) {
      return this.rakenneosa.tutkinnonosa.tosa?.omatutkinnonosa?.laajuus;
    }
  }

  get nimi() {
    if (this.rakenneosa.tutkinnonosa) {
      return this.rakenneosa.tutkinnonosa.tutkinnonOsa?.nimi || this.rakenneosa.tutkinnonosa.nimi;
    }
    return this.rakenneosa.nimi;
  }

  get rakenneStyle() {
    return 'border-color: ' + rakenneNodecolor(this.rakenneosa, null, this);
  }
}
