
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class EpSpinner extends Vue {
  @Prop({ required: false, type: Boolean })
  private small!: boolean;

  @Prop({ required: false })
  private color!: string;

  get style() {
    if (this.color) {
      return { 'background-color': this.color + ' !important' };
    }
  }
}
