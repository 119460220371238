
import { Vue, Component, Prop } from 'vue-property-decorator';
import { PerusteDataStore } from '@/stores/PerusteDataStore';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import ModuuliEsitys from '@shared/components/EpOpintojaksonModuuli/ModuuliEsitys.vue';
import * as _ from 'lodash';

@Component({
  components: {
    EpSpinner,
    ModuuliEsitys,
  },
})
export default class RouteModuuli extends Vue {
  @Prop({ required: true })
  private perusteDataStore!: PerusteDataStore;

  get moduuliId() {
    return _.toNumber(this.$route.params.moduuliId);
  }

  get moduuli() {
    return this.perusteDataStore.getJulkaistuPerusteSisalto({ id: this.moduuliId }) as any;
  }

  get termit() {
    return this.perusteDataStore.termit;
  }

  get kuvat() {
    return this.perusteDataStore.kuvat;
  }

  get koodi() {
    if (this.moduuli) {
      return this.moduuli.koodi;
    }
  }
}
