
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import EpHeader from '@/components/EpHeader/EpHeader.vue';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import PerusteTile from './PerusteTile.vue';
import * as _ from 'lodash';
import { RawLocation } from 'vue-router';
import { TiedoteDto } from '@shared/api/eperusteet';
import EpJulkiLista from '@shared/components/EpJulkiLista/EpJulkiLista.vue';
import { AmmatillinenPerusteKoosteStore } from '@/stores/AmmatillinenPerusteKoosteStore';
import EpSearch from '@shared/components/forms/EpSearch.vue';
import OpetussuunnitelmaTile from './OpetussuunnitelmaTile.vue';
import { Meta } from '@shared/utils/decorators';
import { OpetussuunnitelmaDto } from '@shared/api/amosaa';
import EpBPagination from '@shared/components/EpBPagination/EpBPagination.vue';
import { murupolkuAmmatillinenRoot } from '@/utils/murupolku';

@Component({
  components: {
    EpSpinner,
    EpHeader,
    PerusteTile,
    EpJulkiLista,
    EpSearch,
    OpetussuunnitelmaTile,
    EpBPagination,
  },
})
export default class RouteKoosteAmmatillinen extends Vue {
  @Prop({ required: true })
  private ammatillinenPerusteKoosteStore!: AmmatillinenPerusteKoosteStore;

  private query = '';

  get koulutustyyppi() {
    return 'ammatillinen';
  }

  get murupolku() {
    return [
      murupolkuAmmatillinenRoot(this.koulutustyyppi),
      {
        label: this.peruste!.nimi!,
        location: {
          ...this.$route,
        } as RawLocation,
      },
    ];
  }

  get tiedotteet() {
    if (this.ammatillinenPerusteKoosteStore.tiedotteet.value) {
      return _.chain(this.ammatillinenPerusteKoosteStore.tiedotteet.value)
        .sortBy('luotu')
        .reverse()
        .value();
    }
  }

  get peruste() {
    if (this.ammatillinenPerusteKoosteStore.peruste.value) {
      return {
        ...this.ammatillinenPerusteKoosteStore.peruste.value,
        laajuus: _.get(this.ammatillinenPerusteKoosteStore.peruste.value, 'suoritustavat[0].rakenne.muodostumisSaanto.laajuus.minimi'),
      } as any;
    }
  }

  get perusteRoute() {
    return { name: 'peruste', params: { koulutustyyppi: 'ammatillinen', perusteId: _.toString(this.peruste!.id) } };
  }

  get opetussuunnitelmatPage() {
    return this.ammatillinenPerusteKoosteStore.opetussuunnitelmat.value;
  }

  get opetussuunnitelmat(): any {
    if (this.opetussuunnitelmatPage) {
      return _.map(this.opetussuunnitelmatPage.data, (opetussuunnitelma: OpetussuunnitelmaDto) => (
        {
          ...opetussuunnitelma,
          route: {
            name: 'toteutussuunnitelma',
            params: {
              toteutussuunnitelmaId: _.toString(opetussuunnitelma.id),
              koulutustyyppi: 'ammatillinen',
            },
          },
        }
      ));
    }
  }

  get page() {
    return this.opetussuunnitelmatPage!.sivu + 1;
  }

  set page(page) {
    this.fetch(this.query, page - 1);
  }

  get perPage() {
    return this.opetussuunnitelmatPage!.sivukoko;
  }

  @Watch('query')
  queryChange(val) {
    this.fetch(this.query);
  }

  fetch(nimi?, page?) {
    this.ammatillinenPerusteKoosteStore.fetchOpetussuunnitelmat({ nimi: nimi, sivu: page });
  }

  avaaTiedote(tiedote: TiedoteDto) {
    this.$router.push({
      name: 'uutinen',
      params: {
        tiedoteId: '' + tiedote.id,
      },
    });
  }

  @Meta
  getMetaInfo() {
    if (this.peruste) {
      return {
        title: (this as any).$kaanna(this.peruste.nimi),
      };
    }
  }
}
