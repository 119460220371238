
import _ from 'lodash';
import { Vue, Component, Prop } from 'vue-property-decorator';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import EpButton from '@shared/components/EpButton/EpButton.vue';
import PerusteRakenneOsa from '@/components/EpAmmatillinen/PerusteRakenneOsa.vue';
import { PerusteDataStore } from '@/stores/PerusteDataStore';
import EpSearch from '@shared/components/forms/EpSearch.vue';
import EpPerusteRakenne from '@/components/EpAmmatillinen/EpPerusteRakenne.vue';
import EpColorIndicator from '@shared/components/EpColorIndicator/EpColorIndicator.vue';

@Component({
  components: {
    EpSpinner,
    PerusteRakenneOsa,
    EpButton,
    EpSearch,
    EpPerusteRakenne,
    EpColorIndicator,
  },
})
export default class RouteRakenne extends Vue {
  @Prop({ required: true })
  private perusteDataStore!: PerusteDataStore;

  private naytaRakenteet = false;
  private naytaKuvaukset = false;
  private query = '';

  get peruste() {
    return this.perusteDataStore.peruste;
  }

  get rakenne(): any {
    return _.get(_.first(this.perusteDataStore.getJulkaistuPerusteSisalto('suoritustavat')), 'rakenne');
  }

  get rakenneOsat() {
    return this.setRakenneOsaKoodi(this.rakenne?.osat);
  }

  get perusteenTutkinnonosaViitteetById() {
    return _.keyBy(_.chain(this.perusteDataStore.getJulkaistuPerusteSisalto('suoritustavat'))
      .map(st => st.tutkinnonOsaViitteet)
      .flatMap()
      .value(), 'id');
  }

  get perusteenTutkinnonosatById() {
    return _.keyBy(this.perusteDataStore.getJulkaistuPerusteSisalto('tutkinnonOsat'), 'id');
  }

  setRakenneOsaKoodi(rakenneOsat) {
    return _.map(rakenneOsat, rakenneosa => {
      let tutkinnonosaviite;
      let tutkinnonOsa;
      if (_.get(rakenneosa, '_tutkinnonOsaViite')) {
        tutkinnonosaviite = this.perusteenTutkinnonosaViitteetById[_.get(rakenneosa, '_tutkinnonOsaViite')];
        tutkinnonOsa = this.perusteenTutkinnonosatById[_.get(tutkinnonosaviite, '_tutkinnonOsa')];
      }
      return {
        ...rakenneosa,
        koodiArvo: this.getRakenneosaKoodiArvo(rakenneosa),
        osat: this.setRakenneOsaKoodi(rakenneosa.osat),
        ...(tutkinnonosaviite && { tutkinnonosa: {
          ...tutkinnonosaviite,
          tutkinnonOsa,
        } }),
      };
    });
  }

  getRakenneosaKoodiArvo(rakenneosa) {
    if (rakenneosa.tutkintonimike?.arvo) {
      return rakenneosa.tutkintonimike?.arvo;
    }

    if (rakenneosa.tutkinnonosa?.tutkinnonOsa?.koodi?.arvo) {
      return rakenneosa.tutkinnonosa?.tutkinnonOsa?.koodi?.arvo;
    }

    if (rakenneosa.osaamisala?.osaamisalakoodiArvo) {
      return rakenneosa.osaamisala?.osaamisalakoodiArvo;
    }
  }

  get laajuus() {
    if (this.rakenne.muodostumisSaanto && this.rakenne.muodostumisSaanto.laajuus) {
      return this.rakenne.muodostumisSaanto.laajuus.maksimi;
    }
  }

  get rakenneOsaSuljeTeksti() {
    if (!this.naytaRakenteet) {
      return 'avaa-kaikki';
    }
    else {
      return 'sulje-kaikki';
    }
  }

  get rakenneOsaKuvasTeksti() {
    if (!this.naytaKuvaukset) {
      return 'nayta-ryhmien-kuvaukset';
    }
    else {
      return 'piilota-ryhmien-kuvaukset';
    }
  }

  toggleRakenne() {
    this.naytaRakenteet = !this.naytaRakenteet;
    _.forEach(this.$refs.rakenneosa, (rakenneosa: any) => rakenneosa.toggleRakenne(this.naytaRakenteet));
  }

  toggleKuvaukset() {
    this.naytaKuvaukset = !this.naytaKuvaukset;
    _.forEach(this.$refs.rakenneosa, (rakenneosa: any) => rakenneosa.toggleKuvaus(this.naytaKuvaukset));
  }
}
