
import _ from 'lodash';
import { Vue, Component, Prop } from 'vue-property-decorator';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import EpPreviousNextNavigation from '@/components/EpPreviousNextNavigation/EpPreviousNextNavigation.vue';
import { PerusteVuosiluokkakokonaisuusStore } from '@/stores/PerusteVuosiluokkakokonaisuusStore';
import { Kielet } from '@shared/stores/kieli';
import EpContentViewer from '@shared/components/EpContentViewer/EpContentViewer.vue';
import { PerusteDataStore } from '@/stores/PerusteDataStore';

@Component({
  components: {
    EpSpinner,
    EpContentViewer,
  },
})
export default class RouteVuosiluokkakokonaisuus extends Vue {
  @Prop({ required: true })
  private perusteDataStore!: PerusteDataStore;

  get oppiaine() {
    return this.$route.params.oppiaineId;
  }

  get vlkId() {
    return _.toNumber(this.$route.params.vlkId);
  }
  get laajaalaisetOsaamisetById() {
    return _.keyBy(this.laajaalaisetOsaamiset, 'id');
  }

  get laajaalaisetOsaamiset() {
    return this.perusteDataStore.getJulkaistuPerusteSisalto('perusopetus.laajaalaisetosaamiset') as any;
  }

  get vuosiluokkakokonaisuus() {
    let vuosiluokkakokonaisuus = this.perusteDataStore.getJulkaistuPerusteSisalto({ id: this.vlkId }) as any;
    return {
      ...vuosiluokkakokonaisuus,
      laajaalaisetOsaamiset: _.chain(vuosiluokkakokonaisuus.laajaalaisetOsaamiset)
        .map(lao => {
          return {
            ...lao,
            nimi: _.get(this.laajaalaisetOsaamisetById[_.get(lao, '_laajaalainenOsaaminen')], 'nimi'),
          };
        })
        .sortBy(lao => this.$kaanna(lao.nimi))
        .value(),
    };
  }

  hasContent(obj) {
    return obj?.teksti && _.get(obj, 'teksti')[Kielet.getSisaltoKieli.value];
  }

  get kuvat() {
    return this.perusteDataStore.kuvat;
  }
}
