
import { Vue, Component, Prop } from 'vue-property-decorator';
import _ from 'lodash';
import EpSpinner from '../EpSpinner/EpSpinner.vue';
import EpButton from '../EpButton/EpButton.vue';
import { onkoUusi } from '@shared/utils/tiedote';

export interface JulkiRivi {
  otsikko?: { [key: string]: string; } | string;
  uusi: boolean;
  muokattu?: Date;
  perusteNimi?: string;
  koulutustyyppi?: string;
}
@Component({
  components: {
    EpSpinner,
    EpButton,
  },
})
export default class EpJulkiLista extends Vue {
  @Prop({ required: true })
  private tiedot!: JulkiRivi[];

  @Prop({ required: false, default: null })
  private tietoMaara!: number;

  @Prop({ required: false, default: 'lisahaku' })
  private listausTyyppi!: 'sivutus' | 'lisahaku' | 'none';

  private naytettavaTietoMaara = 3;
  private sivu = 1;

  mounted() {
    if (this.tietoMaara) {
      this.naytettavaTietoMaara = this.tietoMaara;
    }
  }

  get hasClickEvent() {
    return this.$listeners && this.$listeners.avaaTieto;
  }

  get tiedotSize() {
    return _.size(this.tiedot);
  }

  get tiedotFiltered() {
    if (this.tiedot) {
      return _.chain(this.tiedot)
        .map((tieto: JulkiRivi) => {
          return {
            ...tieto,
            uusi: onkoUusi((tieto as any).luotu),
          } as JulkiRivi;
        })
        .filter((tieto, index) => this.listausTyyppi === 'lisahaku' || index >= (this.sivu - 1) * this.naytettavaTietoMaara)
        .take(this.naytettavaTietoMaara)
        .value();
    }
  }

  avaaTieto(tieto: JulkiRivi) {
    this.$emit('avaaTieto', tieto);
  }
}
