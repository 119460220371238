
import { Vue, Component, Prop } from 'vue-property-decorator';
import EpButton from '../EpButton/EpButton.vue';
import EpInput from './EpInput.vue';
import EpMaterialIcon from '@shared/components/EpMaterialIcon/EpMaterialIcon.vue';
import _ from 'lodash';

@Component({
  components: {
    EpButton,
    EpInput,
    EpMaterialIcon,
  },
})
export default class EpList extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  private isEditable!: boolean;

  @Prop({ required: true })
  private value!: any;

  @Prop({ default: 'lisaa-sisalto', type: String })
  private lisays!: string;

  @Prop({ required: true, type: String })
  private kentta!: string;

  get sanitized() {
    if (_.isArray(this.value)) {
      return this.value;
    }
    else {
      return [this.value];
    }
  }

  get internal() {
    return this.sanitized;
  }

  set internal(value: any) {
    this.$emit('input', value);
  }

  get options() {
    return {
      // handle: '.handle',
      animation: 300,
      disabled: false,
    };
  }

  lisaaRivi() {
    this.internal.push({ });
  }

  poista(idx) {
    this.internal = [
      ...this.internal.slice(0, idx),
      ...this.internal.slice(idx + 1),
    ];
  }
}
