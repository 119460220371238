import { render, staticRenderFns } from "./TuvaPaikalliset.vue?vue&type=template&id=d4dfbb80&scoped=true"
import script from "./TuvaPaikalliset.vue?vue&type=script&lang=ts"
export * from "./TuvaPaikalliset.vue?vue&type=script&lang=ts"
import style0 from "./TuvaPaikalliset.vue?vue&type=style&index=0&id=d4dfbb80&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d4dfbb80",
  null
  
)

export default component.exports