import { render, staticRenderFns } from "./EpInfoBanner.vue?vue&type=template&id=162624fc&scoped=true"
import script from "./EpInfoBanner.vue?vue&type=script&lang=ts"
export * from "./EpInfoBanner.vue?vue&type=script&lang=ts"
import style0 from "./EpInfoBanner.vue?vue&type=style&index=0&id=162624fc&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "162624fc",
  null
  
)

export default component.exports