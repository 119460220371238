
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class EpMaterialIcon extends Vue {
  @Prop({ required: false, default: '' })
  iconShape?: string;

  @Prop({ required: false })
  color?: string;

  @Prop({ required: false })
  background?: string;

  @Prop({ required: false })
  size?: string;

  get shape() {
    if (this.iconShape === 'outlined' || this.iconShape === 'rounded') {
      return '-' + this.iconShape;
    }
    else {
      // default = filled
      return '';
    }
  }
};
