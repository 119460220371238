
import { Vue, Component, Prop } from 'vue-property-decorator';
import EpToggle from '@shared/components/forms/EpToggle.vue';
import EpVoimassaoloFilter from '@shared/components/EpVoimassaoloFilter/EpVoimassaoloFilter.vue';

@Component({
  components: {
    EpToggle,
    EpVoimassaoloFilter,
  },
})
export default class EpSisaltotyyppiFilter extends Vue {
  @Prop({ required: true })
  private value!: any;

  get queryData() {
    return this.value;
  }

  set queryData(val) {
    this.$emit('input', val);
  }
}
