
import { Prop, Component, Vue } from 'vue-property-decorator';
import EpLaajuusInput from '@shared/components/forms/EpLaajuusInput.vue';
import EpAmmattitaitovaatimukset from '@shared/components/EpAmmattitaitovaatimukset/EpAmmattitaitovaatimukset.vue';

import _ from 'lodash';

@Component({
  components: {
    EpAmmattitaitovaatimukset,
    EpLaajuusInput,
  },
})
export default class Osaamistavoite extends Vue {
  @Prop({ required: true })
  value!: any;

  @Prop({ required: true })
  isValinnainen!: boolean;

  @Prop({ default: true })
  showLaajuus!: boolean;

  @Prop({ default: true })
  public showKoodiArvo!: boolean;

  get inner() {
    return this.value || {
      laajuus: 0,
      tavoitteet: {
      },
    };
  }

  set inner(v) {
    this.$emit('input', v);
  }

  get tavoitteet() {
    return this.inner.tavoitteet || null;
  }

  set tavoitteet(tavoitteet) {
    this.$emit('input', {
      ...this.inner,
      tavoitteet,
    });
  }

  @Prop({ default: false })
  isEditing!: boolean;

  @Prop({ required: false })
  validation!: any;
}
