
import { Vue, Component, Prop } from 'vue-property-decorator';
import EpVoimassaolo from '@shared/components/EpVoimassaolo/EpVoimassaolo.vue';
import EpCollapse from '@shared/components/EpCollapse/EpCollapse.vue';

@Component({
  components: {
    EpCollapse,
    EpVoimassaolo,
  },
})
export default class EpAmmatillinenTutkinnonosaItem extends Vue {
  @Prop({ required: true })
  private sisalto!: any;
}
