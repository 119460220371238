import { render, staticRenderFns } from "./RouteToteutussuunnitelmaSuorituspolut.vue?vue&type=template&id=641d2fae&scoped=true"
import script from "./RouteToteutussuunnitelmaSuorituspolut.vue?vue&type=script&lang=ts"
export * from "./RouteToteutussuunnitelmaSuorituspolut.vue?vue&type=script&lang=ts"
import style0 from "./RouteToteutussuunnitelmaSuorituspolut.vue?vue&type=style&index=0&id=641d2fae&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "641d2fae",
  null
  
)

export default component.exports