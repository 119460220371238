
import _ from 'lodash';
import { Vue, Component, Prop } from 'vue-property-decorator';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import { PerusopetusOppiaineStore } from '@/stores/PerusopetusOppiaineStore';
import { Kielet } from '@shared/stores/kieli';
import EpContentViewer from '@shared/components/EpContentViewer/EpContentViewer.vue';
import { PerusteDataStore } from '@/stores/PerusteDataStore';
import EpCollapse from '@shared/components/EpCollapse/EpCollapse.vue';
import EpButton from '@shared/components/EpButton/EpButton.vue';
import EpArvioinninkohteetTable from '@shared/components/EpArvioinninkohteetTable/EpArvioinninkohteetTable.vue';
import { LaajaalainenOsaaminenDto } from '@shared/api/eperusteet';

@Component({
  components: {
    EpSpinner,
    EpContentViewer,
    EpCollapse,
    EpButton,
    EpArvioinninkohteetTable,
  },
} as any)
export default class RoutePerusopetusOppiaine extends Vue {
  @Prop({ required: true })
  private perusteDataStore!: PerusteDataStore;

  private tabIndex = 0;

  mounted() {
    if (this.$route.params.vlkId && this.oppiaine) {
      const vlk = _.head(_.filter(this.oppiaine.vuosiluokkakokonaisuudet, vlk => _.toString(_.get(vlk, '_vuosiluokkaKokonaisuus')) === _.toString(this.$route.params.vlkId)));
      this.tabIndex = _.indexOf(this.oppiaine.vuosiluokkakokonaisuudet, vlk);
    }
  }

  get oppiaineId() {
    return _.toNumber(this.$route.params.oppiaineId);
  }

  get laajaalaisetOsaamiset() {
    return this.perusteDataStore.getJulkaistuPerusteSisalto('perusopetus.laajaalaisetosaamiset') as any;
  }

  get perusteenOppiaine() {
    return this.perusteDataStore.getJulkaistuPerusteSisalto({ id: this.oppiaineId }) as any;
  }

  get vuosiluokkakokonaisuudet() {
    return _.get(this.perusteenOppiaine, 'vuosiluokkakokonaisuudet');
  }

  get perusteenVuosiluokkakokonaisuudet() {
    return this.perusteDataStore.getJulkaistuPerusteSisalto('perusopetus.vuosiluokkakokonaisuudet') as any;
  }

  get oppiaine() {
    const vuosiluokkakokonaisuudetById = _.keyBy(this.perusteenVuosiluokkakokonaisuudet, 'id');
    const laajaalaisetOsaamiset = _.keyBy(this.laajaalaisetOsaamiset, 'id');
    return {
      ...this.perusteenOppiaine,
      vuosiluokkakokonaisuudet: _.chain(this.vuosiluokkakokonaisuudet)
        .map(vlk => {
          const vlkSisaltoalueetById = _.keyBy(vlk.sisaltoalueet, 'id');
          return {
            ...vlk,
            nimi: _.get(vuosiluokkakokonaisuudetById[_.get(vlk, '_vuosiluokkaKokonaisuus')], 'nimi'),
            vuosiluokat: _.get(vuosiluokkakokonaisuudetById[_.get(vlk, '_vuosiluokkaKokonaisuus')], 'vuosiluokat'),
            tavoitteet: _.map(vlk.tavoitteet, tavoite => {
              return {
                ...tavoite,
                laajattavoitteet: _.chain(tavoite.laajattavoitteet)
                  .map((laajatavoitet: string) => {
                    return laajaalaisetOsaamiset[laajatavoitet] as LaajaalainenOsaaminenDto;
                  })
                  .sortBy((ltavoite: any) => ltavoite.nimi[Kielet.getSisaltoKieli.value])
                  .value() as any,
                sisaltoalueet: _.chain(tavoite.sisaltoalueet)
                  .map((sisaltoalue: string) => vlkSisaltoalueetById[sisaltoalue])
                  .reject(_.isNil)
                  .sortBy((sisaltoalue: any) => sisaltoalue.nimi[Kielet.getSisaltoKieli.value])
                  .value() as any,
              };
            }),
          };
        })
        .sortBy('vuosiluokat')
        .value(),
    };
  }

  hasContent(obj) {
    return obj?.teksti && _.get(obj, 'teksti')[Kielet.getSisaltoKieli.value];
  }

  get oppimaaratFields() {
    return [{
      key: 'nimi',
      thStyle: {
        display: 'none',
      },
    }];
  }

  get kuvat() {
    return this.perusteDataStore.kuvat;
  }

  get termit() {
    return this.perusteDataStore.termit;
  }

  get oppimaarat() {
    return _.map(this.oppiaine!.oppimaarat, oppimaara => {
      return {
        ...oppimaara,
        route: {
          name: this.$route.params.vlkId ? 'vuosiluokanoppiaine' : 'perusopetusoppiaine',
          params: {
            oppiaineId: _.toString(oppimaara.id),
            ...(this.$route.params.vlkId && { vlkId: this.$route.params.vlkId }),
          },
        },
      };
    });
  }

  toggleTavoitteet() {
    _.forEach(this.$refs.tavoitecollapse, (tavoite: any) => tavoite.toggle());
  }

  get kohdealueetById() {
    if (this.oppiaine) {
      return _.keyBy(this.oppiaine.kohdealueet, 'id');
    }
    else {
      return {};
    }
  }
}
