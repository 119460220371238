
import _ from 'lodash';
import { Vue, Component, Prop } from 'vue-property-decorator';

import EpPrefixList from '@shared/components/EpPrefixList/EpPrefixList.vue';
import EpList from '@shared/components/forms/EpList.vue';

@Component({
  components: {
    EpPrefixList,
    EpList,
  },
})
export default class EpOpintojaksonKeskeisetSisallot extends Vue {
  @Prop({ required: true })
  private value!: any;

  @Prop({ required: false, default: false })
  private isEditing!: boolean;

  @Prop({ required: true })
  private moduulitMap!: any;

  @Prop({ required: false, default: true })
  private showEmptyAlert!: boolean;
}
