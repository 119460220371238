
import _ from 'lodash';
import { Vue, Component, Prop } from 'vue-property-decorator';
import draggable from 'vuedraggable';

import { Kielet } from '@shared/stores/kieli';

import EpButton from '@shared/components/EpButton/EpButton.vue';
import EpInput from '@shared/components/forms/EpInput.vue';

@Component({
  components: {
    draggable,
    EpButton,
    EpInput,
  },
})
export default class EpPrefixList extends Vue {
  @Prop({ default: false })
  private isEditable!: boolean;

  @Prop({ required: true })
  private value!: any;

  @Prop({ default: 'kohde' })
  private kohde!: string;

  @Prop({ default: 'arvot' })
  private arvot!: string;

  @Prop({ default: '' })
  private arvo!: string;

  get hasMultiple() {
    return _.isArray(this.sanitized);
  }

  get sanitized() {
    if (_.isArray(this.value)) {
      return this.value;
    }
    else {
      return [this.value];
    }
  }

  get kieli() {
    return Kielet.getSisaltoKieli.value;
  }

  get internal() {
    return _.map(this.sanitized, el => {
      const res = _.cloneDeep(el);

      // Piilotetaan kohteet, joita ei ole lokalisoitu jos kyseessä lokalisoitu teksti
      const kohde = res[this.kohde];
      if (kohde) {
        if (_.isObject(kohde) && !kohde[this.kieli]) {
          res[this.kohde] = undefined;
        }
      }

      // Piilotetaan arvot, joita ei ole lokalisoitu jos kyseessä lokalisoitu teksti
      const arvot = res[this.arvot];
      if (arvot && !_.isEmpty(arvot)) {
        const arvotFiltered: any[] = [];
        _.each(arvot, arvo => {
          if (_.isObject(arvo)) {
            if (arvo[this.kieli]) {
              arvotFiltered.push(arvo);
            }
          }
          else {
            arvotFiltered.push(arvo);
          }
        });
        res[this.arvot] = arvotFiltered;
      }

      return res;
    });
  }

  set internal(value: any) {
    this.$emit('input', value);
  }

  get options() {
    return {
      // handle: '.handle',
      animation: 300,
      disabled: false,
    };
  }

  private poistaIndeksi(arr: any[], alueIdx: number) {
    arr.splice(alueIdx, 1);
  }

  private lisaaAlue() {
    this.internal.push({
      nimi: {},
      [this.kohde]: {},
      [this.arvot]: [],
    });
  }

  private lisaaArvo(alue: any) {
    alue[this.arvot].push(this.arvot
      ? { [this.arvo]: {} }
      : {});
  }
}
