
import { Maaraykset, MaarayksetParams, MaaraysDto, MaaraysLiiteDtoTyyppiEnum, baseURL, MaaraysDtoLiittyyTyyppiEnum, Perusteet, PerusteDto, MaaraysDtoTilaEnum } from '@shared/api/eperusteet';
import * as _ from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import EpHeader from '@/components/EpHeader/EpHeader.vue';
import { Kielet } from '@shared/stores/kieli';
import maaraysDoc from '@assets/img/images/maarays_doc.svg';
import EpFormContent from '@shared/components/forms/EpFormContent.vue';
import EpVoimassaolo from '@shared/components/EpVoimassaolo/EpVoimassaolo.vue';
import EpContentViewer from '@shared/components/EpContentViewer/EpContentViewer.vue';
import { Meta } from '@shared/utils/decorators';
import EpMaterialIcon from '@shared/components//EpMaterialIcon/EpMaterialIcon.vue';
import EpMaarayskokoelmaKoulutustyyppiSelect from '@shared/components/EpMaarayskokoelmaKoulutustyyppiSelect/EpMaarayskokoelmaKoulutustyyppiSelect.vue';
import { koulutustyyppiTheme } from '@shared/utils/perusteet';
import { MaaraysKevytDtoTilaEnum } from '@shared/generated/eperusteet';

@Component({
  components: {
    EpSpinner,
    EpHeader,
    EpFormContent,
    EpVoimassaolo,
    EpContentViewer,
    EpMaterialIcon,
    EpMaarayskokoelmaKoulutustyyppiSelect,
  },
})
export default class RouteMaarays extends Vue {
  private maarays: MaaraysDto | null = null;
  private peruste: PerusteDto | null = null;

  @Watch('maaraysId', { immediate: true })
  async maaraysChange() {
    this.maarays = null;
    this.maarays = (await Maaraykset.getMaarays(this.maaraysId)).data;
    if (this.maarays.peruste) {
      this.peruste = (await Perusteet.getPerusteenTiedot(this.maarays.peruste.id!)).data;
    }
  }

  @Meta
  getMetaInfo() {
    return {
      title: this.maarays ? this.$kaanna(this.maarays.nimi) : this.$t('maarays'),
    };
  }

  get maaraysId() {
    return _.toNumber(this.$route.params.maaraysId);
  }

  get kieli() {
    return Kielet.getSisaltoKieli.value;
  }

  get kuva() {
    return maaraysDoc;
  }

  get murupolku() {
    return [
      {
        label: 'route-maarayskokoelma',
        location: {
          name: 'maaraykset',
        },
      },
      {
        label: 'tiedot',
        location: {
          name: 'maarays',
          params: {
            maaraysId: this.maaraysId,
          },
        },
      },
    ];
  }

  get liitteet() {
    return _.chain(this.kaikkiLiitteet)
      .filter(liite => liite.tyyppi !== MaaraysLiiteDtoTyyppiEnum.MAARAYSDOKUMENTTI)
      .map(liite => {
        return {
          ...liite,
          url: baseURL + MaarayksetParams.getMaaraysLiite(_.toString(liite.id)).url,
        };
      })
      .value();
  }

  get kaikkiLiitteet() {
    if (this.maarays?.liitteet) {
      return this.maarays?.liitteet[this.kieli].liitteet;
    }
  }

  get maaraysPdfUrl() {
    const maaraysLiite = _.find(this.kaikkiLiitteet, liite => liite.tyyppi === MaaraysLiiteDtoTyyppiEnum.MAARAYSDOKUMENTTI);
    if (maaraysLiite) {
      return baseURL + MaarayksetParams.getMaaraysLiite(_.toString(maaraysLiite.id)).url;
    }

    if (this.peruste && this.maarayskirje) {
      return `${baseURL}/api/perusteet/${this.peruste.id!}/liitteet/${this.maarayskirje.id}`;
    }
  }

  get maarayskirje() {
    if (this.peruste?.maarayskirje?.liitteet) {
      return this.peruste?.maarayskirje?.liitteet[this.kieli] || null;
    }
  }

  get liittyykoToiseenMaaraykseenOtsikko() {
    if (this.maarays?.liittyyTyyppi === MaaraysDtoLiittyyTyyppiEnum.EILIITY) {
      return null;
    }

    if (this.maarays?.liittyyTyyppi === MaaraysDtoLiittyyTyyppiEnum.MUUTTAA) {
      return 'muuttaa-maaraysta';
    }

    if (this.maarays?.liittyyTyyppi === MaaraysDtoLiittyyTyyppiEnum.KORVAA) {
      return 'korvaa-maarayksen';
    }
  }

  get perusteRoute() {
    if (this.peruste) {
      return {
        name: 'peruste',
        params: {
          koulutustyyppi: koulutustyyppiTheme(this.peruste.koulutustyyppi!),
          perusteId: _.toString(this.peruste.id),
        },
      };
    }
  }

  get korvaavatMuuttavatMaaraykset() {
    if (this.maarays) {
      return _.chain([
        ...(this.maarays.korvaavatMaaraykset || []),
        ...(this.maarays.muuttavatMaaraykset || []),
      ])
        .filter({ tila: MaaraysKevytDtoTilaEnum.JULKAISTU })
        .sortBy('voimassaoloAlkaa')
        .reverse()
        .value();
    }
  }

  get korvaavatMuuttavatFields() {
    return [{
      key: 'nimi',
      label: this.$t('nimi'),
      thStyle: { width: '60%' },
      thClass: 'border-bottom-1',
      tdClass: 'align-middle',
      sortable: false,
    }, {
      key: 'voimassaoloAlkaa',
      label: this.$t('voimassaolo-alkaa'),
      thClass: 'border-bottom-1',
      tdClass: 'align-middle',
      sortable: false,
      formatter: (value: any, key: any, item: any) => {
        return (this as any).$sd(value);
      },
    }];
  }
}
