
import { Vue, Component, Prop } from 'vue-property-decorator';
import EpHeader from '@/components/EpHeader/EpHeader.vue';
import _ from 'lodash';
import { OsaamismerkkiStore } from '@/stores/OsaamismerkkiStore';
import { Meta } from '@shared/utils/decorators';
import { murupolkuOsaamismerkkiTiedot } from '@/utils/murupolku';
import EpVoimassaolo from '@shared/components/EpVoimassaolo/EpVoimassaolo.vue';

@Component({
  components: {
    EpVoimassaolo,
    EpHeader,
  },
})
export default class RouteOsaamismerkkiTiedot extends Vue {
  @Prop({ required: true })
  private osaamismerkkiStore!: OsaamismerkkiStore;

  get koulutustyyppi() {
    return _.get(this.$route.params, 'koulutustyyppi') || 'vapaasivistystyo';
  }

  get osaamismerkki() {
    return this.osaamismerkkiStore.osaamismerkki.value;
  }

  get kategoria() {
    return this.osaamismerkki?.kategoria;
  }

  get imageUrl() {
    return this.kategoria ? 'data:' + this.kategoria.liite?.mime + ';base64,' + this.kategoria.liite?.binarydata : null;
  }

  get murupolku() {
    return murupolkuOsaamismerkkiTiedot(this.koulutustyyppi, this.osaamismerkki);
  }

  get isVanhentunut() {
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return this.osaamismerkki?.voimassaoloLoppuu && _.toNumber(this.osaamismerkki.voimassaoloLoppuu) < currentDate.getTime();
  }

  @Meta
  getMetaInfo() {
    return {
      title: this.$t('osaamismerkki'),
    };
  }
}
