
import { ToteutussuunnitelmaDataStore } from '@/stores/ToteutussuunnitelmaDataStore';
import * as _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import EpFormContent from '@shared/components/forms/EpFormContent.vue';
import EpToteutukset from '@/components/EpToteutussuunnitelma/EpToteutukset.vue';
import GeneerinenArviointiTaulukko from '@/components/EpAmmatillinen/GeneerinenArviointiTaulukko.vue';
import Osaamistavoite from '@shared/components/EpOsaamistavoite/Osaamistavoite.vue';
import Arviointi2020Taulukko from '@shared/components/EpTutkinnonosa/Arviointi2020Taulukko.vue';
import EpContentViewer from '@shared/components/EpContentViewer/EpContentViewer.vue';

@Component({
  components: {
    EpContentViewer,
    EpSpinner,
    EpFormContent,
    EpToteutukset,
    Osaamistavoite,
    GeneerinenArviointiTaulukko,
    Arviointi2020Taulukko,
  },
})
export default class RouteToteutussuunnitelmaOsaAlue extends Vue {
  @Prop({ required: true })
  opetussuunnitelmaDataStore!: ToteutussuunnitelmaDataStore;

  get osaalueId() {
    return _.toNumber(this.$route.params.osaalueId);
  }

  get sisaltoviiteId() {
    return _.toNumber(this.$route.params.sisaltoviiteId);
  }

  get osaalue() {
    return this.opetussuunnitelmaDataStore.getJulkaistuSisalto({ id: this.osaalueId });
  }

  get tutkinnonOsa() {
    return this.opetussuunnitelmaDataStore.getJulkaistuSisalto({ id: this.sisaltoviiteId });
  }

  get perusteOsaAlue() {
    return _.chain(this.opetussuunnitelmaDataStore.perusteidenTutkinnonOsat)
      .map(tosa => tosa.osaAlueet as any[])
      .flatten()
      .find(perusteenOsaAlue => perusteenOsaAlue.id === this.osaalue.perusteenOsaAlueId)
      .value();
  }

  get koodi() {
    return this.osaalue.perusteenOsaAlueKoodi?.split('_')[1].toUpperCase();
  }

  get kuvat() {
    return [
      ...this.opetussuunnitelmaDataStore.kuvat,
      ...this.opetussuunnitelmaDataStore.perusteKuvat,
    ];
  }

  get osaamistavoitteetNimi() {
    if (!this.perusteOsaAlue) {
      return this.$t('osaamistavoitteet') + ', ' + this.osaalue.laajuus + ' ' + this.$t('osaamispiste-lyhenne');
    }

    const nimi = this.osaalue.tyyppi === 'pakollinen'
      ? this.$t('pakolliset-osaamistavoitteet')
      : this.$t('valinnaiset-osaamistavoitteet');
    if (this.osaamistavoite?.laajuus) {
      const laajuusosa = ', ' + this.osaamistavoite.laajuus + ' ' + this.$t('osaamispiste-lyhenne');
      return nimi + laajuusosa;
    }
    else {
      return nimi;
    }
  }

  get osaamistavoite() {
    if (!this.perusteOsaAlue) {
      return { tavoitteet: this.osaalue.osaamistavoitteet };
    }
    else {
      if (this.perusteOsaAlue) {
        if (this.osaalue.tyyppi === 'pakollinen') {
          return this.perusteOsaAlue.pakollisetOsaamistavoitteet;
        }
        else if (this.osaalue.tyyppi === 'valinnainen') {
          return this.perusteOsaAlue.valinnaisetOsaamistavoitteet;
        }
      }
    }
  }

  get arviointi() {
    return this.perusteOsaAlue?.arviointi || this.osaalue.geneerinenArviointiasteikko;
  }
}
