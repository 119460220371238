
import _ from 'lodash';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Kielet } from '@shared/stores/kieli';
import EpHeader from '@/components/EpHeader/EpHeader.vue';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import EpSearch from '@shared/components/forms/EpSearch.vue';
import OpetussuunnitelmaTile from './OpetussuunnitelmaTile.vue';
import EpMultiSelect from '@shared/components/forms/EpMultiSelect.vue';
import EpToggle from '@shared/components/forms/EpToggle.vue';
import { YleisetPaikallisetStore } from '@/stores/YleisetPaikallisetStore';
import EpBPagination from '@shared/components/EpBPagination/EpBPagination.vue';
import { voimassaoloTieto } from '@/utils/voimassaolo';
import EpVoimassaoloFilter from '@shared/components/EpVoimassaoloFilter/EpVoimassaoloFilter.vue';

@Component({
  components: {
    EpHeader,
    EpSearch,
    EpSpinner,
    OpetussuunnitelmaTile,
    EpMultiSelect,
    EpBPagination,
    EpToggle,
    EpVoimassaoloFilter,
  },
})
export default class JotpaPaikalliset extends Vue {
  @Prop({ required: true })
  private paikallinenStore!: YleisetPaikallisetStore;

  private perPage = 10;
  private query = {
    nimi: null,
    sivu: 0,
    sivukoko: 10,
    kieli: this.kieli,
    jotpatyyppi: ['VST', 'MUU'],
    tuleva: true,
    voimassaolo: true,
    poistunut: false,
  };

  async mounted() {
    if (this.paikallinenStore) {
      await this.paikallinenStore.fetchQuery(this.query);
    }
  }

  get kieli() {
    return Kielet.getSisaltoKieli.value;
  }

  get page() {
    return this.opetussuunnitelmatPaged?.sivu! + 1;
  }

  set page(page) {
    this.query = {
      ...this.query,
      sivu: page - 1,
    };
  }

  get queryNimi() {
    return this.query.nimi;
  }

  @Watch('queryNimi')
  nimiChange() {
    this.query.sivu = 0;
  }

  @Watch('kieli')
  kieliChange(val) {
    this.query = {
      ...this.query,
      kieli: val,
    };
  }

  @Watch('query', { deep: true })
  async queryChange() {
    await this.paikallinenStore.fetchQuery(this.query);
  }

  get total() {
    return this.opetussuunnitelmatPaged?.kokonaismäärä;
  }

  get opetussuunnitelmat() {
    return this.paikallinenStore.opetussuunnitelmat.value;
  }

  get opetussuunnitelmatPaged() {
    return this.paikallinenStore.opetussuunnitelmatPaged.value;
  }

  get opetussuunnitelmatMapped() {
    return _.chain(this.opetussuunnitelmat)
      .map(ops => ({
        ...ops,
        route: {
          name: 'toteutussuunnitelma',
          params: {
            toteutussuunnitelmaId: _.toString(ops.id),
            koulutustyyppi: ops.jotpatyyppi === 'MUU' ? 'muukoulutus' : 'vapaasivistystyo',
          },
        },
        voimassaoloTieto: voimassaoloTieto(ops),
      }))
      .sortBy(ops => Kielet.sortValue(ops.nimi))
      .value();
  }
}
