
import _ from 'lodash';
import { Vue, Component, Prop } from 'vue-property-decorator';

import EpContent from '@shared/components/EpContent/EpContent.vue';

@Component({
  components: {
    EpContent,
  },
})
export default class EpOpintojaksonArviointi extends Vue {
  @Prop({ required: false })
  private opetussuunnitelmaStore!: any;

  @Prop({ required: true })
  private value!: any;

  @Prop({ required: false, default: false })
  private isEditing!: boolean;

  @Prop({ required: true })
  private opintojaksonOppiaineidenTiedot!: any;

  @Prop({ required: false, default: true })
  private showEmptyAlert!: boolean;

  @Prop({ required: false, default: true })
  private showPerustesisalto!: boolean;
}
