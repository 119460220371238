
import * as _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {

  },
})
export default class EpOsaAlueListaus extends Vue {
  @Prop()
  osaAlueet!: any[];

  @Prop()
  sisaltoviiteId!: number;

  get randomKey() {
    return (Math.random() + 1).toString(36).substring(7);
  }
}
