
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Matala, OpetussuunnitelmaDto, Sisaltoviitteet } from '@shared/api/amosaa';
import EpContentViewer from '@shared/components/EpContentViewer/EpContentViewer.vue';
import EpFormContent from '@shared/components/forms/EpFormContent.vue';
import _ from 'lodash';
import { ToteutussuunnitelmaDataStore } from '@/stores/ToteutussuunnitelmaDataStore';
import EpLinkki from '@shared/components/EpLinkki/EpLinkki.vue';
import { Kielet } from '@shared/stores/kieli';

@Component({
  components: {
    EpContentViewer,
    EpFormContent,
    EpLinkki,
  },
})
export default class EpToteutussuunnitelmaKoulutuksenOsa extends Vue {
  @Prop({ required: true })
  private sisaltoviite!: Matala;

  @Prop({ required: true })
  private kuvat!: any[];

  @Prop({ required: true })
  private opetussuunnitelma!: OpetussuunnitelmaDto;

  @Prop({ required: true })
  private opetussuunnitelmaDataStore!: ToteutussuunnitelmaDataStore;

  get laajaAlaisetKoodilla() {
    return _.keyBy(this.laajaAlaisetOsaamiset, 'tuvaLaajaAlainenOsaaminen.nimiKoodi');
  }

  get koulutuksenosa() {
    return this.sisaltoviite.koulutuksenosa;
  }

  get koulutuksenosaKoodi() {
    if (this.koulutuksenosa?.nimiKoodi) {
      return _.split(this.koulutuksenosa.nimiKoodi, '_')[1];
    }
  }

  get tavoitteet() {
    return [
      ...this.perusteenOsa?.tavoitteet!,
      ...(this.koulutuksenosa?.paikallinenTarkennus ? this.koulutuksenosa?.paikallinenTarkennus?.tavoitteet! : []),
    ];
  }

  get laajaAlaisetOsaamiset() {
    return this.opetussuunnitelmaDataStore.getJulkaistuSisaltoList({ 'tyyppi': 'laajaalainenosaaminen' });
  }

  get kieli() {
    return Kielet.getSisaltoKieli.value;
  }

  get perusteenOsa() {
    if (this.sisaltoviite.perusteenOsaId) {
      return this.opetussuunnitelmaDataStore.getJulkaistuPerusteSisalto({ id: this.sisaltoviite.perusteenOsaId });
    }
    else {
      return this.koulutuksenosa;
    }
  }
}
