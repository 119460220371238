
import { Vue, Component, Prop } from 'vue-property-decorator';
import _ from 'lodash';

@Component
export default class EpTiedostoInput extends Vue {
  @Prop({ required: false })
  private file!: any;

  @Prop({ required: true })
  private fileTypes!: string[];

  async onInput(file: File) {
    this.$emit('input', file);
  }

  get accept() {
    return _.join(this.fileTypes, ', ');
  }

  get fileSelected() {
    return !!this.file;
  }

  get placeholder() {
    return this.$t('fu-placeholder');
  }

  get dropPlaceholder() {
    return this.$t('fu-placeholder');
  }

  get browseText() {
    return this.$t('fu-browse-text');
  }

  resetFile() {
    if (!this.fileSelected) {
      (this as any).$refs['file-input'].reset();
    }
  }
}
