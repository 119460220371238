
import * as _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {

  },
})
export default class EpPdfLink extends Vue {
  @Prop({ required: true })
  url!: string;
}
