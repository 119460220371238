
import { Vue, Component, Prop } from 'vue-property-decorator';
import EpFormContent from './EpFormContent.vue';

@Component({
  components: {
    EpFormContent,
  },
})
export default class EpToggle extends Vue {
  @Prop({ default: true, type: Boolean })
  private isEditing!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  private value!: boolean;

  @Prop({ default: true, type: Boolean })
  private inline!: boolean;

  @Prop({ default: true })
  private isSWitch!: boolean;

  @Prop({ default: false, type: Boolean })
  private checkbox!: Boolean;

  @Prop({ required: false })
  private size!: string | undefined;

  get lgSize() {
    return this.size ? this.size === 'lg' : false;
  }

  get innerValue() {
    return this.value;
  }

  set innerValue(value) {
    this.$emit('input', value);
  }

  get asSwitch() {
    return !this.checkbox && this.isSWitch;
  }
}
