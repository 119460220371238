
import { Component, Prop, Vue } from 'vue-property-decorator';
import _ from 'lodash';
import { KoodistoSelectStore } from '../EpKoodistoSelect/KoodistoSelectStore';
import { Koodisto } from '@shared/api/eperusteet';
import draggable from 'vuedraggable';
import EpButton from '@shared/components/EpButton/EpButton.vue';
import EpKoodistoSelect from '@shared/components/EpKoodistoSelect/EpKoodistoSelect.vue';
import EpInput from '@shared/components/forms/EpInput.vue';
import { koodistoKoodiValidator } from '@shared/validators/required';
import { Validations } from 'vuelidate-property-decorators';
import { generateTemporaryKoodiUri } from '@shared/utils/koodi';
import EpMaterialIcon from '@shared/components/EpMaterialIcon/EpMaterialIcon.vue';

@Component({
  components: {
    EpKoodistoSelect,
    EpButton,
    draggable,
    EpInput,
    EpMaterialIcon,
  },
})
export default class EpTavoitealueTavoitteet extends Vue {
  @Prop({ required: true })
  private value!: any[];

  get tavoitteet() {
    return this.value;
  }

  set tavoitteet(value) {
    this.$emit('input', value);
  }

  @Validations()
    validations = {
      tavoitteet: {
        $each: {
          ...koodistoKoodiValidator(),
        },
      },
    };

  private readonly tavoitteetlukutaidotKoodisto = new KoodistoSelectStore({
    koodisto: 'tavoitteetlukutaidot',
    async query(query: string, sivu = 0, koodisto: string) {
      const { data } = (await Koodisto.kaikkiSivutettuna(koodisto, query, {
        params: {
          sivu,
          sivukoko: 10,
        },
      }));
      return data as any;
    },
  });

  lisaaTavoite() {
    this.tavoitteet = [
      ...this.tavoitteet,
      {
        ...(!this.$scopedSlots['default'] && { uri: generateTemporaryKoodiUri('tavoitteetlukutaidot') }),
      },
    ];
  }

  poistaTavoite(tavoite) {
    this.tavoitteet = _.filter(this.tavoitteet, rivi => rivi !== tavoite);
  }

  get defaultDragOptions() {
    return {
      animation: 300,
      emptyInsertThreshold: 10,
      handle: '.order-handle',
      ghostClass: 'dragged',
    };
  }

  get tavoitteetOptions() {
    return {
      ...this.defaultDragOptions,
      group: {
        name: 'tavoitteet',
      },
    };
  }
}
