
import { Component, Vue, Prop } from 'vue-property-decorator';
import EpFormContent from '@shared/components/forms/EpFormContent.vue';
import * as _ from 'lodash';

@Component({
  components: {
    EpFormContent,
  },
})
export default class EpAmmatillinenArvioinninKohdealueet extends Vue {
  @Prop({ required: true })
  private arvioinninKohdealueet!: any;

  @Prop({ required: false })
  private arviointiasteikot!: any[];

  get arvioinninKohdealueetFilled() {
    return _.map(this.arvioinninKohdealueet, arvKohdealue => {
      return {
        ...arvKohdealue,
        arvioinninKohteet: _.map(arvKohdealue.arvioinninKohteet, arvioinninKohde => {
          const osaamistasot = _.keyBy(this.getArviointiasteikko(arvioinninKohde).osaamistasot, 'id');
          return {
            ...arvioinninKohde,
            osaamistasonKriteerit: _.sortBy(_.map(arvioinninKohde.osaamistasonKriteerit, osaamistasonKriteeri => {
              return {
                ...osaamistasonKriteeri,
                osaamistaso: osaamistasot[osaamistasonKriteeri._osaamistaso],
              };
            }), '_osaamistaso'),
          };
        }),
      };
    });
  }

  getArviointiasteikko(arvioinninkohde) {
    if (arvioinninkohde._arviointiAsteikko || arvioinninkohde._arviointiasteikko) {
      const arviointiasteikkoId = arvioinninkohde._arviointiAsteikko || arvioinninkohde._arviointiasteikko;
      const arviointiAsteikko = _.keyBy(this.arviointiasteikot, 'id')[arviointiasteikkoId];

      return arviointiAsteikko;
    }

    return arvioinninkohde.arviointiasteikko;
  }

  get osaamistasonKriteeritFields() {
    return [{
      key: 'osaamistaso',
      thStyle: { width: '40%' },
    }, {
      key: 'kriteerit',
    }] as any[];
  }
}
