import { render, staticRenderFns } from "./RouteKoosteAmmatillinen.vue?vue&type=template&id=01651749&scoped=true"
import script from "./RouteKoosteAmmatillinen.vue?vue&type=script&lang=ts"
export * from "./RouteKoosteAmmatillinen.vue?vue&type=script&lang=ts"
import style0 from "./RouteKoosteAmmatillinen.vue?vue&type=style&index=0&id=01651749&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01651749",
  null
  
)

export default component.exports