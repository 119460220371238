
import { Prop, Component, Vue } from 'vue-property-decorator';
import EpSelect from '@shared/components/forms/EpSelect.vue';
import EpMultiSelect from '@shared/components/forms/EpMultiSelect.vue';
import EpInput from '@shared/components/forms/EpInput.vue';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import EpColorIndicator from '@shared/components/EpColorIndicator/EpColorIndicator.vue';
import { EperusteetKoulutustyypit, EperusteetKoulutustyyppiRyhmat } from '../../utils/perusteet';
import * as _ from 'lodash';
import EpButton from '@shared/components/EpButton/EpButton.vue';
import EpMaterialIcon from '@shared/components//EpMaterialIcon/EpMaterialIcon.vue';

@Component({
  components: {
    EpColorIndicator,
    EpInput,
    EpMultiSelect,
    EpSelect,
    EpSpinner,
    EpButton,
    EpMaterialIcon,
  },
})
export default class KoulutustyyppiSelect extends Vue {
  @Prop({ required: true })
  value!: string | string[];

  @Prop({ default: false })
  isEditing!: boolean;

  @Prop({ type: Boolean })
  required!: Boolean;

  @Prop({ default: false, type: Boolean })
  nocolor!: Boolean;

  @Prop({ default: () => EperusteetKoulutustyypit })
  koulutustyypit!: string[];

  @Prop({ default: () => EperusteetKoulutustyyppiRyhmat })
  koulutustyyppiryhmat!: any;

  @Prop({ default: () => [] })
  eiTuetutKoulutustyypit!: string[];

  @Prop({ default: false, type: Boolean })
  textWrap!: Boolean;

  identity(tr: any) {
    return _.toLower(this.$kaanna(tr.nimi));
  }

  get model() {
    return this.isMultiple ? this.toArrayValue : this.toValue;
  }

  get toArrayValue() {
    return _.chain(this.vaihtoehdot)
      .map(vaihtoehto => vaihtoehto.koulutustyypit)
      .flatMap()
      .filter(kt => _.includes(this.value, kt.koulutustyyppi))
      .value();
  }

  get toValue() {
    return _.chain(this.vaihtoehdot)
      .map(vaihtoehto => vaihtoehto.koulutustyypit)
      .flatMap()
      .find(kt => kt.koulutustyyppi === this.value)
      .value();
  }

  get asArray() {
    if (this.isMultiple) {
      return this.value;
    }

    return this.value ? [this.value] : [];
  }

  get isMultiple() {
    return _.isArray(this.value);
  }

  set model(value: any) {
    if (_.get(value, 'koulutustyyppi') === 'kaikki') {
      this.$emit('input', this.isMultiple ? [] : undefined);
    }
    else {
      if (!this.isMultiple) {
        this.$emit('input', _.get(value, 'koulutustyyppi'));
      }
      else {
        this.$emit('input', _.map(value, 'koulutustyyppi'));
      }
    }
  }

  get placeholder() {
    if (this.required) {
      return this.$t('valitse');
    }
    else {
      return this.$t('kaikki');
    }
  }

  get selectVaihtoehdot() {
    if (this.required) {
      return this.vaihtoehdot;
    }
    else {
      return this.kaikkiVaihtoehdot;
    }
  }

  get kaikkiVaihtoehdot() {
    return [
      {
        ryhma: 'kaikki',
        koulutustyypit: [{ koulutustyyppi: 'kaikki' }],
      },
      ...this.vaihtoehdot,
    ];
  }

  get vaihtoehdot() {
    return _.chain(this.koulutustyyppiryhmat)
      .keys()
      .map(ryhma => {
        return {
          ryhma,
          koulutustyypit: _.chain(this.koulutustyyppiryhmat[ryhma])
            .filter(koulutustyyppi => _.isEmpty(this.koulutustyypit) || _.includes(this.koulutustyypit, koulutustyyppi))
            .reject(koulutustyyppi => _.includes(this.eiTuetutKoulutustyypit, koulutustyyppi))
            .map(koulutustyyppi => ({ koulutustyyppi, nimi: this.$t(koulutustyyppi) }))
            .value(),
        };
      })
      .value();
  }

  remove(option) {
    const poistettava = _.get(option, 'koulutustyyppi');
    this.$emit('input', _.without(this.value, poistettava));
  }

  removeAll() {
    this.$emit('input', []);
  }

  sulje() {
    (this.$refs.koulutustyyppi_multiselect as any)?.sulje();
  }
}
