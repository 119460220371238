
import * as _ from 'lodash';
import VueScrollTo from 'vue-scrollto';
import { Vue, Component, Prop } from 'vue-property-decorator';

import { Lops2019OppiaineKaikkiDto, TermiDto, Lops2019OpintojaksoDto } from '@shared/api/ylops';
import { LiiteDtoWrapper } from '@shared/tyypit';
import EpColorIndicator from '@shared/components/EpColorIndicator/EpColorIndicator.vue';
import EpContentViewer from '@shared/components/EpContentViewer/EpContentViewer.vue';
import { NavigationNode } from '@shared/utils/NavigationBuilder';
import EpOpintojaksonModuuli from '@shared/components/EpOpintojaksonModuuli/EpOpintojaksonModuuli.vue';
import { koodiSorters } from '@shared/utils/perusteet';

@Component({
  components: {
    EpColorIndicator,
    EpContentViewer,
    EpOpintojaksonModuuli,
  },
})
export default class OppiaineEsitys extends Vue {
  @Prop({ required: false, default: true })
  private isPerusteView!: boolean;

  @Prop({ required: true })
  private oppiaine!: Lops2019OppiaineKaikkiDto;

  @Prop({ required: false, type: Array })
  private termit!: TermiDto[];

  @Prop({ required: false, type: Array })
  private kuvat!: LiiteDtoWrapper[];

  @Prop({ required: false, type: Array })
  private navOppimaarat!: NavigationNode[];

  @Prop({ required: false })
  private opintojaksot!: Lops2019OpintojaksoDto[];

  updated() {
    // Odotetaan myös alikomponenttien päivittymistä
    this.$nextTick(() => {
      if (this.$route && this.$route.hash && this.oppiaine) {
        if (this.$route.hash === '#moduulit' && this.hasModuulit) {
          VueScrollTo.scrollTo('#moduulit');
        }
        else if (this.$route.hash === '#oppimaarat' && this.hasOppimaarat) {
          VueScrollTo.scrollTo('#oppimaarat');
        }
      }
    });
  }

  get koodi() {
    if (this.oppiaine) {
      return this.oppiaine.koodi;
    }
  }

  get hasTehtava() {
    if (this.oppiaine) {
      return this.oppiaine.tehtava && this.oppiaine.tehtava.kuvaus;
    }
  }

  get hasLaajaAlaiset() {
    if (this.oppiaine) {
      return this.oppiaine.laajaAlaisetOsaamiset && this.oppiaine.laajaAlaisetOsaamiset.kuvaus;
    }
  }

  get tavoitteet() {
    if (this.oppiaine) {
      return this.oppiaine.tavoitteet;
    }
  }

  get hasTavoitteet() {
    if (this.tavoitteet) {
      return !_.isEmpty(this.tavoitteet) && !_.isEmpty(this.tavoitteet.tavoitealueet);
    }
  }

  get hasArviointi() {
    if (this.oppiaine) {
      return this.oppiaine.arviointi && this.oppiaine.arviointi.kuvaus;
    }
  }

  get hasOpiskeluymparistoTyotavat() {
    if (this.oppiaine) {
      return this.oppiaine.opiskeluymparistoTyotavat && this.oppiaine.opiskeluymparistoTyotavat.kuvaus;
    }
  }

  get moduulit() {
    if (this.oppiaine) {
      return _.filter(this.oppiaine.moduulit, moduuli => _.size(this.opintojaksojenModuuliUrit) === 0 || _.includes(this.opintojaksojenModuuliUrit, _.get(moduuli, 'koodi.uri')));
    }
  }

  get hasModuulit() {
    return !_.isEmpty(this.moduulit);
  }

  get opintojaksojenModuuliUrit() {
    return _.chain(this.opintojaksot)
      .map(opintojakso => opintojakso.moduulit)
      .flatMap()
      .map(moduuli => moduuli.koodiUri)
      .value() as any[];
  }

  get pakollisetModuulit() {
    return _.filter(this.moduulit, { pakollinen: true });
  }

  get pakollisetModuulitExtended() {
    if (this.pakollisetModuulit) {
      return _.map(this.pakollisetModuulit, moduuli => {
        return {
          ...moduuli,
          location: {
            name: this.isPerusteView ? 'lops2019moduuli' : 'lops2019OpetussuunnitelmaModuuli',
            params: { moduuliId: _.toString(moduuli.id) },
          },
          koodiLabel: _.get(moduuli, 'koodi.arvo'),
        };
      });
    }
  }

  get hasPakollisetModuulit() {
    return !_.isEmpty(this.pakollisetModuulit);
  }

  get valinnaisetModuulit() {
    return _.filter(this.moduulit, { pakollinen: false });
  }

  get valinnaisetModuulitExtended() {
    if (this.valinnaisetModuulit) {
      return _.map(this.valinnaisetModuulit, moduuli => {
        return {
          ...moduuli,
          location: {
            name: this.isPerusteView ? 'lops2019moduuli' : 'lops2019OpetussuunnitelmaModuuli',
            params: { moduuliId: _.toString(moduuli.id) },
          },
          koodiLabel: _.get(moduuli, 'koodi.arvo'),
        };
      });
    }
  }

  get hasValinnaisetModuulit() {
    return !_.isEmpty(this.valinnaisetModuulit);
  }

  get oppimaarat() {
    if (this.oppiaine) {
      return this.oppiaine.oppimaarat;
    }
  }

  get oppimaaratExtended() {
    if (!_.isEmpty(this.navOppimaarat)) {
      return _.map(this.navOppimaarat, oppimaara => ({
        ...oppimaara,
        nimi: oppimaara.label,
      }));
    }
    else if (this.oppimaarat) {
      return _.map(this.oppimaarat, oppimaara => {
        return {
          ...oppimaara,
          location: {
            name: this.isPerusteView ? 'lops2019oppiaine' : 'lops2019OpetussuunnitelmaOppiaine',
            params: { oppiaineId: _.toString(oppimaara.id) },
          },
        };
      });
    }
  }

  get hasOppimaarat() {
    return !_.isEmpty(this.oppimaarat);
  }

  get hasOpintojaksot() {
    return !_.isEmpty(this.opintojaksot);
  }

  get opintojaksotExtended() {
    if (this.opintojaksot) {
      return _.chain(this.opintojaksot)
        .map(oj => {
          const ojOm: any = _.find(oj.oppiaineet, { koodi: this.oppiaine!.koodi!.uri });
          return {
            ...oj,
            location: {
              name: 'lops2019OpetussuunnitelmaOpintojakso',
              params: { opintojaksoId: _.toString(oj.id) },
            },
            koodiLabel: _.get(oj, 'koodi'),
            jarjestys: ojOm.jarjestys,
          };
        })
        .sortBy('jarjestys', ...koodiSorters())
        .value();
    }
  }
}
